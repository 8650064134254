import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiResponse, PagableData } from '../../models/common';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { Vendor, ReqAddVendor, VendorContact, ReqAddVendorContact } from '../../models/assets-management/vendor';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { StatusEnum } from '../../models/common-enum';
@Injectable({
  providedIn: 'root'
})
export class VendorService {
  domain: string = environment.api.domain_assets;;
  uri = `vendor`;
  uri_sub = `vendor-contact`;
  constructor(private http: HttpClient, private authService: AuthService) {
  }
  getVendor(page?: number, limit?: number, short?: number, company_name?: string, hashtag?: string) {
    let params = new HttpParams();
    if (!_.isNil(page)) {
      params = params.append('page', page.toString());
    }
    if (!_.isNil(limit)) {
      params = params.append('limit', limit.toString());
    }
    if (!_.isNil(short)) {
      params = params.append('short', short.toString());
    }
    if (!_.isNil(company_name)) {
      params = params.append('company_name', company_name.toString());
    }
    if (!_.isNil(hashtag)) {
      params = params.append('hashtag', hashtag.toString());
    }
    const uri = `${this.uri}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<Vendor[]>>>(url, httpOptions);
    // let url = 'assets/data/owner.json';
    // return this.http.get<ApiResponse<PagableData<Vendor[]>>>(url);
  }
  getVendorById(id?: number) {
    const uri = `${this.uri}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<Vendor[]>>(url, httpOptions);
  }
  addVendor(req: ReqAddVendor): Observable<ApiResponse<any>> {
    const uri = `${this.uri}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }

  editVendor(req: ReqAddVendor, id?: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteVendor(id: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }

  deleteVendorEditStatus(req: ReqAddVendor, id?: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `${this.uri}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  getVendorContact(page?: number, limit?: number, short?: number, vendor_id?: number) {
    let params = new HttpParams();
    if (!_.isNil(page)) {
      params = params.append('page', page.toString());
    }
    if (!_.isNil(limit)) {
      params = params.append('limit', limit.toString());
    }
    if (!_.isNil(short)) {
      params = params.append('short', short.toString());
    }
    const uri = `${this.uri}/${vendor_id}/${this.uri_sub}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<VendorContact[]>>>(url, httpOptions);
    // let url = 'assets/data/owner.json';
    // return this.http.get<ApiResponse<PagableData<VendorContact[]>>>(url);
  }
  getVendorContactById(id?: number, vendor_id?: number) {
    const uri = `${this.uri}/${vendor_id}/${this.uri_sub}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<VendorContact[]>>(url, httpOptions);
  }
  addVendorContact(req: ReqAddVendorContact, vendor_id?: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${vendor_id}/${this.uri_sub}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }

  editVendorContact(req: ReqAddVendorContact, id?: number, vendor_id?: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${vendor_id}/${this.uri_sub}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }

  deleteVendorContact(id: number, vendor_id?: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${vendor_id}/${this.uri_sub}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }

  deleteVendorContactEditStatus(req: ReqAddVendorContact, id?: number, vendor_id?: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `${this.uri}/${vendor_id}/${this.uri_sub}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
}