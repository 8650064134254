export class  ReversibleLane {
    id? : number
    reversible_name? : string
    description? : string
    route_id? : number
    km? : number
    m? : number
    direction? : number
    latitude? : number
    longitude? : number
    province_id? : number
    district_id? : number
    sampling_day? : number
    sampling_night? : number
    idle_day? : number
    idle_night? : number
    sensor_in? : any
    sensor_out? : any
    direction_list? : any
    criteria? : number
    cal_period? : number
    st_time? : any
    en_time? : any
}
