import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MediaBehaviorType, SpecialTextType } from 'src/app/models/sign-control/enum';
import { MediaInfo, SignMediaInfo } from 'src/app/models/sign-control/media';

@Component({
  selector: 'app-sign-image-item',
  templateUrl: './sign-image-item.component.html',
  styleUrls: ['./sign-image-item.component.scss']
})
export class SignImageItemComponent implements OnInit, OnChanges {
  @Input() media: MediaInfo;
  @Input() sign_height: any;
  @Input() resize: any = 1;
  @Input() config_road: any[];
  @Input() ratio: any;
  @Input() rain: number;
  @Input() is_board: boolean;
  specialTextType = SpecialTextType;
  mediaBehaviorType = MediaBehaviorType;
  text_speed = 90;
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {

    if (this.media) {
      if (this.media.behavior_type == MediaBehaviorType.Special) {
        if (this.media.special_property) {
          if (this.media.special_property.text_items.length > 0) {
            this.media.special_property.text_items.forEach(item => {
              if (item.text_type != SpecialTextType.FreeText && !this.is_board)
                item.text_speed = this.text_speed;
              if (item.text_type != SpecialTextType.FreeText && this.config_road) {
                const config_lane = this.config_road.find(x => x.lane_no == item.lane);
                if(config_lane){
                  const config_class = config_lane.veh_class.find(x => x.class_no == item.class);
                  if (item.text_type == SpecialTextType.SpeedLimit) {
                    if (this.rain == 1) {
                      if (config_class.rain_speed != 0)
                        item.text_speed = parseInt(config_class.rain_speed);
                    } else {
                      if (config_class.normal_speed != 0)
                        item.text_speed = parseInt(config_class.normal_speed);
                    }
                  } else if (item.text_type == SpecialTextType.SpeedSuggest) {
                    if (this.rain == 1) {
                      if (config_class.rain_suggest_speed != 0)
                        item.text_speed = parseInt(config_class.rain_suggest_speed);
                    } else {
                      if (config_class.suggest_speed != 0)
                        item.text_speed = parseInt(config_class.suggest_speed);
                    }
                  }
                }
                
              }
            });
          }
        }
      }
    }
  }
  medie_sign_width(ratio: string, set_sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return set_sign_height * r;
    } else {
      return 0;
    }
  }
}
