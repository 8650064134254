export class NoteTypeInfo {
    id: number;
    created_datetime:  Date;
    lastupdated_datetime: Date;
    name:  string;
    status:  number;
    desc: string;
}
export class ReqAddNoteType {
    name: string;
    status: number;
    desc: string;
}