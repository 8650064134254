<div class="m-3 card">
    <div class="card-header table-header"><h5>ตำแหน่งจุดสำรวจ</h5></div>
    <div class="card-body blogDetail-BG">
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <p class="input-group-text">จุดสำรวจ
              <span class="red-txt"> *</span></p>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.survey">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">หมายเลขทางหลวง</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.route">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">ชื่อย่อ</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.short_name">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">ตอนควบคุม</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.control">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">ชื่อสายทาง</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.name">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">สำนักทางหลวงที่</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.head">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">แขวงการทาง</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.road_district">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">หมวดการทางที่</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.road_subdistrict">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">กม. จุดสำรวจ</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.km">
            <div class="input-group-prepend">
              <span class="input-group-text">+</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.m">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">พิกัดจุดสำรวจ (แบบทศนิยม)</span>
            </div>
            <div class="input-group-prepend">
              <span class="input-group-text">Latitude</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.latitude">
            <div class="input-group-prepend">
              <span class="input-group-text">Longtitude</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.longitude">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">แบบองศา-ลิปดา</span>
            </div>
            <div class="input-group-prepend">
              <span class="input-group-text">Latitude</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.lipda1_1">
            <div class="input-group-prepend">
              <span class="input-group-text">°</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.lipda1_2">
            <div class="input-group-prepend">
              <span class="input-group-text">′</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.lipda1_3">
            <div class="input-group-prepend">
              <span class="input-group-text">″</span>
            </div>
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Longtitude</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.lipda2_1">
            <div class="input-group-prepend">
              <span class="input-group-text">°</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.lipda2_2">
            <div class="input-group-prepend">
              <span class="input-group-text">′</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.lipda2_3">
            <div class="input-group-prepend">
              <span class="input-group-text">″</span>
            </div>
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">จุดสังเกตบริเวณติดตั้ง</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.landmark">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">จังหวัด</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.province">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">อำเภอ</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.district">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <p class="input-group-text">จำนวนช่องจรารจร <span class="red-txt"> *</span></p>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.num_lane">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">ลักษณะผิวทาง</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="data.surface_type">
        </div>
    </div>
    <div class="card-footer text-right">
        <button class="btn-outline-info btn mr-2" (click)="close()">ยกเลิก</button>
        <button class="btn-info btn" (click)="save()">บันทึก</button>
    </div>
</div>