import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NumberDirective } from './directives/numbers-only.directive';
import { NumericDirective } from './directives/numeric.directive';
import { AutoScrollDirective } from './directives/auto-scroll.directive';
import { SafePipe } from './pipe/safe.pipe';
import { LinkTimePipe } from './pipe/link-time.pipe';
import { CoverLaneTextPipe } from './pipe/coverlane.pipe';
import { SignDirectionToTextPipe } from './pipe/sign-direction.pipe';
import { MaManagementComponent } from './components/ma-management/ma-management.component';

import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ModalModule, BsModalRef  } from 'ngx-bootstrap/modal';
import { ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PlaylistItemComponent } from './components/playlist-item/playlist-item.component';
import { SignImageItemComponent } from './components/sign-image-item/sign-image-item.component';
import { DisplayRtspComponent } from './components/display-rtsp/display-rtsp.component';
import { DisplayFlvComponent } from './components/display-flv/display-flv.component';

import { SignSettingComponent } from './components/sign-control/sign-setting/sign-setting.component';
import { FavoriteComponent } from './components/sign-control/favorite/favorite.component';
import { BoardsignManagementComponent } from './components/sign-control/boardsign-management/boardsign-management.component';
import { BoardsignInfoComponent } from './components/sign-control/boardsign-info/boardsign-info.component';
import { FirmwareComponent } from './components/sign-control/firmware/firmware.component';
import { ChangeMediaPanelComponent } from './components/sign-control/change-media-panel/change-media-panel.component';
import { AtisImageComponent } from './components/sign-control/atis-image/atis-image.component';
import { MediaSelectorComponent } from './components/sign-control/media-selector/media-selector.component';
import { MediaSelectorAllComponent } from './components/sign-control/media-selector-all/media-selector-all.component';
import { FavoriteSelectorComponent } from './components/sign-control/favorite-selector/favorite-selector.component';

import { CctvDisplayFlvComponent } from './components/sign-control/cctv-display-flv/cctv-display-flv.component';
import { CctvDisplayImgComponent } from './components/sign-control/cctv-display-img/cctv-display-img.component';
import { CctvDisplayRtspComponent } from './components/sign-control/cctv-display-rtsp/cctv-display-rtsp.component';

import { NoCommaPipe } from './pipe/nocomma.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PaginationModule.forRoot(),
    ComboBoxModule,
    DropDownsModule,
    IntlModule,
    DateInputsModule,
    SweetAlert2Module,
    ModalModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
  ],
  declarations: [NumberDirective, NumericDirective, SafePipe, LinkTimePipe, AutoScrollDirective, CoverLaneTextPipe, SignDirectionToTextPipe, MaManagementComponent, PlaylistItemComponent, SignImageItemComponent, DisplayRtspComponent, DisplayFlvComponent, SignSettingComponent, FavoriteComponent, BoardsignManagementComponent, BoardsignInfoComponent, FirmwareComponent, ChangeMediaPanelComponent, AtisImageComponent, MediaSelectorComponent, MediaSelectorAllComponent, FavoriteSelectorComponent, CctvDisplayFlvComponent, CctvDisplayImgComponent, CctvDisplayRtspComponent, NoCommaPipe],
  exports: [NumberDirective, NumericDirective, SafePipe, LinkTimePipe, AutoScrollDirective, CoverLaneTextPipe, SignDirectionToTextPipe, MaManagementComponent, PlaylistItemComponent, SignImageItemComponent, DisplayRtspComponent, DisplayFlvComponent, SignSettingComponent, FavoriteComponent, BoardsignManagementComponent, BoardsignInfoComponent, FirmwareComponent, ChangeMediaPanelComponent, AtisImageComponent, MediaSelectorComponent, MediaSelectorAllComponent, FavoriteSelectorComponent, CctvDisplayFlvComponent, CctvDisplayImgComponent, CctvDisplayRtspComponent, NoCommaPipe],
  providers: [BsModalRef]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule
    };
  }
}
