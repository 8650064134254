import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiResponse, PagableData } from '../../models/common';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { NewsInfo, ReqAddNews } from '../../models/information/news';
import { StatusEnum } from '../../models/common-enum';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  domain: string;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.domain = environment.api.domain_information;
  }
  getNews(title?: string, status?: number, st_time?: string, en_time?: string, news_type_id?:number, hashtag?:string, page?: number, limit?: number, short?: number) {
    let params = new HttpParams();
    if (!_.isNil(page)) {
      params = params.append('page', page.toString());
    }
    if (!_.isNil(limit)) {
      params = params.append('limit', limit.toString());
    }
    if (!_.isNil(title)) {
      params = params.append('title', title.toString());
    }
    if (!_.isNil(st_time)) {
      params = params.append('st_time', st_time.toString());
    }
    if (!_.isNil(en_time)) {
      params = params.append('en_time', en_time.toString());
    }
    if (!_.isNil(news_type_id)) {
      params = params.append('news_type_id', news_type_id.toString());
    }
    if (!_.isNil(hashtag)) {
      params = params.append('hashtag', hashtag.toString());
    }
    if (!_.isNil(status)) {
      params = params.append('status', status.toString());
    }
    if (!_.isNil(short)) {
      params = params.append('short', short.toString());
    }
    const uri = `news`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<NewsInfo[]>>>(url, httpOptions);
    // let url = 'assets/data/news_type.json';
    // return this.http.get<ApiResponse<PagableData<NewsTypeInfo[]>>>(url);
  }
  getNewsById(news_id?: number) {
    const uri = `news/${news_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<NewsInfo[]>>(url, httpOptions);
  }
  addNews(req: ReqAddNews): Observable<ApiResponse<any>> {
    const uri = `news`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }

  editNews(req: ReqAddNews, news_id?: number): Observable<ApiResponse<any>> {
    const uri = `news/${news_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteNews(news_id: number): Observable<ApiResponse<any>> {
    const uri = `news/${news_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
  deleteNewsEditStatus(req: ReqAddNews, news_id?: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `news/${news_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
}
