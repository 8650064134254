import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MediaType, SpecialTextType } from 'src/app/models/sign-control/enum';
import { MediaInfo } from 'src/app/models/sign-control/media';
import { MediaService } from 'src/app/services/sign-control/media.service';
import { PlaylistService } from 'src/app/services/sign-control/playlist.service';
import { BoardsignInfo, SignInfo } from 'src/app/models/sign-control/boardsign';
import { StatusEnum } from 'src/app/models/common-enum';

@Component({
  selector: 'app-media-selector-all',
  templateUrl: './media-selector-all.component.html',
  styleUrls: ['./media-selector-all.component.scss']
})
export class MediaSelectorAllComponent implements OnInit, OnChanges {
  data_key = 'MEDIASELECTTAB';
  specialTextType = SpecialTextType;
  public mediaType = MediaType;
  isLoading = false;
  selectedMedia: MediaInfo;
  totalItems: number;
  countItems: number;
  @Input() img_w: number;
  @Input() img_h: number;
  @Input() sign_height: number;
  @Input() sign: any; //{sign_type_id: 5 ,dir: 0,id: 15,media: null,ratio: "5:2",sign_no: 0};
  @Input() board: BoardsignInfo;
  @Input() config_road: any;
  @Input() rain: any;
  @Input() is_board: any;
  @Output() onComponentClosed: EventEmitter<MediaInfo> = new EventEmitter<MediaInfo>();

  select_type_tab: number;
  imgList: MediaInfo[];
  gifList: MediaInfo[];
  playList: MediaInfo[];
  constructor(private mediaService: MediaService, private playlistService: PlaylistService) {
  }

  ngOnInit() {
    this.selectedMedia = null;
    let tab = localStorage.getItem(this.data_key);
    if (tab != null) {
      this.select_type_tab = +tab;
    } else {
      this.select_type_tab = this.mediaType.Image;
    }
  }
  get_sign_width(ratio: string, sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return sign_height * r;
    } else {
      return 0;
    }
  }
  async getImg(skip?: number, take?: number,) {
    this.isLoading = true;
    this.imgList = [];
    let sign_type = null;
    let ratio = null;
    if (this.sign) {
      sign_type = +this.sign.sign_type_id;
      ratio = this.sign.ratio;
    }
    this.mediaService.getImage(null, null, null, null, MediaType.Image, null, sign_type, StatusEnum.Active, this.board.id, this.sign.id, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.imgList = res.data.data;
          }
        }

        this.isLoading = false;
      },
      err => {
        console.log(JSON.stringify(err.statusText));
        this.isLoading = false;
      });
  }
  async getGif(skip?: number, take?: number) {
    this.isLoading = true;
    this.gifList = [];
    let sign_type = null;
    let ratio = null;
    if (this.sign) {
      sign_type = +this.sign.sign_type_id;
      ratio = this.sign.ratio;
    }
    this.mediaService.getImage(null, null, null, null, MediaType.Gif, null, sign_type, StatusEnum.Active, this.board.id, this.sign.id, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.gifList = res.data.data;
          }
        }
        this.isLoading = false;
      },
      err => {
        console.log(JSON.stringify(err.statusText));
        this.isLoading = false;
      });
  }
  async getPlaylist(skip?: number, take?: number) {
    this.isLoading = true;
    this.playList = [];
    let sign_type = null;
    let ratio = null;
    if (this.sign) {
      sign_type = +this.sign.sign_type_id;
      ratio = this.sign.ratio;
    }
    this.playlistService.getPlaylist(null, null, null, null, null, sign_type, StatusEnum.Active, this.board.id, this.sign.id, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.playList = res.data.data;
          }
        }
        this.isLoading = false;
      },
      err => {
        console.log(JSON.stringify(err.statusText));
        this.isLoading = false;
      });
  }
  select(media: MediaInfo) {
    this.selectedMedia = media;
    this.selectedMedia.media_type = this.select_type_tab;
  }

  changeTab(tab: number) {
    if (this.select_type_tab != tab) {
      this.select_type_tab = tab;
      if (this.select_type_tab == this.mediaType.Image) {
        this.getImg();
      } else if (this.select_type_tab == this.mediaType.Gif) {
        this.getGif();
      } else if (this.select_type_tab == this.mediaType.Playlist) {
        this.getPlaylist();
      }
      localStorage.setItem(this.data_key, JSON.stringify(this.select_type_tab));
    }
  }
  closeComponent(media: MediaInfo) {
    if (media) {
      this.onComponentClosed.emit(media);
    }
    else {
      this.onComponentClosed.emit(null);
    }
    this.selectedMedia = null;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedMedia = null;
    let tab = localStorage.getItem(this.data_key);
    if (tab != null) {
      this.select_type_tab = +tab;
    } else {
      this.select_type_tab = this.mediaType.Image;
    }
    if (this.select_type_tab == this.mediaType.Image) {
      this.getImg();
    } else if (this.select_type_tab == this.mediaType.Gif) {
      this.getGif();
    } else if (this.select_type_tab == this.mediaType.Playlist) {
      this.getPlaylist();
    }
  }

  getSignNameTextStyles(w?: number) {
    let styles = {
      'width': w + 'px',
      'max-width': w + 'px',
      'white-space': 'nowrap',
      'overflow': 'hidden',
      'text-overflow': 'ellipsis'
    };
    return styles;
  }

}