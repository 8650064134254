import { Component, OnInit, AfterViewInit, Pipe, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import * as _ from 'lodash';
import { interval } from 'rxjs';
import { PlotBand } from '@progress/kendo-angular-charts';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
@Component({
  selector: 'app-reversible-lane',
  templateUrl: './reversible-lane.component.html',
  styleUrls: ['./reversible-lane.component.scss']
})
export class ReversibleLaneComponent implements OnInit, OnDestroy {
  stationList: Array<any> = [];
  selected: any;
  selected2: any;
  selected_text: string;

  public allowCustom: boolean = false;

  public style: string = 'smooth';
  public dashType: string = 'dot';
  subscription: any;
  data_sta: any[] = [];
  total: any = []
  staList: any;
  sta: any;

  pcu: any = 0;
  pcu1 = 1;
  pcu0 = 0;
  data_normal_speed_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_reverse_speed_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_normal_flow_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_reverse_flow_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_normal_speed_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_reverse_speed_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_normal_flow_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_reverse_flow_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data = {
    normal_speed_in: 88,
    reverse_speed_in: 80,
    normal_flow_in: 758,
    reverse_flow_in: 700,
    normal_speed_out: 85,
    reverse_speed_out: 86,
    normal_flow_out: 987,
    reverse_flow_out: 877,
  }
  public categoryPlotBands: PlotBand[] = [{
    from: null,
    to: null,
    color: null,
    opacity: 0
  }];
  // markers_max= { background: "#ff6358"  };
  // markers_min= { background: "#ffd246"  };
  // markers_avg= { background: "#78d237"  };
  // markers_current= { background: "#28b4c8"  };
  markers= { size:3, background: null };
  // markers_max= { size:5, background: null };
  // markers_min= { size:5,  background: null };
  // markers_avg= { size:5, background: null };
  // markers_current= {size:5,  background: null };
  constructor(private dashboardService: DashboardService,
    private commonService: CommonService) { 
    this.commonService.activityLog(ActionEnum.Get, 'Dashboard ➡ Reversible Lane').subscribe(res => { }, error => { console.log(error); });
    }

  async ngOnInit() {
    this.staList = await this.getReversibleList();
    this.data_sta = this.staList;
    if (this.data_sta.length > 0)
      this.sta = this.data_sta[0];
    setTimeout(() => {
      this.getReversibleTimeseries();
    }, 2000);

    this.subscription = interval(60000).subscribe((x) => {
      this.getReversibleTimeseries();
    });
  }
  handleFilter(value) {
    if (value.length > 0) {
      this.data_sta = this.data_sta.filter((s) => s.station_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_sta = _.cloneDeep(this.staList);
    } else {
      // this.data_sta = [];
    }
  }
  handleValue(value) {
    if (value) {
      this.sta = value;
    } else {
      this.sta = null;
    }
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async getReversibleList() {
    return new Promise((reslove, reject) => {

      this.dashboardService.getReversibleList(null,null,null,null,null,null,1).subscribe(
        (res: any) => {
          if (res.code == 1) {
            reslove(res.data)
          }
        },
        err => {
          console.log(JSON.stringify(err.statusText));
          reslove(false)
        });
    });
  }
  search() {
    this.getReversibleTimeseries();
  }


  getData() {
    this.getReversibleTimeseries();
  }

  async getReversibleTimeseries() {
    if (this.sta) {
      let pcu1 = null;
      if (this.pcu)
        pcu1 = this.pcu;
      this.data_normal_speed_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data_reverse_speed_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data_normal_flow_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data_reverse_flow_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data_normal_speed_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data_reverse_speed_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data_normal_flow_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data_reverse_flow_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data = {
        normal_speed_in: null,
        reverse_speed_in: null,
        normal_flow_in: null,
        reverse_flow_in: null,
        normal_speed_out: null,
        reverse_speed_out: null,
        normal_flow_out: null,
        reverse_flow_out: null,
      }
      this.categoryPlotBands = [{
        from: null,
        to: null,
        color: null,
        opacity: 0
      }];
      this.dashboardService.getReversibleTimeseries(this.sta.id, pcu1).subscribe(
        res => {
          if (res.code == 1) {
            if (res.data) {
              if (res.data.in) {
                if (res.data.in.normal) {
                  this.data.normal_speed_in = res.data.in.normal.avg_speed;
                  this.data.normal_flow_in = res.data.in.normal.flow;
                  //this.data_normal_speed_in.categories = _.map(res.data.in.normal.avg_speed_graph, 'hour');
                  // let c = [];
                  // res.data.in.normal.avg_speed_graph.forEach(item => {
                  //   let s = item.hour.toString().split(":");
                  //   if(s.length>1){
                  //     if(s[1] == '00'){
                  //       c.push(parseInt(s[1]));
                  //     }else{
                  //       c.push(null);
                  //     }
                  //   }else{
                  //     c.push(item.hour);
                  //   }
                  // });
                  let current_index = res.data.in.normal.avg_speed_graph.findIndex(x => x.is_current == 1);
                  this.categoryPlotBands = [{
                    from: current_index,
                    to: current_index,
                    color: '#28b4c8',
                    opacity: 0.8
                  }];
                  this.data_normal_speed_in.categories = this.getHour(res.data.in.normal.avg_speed_graph);
                  this.data_normal_speed_in.current_bullet = [this.data_normal_speed_in.current[5], this.data_normal_speed_in.categories[5]];
                  this.data_normal_speed_in.current = _.map(res.data.in.normal.avg_speed_graph, 'current_value');
                  this.data_normal_speed_in.max = _.map(res.data.in.normal.avg_speed_graph, 'max_old_value');
                  this.data_normal_speed_in.avg = _.map(res.data.in.normal.avg_speed_graph, 'avg_old_value');
                  this.data_normal_speed_in.min = _.map(res.data.in.normal.avg_speed_graph, 'min_old_value');

                  //this.data_normal_flow_in.categories = _.map(res.data.in.normal.flow_graph, 'hour');
                  this.data_normal_flow_in.categories = this.getHour(res.data.in.normal.flow_graph);
                  this.data_normal_flow_in.current = _.map(res.data.in.normal.flow_graph, 'current_value');
                  this.data_normal_flow_in.max = _.map(res.data.in.normal.flow_graph, 'max_old_value');
                  this.data_normal_flow_in.avg = _.map(res.data.in.normal.flow_graph, 'avg_old_value');
                  this.data_normal_flow_in.min = _.map(res.data.in.normal.flow_graph, 'min_old_value');
                }
                if (res.data.in.reverse) {

                  this.data.reverse_speed_in = res.data.in.reverse.avg_speed;
                  this.data.reverse_flow_in = res.data.in.reverse.flow;
                  //this.data_reverse_speed_in.categories = _.map(res.data.in.reverse.avg_speed_graph, 'hour');
                  this.data_reverse_speed_in.categories = this.getHour(res.data.in.reverse.avg_speed_graph);
                  this.data_reverse_speed_in.current = _.map(res.data.in.reverse.avg_speed_graph, 'current_value');
                  this.data_reverse_speed_in.max = _.map(res.data.in.reverse.avg_speed_graph, 'max_old_value');
                  this.data_reverse_speed_in.avg = _.map(res.data.in.reverse.avg_speed_graph, 'avg_old_value');
                  this.data_reverse_speed_in.min = _.map(res.data.in.reverse.avg_speed_graph, 'min_old_value');

                  //this.data_reverse_flow_in.categories = _.map(res.data.in.reverse.flow_graph, 'hour');
                  this.data_reverse_flow_in.categories = this.getHour(res.data.in.reverse.flow_graph);
                  this.data_reverse_flow_in.current = _.map(res.data.in.reverse.flow_graph, 'current_value');
                  this.data_reverse_flow_in.max = _.map(res.data.in.reverse.flow_graph, 'max_old_value');
                  this.data_reverse_flow_in.avg = _.map(res.data.in.reverse.flow_graph, 'avg_old_value');
                  this.data_reverse_flow_in.min = _.map(res.data.in.reverse.flow_graph, 'min_old_value');
                }
              }
              if (res.data.out) {
                if (res.data.out.normal) {
                  this.data.normal_speed_out = res.data.out.normal.avg_speed;
                  this.data.normal_flow_out = res.data.out.normal.flow;
                  //this.data_normal_speed_out.categories = _.map(res.data.out.normal.avg_speed_graph, 'hour');
                  this.data_normal_speed_out.categories = this.getHour(res.data.out.normal.avg_speed_graph);
                  this.data_normal_speed_out.current = _.map(res.data.out.normal.avg_speed_graph, 'current_value');
                  this.data_normal_speed_out.max = _.map(res.data.out.normal.avg_speed_graph, 'max_old_value');
                  this.data_normal_speed_out.avg = _.map(res.data.out.normal.avg_speed_graph, 'avg_old_value');
                  this.data_normal_speed_out.min = _.map(res.data.out.normal.avg_speed_graph, 'min_old_value');

                  //this.data_normal_flow_out.categories = _.map(res.data.out.normal.flow_graph, 'hour');
                  this.data_normal_flow_out.categories = this.getHour(res.data.out.normal.flow_graph);
                  this.data_normal_flow_out.current = _.map(res.data.out.normal.flow_graph, 'current_value');
                  this.data_normal_flow_out.max = _.map(res.data.out.normal.flow_graph, 'max_old_value');
                  this.data_normal_flow_out.avg = _.map(res.data.out.normal.flow_graph, 'avg_old_value');
                  this.data_normal_flow_out.min = _.map(res.data.out.normal.flow_graph, 'min_old_value');
                }
                if (res.data.out.reverse) {
                  this.data.reverse_speed_out = res.data.out.reverse.avg_speed;
                  this.data.reverse_flow_out = res.data.out.reverse.flow;
                  //this.data_reverse_speed_out.categories = _.map(res.data.out.reverse.avg_speed_graph, 'hour');
                  this.data_reverse_speed_out.categories = this.getHour(res.data.out.reverse.avg_speed_graph);
                  this.data_reverse_speed_out.current = _.map(res.data.out.reverse.avg_speed_graph, 'current_value');
                  this.data_reverse_speed_out.max = _.map(res.data.out.reverse.avg_speed_graph, 'max_old_value');
                  this.data_reverse_speed_out.avg = _.map(res.data.out.reverse.avg_speed_graph, 'avg_old_value');
                  this.data_reverse_speed_out.min = _.map(res.data.out.reverse.avg_speed_graph, 'min_old_value');

                  //this.data_reverse_flow_out.categories = _.map(res.data.out.reverse.flow_graph, 'hour');
                  this.data_reverse_flow_out.categories = this.getHour(res.data.out.reverse.flow_graph);
                  this.data_reverse_flow_out.current = _.map(res.data.out.reverse.flow_graph, 'current_value');
                  this.data_reverse_flow_out.max = _.map(res.data.out.reverse.flow_graph, 'max_old_value');
                  this.data_reverse_flow_out.avg = _.map(res.data.out.reverse.flow_graph, 'avg_old_value');
                  this.data_reverse_flow_out.min = _.map(res.data.out.reverse.flow_graph, 'min_old_value');
                }
              }
            }

          }
        },
        err => {
          console.log(JSON.stringify(err.statusText));
        });
    }
  }

  getHour(list?: any) {
    let c = [];
    list.forEach(item => {
      let s = item.hour.toString().split(":");
      if (s.length > 1) {
        if (s[1] == '00') {
          c.push(parseInt(s[0]));
        } else {
          c.push('');
        }
      } else {
        c.push(item.hour);
      }
    });
    return c;
  }
}