<div class="login-bg"></div>
<div class="login-bg-overlay"></div>
<div class="login-body">
  <div class="login-box d-flex align-items-start pt-3">
    <div class="text-center">     
      <img class="mt-5" src="assets/img/logo2.png" width="180" height="auto">
      <div class="login-text mt-3 mb-1 text-dark font-3">
        <h3>เข้าสู่ระบบ</h3>
      </div>
      <div class="login-input-box">
        <input autocomplete="off" type="text" name=username placeholder="ชื่อผู้ใช้ (Username)"
          class="text-dark form-control w-100 mt-1 mb-1" [(ngModel)]="model.username"
          [ngModelOptions]="{standalone: true}" required />
        <input autocomplete="off" type="password" name=password placeholder="รหัสผ่าน (Password)"
          class="text-dark form-control w-100 mt-2 mb-2" [(ngModel)]="model.password"
          [ngModelOptions]="{standalone: true}" required (keyup.enter)="login()"/>
        <button type="button" class="btn-block btn btn-primary px-4" (click)="login()"><i *ngIf="isLoading" style="color:#FFF"
            class="fa fa-spinner fa-spin fa-fw" aria-hidden="true"></i><span *ngIf="!isLoading"
            class="font-2 text-white">เข้าสู่ระบบ</span></button>
      </div>
    </div>

  </div>
</div>
<swal #swalInfo confirmButtonColor="#e4a723" title="<div class='text-warning'>ไม่สำเร็จ</div>"
  html="<div class='text-light font-2'>{{resMessage}}</div>" type="warning"></swal>
