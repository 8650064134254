<ng-template #tolTemplate>
  <div class="m-2">
    <div class="m-1 d-flex justify-content-center" *ngFor="let pl of playlist; index as ii">
      <div>
        <div class="border border-primary" *ngIf="!pl?.boardsign_image?.path" [style.width.px]="widthpx"
          [style.height.px]="heightpx"></div>
        <!-- <img class="border border-primary" *ngIf="pl?.boardsign_image?.path" [src]="pl?.boardsign_image?.path"
          [style.width.px]="widthpx" [style.height.px]="heightpx" /> -->
        <div class="d-flex justify-content-center">
          <div [style.height.px]="heightpx" style="background-color: black;position: relative;"
            [style.width.px]="widthpx">
            <img class="border border-primary" *ngIf="pl?.boardsign_image?.path" [src]="pl?.boardsign_image?.path"
              width="100%" height="100%" style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;" />
            <div style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;"
              *ngFor="let item of pl?.boardsign_image?.special_property?.text_items;index as iii;"
              [ngClass]="(item?.is_center)?'d-flex align-items-center justify-content-center':''">
              <span *ngIf="item?.is_center && item?.text_type == specialTextType.FreeText"
                [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                [style.font-size.px]="item?.font_size * resize" [style.family]="item?.font_family">{{item?.text}}</span>
              <span *ngIf="!item?.is_center && item?.text_type == specialTextType.FreeText"
                [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                [style.font-size.px]="item?.font_size * resize" [style.family]="item?.font_family">{{item?.text}}</span>
              <span
                *ngIf="item?.is_center && (item?.text_type == specialTextType.SpeedLimit || item?.text_type == specialTextType.SpeedSuggest)"
                [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                [style.font-size.px]="item?.font_size * resize"
                [style.family]="item?.font_family">{{item?.text_speed}}</span>
              <span
                *ngIf="!item?.is_center && (item?.text_type == specialTextType.SpeedLimit || item?.text_type == specialTextType.SpeedSuggest)"
                [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                [style.font-size.px]="item?.font_size * resize"
                [style.family]="item?.font_family">{{item?.text_speed}}</span>
            </div>
          </div>
        </div>

        <div *ngIf="pl?.boardsign_image?.behavior_type == mediaBehaviorType.Special">
          <div class="mt-1 text-danger center small">Special Item</div>
          <div class="mt-1 mb-1" *ngFor="let item of pl?.boardsign_image?.special_property?.text_items;index as iiii;">
            <div class="center small" *ngIf="item?.text_type == specialTextType.FreeText">
              <span>{{item?.text}} = Free Text</span>
            </div>
            <div class="center small" *ngIf="item?.text_type == specialTextType.SpeedLimit">
              <span>{{item?.text_speed}} = Speed Limit</span>
            </div>
            <div class="center small" *ngIf="item?.text_type == specialTextType.SpeedSuggest">
              <span>{{item?.text_speed}} = Speed Suggest</span>
            </div>
          </div>
        </div>
        <div *ngIf="pl?.boardsign_image?.behavior_type == mediaBehaviorType.Dynamic">
          <div class="mt-1 text-danger center small">Dynamic Image</div>
        </div>
        <div class="center small">{{pl?.duration}} วินาที </div>
      </div>
    </div>
  </div>

</ng-template>
<div [style.width.px]="widthpx" [style.height.px]="heightpx" style="top:0;left:0;position: relative;"
  class="bg-gray-600" oncontextmenu="return false;">
  <img *ngIf="src" [src]="src" width="100%" height="100%" style="position: absolute;top:0;left:0;"
    oncontextmenu="return false;" />
  <div style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;"
    *ngFor="let item of playlistItem?.boardsign_image?.special_property?.text_items;index as ii;"
    [ngClass]="(item?.is_center)?'d-flex align-items-center justify-content-center':''">
    <span *ngIf="item?.is_center && item?.text_type == specialTextType.FreeText"
      [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
      [style.font-size.px]="item?.font_size * resize" [style.family]="item?.font_family">{{item?.text}}</span>
    <span *ngIf="!item?.is_center && item?.text_type == specialTextType.FreeText"
      [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute" [style.top.px]="item?.y"
      [style.left.px]="item?.x" [style.color]="item?.color" [style.font-size.px]="item?.font_size * resize"
      [style.family]="item?.font_family">{{item?.text}}</span>
    <span
      *ngIf="item?.is_center && (item?.text_type == specialTextType.SpeedLimit || item?.text_type == specialTextType.SpeedSuggest)"
      [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
      [style.font-size.px]="item?.font_size * resize" [style.family]="item?.font_family">{{item?.text_speed}}</span>
    <span
      *ngIf="!item?.is_center && (item?.text_type == specialTextType.SpeedLimit || item?.text_type == specialTextType.SpeedSuggest)"
      [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute" [style.top.px]="item?.y"
      [style.left.px]="item?.x" [style.color]="item?.color" [style.font-size.px]="item?.font_size * resize"
      [style.family]="item?.font_family">{{item?.text_speed}}</span>
  </div>
  <div *ngIf="playlistItem?.boardsign_image?.behavior_type == mediaBehaviorType.Special" class="small"
    style="position: absolute;bottom:1px;left:1px;">
    <div class="badge badge-danger">S</div>
  </div>
  <div *ngIf="playlistItem?.boardsign_image?.behavior_type == mediaBehaviorType.Dynamic" class="small"
    style="position: absolute;bottom:1px;left:1px;">
    <div class="badge badge-danger">D</div>
  </div>
  <div [tooltip]="tolTemplate" placement="right" container="body" *ngIf="count > 1 && show_count" class="small"
    style="position: absolute;top:1px;right:2px;">
    <div class="badge badge-warning">{{count}}</div>
  </div>
</div>