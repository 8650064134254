<div class="modal-body text-center">
    <div class="row">
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ระบุตำแหน่งของอุปกรณ์</p>
                </div>
                <kendo-combobox class="form-control" [data]="route_list" [allowCustom]="allowCustom" 
                [suggest]="true" [(ngModel)]="search_route"[filterable]="true" [placeholder]="'ทั้งหมด'" 
                (filterChange)="FilterRoute($event)"  (valueChange)="ChageRoute($event)"></kendo-combobox>
                <div class="input-group-prepend">
                    <p class="input-group-text">กิโลเมตร</p>
                </div>
                <input type="text" [(ngModel)]="km1" class="form-control" placeholder="กม." numbersOnly maxlength="4">
                <div class="input-group-prepend">
                    <p class="input-group-text">เมตร</p>
                </div>
                <input type="text" [(ngModel)]="m1" class="form-control" placeholder="ม." numbersOnly maxlength="4">
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ระบุวันเวลาเริ่มต้นเหตุการณ์ (ถ้าทราบ)</p>
                </div>
                <kendo-datetimepicker [(ngModel)]="st_dt" class="form-control" [format]="'dd-MMM-yyyy HH:mm'"></kendo-datetimepicker>
            </div>
        </div>
        <div class="col-12" *ngIf="event_group==2">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ระบุที่มาของข้อมูล</p>
                </div>
                <input type="text" [(ngModel)]="values.src_group_name" class="form-control" [disabled]="true">
            </div>
        </div>
        <div class="col-12" *ngIf="event_group!=2">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ระบุที่มาของข้อมูล</p>
                </div>
                <label class="ml-3 src">
                    <input type="radio" name="source" id="" [value]="1" [(ngModel)]="src_type" (click)="src_type_etc=''">
                    Radio
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="source" id="" [value]="2" [(ngModel)]="src_type" (click)="src_type_etc=''">
                    Tel
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="source" id="" [value]="3" [(ngModel)]="src_type" (click)="src_type_etc=''">
                    Secial Media
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="source" id="" [value]="4" [(ngModel)]="src_type" (click)="src_type_etc=''">
                    ข้อมูลจากส่วนกลาง
                </label>
                
                <label class="ml-3 src">
                    <input type="radio" name="source" id="" [value]="8" [(ngModel)]="src_type">
                    อื่นๆ
                </label>
                
                <input type="text" [(ngModel)]="src_type_etc" class="form-control" [disabled]="src_type!=8">
            </div>
        </div>
        <div class="col-12" *ngIf="event_group==2">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ประเภทเหตุการณ์</p>
                </div>
                <input type="text" value="System Alert" class="form-control" [disabled]="true">
            </div>
        </div>
        <div class="col-12" *ngIf="event_group!=2">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ประเภทเหตุการณ์</p>
                </div>
                <div style="flex: 1 1 auto;">
                    <select class="form-control" [(ngModel)]="veh_dmg">
                        <option [value]=null>Select type</option>
                        <option [value]=2 [disabled]="!values">System Alert (AutoFill)</option>
                        <option [value]=1>แจ้งเหตุการณ์</option>
                    </select>
                    <div class="modal-header menu-BG">
                        <button class="btn menu-BT" [ngClass]="{'active': evt_subg == 1}" (click)="evt_subg = 1">Accident</button>
                        <button class="btn menu-BT" [ngClass]="{'active': evt_subg == 2}" (click)="evt_subg = 2">Incident</button>
                    </div>
                    <select class="form-control" [(ngModel)]="veh_dmg_type" (change)="getEvtSubType()">
                        <option [value]=null>Select</option>
                        <option *ngFor="let i of evtTypeList" [value]=i.id>{{i.name}}</option>
                    </select>
                    <div class="text-left" *ngIf="veh_dmg_type == 1">
                        <label *ngFor="let i of evtSubTypeList" class="ml-3 src">
                            <input type="checkbox" name="type" id="" [value]="i.id" [(ngModel)]="veh_dmg_list[i.id]" [disabled]="disabled">
                            {{i.name}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="event_group==2" class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">รูปภาพประกอบ</p>
                </div>
                
                <div *ngFor="let i of images_Evtdetail" class="addimg-BG position-relative">
                    <img [src]="i" alt="images_Evtdetail" style="max-height: 60px;" onerror="this.src='assets/noimage.png'">
                    <button *ngIf="!disabled" (click)="rmImage(i)">X</button>
                </div>
                <input type="file" name="" id="addImage" accept="image/*" (change)="uploadImg(ImagesEvt,1)" #ImagesEvt>
                <Label (click)="getImg()" class="ml-3 mb-0 btn btn-info addImg-BT">
                    <i class="icon-options"></i>

                </Label>
            </div>
        </div>
        <div class="col-12 text-right">
            <button type="button" class="btn btn-danger mr-2" (click)="EvtOutput(false)">ปิด</button>
            <button type="button" class="btn btn-info" (click)="save()">ยืนยัน</button>
        </div>
    </div>
</div>