import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
declare let videojs: any;
import { ModalDirective } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-cctv-display-flv',
  templateUrl: './cctv-display-flv.component.html',
  styleUrls: ['./cctv-display-flv.component.scss']
})
export class CctvDisplayFlvComponent implements OnInit, OnChanges {
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() toggle: boolean;
  @Input() item: any;
  @Input() station: any;
  @ViewChild('infoModal', { static: false }) private infoModal: ModalDirective;
  //poster:any = 'http://api.toc.highwaytraffic.go.th/cctv/?ip=10.2.0.157&port=11621&username=admin&password=Tr@nsc0de';
  //src:any = 'http://toc.highwaytraffic.go.th:20105/0002-0077250-2/cam.m3u8';
  poster: any;
  src: any;
  //player: any;
  nn: boolean = false;
  idx: number;
  constructor() { }

  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges) {
    //console.log(this.item);
    if (this.item) {
      //this.src = 'http://toc.highwaytraffic.go.th:20105/0002-0077250-2/cam.m3u8';
      if (this.item.cctv_flv) {
        this.src = this.item.cctv_flv;
      }
      this.idx = this.item.device_id;
      //this.poster = 'http://api.toc.highwaytraffic.go.th/cctv/?ip=10.2.0.157&port=11621&username=admin&password=Tr@nsc0de';
      if (this.item.link) {
        this.poster = this.item.link;
        let n = this.item.link.includes('?');
        if (n == false) {
          this.poster = this.item.link + '?t=' + new Date().getTime();
        } else {
          this.poster = this.item.link + '&t=' + new Date().getTime();
        }
      }
      setTimeout(() => this.ngAfterViewInit1(this.src), 1000);
    }
    if (changes.toggle) {
      if (changes.toggle.currentValue) {
        setTimeout(() => {
        this.infoModal.show();
        }, 100);
      }
    }
  }
  clickClose() {
    this.infoModal.hide();
    this.onClose.emit(null);
  }
  ngAfterViewInit1(flvUrl) {

    // ID with which to access the template's video element
    let el = 'video_flv_' + this.idx;
    //var flvUrl = "http://54.169.236.7:12060/live.flv?devid=0099000060&chl=1&svrid=127.0.0.1&svrport=17891&st=1&audio=0";
    //let flvUrl = url;
    // setup the player via the unique element ID
    let player = videojs(document.getElementById(el), {
      techOrder: ['html5', 'flvjs'],
      flvjs: {
        mediaDataSource: {
          isLive: true,
          cors: true,
          withCredentials: false,
        },
      },
      sources: [{
        src: flvUrl,
        type: 'video/mp4'
      }],
      controls: true,
      preload: "none"

    }, function onPlayerReady() {
      console.log('player ready')

      player.on('error', (err) => {
        console.log('first source load fail')

        player.src({
          src: flvUrl,
          type: 'video/x-flv'
        });

        player.ready(function () {
          console.log('player ready')
          player.load();
          player.play();
        });
      })
    });
  }

}
