import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import * as _ from 'lodash'
import { FuncService } from 'src/app/services/user/func.service';
import { ReportService } from 'src/app/services/user/report.service';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
import { ApiService } from 'src/app/services/user/api.service';
@Component({
  selector: 'app-per-detail',
  templateUrl: './per-detail.component.html',
  styleUrls: ['./per-detail.component.scss']
})
export class PerDetailComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  data = {
    survey: null,
    route: null,
    short_name: null,
    control: null,
    name: null,
    head: null,
    road_district: null,
    road_subdistrict: null,
    km: null,
    m: null,
    latitude: null,
    longitude: null,
    lipda1_1: null,
    lipda1_2: null,
    lipda1_3: null,
    lipda2_1: null,
    lipda2_2: null,
    lipda2_3: null,
    landmark: null,
    province: null,
    district: null,
    num_lane: null,
    surface_type: null,
  }
  constructor(
    private bsModalRef: BsModalRef,
    private func: FuncService,
    private rp_S: ReportService,
    private apiService: ApiService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    if (this.values) {
      this.data = _.cloneDeep(this.values)
      if (this.values.lipda1) {
        let l = this.values.lipda1.split('-')
        this.data.lipda1_1 = Number(l[0]) || ''
        this.data.lipda1_2 = Number(l[1]) || ''
        this.data.lipda1_3 = Number(l[2]) || ''
      }
      if (this.values.lipda2) {
        let l = this.values.lipda2.split('-')
        this.data.lipda2_1 = Number(l[0]) || ''
        this.data.lipda2_2 = Number(l[1]) || ''
        this.data.lipda2_3 = Number(l[2]) || ''
      }
    }
  }
  async save() {
    let req: any = _.cloneDeep(this.data)
    req.lipda1 = `${this.data.lipda1_1 || null}-${this.data.lipda1_2 || null}-${this.data.lipda1_3 || null}`
    req.lipda2 = `${this.data.lipda2_1 || null}-${this.data.lipda2_2 || null}-${this.data.lipda2_3 || null}`
    const req1 = _.cloneDeep(req);
    let txt_req = _.cloneDeep(req1);
    let res: any = await this.rp_S.addPerStation(req)
    if (res) {
      this.confirm()
      let action = ActionEnum.Add;
      let request_data = {url: `${this.apiService.api.perStaion}`, request: req1};
      if (req1.id) {
        action = ActionEnum.Edit;
        delete txt_req.id
        request_data = { url: `${this.apiService.api.perStaion}/${req1.id}`, request: txt_req};
      }
      this.commonService.activityLog(action, 'Traffic Information ➡ รายงานรายคันจากจุดสำรวจ', JSON.stringify(request_data)).subscribe(res => { }, error => { console.log(error); });
    }
  }
  close(chk?: any) {
    if (chk && !_.isEmpty(this.values)) {
      this.confirm()
    } else {
      this.bsModalRef.hide();
    }
  }
  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
}
