import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { StationService } from 'src/app/services/assets-management/station.service';
import { MaService } from 'src/app/services/assets-management/ma.service';
import { Station, ReqAddStation } from 'src/app/models/assets-management/station';
import { StatusEnum, MonitorTypeEnum, MaStatusEnum, RouteDirectionEnum, ActionEnum } from 'src/app/models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Owner } from 'src/app/models/assets-management/owner';
import { Vendor } from 'src/app/models/assets-management/vendor';
import { DeviceGroup } from 'src/app/models/assets-management/device-group';
import { OwnerService } from 'src/app/services/assets-management/owner.service';
import { MonitorService } from 'src/app/services/assets-management/monitor.service';

import { VendorService } from 'src/app/services/assets-management/vendor.service';
import { DeviceGroupService } from 'src/app/services/assets-management/device-group.service';
import { DeviceTypeService } from 'src/app/services/assets-management/device-type.service';
import { DeviceType } from 'src/app/models/assets-management/device-type';
import { DeviceInfo } from 'src/app/models/assets-management/device';
import { MaInfo, MaLogInfo } from 'src/app/models/assets-management/ma';
import { Img } from 'src/app/models/common';
import { DeviceService } from 'src/app/services/assets-management/device.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserProfile } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { MonitorStation, MonitorStationDevice, SoftwareResponse, MonitorDevice } from 'src/app/models/assets-management/monitor';
import { BoardsignService } from 'src/app/services/sign-control/boardsign.service';
import { AddBoardsign, BoardsignInfo, StructureLayerInfo } from 'src/app/models/sign-control/boardsign';
import { MediaType, SignDirection } from '../../../models/sign-control/enum';
@Component({
  selector: 'app-ma-management',
  templateUrl: './ma-management.component.html',
  styleUrls: ['./ma-management.component.scss']
})
export class MaManagementComponent implements OnInit, OnChanges {
  @Input() typeId: number; //hardware=1, software = 2, boardsign = 3;
  @Input() refId: number;//device_id, software_id, boardsign_id;
  @Input() clickOpen: boolean;
  @Input() view: number; // add select = 1, add by refId = 2, add log = 3;
  @Input() maId: number;

  @Input() isDescDevice: boolean;
  @Input() isDescMa: boolean;
  @Input() isAddMa: boolean;
  @Input() isMaLog: boolean;
  @Input() isAddMaLog: boolean;
  @Input() boardMaSelect: BoardsignInfo;

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('changeStatusModal', { static: false }) public changeStatusModal: ModalDirective;
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('addLogModal', { static: false }) public addLogModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  @ViewChild('imInput') imInputVariable: ElementRef;
  @ViewChild('imInput2') imInputVariable2: ElementRef;
  mediaType = MediaType;
  signDir = SignDirection;
  ma_images: Img[] = [];
  ma_images2: Img[] = [];
  statusEnum = StatusEnum;
  maStatusEnum = MaStatusEnum;
  monitorTypeEnum = MonitorTypeEnum;
  routeDirectionEnum = RouteDirectionEnum;
  selectItem: MaInfo = {
    detail: ""
  };
  message: string;
  selectMessageStatus: number;
  price: number;
  monitor_type_list = [
    { id: MonitorTypeEnum.Hardware, name: "Hardware" },
    { id: MonitorTypeEnum.Software, name: "Software" },
    { id: MonitorTypeEnum.Boardsign, name: "ป้ายแสดงผล" }
  ];
  is_loading: boolean = false;
  monitor_type: any;
  data_list_software: SoftwareResponse[] = [];
  software_list: SoftwareResponse[] = [];
  selectSoftware: SoftwareResponse;
  currentUser: UserProfile;
  currentUserSubscription: Subscription;

  selectDevice: any;
  device_modal: DeviceInfo;
  data_device_modal: DeviceInfo[] = [];
  device_list_all: DeviceInfo[] = [];
  device_list: DeviceInfo[] = [];

  data_board_list: any = [];
  board_list: any = [];
  select_boardlist: any;
  selectBoardsign: BoardsignInfo;
  station_modal: Station;
  data_station_search: Station[];
  data_station_modal: Station[];
  station_list: Station[];

  route_modal: string;
  data_route: Array<string> = [];
  data_route_modal: Array<string> = [];
  route_list: string[];

  res_message: any;

  selectMaItem: MaInfo;
  selectTab = 1;
  public allowCustom: boolean = false;
  event_logs: MaLogInfo[] = [];
  constructor(private commonService: CommonService, private stationService: StationService, private ownerService: OwnerService,
    private vendorService: VendorService, private deviceGroupService: DeviceGroupService, private deviceTypeService: DeviceTypeService,
    private maService: MaService, private deviceService: DeviceService, private authService: AuthService,
    private monitorService: MonitorService, private boardsignService: BoardsignService) {
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit(): void {
    this.monitor_type = this.monitor_type_list.find(x => x.id == MonitorTypeEnum.Hardware);
    this.getRoute();
    this.getStation();
    this.getDeviceAll();
    this.getMonitorSoftware();
  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }
  changeTab(t) {
    this.selectTab = t;
  }
  changeStatus(s) {
    if (this.selectMessageStatus != s) {
      this.selectMessageStatus = s;
      this.changeStatusModal.show();
    }
  }
  clickClose() {
    this.addModal.hide()
    this.onClose.emit(null);
    this.clearDataImage();
  }
  clickCloseLog() {
    this.addLogModal.hide()
    this.onClose.emit(null);
    this.clearDataImage();
  }
  set_sign_height_small = 50;
  get_sign_width(ratio: string, set_sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return set_sign_height * r;
    } else {
      return 0;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.clickOpen) {
      if (this.view == 1) {
        this.monitor_type = this.monitor_type_list.find(x => x.id == MonitorTypeEnum.Hardware);
        this.getRoute();
        this.getStation();
        this.getDeviceAll();
        this.getMonitorSoftware();
        this.getBoardsignList();
        this.openAddModal();
      } else if (this.view == 2) {
        this.monitor_type = this.monitor_type_list.find(x => x.id == this.typeId);
        if (this.typeId == MonitorTypeEnum.Hardware) {
          this.getDeviceByID(this.refId);
        } else if (this.typeId == MonitorTypeEnum.Software) {
          this.getSoftwareById(this.refId);
        } else {
          //this.getBoardsignById(this.refId);
          this.selectBoardsign = this.boardMaSelect;
        }

        this.openAddModal();

      } else {
        if (this.maId) {
          this.selectTab = 1;
          this.getMaById(this.maId);

        } else {
          if (this.typeId == MonitorTypeEnum.Hardware) {
            this.getMaByDeviceId(this.refId);
            this.getDeviceByID(this.refId);
          } else if (this.typeId == MonitorTypeEnum.Software) {
            this.getMaBySoftwareId(this.refId);
            this.getSoftwareById(this.refId);
          } else {
            this.getMaByBoardsignId(this.refId);
            this.getBoardsignById(this.refId);
          }
        }
        setTimeout(() => {
          this.openAddLogModal();
        }, 500);
      }
    }

  }
  openAddLogModal() {
    this.addLogModal.show();
  }
  openAddModal() {
    this.selectDevice = null;
    this.selectItem = new MaInfo();
    this.selectItem.detail = "";
    this.addModal.show();
  }

  addMa(urls?: string[]) {
    this.is_loading = true;
    let req = new MaInfo();
    req.detail = this.selectItem.detail;
    if (this.monitor_type) {
      req.image_url = [];
      if (urls) {
        req.image_url = urls;
      }
      req.type_id = this.monitor_type.id;
      req.user_id = this.currentUser.id;
      req.username = `${this.currentUser.firstname} ${this.currentUser.lastname}`;
      if (this.monitor_type.id == MonitorTypeEnum.Hardware) {
        req.ref_id = this.selectDevice.device_id;
        req.station_id = this.selectDevice.station_id;
        req.status = MaStatusEnum.Create;
      } else if (this.monitor_type.id == MonitorTypeEnum.Software) {
        req.ref_id = this.selectSoftware.id;
        req.status = MaStatusEnum.Create;
      } else if (this.monitor_type.id == MonitorTypeEnum.Boardsign) {
        if (this.selectBoardsign) {
          req.ref_id = this.selectBoardsign.id;
        } else {
          if (this.select_boardlist) {
            req.ref_id = this.select_boardlist.id;
          }
        }
        req.station_id = this.selectBoardsign.station_id;
        req.status = MaStatusEnum.Create;
      }
    }
    this.res_message = "";
    this.maService.addMa(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { request: req, response: res };
            this.commonService.activityLog(ActionEnum.Add, `Assets Management ➡ แจ้งซ่อม`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `เพิ่มการแจ้งซ่อมสำเร็จ`;
            if (this.monitor_type.id == MonitorTypeEnum.Boardsign) {
              this.editBoardsignStatus(this.statusEnum.Ma, req.ref_id, true);
            } else {
              this.onSuccess.emit(null);
            }
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              //this.onSuccess.emit(null);
            }, 500);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  bucket_name = 'asset';
  path = 'ma';
  async addMaAsyn() {
    let add_img = this.ma_images.filter(x => x.delete != true && x.file != null && x.dataurl != null);
    if (add_img.length > 0) {
      const f = this.ma_images.map(x => x.file)
      let imgs = await this.commonService.uploadImages(f, this.bucket_name, this.path).toPromise();
      if (imgs) {
        if (imgs.data) {
          this.addMa(imgs.data.img_urls);
        } else {
          this.addMa();
        }
      } else {
        this.addMa();
      }
    } else {
      this.addMa();
    }
  }

  addMaLog(urls?: string[]) {
    this.is_loading = true;
    let req = new MaLogInfo();
    req.message = this.message;
    if (this.monitor_type) {
      req.image_url = [];
      if (urls) {
        req.image_url = urls;
      }
      req.type_id = this.typeId;
      req.user_id = this.currentUser.id;
      req.username = `${this.currentUser.firstname} ${this.currentUser.lastname}`;
      req.status = this.selectMessageStatus;
      req.ma_price = 0;
      if (this.selectMessageStatus == MaStatusEnum.Close) {
        req.ma_price = +this.price;
      }
    }
    this.res_message = "";
    this.maService.addMaLog(req, this.selectMaItem.ma_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { ma_id: this.selectMaItem.ma_id, request: req, response: res };
            this.commonService.activityLog(ActionEnum.Add, `Assets Management ➡ แจ้งซ่อม ➡ Activity log`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.selectMaItem.status = this.selectMessageStatus;;
            if (this.monitor_type.id == MonitorTypeEnum.Boardsign) {
              if (this.selectMessageStatus == MaStatusEnum.Close) {
                this.editBoardsignStatus(this.statusEnum.Active, this.selectBoardsign.id);
              } else {
                this.editBoardsignStatus(this.statusEnum.Ma, this.selectBoardsign.id);
              }
            }
            setTimeout(() => {
              this.is_loading = false;
              this.changeStatusModal.hide();
            }, 500);
            this.clearDataImage();
            this.getMaLogByMaId(this.selectMaItem.ma_id);

          } else {

            setTimeout(() => { this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {
          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 100);
          }
        } else {
        }
        this.is_loading = false;
      });
  }
  async addMaLogAsyn() {
    let add_img = this.ma_images2.filter(x => x.delete != true && x.file != null && x.dataurl != null);
    if (add_img.length > 0) {
      const f = this.ma_images2.map(x => x.file)
      let imgs = await this.commonService.uploadImages(f, this.bucket_name, this.path).toPromise();
      if (imgs) {
        if (imgs.data) {
          this.addMaLog(imgs.data.img_urls);
        } else {
          this.addMaLog();
        }
      } else {
        this.addMaLog();
      }
    } else {
      this.addMaLog();
    }
  }
  getMaById(ma_id) {
    this.maService.getMaById(ma_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.selectMaItem = res.data[0];
              this.typeId = this.selectMaItem.type_id;
              this.selectMessageStatus = this.selectMaItem.status;
              this.getMaLogByMaId(this.selectMaItem.ma_id);
              if (this.typeId == MonitorTypeEnum.Hardware) {
                this.getDeviceByID(this.selectMaItem.ref_id);
              } else if (this.typeId == MonitorTypeEnum.Software) {
                this.getSoftwareById(this.selectMaItem.ref_id);
              } else if (this.typeId == MonitorTypeEnum.Boardsign) {
                this.getBoardsignById(this.selectMaItem.ref_id);
              }
            }
          }
        }
      },
      error => {
      });
  }
  getMaLogByMaId(ma_id) {
    this.maService.getMaLog(ma_id).subscribe(
      res => {
        if (res) {

          if (res.code == 1) {
            if (res.data) {
              this.event_logs = res.data;
              //console.log(this.event_logs);

            }
          }
        }
      },
      error => {
      });
  }
  getMaByDeviceId(device_id) {
    this.maService.getMaByDeviceId(device_id).subscribe(
      res => {
        if (res) {
          //console.log(res);
          if (res.code == 1) {
            if (res.data) {
              this.selectMaItem = res.data[0];
              this.selectMessageStatus = this.selectMaItem.status;
              this.getMaLogByMaId(this.selectMaItem.ma_id);
            }
          }
        }
      },
      error => {
      });
  }
  getMaBySoftwareId(software_id) {
    this.maService.getMaSoftwareId(software_id).subscribe(
      res => {
        if (res) {
          //console.log(res);
          if (res.code == 1) {
            if (res.data) {
              this.selectMaItem = res.data[0];
              this.selectMessageStatus = this.selectMaItem.status;
              this.getMaLogByMaId(this.selectMaItem.ma_id);
            }
          }
        }
      },
      error => {
      });
  }

  getMaByBoardsignId(boardsign_id) {
    this.maService.getMaBoardsignId(boardsign_id).subscribe(
      res => {
        if (res) {
          //console.log(res);
          if (res.code == 1) {
            if (res.data) {
              this.selectMaItem = res.data[0];
              this.selectMessageStatus = this.selectMaItem.status;
              this.getMaLogByMaId(this.selectMaItem.ma_id);
            }
          }
        }
      },
      error => {
      });
  }
  //--------------------------------------------------------------------------------------//
  async getBoardsignById(boardsign_id?: number) {
    this.selectBoardsign = null;
    this.boardsignService.getBoardsign(null, null, null, null, null, null, null, null, null, null, [boardsign_id]).subscribe(
      res => {
        console.log(res);
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              if (res.data.data.length > 0) {
                this.selectBoardsign = _.cloneDeep(res.data.data[0]);
                if (this.selectBoardsign.signs) {
                  this.selectBoardsign.signs = this.selectBoardsign.signs.filter(x => x.status == this.statusEnum.Active);
                  if (this.selectBoardsign.signs.length > 0 && this.selectBoardsign.station.boardsign_structure) {
                    // layers
                    //const layers_text = this.selectBoardsign.station.boardsign_structure.split("|");
                    let layer_no_list = _.sortedUniq(this.selectBoardsign.signs.map(a => a.layer_no));
                    let layers = [];
                    layer_no_list.forEach((l, index) => {
                      // signs
                      let signs = [];
                      let find_sign = this.selectBoardsign.signs.filter(x => x.layer_no == l);

                      if (find_sign) {
                        find_sign.forEach(s => {
                          if (s.media) {
                            let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: s.media };
                            signs.push(d);
                          } else {
                            let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: null };
                            signs.push(d);
                          }
                        });
                        signs = _.sortBy(signs, ['sign_no']);
                        layers.push(signs);
                      }

                    });
                    setTimeout(() => {
                      this.selectBoardsign.layers = layers;
                    }, 100);

                  } else {
                    this.selectBoardsign.signs = [];
                    this.selectBoardsign.layers = [];
                  }
                } else {
                  this.selectBoardsign.signs = [];
                  this.selectBoardsign.layers = [];
                }
              }
            }
          }
        }
      },
      error => {
      });


  }
  async getBoardsignList() {
    this.boardsignService.getBoardsign(null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.board_list = _.cloneDeep(res.data);
              this.data_board_list = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => {
      });
  }
  handleFilterBoard(value) {
    if (value.length > 0) {
      this.data_board_list = this.board_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_board_list = _.cloneDeep(this.board_list);
    } else {
      this.data_board_list = [];
    }
  }
  handleValueBoard(value) {
    if (value) {
      this.select_boardlist = value;
      this.getBoardsignById(this.select_boardlist.id);
    } else {
      this.select_boardlist = null;
      this.selectBoardsign = null;
    }
  }
  editBoardsignStatus(status?: number, boardsign_id?: number, is_add?: boolean) {
    let req = new AddBoardsign();
    req.status = status;
    this.boardsignService.editBoardsign(req, boardsign_id).subscribe(
      res => {
        if (is_add) {
          this.onSuccess.emit(null);
        }
      },
      error => {
        console.log(error);
      });
  }
  //--------------------------------------------------------------------------------------//
  getDeviceAll() {
    this.deviceService.getDevice(null, null, 1).subscribe(
      res => {
        if (res) {
          //console.log(res);
          if (res.code == 1) {
            if (res.data) {
              this.device_list_all = _.cloneDeep(res.data);
              this.device_list = _.cloneDeep(res.data);
              this.data_device_modal = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => {
      });
  }
  getDeviceByID(device_id) {
    this.deviceService.getDeviceById(device_id).subscribe(
      res => {
        if (res) {
          //console.log(res);

          if (res.code == 1) {
            if (res.data) {
              this.selectDevice = res.data;
            }
          }
        }
      },
      error => {
      });
  }
  getDevice() {
    let station = null;
    if (this.station_modal)
      station = this.station_modal.id;
    this.deviceService.getDevice(null, null, 1, this.route_modal, null, null, null, null, null, station).subscribe(
      res => {
        if (res) {
          //console.log(res);

          if (res.code == 1) {
            if (res.data) {
              this.device_list = _.cloneDeep(res.data);
              this.data_device_modal = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => {
      });
  }
  handleFilterDeviceModal(value) {
    if (value.length > 0) {
      this.data_device_modal = this.device_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_device_modal = _.cloneDeep(this.device_list);
    } else {
      this.data_device_modal = [];
    }
  }
  handleValueDeviceModal(value) {
    if (value) {
      this.device_modal = value;
      this.getDeviceByID(this.device_modal.device_id)
    } else {
      this.device_modal = null;
      this.selectDevice = null;
    }
  }
  handleValueMonitorType(value) {
    //console.log(value);

    if (value) {
      this.monitor_type = value;
    } else {
      this.monitor_type = null;
    }
    this.select_boardlist = null;
    this.selectBoardsign = null;
  }
  getStation() {
    this.stationService.getStation(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.station_list = _.cloneDeep(res.data.data);
              this.data_station_modal = _.cloneDeep(res.data.data);
            }
          }
        }
      },
      error => {
      });
  }


  getStationModalByRoute(route?: string) {
    this.stationService.getStation(null, null, 1, route).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_station_modal = _.cloneDeep(res.data.data);
            }
          }
        }
      },
      error => {
      });
  }
  handleFilterStationModal(value) {
    if (value.length > 0) {
      this.data_station_modal = this.station_list.filter(s=>s.name != null).filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_station_modal = _.cloneDeep(this.station_list);
    } else {
      this.data_station_modal = [];
    }
  }
  handleValueStationModal(value) {
    if (value) {
      this.station_modal = value;
      this.getDevice();
    } else {
      this.station_modal = null;
      this.device_list = _.cloneDeep(this.device_list_all);
    }
  }

  getRoute() {
    this.commonService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route = _.cloneDeep(res.data);
              this.data_route_modal = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => {
      });
  }


  handleFilterRouteModal(value) {
    if (value.length > 0) {
      this.data_route_modal = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route_modal = _.cloneDeep(this.route_list);
    } else {
      this.data_route_modal = [];
    }
  }
  handleValueRouteModal(value) {
    if (value) {
      this.route_modal = value;
      this.getDevice();
      this.getStationModalByRoute(this.route_modal)
    } else {
      this.route_modal = null;
      this.device_list = _.cloneDeep(this.device_list_all);
      this.data_station_modal = _.cloneDeep(this.station_list);
    }
  }

  getMonitorSoftware() {
    this.data_list_software = [];
    let status = StatusEnum.Active;
    this.monitorService.getSoftware(null, null, null, null, null, null, null).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              //console.log(res);
              this.software_list = _.cloneDeep(res.data.data);
              this.data_list_software = _.cloneDeep(res.data.data);

            }
          }
        }
      },
      error => {
      });
  }

  getSoftwareById(id?: number) {
    this.monitorService.getSoftware(null, null, null, null, null, null, null).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              console.log(res);
              this.selectSoftware = res.data.data.find(x => x.id == id);
            }
          }
        }
      },
      error => {
      });

  }
  handleFilterSoftware(value) {
    if (value.length > 0) {
      this.data_list_software = this.software_list.filter((s) => s.app_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_list_software = _.cloneDeep(this.software_list);
    } else {
      this.data_list_software = [];
    }
  }
  handleValueSoftware(value) {
    if (value) {
      this.selectSoftware = value;
    } else {
      this.selectSoftware = null;
    }
  }
  //-----------------------------------------------------------------------------------------//
  deleteImageFiles(item: any) {
    const index: number = this.ma_images.indexOf(item);
    if (item.url) {
      item.delete = true;
    } else {
      if (index !== -1) {
        this.ma_images.splice(index, 1);
      }
    }
  }
  detectFiles(event) {
    let acceptedType = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        if (acceptedType.includes(file.type)) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            let im = new Img();
            im.file = file;
            im.dataurl = e.target.result;
            this.ma_images.push(im);
          }
          reader.readAsDataURL(file);
        } else {
          const error_text = "รองรับไฟล์นามกุล JPEG,PNG,JPG,GIF เท่านั้น";
          this.res_message = error_text;
          this.is_loading = false
          setTimeout(() => { this.swalError.fire() }, 500);
        }
      }
    }
  }

  deleteImageFiles2(item: any) {
    const index: number = this.ma_images2.indexOf(item);
    if (item.url) {
      item.delete = true;
    } else {
      if (index !== -1) {
        this.ma_images2.splice(index, 1);
      }
    }
  }
  detectFiles2(event) {
    let acceptedType = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        if (acceptedType.includes(file.type)) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            let im = new Img();
            im.file = file;
            im.dataurl = e.target.result;
            this.ma_images2.push(im);
          }
          reader.readAsDataURL(file);
        } else {
          const error_text = "รองรับไฟล์นามกุล JPEG,PNG,JPG,GIF เท่านั้น";
          this.res_message = error_text;
          this.is_loading = false
          setTimeout(() => { this.swalError.fire() }, 500);
        }
      }
    }
  }
  clearDataImage() {
    this.message = null;
    this.price = null;
    this.ma_images = [];
    if (this.imInputVariable) {
      this.imInputVariable.nativeElement.value = "";
    }
    this.ma_images2 = [];
    if (this.imInputVariable2) {
      this.imInputVariable2.nativeElement.value = "";
    }
  }

  //--------------------------------------------------------------------------------------------//
  clearChangeStatus() {
    this.selectMessageStatus = this.selectMaItem.status;
    this.message = null;
    this.price = null;
    this.ma_images = [];
    if (this.imInputVariable) {
      this.imInputVariable.nativeElement.value = "";
    }
    this.ma_images2 = [];
    if (this.imInputVariable2) {
      this.imInputVariable2.nativeElement.value = "";
    }
  }

}
