export const mainRouteUrl = "assets-management";
export const id = 800;

export const configuration_url = 'configuration';
export const configuration_id = 843;
export const setting_url = 'setting';
export const setting_id = 844;
export const menuItems = [
    {
        id: 801,
        name: 'Monitor',
        url: `/${mainRouteUrl}/monitor`,
        icon: 'icon-speedometer',
        parent_id: id
    },
    {
        id: 843,
        name: 'Configuration',
        url: `/${mainRouteUrl}/${configuration_url}`,
        icon: 'fa fa-building',
        parent_id: id
    },
    {
        id: 844,
        name: 'Setting',
        url: `/${mainRouteUrl}/${setting_url}`,
        icon: 'fa fa-cog',
        parent_id: id
    },
    {
        id: 840,
        name: 'แจ้งซ่อม',
        url: `/${mainRouteUrl}/ma`,
        icon: 'icon-wrench',
        parent_id: id
    }
];
export const configurationMenuItems = [
    {
        id: 817,
        name: 'Station',
        url: `/${mainRouteUrl}/${configuration_url}/station`,
        icon: 'icon-home',
        parent_id: configuration_id
    },
    {
        id: 820,
        name: 'กลุ่มอุปกรณ์',
        url: `/${mainRouteUrl}/${configuration_url}/device-group`,
        icon: 'fa fa-cubes',
        parent_id: configuration_id
    },
    {
        id: 823,
        name: 'ประเภทอุปกรณ์',
        url: `/${mainRouteUrl}/${configuration_url}/device-type`,
        icon: 'fa fa-cube',
        parent_id: configuration_id
    },
    {
        id: 826,
        name: 'Vendor',
        url: `/${mainRouteUrl}/${configuration_url}/vendor`,
        icon: 'fa fa-building',
        parent_id: configuration_id
    },
    {
        id: 831,
        name: 'Owner',
        url: `/${mainRouteUrl}/${configuration_url}/owner`,
        icon: 'fa fa-building',
        parent_id: configuration_id
    },
    {
        id: 834,
        name: 'อุปกรณ์',
        url: `/${mainRouteUrl}/${configuration_url}/device`,
        icon: 'fa fa-cog',
        parent_id: configuration_id
    },
    {
        id: 837,
        name: 'ยี่ห้อ/รุ่น',
        url: `/${mainRouteUrl}/${configuration_url}/brand`,
        icon: 'icon-shield',
        parent_id: configuration_id
    }
];
export const settingMenuItems = [
    {
        id: 845,
        name: 'Smart Micro',
        url: `/${mainRouteUrl}/${setting_url}/smartmicro`,
        icon: 'fa fa-sun-o',
        parent_id: setting_id
    },
    {
        id: 848,
        name: 'Reversible',
        url: `/${mainRouteUrl}/${setting_url}/reversible`,
        icon: 'icon-camera',
        parent_id: setting_id
    },
    {
        id: 851,
        name: 'Raining Alert Config',
        url: `/${mainRouteUrl}/${setting_url}/raining_config`,
        icon: 'fa fa-skyatlas',
        parent_id: setting_id
    }
];
export const permission = {
    monitor: {
        hardware_id: 802,
        hardware: {
            station_id: 803,
            desc_id: 804,
            desc: {
                desc_devcie_id: 805,
                desc_ma_id: 806,
                add_ma_id: 807,
                ma_log_id: 808,
                add_ma_log_id: 809
            }
        },
        software_id: 810,
        software: {
            desc_id: 811,
            desc: {
                desc_devcie_id: 812,
                desc_ma_id: 813,
                add_ma_id: 814,
                ma_log_id: 815,
                add_ma_log_id: 816
            }
        }
    },
    configuration: {
        station: {
            add_id: 818,
            edit_id: 819
        },
        device_group: {
            add_id: 821,
            edit_id: 822
        },
        device_type: {
            add_id: 824,
            edit_id: 825
        },
        vendor: {
            add_id: 827,
            edit_id: 828,
        },
        vendor_contact: {
            add_id: 829,
            edit_id: 830,
        },
        owner: {
            add_id: 832,
            edit_id: 833,
        },
        device: {
            add_id: 835,
            edit_id: 836,
        },
        brand: {
            add_id: 838,
            edit_id: 839,
        }
    },
    setting:{
        smartmicro: {
            add_id: 846,
            edit_id: 847,
        },
        reversible: {
            add_id: 849,
            edit_id: 850,
        }
    },
    ma: {
        add_id: 841,
        edit_id: 842,
    }
};