import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { DataBankTypeService } from 'src/app/services/information/data-bank-type.service';
import { DataBankTypeInfo, ReqAddDataBankType } from 'src/app/models/information/data-bank-type';
import { ActionEnum, StatusEnum } from 'src/app/models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { permission } from 'src/app/views/information/_menu';

@Component({
  selector: 'app-data-bank-type',
  templateUrl: './data-bank-type.component.html',
  styleUrls: ['../data-bank.component.scss']
})
export class DataBankTypeComponent implements OnInit {
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  statusEnum = StatusEnum;
  myDate = new Date();
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  data_list: DataBankTypeInfo[];
  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" }
  ];
  status: any;
  selectItem: any = {
    name: "",
    desc: "",
    hashtag: "",
    status: null
  };
  public allowCustom: boolean = false;
  res_message: any;
  category_modal: any;
  status_modal: any;
  search_name: any;
  search_hashtag: any;

  isAdd: boolean = false;
  isEdit: boolean = false;
  constructor(private commonService: CommonService, private dataBankTypeService: DataBankTypeService) {
    this.commonService.activityLog(ActionEnum.Get, 'Information ➡ คลังข้อมูล ➡ ประเภทคลังข้อมูล').subscribe(res => { }, error => { console.log(error); });
   }

  async ngOnInit() {
    //this.getRoute();
    this.is_loading = true;
    this.getData();
    this.isAdd = await this.commonService.getPermission(permission.data_bank.data_bank_type.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.data_bank.data_bank_type.edit_id).toPromise();
  }

  handleValueStatus(value) {
    if (value) {
      this.status = value;
    } else {
      this.status = null;
    }
  }
  is_loading: boolean = false;

  search() {
    this.currentPage = 1;
    this.getData();
  }
  getData() {
    this.is_loading = true;
    this.data_list = [];
    let sta = null;
    let hash = null;
    let name = null;
    if (this.status)
      sta = this.status.id;
    if (this.search_name)
      name = this.search_name;
    if (this.search_hashtag)
      hash = this.search_hashtag;
    this.dataBankTypeService.getDataBankType(name, sta, hash, this.currentPage, this.itemsPerPage).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = res.data.data;
              this.totalItems = res.data.total_items;
            } else {
              this.totalItems = 0;
            }
          } else {
            this.totalItems = 0;
          }
        } else {
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
      });
  }
  openAddModal() {
    this.selectItem = new DataBankTypeInfo();
    this.selectItem.name = "";
    this.category_modal = null;
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.addModal.show();
  }
  openEditModal(item?: any) {
    this.selectItem = item;
    this.status_modal = this.status_list.find(x => x.id == item.status);

    this.addModal.show();
  }
  openDeleteModal(item?: any) {
    this.selectItem = item;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.deleteModal.show();
  }
  clearData() {
    this.selectItem = new DataBankTypeInfo();
  }
  handleValueStatusModal(value) {
    if (value) {
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  handleValueCategoryModal(value) {
    if (value) {
      this.category_modal = value;
    } else {
      this.category_modal = null;
    }
  }
  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.getData();
    }
  }
  addData() {
    this.is_loading = true;
    let req = new ReqAddDataBankType();
    req.name = this.selectItem.name;
    req.desc = this.selectItem.desc;
    req.hashtag = this.selectItem.hashtag;
    req.status = this.status_modal.id;
    this.res_message = "";
    this.dataBankTypeService.addDataBankType(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { request: req, response: res };
            this.commonService.activityLog(ActionEnum.Add, 'Information ➡ คลังข้อมูล ➡ ประเภทคลังข้อมูล', JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `เพิ่มประเภทคลังข้อมูลสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editData() {
    this.is_loading = true;
    let req = new ReqAddDataBankType();
    req.name = this.selectItem.name;
    req.desc = this.selectItem.desc;
    req.hashtag = this.selectItem.hashtag;
    req.status = this.status_modal.id;
    this.res_message = "";
    this.dataBankTypeService.editDataBankType(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { id: this.selectItem.id, request: req, response: res };
            this.commonService.activityLog(ActionEnum.Edit, 'Information ➡ คลังข้อมูล ➡ ประเภทคลังข้อมูล', JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `แก้ไขประเภทคลังข้อมูลสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  deleteData() {
    this.is_loading = true;
    let req = new ReqAddDataBankType();
    req.name = this.selectItem.name;
    req.desc = this.selectItem.desc;
    req.hashtag = this.selectItem.hashtag;
    req.status = this.status_modal.id;
    this.res_message = "";
    this.dataBankTypeService.deleteDataBankTypeEditStatus(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { id: this.selectItem.id };
            this.commonService.activityLog(ActionEnum.Delete, 'Information ➡ คลังข้อมูล ➡ ประเภทคลังข้อมูล', JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `ลบประเภทคลังข้อมูลสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

}