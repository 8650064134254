import { Component, OnInit ,Input,Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-evt-status',
  templateUrl: './evt-status.component.html',
  styleUrls: ['./evt-status.component.scss']
})
export class EvtStatusComponent implements OnInit {
  @Input() event_group : any;
  @Input() _Add : any;
  @Output() newEvent = new EventEmitter<string>();
// - event = 1
// - security event = 2
// - rain event = 3
// - vsw event = 4
  evtType : number = 1
  constructor() { }

  ngOnInit(): void {
    console.log(this.event_group)
  }
  addNewItem(value: any) {
    this.newEvent.emit(value);
  }
}
