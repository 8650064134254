import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, ViewChild, EventEmitter, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { startOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addWeeks, subWeeks, addMonths, subMonths, addHours } from 'date-fns';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CalendarEvent, CalendarEventAction, CalendarDateFormatter } from 'angular-calendar'; // import should be from `angular-calendar` in your app
import { CustomDateFormatter } from './custom-date-formatter.provider';
import * as moment from 'moment'
import { FuncService } from 'src/app/services/user/func.service';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { DOCUMENT } from '@angular/common';
import { ReportService } from 'src/app/services/user/report.service';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
import { ApiService } from 'src/app/services/user/api.service';
@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class ImportFileComponent implements OnInit, OnChanges,OnDestroy {
  
  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  @Input() viewPanel: number;
  data_file: any;
  // direction: number = null;
  actual_in: string;
  file_in: string;
  actual_out: string;
  file_out: string;
  station: any;
  file_txt: any;
  private readonly darkThemeClass = 'dark-theme';
  _isLoading : boolean = false
  constructor(
    private router: Router,
    private func : FuncService,
    private bsModalRef: BsModalRef,
    private rp_S:ReportService,
    @Inject(DOCUMENT) private document,
    private apiService: ApiService,
    private commonService: CommonService
    ) { }

  ngOnInit() {
    console.log(this.values)
    // this.station.id = Number(this.values.id)
    this.document.body.classList.add(this.darkThemeClass);
    this.getCalendar(Number(moment().format("M")),Number(moment().format("YYYY")))
  }
  ngOnDestroy(): void {
    this.document.body.classList.remove(this.darkThemeClass);
  }
  close(chk?: any) {
    if (chk && !_.isEmpty(this.values)) {
      this.confirm()
    } else {
      this.bsModalRef.hide();
    }
  }
  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  locale: string = 'th';

  view: string = 'month';

  viewDate: Date = new Date();
  activeDayIsOpen: boolean = false;
  refresh: Subject<any> = new Subject();
  events: Array<CalendarEvent<any>> = [];
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-thermometer-three-quarters" aria-hidden="true"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        console.log(event)
        // this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fa fa-battery-empty" aria-hidden="true"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        console.log(event)
        // this.handleEvent('Deleted', event);
      },
    },
  ];
  increment(): void {

    const addFn: any = {
      day: addDays,
      week: addWeeks,
      month: addMonths
    }[this.view];

    this.viewDate = addFn(this.viewDate, 1);

    const month = this.viewDate.getMonth();
    const year = this.viewDate.getFullYear();
    this.getCalendar(month, year);
  }

  decrement(): void {
    const subFn: any = {
      day: subDays,
      week: subWeeks,
      month: subMonths
    }[this.view];

    this.viewDate = subFn(this.viewDate, 1);

    const month = this.viewDate.getMonth();
    const year = this.viewDate.getFullYear();
    this.getCalendar(month, year);
  }

  incrementYear(): void {
    const addFn: any = {
      day: addDays,
      week: addWeeks,
      month: addMonths
    }['month'];
    if (this.viewDate.getFullYear() < (new Date()).getFullYear()) {
      this.viewDate = addFn(this.viewDate, 12);
    }
  }

  decrementYear(): void {
    const subFn: any = {
      day: subDays,
      week: subWeeks,
      month: subMonths
    }['month'];
    this.viewDate = subFn(this.viewDate, 12);
  }

  today(): void {
    this.viewDate = new Date();
  }
  async getCalendar(month?: number, year?: number) {
    this.events = [];
    let res : any = await this.rp_S.getPerCarlendar({
      station:this.values.id,
      month:month+1,
      year:year,
    })
    // this.stationService.getStationPerCalendar(sta, month+1, year).subscribe(res => {
    if (res) {
      let items = [];
      res.forEach(item => {
        const t1 = new Date(year, month, item, 0, 0, 0);
        const t2 = new Date(year, month, item, 23, 59, 59);
        const event = {
          start: t1,
          end: t2,
          title: "Click for remove",
          color: {
            primary: "#f86c6b",
            secondary: "#f28e8e"
          },
          actions: null,
          meta: item
        }
        const event_view = {
          start: t1,
          end: t2,
          title: "Go to view",
          color: {
            primary: "#58d2ff",
            secondary: "#677d88"
          },
          actions: null,
          meta: item
        }
        items.push(event);
        items.push(event_view);
      });
      this.events = items;
      this.refresh.next();
    }
    // },
    // err => {
    //   console.log(err);
    // });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.viewPanel == 2) {
      if (this.station) {
        this.viewDate = new Date();
        const now = new Date();
        const month = now.getMonth();
        const year = now.getFullYear();
        setTimeout(() => {
          this.getCalendar( month, year);
        }, 500);
      }
    }
  }
  clear() {
    // this.onClose.emit(null);
    // this.events = [];
    this.file_txt = null;
    this.data_file = null;
    this.actual_in = null
    this.file_in = null
    this.actual_out = null
    this.file_out = null
  }
  detectFiles(event) {
    const files = event.target.files;
    if (files) {
      for (let file of files) {
        this.data_file = file;
      }
    }
  }
  async save() {
    this._isLoading = true
    if((this.actual_in&&this.file_in&&(!this.ChkArStr(this.actual_in) || !this.ChkArStr(this.file_in))) || (this.actual_out&&this.file_out&&(!this.ChkArStr(this.actual_out) || !this.ChkArStr(this.file_out)))){
      this._isLoading = false
      return this.func.alertpopup("โปรดใส่ข้อความในรูปแบบ 1,2,3");
    }
    if((this.actual_in && this.file_in)&&this.actual_in.length!=this.file_in.length){
      this._isLoading = false
      return this.func.alertpopup("จำนวนค่า In acutal กับ file ไม่เท่ากัน");
    }
    if((this.actual_out && this.file_out)&&this.actual_out.length!=this.file_out.length){
      this._isLoading = false
      return this.func.alertpopup("จำนวนค่า Out acutal กับ file ไม่เท่ากัน");
    }
    if((this.file_in && this.file_out)&&this.file_in.length && this.file_out.length){
      let ar1 = (this.file_in.match(','))? this.file_in.split(',') : [this.file_in]
      let ar2 = (this.file_out.match(','))? this.file_out.split(',') : [this.file_out]
      if(_.intersection(ar1, ar2).length) {
        this._isLoading = false;
        return this.func.alertpopup("มีจำนวนซ้ำใน File ทั้งสอง");}
    }
    let data : any = {
      files : [this.data_file],
      station_id : this.values.id,
      actual_in : this.actual_in,
      file_in : this.file_in,
      actual_out : this.actual_out,
      file_out : this.file_out,
    }
    let req1 = _.cloneDeep(data);
    let res : any = await this.rp_S.addPerCarlendar(data)
    // this.stationService.uploadSensorDataFile([this.data_file], this.station.id,this.actual_in,this.file_in,this.actual_out,this.file_out).subscribe(res => {
      if (res) {
        req1.files = null;
        if (this.data_file)
          req1.files = this.data_file.name;
        let request_data = { url: `${this.apiService.api.perStaion}${this.apiService.api.upload}`, request: req1};
        this.commonService.activityLog(ActionEnum.Add, 'Traffic Information ➡ รายงานรายคันจากจุดสำรวจ ➡ Import', JSON.stringify(request_data)).subscribe(res => { }, error => { console.log(error); });

        this.clear()
        const month = this.viewDate.getMonth();
        const year = this.viewDate.getFullYear();
        await this.getCalendar( month, year);
        // this.func.alertpopup("Success");
      }
      this._isLoading = false;
    // },
    //   err => {
    //     console.log(err);
    //     if (err.statusText) {
    //       this.func.alertpopup(err.statusText, 2)
    //     } else {
    //       this.func.alertpopup('', 2);
    //     }
    //   });
  }
  // ResMessage: string = '';
  // func.alertpopup(message?: string, type?: number) {
  //   this.ResMessage = '';
  //   if (type == 1) {
  //     this.swalSuccess.show();
  //   } else {
  //     this.ResMessage = message;
  //     setTimeout(() => {
  //       this.swalError.show();
  //     }, 1000);
  //     }
  // }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    console.log(date,events)
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  eventTimesChanged(evt:any){
    console.log(evt)
  }
  calendar:any
  async rmlist(evt){
    this.calendar = evt.start
    if(evt.title == 'Click for remove') {
      let result = await this.func.alertpopup('<h4>คุณต้องการลบใช่หรือไม่</h4>', `<i class="icon-info mr-2"></i>ลบ file`, 1);
      if(result){
        this._isLoading = true
        let dt = moment(this.calendar, "DD/MM/YYYY").add(7, "hours").format("DD/MM/YYYY")
        let res = await this.rp_S.rmPerCarlendar({
          station_id:this.values.id,dt:dt
        })
        if(res){
          let req1 = {station_id: this.values.id, dt: dt};
          let request_data = {url:`${this.apiService.api.perStaion}${this.apiService.api.del}`, request:req1};
          this.commonService.activityLog(ActionEnum.Delete, 'Traffic Information ➡ รายงานรายคันจากจุดสำรวจ ➡ Import', JSON.stringify(request_data)).subscribe(res => { }, error => { console.log(error); });
          
          const month = this.viewDate.getMonth();
          const year = this.viewDate.getFullYear();
          await this.getCalendar(month, year);
        }
        this._isLoading = false
      }
      // this.func.alertpopup("OK rm")
    }
    if(evt.title == 'Go to view') {
      this.router.navigate(['/traffictool/sensor-measurement', { 
      id: this.values.id,
      st_dt: moment(evt.start).toISOString(),
      en_dt : moment(evt.end).toISOString(),
      }])
      this._isLoading = false
      this.close()
    }
  }
  ChkArStr(txt){
    // return /[A-Za-z0-9]{4,20}/.test(txt)
    return /^[0-9|,]+$/.test(txt)
  }
  lineImg:string="assets/img/lineImg.PNG"
  openImg(){
    this.func.alertpopup(`<img src="${this.lineImg}" class="w-100">`)
    // this.swalImg.show();
    // Swal.fire({
    //   imageUrl: 'https://placeholder.pics/svg/300x1500',
    //   imageHeight: 600,
    //   imageAlt: 'A tall image'
    // })
  }
}
