<div class="col-12 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
  <div class="font-2">
    <i class="icon-calendar mr-2"></i> ปฏิทิน
  </div>
  <div class="d-flex align-items-center justify-content-start">
    <div class="ml-1 mr-1">ตำแหน่งป้าย :</div>
    <div class="ml-1 mr-1">
      <kendo-combobox [data]="data_board_list" [allowCustom]="allowCustom" [textField]="'name'" [valueField]="'id'"
        [value]="select_board" (valueChange)="handleValueBoard($event)" (filterChange)="handleFilterBoard($event)"
        [placeholder]="'ไม่ระบุ'" [filterable]="true">
      </kendo-combobox>
    </div>
    <button type="button" (click)="search()" class="btn btn-primary pull-right text-white ml-1"> <i
        class="fa fa-search"></i>
      ค้นหา </button>
  </div>
</div>
<div class="schedule-panel scrollbar-style bg-gray-800">
  <div class="d-flex align-items-center justify-content-end mb-2 mt-2 mr-3 ml-3">
    <button *ngIf="isAdd" type="button" (click)="openAddModal()" class="btn btn-primary pull-right text-white ml-1"> <i
        class="fa fa-plus"></i>
      เพิ่มชุดแสดงภาพตามเวลา </button>
  </div>
  <div class="mr-3 ml-3 mb-3 mt-0">

    <div class="row text-center">
      <div class="col-md-4">
        <div class="btn-group">
          <div class="btn btn-outline-secondary" (click)="decrement()" title="ก่อนหน้า">
            <span class="mr-1 ml-1"> &lsaquo; </span>
          </div>
          <div class="btn btn-primary" (click)="today()">
            วันนี้
          </div>
          <div class="btn btn-outline-secondary" (click)="increment()" title="ถัดไป">
            <span class="mr-1 ml-1"> &rsaquo; </span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h3>{{ viewDate | calendarDate: view + 'ViewTitle': locale }}</h3>
      </div>
      <div class="col-md-4">
        <div class="btn-group">
          <div class="btn btn-primary" (click)="view = 'month'" [ngClass]="{'active': view === 'month'}">
            เดือน
          </div>
          <div class="btn btn-primary" (click)="view = 'week'" [ngClass]="{'active': view === 'week'}">
            สัปดาห์
          </div>
          <div class="btn btn-primary" (click)="view = 'day'" [ngClass]="{'active': view === 'day'}">
            วัน
          </div>
        </div>
      </div>
    </div>
    <br />
    <div [ngSwitch]="view">
      <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [locale]="locale"
        [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" [weekStartsOn]="1"
        (eventClicked)="eventClicked($event)">
      </mwl-calendar-month-view>
      <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events" [weekStartsOn]="1"
        [locale]="locale">
      </mwl-calendar-week-view>
      <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events" [locale]="locale">
      </mwl-calendar-day-view>
    </div>
  </div>
</div>
<div bsModal #addModal="bs-modal" class="modal fade  bd-example-modal-xl" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}"
  [ngClass]="(searchBoardsignModal?._isShown)?'modal-hide-when-second-open':''">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span *ngIf="!schedule_select?.id">เพิ่ม</span><span
            *ngIf="schedule_select?.id">แก้ไข</span>ชุดแสดงภาพตามเวลา</h4>
        <button type="button" class="close text-light" (click)="addModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-body-h text-white">
        <div class="p-3">
          <div class="form-group row">
            <label class="col-lg-3 col-md-4 col-sm-4 col-form-label"> <span
                class="float-xs-left float-sm-right float-md-right">
                ชื่อชุดแสดงภาพ <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-9 col-md-8 col-sm-8">
              <input type="text" [(ngModel)]="input_name" name="text-struc-name" class="form-control" placeholder="" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-md-4 col-sm-4 col-form-label"> <span
                class="float-xs-left float-sm-right float-md-right">
                สี <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-9 col-md-8 col-sm-8">
              <div class="d-flex align-items-center justify-content-start w-100">
                <i *ngIf="color_modal" [ngStyle]="setColorStyles(color_modal?.primary)"></i>
                <kendo-combobox class="w-100" [data]="color_list" [allowCustom]="allowCustom" [textField]="'primary'"
                  [valueField]="''" [value]="color_modal" (valueChange)="handleValueColorModal($event)"
                  [placeholder]="'โปรดระบุ'" [filterable]="true" [valuePrimitive]="true">
                  <ng-template kendoComboBoxItemTemplate let-dataItem>
                    <i [style.color]="dataItem.primary" class="fa fa-circle mr-2"></i> {{ dataItem.primary }}
                  </ng-template>
                </kendo-combobox>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-md-4 col-sm-4 col-form-label"> <span
                class="float-xs-left float-sm-right float-md-right">
                รูปแบบการแสดง <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-9 col-md-8 col-sm-8">
              <kendo-combobox class="w-100" [data]="mode_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="mode_modal" (valueChange)="handleValueModeModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div *ngIf="mode_modal?.id == scheduleMode.NotRepeat" class="form-group row">
            <label class="col-lg-3 col-md-4 col-sm-4 col-form-label"> <span
                class="float-xs-left float-sm-right float-md-right">
                เริ่มต้น <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-9 col-md-8 col-sm-8">
              <kendo-datetimepicker class="w-100" [format]="'dd-MMM-yyyy HH:mm'" [(value)]="start_datetime"
                [formatPlaceholder]="formatPlaceholder">
              </kendo-datetimepicker>
            </div>
          </div>
          <div *ngIf="mode_modal?.id == scheduleMode.NotRepeat" class="form-group row">
            <label class="col-lg-3 col-md-4 col-sm-4 col-form-label"> <span
                class="float-xs-left float-sm-right float-md-right">
                สิ้นสุด <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-9 col-md-8 col-sm-8">
              <kendo-datetimepicker class="w-100" [format]="'dd-MMM-yyyy HH:mm'" [(value)]="stop_datetime"
                [formatPlaceholder]="formatPlaceholder">
              </kendo-datetimepicker>
            </div>
          </div>

          <div *ngIf="mode_modal?.id  == scheduleMode.EveryWeek" class="form-group row">
            <label class="col-lg-3 col-md-3 col-sm-3 col-form-label mt-1" for="text-sche-name"> <span
                class="float-xs-left float-sm-right float-md-right">
                เริ่มต้น <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
              <div class="d-flex flex-wrap  align-items-center">
                <div class="d-flex flex-wrap  align-items-center">
                  <div *ngFor="let d of days1;">
                    <button type="button" class="btn m-1" (click)="startDayOnChange(d.id)"
                      [ngClass]="(d?.id == start_day)? 'btn-primary':'btn-secondary'">{{d?.name}}</button>
                  </div>
                </div>
                <div class="d-flex align-items-center" style="min-width: 140px">
                  <div class="text-center" style="min-width: 50px">เวลา :</div>
                  <kendo-timepicker class="w-100" [format]="'HH:mm'" [(value)]="start_time"
                    [formatPlaceholder]="formatPlaceholder"></kendo-timepicker>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="mode_modal?.id  == scheduleMode.EveryWeek" class="form-group row">
            <label class="col-lg-3 col-md-3 col-sm-3 col-form-label mt-1" for="text-sche-name"> <span
                class="float-xs-left float-sm-right float-md-right">
                สิ้นสุด <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
              <div class="d-flex flex-wrap align-items-center">
                <div class="d-flex flex-wrap align-items-center">
                  <div *ngFor="let d of days1;">
                    <button type="button" class="btn m-1" (click)="stopDayOnChange(d.id)"
                      [ngClass]="(d?.id == stop_day)? 'btn-primary':'btn-secondary'">{{d?.name}}</button>
                  </div>
                </div>
                <div class="d-flex align-items-center" style="min-width: 140px">
                  <div class="text-center" style="min-width: 50px">เวลา :</div>
                  <kendo-timepicker class="w-100" [format]="'HH:mm'" [(value)]="stop_time"
                    [formatPlaceholder]="formatPlaceholder"></kendo-timepicker>
                </div>
              </div>

            </div>
          </div>
          <div
            *ngIf="mode_modal?.id == scheduleMode.EveryDay || mode_modal?.id == scheduleMode.EveryWeekDay || mode_modal?.id == scheduleMode.EveryWeekEnd"
            class="form-group row">
            <label class="col-lg-3 col-md-4 col-sm-4 col-form-label mt-1" for="text-sche-name"> <span
                class="float-xs-left float-sm-right float-md-right">
                เวลาเริ่มต้น <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-3 col-md-8 col-sm-8 mt-1">
              <kendo-timepicker class="w-100" [format]="'HH:mm'" [(value)]="start_time"
                [formatPlaceholder]="formatPlaceholder"></kendo-timepicker>
            </div>
            <label class="col-lg-3 col-md-4 col-sm-4 col-form-label mt-1" for="text-sche-name"> <span
                class="float-xs-left float-sm-right float-md-right">
                เวลาสิ้นสุด <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-3 col-md-8 col-sm-8 mt-1">
              <kendo-timepicker class="w-100" [format]="'HH:mm'" [(value)]="stop_time"
                [formatPlaceholder]="formatPlaceholder"></kendo-timepicker>
            </div>
          </div>
          <div
            *ngIf="mode_modal?.id == scheduleMode.EveryDay || mode_modal?.id == scheduleMode.EveryWeekDay || mode_modal?.id == scheduleMode.EveryWeekEnd"
            class="form-group row">
            <label class="col-lg-3 col-md-4 col-sm-4 col-form-label"> <span
                class="float-xs-left float-sm-right float-md-right">
                วันเริ่มต้น <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-9 col-md-8 col-sm-8">
              <kendo-datepicker class="w-100" [format]="'dd-MMM-yyyy'" [(value)]="start_datetime"
                [formatPlaceholder]="formatPlaceholder">
              </kendo-datepicker>
            </div>
          </div>
          <div
            *ngIf="mode_modal?.id == scheduleMode.EveryDay  || mode_modal?.id == scheduleMode.EveryWeekDay || mode_modal?.id == scheduleMode.EveryWeekEnd"
            class="form-group row">
            <label class="col-lg-3 col-md-4 col-sm-4 col-form-label"> <span
                class="float-xs-left float-sm-right float-md-right">
                วันสิ้นสุด <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-9 col-md-8 col-sm-8">
              <kendo-datepicker class="w-100" [format]="'dd-MMM-yyyy'" [(value)]="stop_datetime"
                [formatPlaceholder]="formatPlaceholder">
              </kendo-datepicker>
            </div>
          </div>

          <div *ngIf="mode_modal?.id  == scheduleMode.EveryWeek" class="form-group row">
            <label class="col-lg-3 col-md-4 col-sm-4 col-form-label"><span
                class="float-xs-left float-sm-right float-md-right">
                วันที่เริ่มต้น <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-9 col-md-8 col-sm-8">
              <kendo-datepicker class="w-100" [format]="'dd-MMM-yyyy'" [(value)]="start_datetime"
                [formatPlaceholder]="formatPlaceholder" [disabledDates]="disabledDatesStart" [navigation]="false">
              </kendo-datepicker>
            </div>
          </div>
          <div *ngIf="mode_modal?.id  == scheduleMode.EveryWeek" class="form-group row">
            <label class="col-lg-3 col-md-4 col-sm-4 col-form-label"> <span
                class="float-xs-left float-sm-right float-md-right">
                วันที่สิ้นสุด <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-9 col-md-8 col-sm-8">
              <kendo-datepicker class="w-100" [format]="'dd-MMM-yyyy'" [(value)]="stop_datetime"
                [formatPlaceholder]="formatPlaceholder" [disabledDates]="disabledDatesStop" [navigation]="false">
              </kendo-datepicker>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button type="button" class="btn m-1 btn-success" (click)="openSearchBoardModal()"><i
                class="fa fa-plus mr-2"> </i>เลือกป้าย<span *ngIf="boardAddList.length>=1">เพิ่ม</span></button>
          </div>
          <div class="mt-2" *ngFor="let item of boardAddList">
            <div class="d-flex justify-content-between align-items-center bg-gray-900 p-2"><span
                class="ml-1">{{item?.name}}</span><button title="ลบ" type="button"
                class="btn btn-sm btn-pill btn-danger" (click)="deleteBoardAddList(item)"><i
                  class="fa fa-times pointer"></i></button></div>
            <div class="d-flex justify-content-around bg-gray-800 p-2">
              <div>
                <div class="sign-dir-text mb-1 d-flex justify-content-center">ป้ายหน้า</div>
                <div *ngFor="let l of item?.layers_new;index as ii;">
                  <div class="d-flex justify-content-center flex-wrap">
                    <div *ngFor="let sign of l;index as iii;">
                      <div class="sign-box-search pointer"
                        [style.width.px]="get_sign_width(sign?.ratio,set_sign_height)"
                        [style.height.px]="set_sign_height" *ngIf="sign?.dir == signDir.Front"
                        (click)="clickSign(sign,item)">
                        <!-- <img [src]="sign?.media?.media?.path" height="{{set_sign_height}}"
                          width="{{get_sign_width(sign?.ratio,set_sign_height)}}"
                          *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                        <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height"
                          [ratio]="sign?.ratio" [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                        </app-sign-image-item>
                        <app-playlist-item class="pointer" [show_count]="true"
                          [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                          [playlist]="sign?.media?.items" [height]="set_sign_height"
                          [width]="get_sign_width(sign?.ratio,set_sign_height)">
                        </app-playlist-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="sign-dir-text mb-1 d-flex justify-content-center">ป้ายหลัง</div>
                <div *ngFor="let l of item?.layers_new;index as ii;" [style.height.px]="set_sign_height+4">
                  <div class="d-flex justify-content-center flex-wrap">
                    <div *ngFor="let sign of l;index as iii;">
                      <div class="sign-box-search pointer"
                        [style.width.px]="get_sign_width(sign?.ratio,set_sign_height)"
                        [style.height.px]="set_sign_height" *ngIf="sign?.dir == signDir.Back"
                        (click)="clickSign(sign,item)">
                        <!-- <img [src]="sign?.media?.media?.path" height="{{set_sign_height}}"
                          width="{{get_sign_width(sign?.ratio,set_sign_height)}}"
                          *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                        <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height"
                          [ratio]="sign?.ratio" [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                        </app-sign-image-item>
                        <app-playlist-item class="pointer" [show_count]="true"
                          [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                          [playlist]="sign?.media?.items" [height]="set_sign_height"
                          [width]="get_sign_width(sign?.ratio,set_sign_height)">
                        </app-playlist-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div class="modal-footer d-flex justify-content-end bg-gray-900">
        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-secondary" (click)="addModal.hide()">ยกเลิก</button>
          <button [disabled]="!(input_name != null && start_datetime != null && stop_datetime != null)"
            *ngIf="schedule_select?.id" type="button" class="btn btn-primary text-white ml-2"
            (click)="editSchedule()">บันทึก</button>
          <button [disabled]="!(input_name != null && start_datetime != null && stop_datetime != null)"
            *ngIf="!schedule_select?.id" type="button" class="btn btn-primary text-white ml-2"
            (click)="addSchedule()">บันทึก</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #deleteModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i>ลบชุดแสดงภาพตามเวลา</h4>
        <button type="button" class="close text-light" (click)="deleteModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt-2 text-center">
          <h4>คุณต้องการลบใช่หรือไม่</h4>
        </div>
        <div class="mt-3 mb-4 text-center">
          <h5>{{schedule_select?.name}}</h5>
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="deleteModal.hide()">ยกเลิก</button>
          <!-- <button type="button" class="btn btn-primary ml-2" (click)="deleteType(newsTypeItem.id)">ยืนยัน</button> -->
          <button type="button" class="btn btn-primary text-white ml-2" (click)="deleteSchedule()">ยืนยัน</button>
        </div>
      </div>
      <!-- <div class="modal-footer">
          
        </div> -->
    </div>
  </div>
</div>
<div bsModal #searchBoardsignModal="bs-modal" class="modal fade  bd-example-modal-xl" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="onHideSearchBoardsignModal()"
  [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i>เลือกป้ายเพิ่ม</h4>
        <button type="button" class="close text-light" (click)="searchBoardsignModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-body-h text-white">
        <div class="row">
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div>ชื่อป้าย :</div>
            <div>
              <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="" maxlength="50"
                  [(ngModel)]="search_name" [ngModelOptions]="{standalone: true}"></div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div>ทางหลวง :</div>
            <div>
              <kendo-combobox class="w-100" [data]="data_route" [allowCustom]="allowCustom" [textField]=""
                [valueField]="" [value]="search_route" (valueChange)="handleValueRoute($event)"
                (filterChange)="handleFilterRoute($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div>ช่วงกิโลเมตร :</div>
            <div class="d-flex align-items-center">
              <div class="flex-fill"><span class="nowrap mr-1">เริ่มต้น :</span></div>
              <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly
                  maxlength="4" [(ngModel)]="search_km_st" [ngModelOptions]="{standalone: true}"></div>
              <div class="flex-fill ml-1 mr-1"> + </div>
              <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly
                  maxlength="3" [(ngModel)]="search_m_st" [ngModelOptions]="{standalone: true}"></div>
              <div class="flex-fill"><span class="nowrap ml-1 mr-1">สิ้นสุด :</span></div>
              <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly
                  maxlength="4" [(ngModel)]="search_km_en" [ngModelOptions]="{standalone: true}"></div>
              <div class="flex-fill ml-1 mr-1"> + </div>
              <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly
                  maxlength="3" [(ngModel)]="search_m_en" [ngModelOptions]="{standalone: true}"></div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div>สถานะ :</div>
            <div>
              <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
                [filterable]="true" [valueField]="'id'" [value]="search_status"
                (valueChange)="handleValueStatus($event)" [placeholder]="'ทั้งหมด'">
              </kendo-combobox>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 d-flex align-items-end justify-content-end">
            <div class="mt-1">
              <button type="button" (click)="searchBoard()" class="btn btn-primary pull-right text-white ml-1"> <i
                  class="fa fa-search"></i>
                ค้นหา </button>
            </div>
          </div>
          <div class="col-12 p-0 table-panel-body-height scrollbar-style">
            <div class="p-3">
              <table class="table table-outline mb-0">
                <thead class="thead-dark" style="background-color: black;">
                  <tr style="height: 50px;" class="align-items-center">
                    <th class="text-center text-light"><i class="fa fa-hashtag"></i></th>
                    <th class="text-center text-light">การเชื่อมต่อ</th>
                    <th class="text-center text-light">ชื่อป้าย</th>
                    <th class="text-center text-light">ตำแหน่ง</th>
                    <th class="text-center text-light">กลุ่ม</th>
                    <th class="text-center text-light">วันที่แก้ไขล่าสุด</th>
                    <th class="text-center text-light">Auto Suggestion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="!this.is_loading_search && itemList?.length == 0">
                    <td class="text-center" colspan="7" style="height: 50px;">
                      ไม่พบข้อมูล
                    </td>
                  </tr>
                  <tr *ngIf="this.is_loading_search">
                    <td class="text-center" colspan="7" style="height: 50px;">
                      กำลังโหลด ..
                    </td>
                  </tr>
                  <ng-template ngFor let-p [ngForOf]="itemList" let-i="index">
                    <tr class="bg-gray-900 table-no-border sign-dir-text" style="border-width: 0">
                      <td class="d-flex justify-content-center">
                        <label title="เลือกป้าย" class="checkbox-container">
                          <input type="checkbox" [value]="p.id" [(ngModel)]="p.check"
                            (change)="checkValue($event,p?.id)">
                          <span class="checkmark-box"></span>
                        </label>
                      </td>
                      <td>
                        <div>
                          <span class="fa fa-2x fa-wifi"
                            [ngClass]="(p?.connection_status == this.connectionStatusEnum.Online)?'text-success':'text-danger'"
                            [title]="(p?.connection_status == this.connectionStatusEnum.Online)? 'Online' : 'Offline'">

                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-content-start align-items-center">
                          <div class="mr-1 rounded-circle bg-info d-flex justify-content-center align-items-center"
                            style="width:auto;height:auto;min-height:32px;min-width:32px">
                            <strong>{{(i+1)+((this.currentPage - 1)*itemsPerPage)}}</strong></div>
                          <div> {{p?.name}}</div>
                        </div>
                      </td>
                      <td>
                        <div>{{p?.station?.station_name}}</div>
                      </td>
                      <td>
                        <div>{{p?.device_group?.group_name}}</div>
                      </td>
                      <td class="text-center">
                        <div>{{p?.lastupdate_date| date:'yyyy.MM.dd HH:mm'}}</div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center justify-content-center">
                          <label class="switch switch-label switch-sm switch-pill switch-success b-0">
                            <input [disabled]="true" checked={{p.auto_suggestion}} type="checkbox" class="switch-input">
                            <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr style="min-height: 150px;" class="bg-gray-400">
                      <td style="height: 50px !important;" class="text-center text-dark" colspan="7"
                        *ngIf="!(p?.signs?.length>0)">
                        <div>ยังไม่มีป้ายแสดงภาพที่ตำแหน่งนี้</div>
                      </td>
                      <td class="text-center" colspan="10" *ngIf="(p?.signs?.length>0)">
                        <div *ngIf="is_loading_search" class="d-flex align-items-center justify-content-center">
                          <div class="lds-dual-ring"></div>
                        </div>
                        <div class="d-flex justify-content-start">
                          <span *ngIf="p?.status == this.statusEnum.Active" class="badge badge-success">Active</span>
                          <span *ngIf="p?.status == this.statusEnum.Inactive"
                            class="badge badge-danger">Defective</span>
                          <span *ngIf="p?.status == this.statusEnum.Ma" class="badge badge-warning">MA</span>
                        </div>
                        <div class="d-flex justify-content-around">
                          <div>
                            <div class="sign-dir-text mb-1 text-dark d-flex justify-content-center"><span
                                class="small">ป้ายหน้า</span></div>
                            <div *ngFor="let l of p?.layers;index as ii;">
                              <div class="d-flex justify-content-center flex-wrap">
                                <div *ngFor="let sign of l;index as iii;">
                                  <div class="sign-box-search"
                                    [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)"
                                    [style.height.px]="set_sign_height_small" *ngIf="sign?.dir == signDir.Front">
                                    <!-- <img [src]="sign?.media?.media?.path" height="{{set_sign_height_small}}"
                                      width="{{get_sign_width(sign?.ratio,set_sign_height_small)}}"
                                      *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                                    <app-sign-image-item [media]="sign?.media?.media"
                                      [sign_height]="set_sign_height_small" [ratio]="sign?.ratio"
                                      [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                                      *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                                    </app-sign-image-item>
                                    <app-playlist-item [show_count]="true"
                                      [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                                      *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                                      [playlist]="sign?.media?.items" [height]="set_sign_height_small"
                                      [width]="get_sign_width(sign?.ratio,set_sign_height_small)">
                                    </app-playlist-item>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="sign-dir-text mb-1 text-dark d-flex justify-content-center"><span
                                class="small">ป้ายหลัง</span></div>
                            <div *ngFor="let l of p?.layers;index as ii;" [style.height.px]="set_sign_height_small+4">
                              <div class="d-flex justify-content-center flex-wrap">
                                <div *ngFor="let sign of l;index as iii;">
                                  <div class="sign-box-search"
                                    [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)"
                                    [style.height.px]="set_sign_height_small" *ngIf="sign?.dir == signDir.Back">
                                    <!-- <img [src]="sign?.media?.media?.path" height="{{set_sign_height_small}}"
                                      width="{{get_sign_width(sign?.ratio,set_sign_height_small)}}"
                                      *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                                    <app-sign-image-item [media]="sign?.media?.media"
                                      [sign_height]="set_sign_height_small" [ratio]="sign?.ratio"
                                      [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                                      *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                                    </app-sign-image-item>
                                    <app-playlist-item [show_count]="true"
                                      [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                                      *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                                      [playlist]="sign?.media?.items" [height]="set_sign_height_small"
                                      [width]="get_sign_width(sign?.ratio,set_sign_height_small)">
                                    </app-playlist-item>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer table-header d-flex align-items-center justify-content-between">
        <div class="mr-2">
          <pagination [totalItems]="totalItems" [maxSize]="5" [itemsPerPage]="itemsPerPage" [(ngModel)]="currentPage"
            (pageChanged)="pageChanged($event)" class="pagination p-0 m-0" previousText="&lsaquo;" nextText="&rsaquo;"
            [boundaryLinks]="true" firstText="&laquo;" lastText="&raquo;"></pagination>
        </div>
        <div></div>
        <div class="ml-2 d-flex align-items-center">
          <button type="button" class="btn btn-danger ml-1 mr-1" (click)="searchBoardsignModal.hide()">
            ปิด </button>
          <button *ngIf="board_select_list_search.length>0" type="button" class="btn btn-secondary ml-1 mr-1"
            (click)="clickClearBoardsignSelect()">
            Clear </button>
          <button type="button" class="btn btn-info text-white ml-1" (click)="addSelectBoard()"
            [disabled]="board_select_list_search.length==0"> <span class="mr-1"
              *ngIf="board_select_list_search.length>0">{{board_select_list_search.length}}</span> <i
              class="fa fa-eye"></i>
            เลือก</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #mediaListModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog  modal-xl" role="document">
    <div class="modal-content">
      <!-- <div class="modal-body"> -->
      <div *ngIf="sign_select!= null">
        <app-media-selector-all [sign_height]="100" [sign]="sign_select"
          (onComponentClosed)="closeMediaModalChangeMediaNew($event)">
        </app-media-selector-all>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>

<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>
