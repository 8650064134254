export const perlist = [
    {
        control: "0100",
        createdate: "2021-05-06T09:20:45.000Z",
        district: "แม่สอด",
        head: "4",
        id: 24,
        km: 7,
        landmark: null,
        lastupdate: "2021-05-06T09:20:45.000Z",
        latitude: 16.7227,
        lipda1: "null-null-null",
        lipda2: "90-20-125",
        longitude: 98.575,
        m: 280,
        model_id: 68,
        name: "กลางสะพานมิตรภาพที่แม่สอด(เขตแดน ไทย / พม่า) - แม่ละเมา",
        num_lane: 4,
        province: "แม่สอด",
        road_district: "ตากที่ 2 (แม่สอด)",
        road_subdistrict: null,
        route: 12,
        short_name: null,
        status: 1,
        surface_type: "AC",
        survey: "PER-7-029",
    },
    {
        control: "0602",
        createdate: "2021-05-06T09:29:13.000Z",
        district: "ปัว",
        head: "2",
        id: 25,
        km: 421,
        landmark: null,
        lastupdate: "2021-05-06T09:29:13.000Z",
        latitude: 19.1746,
        lipda1: "null-null-null",
        lipda2: "null-null-null",
        longitude: 100.861777,
        m: 675,
        model_id: 68,
        name: "ท่าวังผา - ปางหก",
        num_lane: 2,
        province: "น่าน",
        road_district: "น่านที่ 2",
        road_subdistrict: null,
        route: 101,
        short_name: null,
        status: 1,
        surface_type: "AC",
        survey: "PER-8-016",
    },
    {
        control: "0203",
        createdate: "2021-05-06T09:37:23.000Z",
        district: "เชียงของ",
        head: "2",
        id: 26,
        km: 126,
        landmark: null,
        lastupdate: "2021-05-06T09:37:23.000Z",
        latitude: 20.169875,
        lipda1: "null-null-null",
        lipda2: "null-null-null",
        longitude: 100.407564,
        m: 955,
        model_id: 68,
        name: "ขุนตาล - เชียงของ",
        num_lane: 4,
        province: "เชียงราย",
        road_district: "เชียงรายที่ 2",
        road_subdistrict: null,
        route: 1020,
        short_name: null,
        status: 1,
        surface_type: "AC",
        survey: "PER-9-003"
    }
]