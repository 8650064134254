<!-- <div class="camera-panel bg-gray-700">
  <div class="w-100">
    <div class="header-info bg-gray-900 d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center ml-3">
          <h5> {{item?.view}} ทล.{{station?.route_id}}
              ( {{station?.km}}+{{station?.m | number:'3.0-0'}} )</h5>
      </div>
      <div>
        <i title="Close" class="fa fa-close fa-2x text-light pull-right mr-3 pointer close" (click)="clickClose()"></i>
      </div>
    </div>
    <div class="img1-panel d-flex justify-content-center scrollbar-style">
      <img imgloadfail width="auto" height="100%" *ngIf="src" [src]="src" default="assets/img/no-image.png" />
    </div>
  </div>
</div> -->

<div bsModal #infoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <!-- <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="fa fa-cog mr-2"></i><span>Sign setting
          </span>
        </h4>
        <button type="button" class="close text-light" (click)="imModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <div class="modal-body body-panel-h">
        <div class="camera-panel bg-gray-700">
          <div class="w-100">
            <div class="header-info bg-gray-950 d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center p-3">
                  <h5> {{item?.view}} ทล.{{station?.route_id}}
                      ( {{station?.km}}+{{station?.m | number:'3.0-0'}} )</h5>
              </div>
              <div>
                <i title="Close" class="fa fa-close fa-2x text-light pull-right mr-3 pointer close" (click)="clickClose()"></i>
              </div>
            </div>
            <div class="img1-panel d-flex justify-content-center scrollbar-style">
              <img imgloadfail width="auto" height="100%" *ngIf="src" [src]="src" default="assets/img/no-image.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>