<div class="modal-header">
    <h4 class="modal-title pull-left">Reversible lane</h4>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body text-center">
    <div class="row txtblog-BG">
      <div class="col-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">หมายเลขทางหลวง</span>
          </div>
          <kendo-combobox class="form-control" [data]="setRouteList"
          [allowCustom]="allowCustom" [suggest]="true" [(ngModel)]="route_id" [filterable]="true"
          (filterChange)="FilterRoute($event)"
           [placeholder]="'ทั้งหมด'">
          </kendo-combobox>
        </div>
      </div>
      <div class="col-6">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">ช่วงกิโลเมตรเริ่มต้น</span>
          </div>
          <input type="number" class="form-control" [(ngModel)]="start_km" min="0" (change)="start_km = checkDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text">+ </span>
          </div>
          <input type="number" class="form-control" [(ngModel)]="start_m" min="0" (change)="start_m = checkDegree($event)">
          
          <div class="input-group-prepend">
            <span class="input-group-text">สิ้นสุด </span>
          </div>
          <input type="number" class="form-control" [(ngModel)]="end_km" min="0" (change)="end_km = checkDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text">+ </span>
          </div>
          <input type="number" class="form-control" [(ngModel)]="end_m" min="0" (change)="end_m = checkDegree($event)">
        </div>
      </div>
      <!-- <div class="col-6">
        <div class="input-group">
        </div>
      </div> -->
      <!-- <div class="col-6">
  
        <div class="input-group">
          <div class="input-group-prepend">
          </div>
          <div class="d-flex align-items-center chkbox-form-control" style="flex: 1 1 auto;    border-radius: 5px;">
            <input class="ml-2" type="checkbox" name="pvr" id="p1">
            <label style="margin-top: 5px;" class="ml-2" for="p1">
              ขาเข้า
            </label>
            <input class="ml-2" type="checkbox" name="pvr" id="p2">
            <label style="margin-top: 5px;" class="ml-2" for="p2">
              ขาออก
            </label>
          </div>
  
        </div>
      </div> -->
      <div class="col-2 text-right d-flex align-items-center justify-content-end">
        <button style="max-height: 33px;" class="ml-3 mb-3 btn btn-info" (click)="search()">Search</button>
      </div>
    </div>
    <div class="row">
      <div *ngIf="view == 1" class="col-12 bg-gray-900 pt-3">
        
        <table class="table table-bordered table-no-border table-hover table-outline">
          <thead>
            <tr>
              <th>#</th>
              <th>Sensor</th>
              <th>Create date</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody *ngIf="!values||!values.length">
            <td colspan="4">Nodata</td>
          </tbody>
          <tbody *ngIf="values.length">
            <tr class="pointer" *ngFor="let i of values;index as idx">
              <td (click)="i.checked = !i.checked">  
                <input type="checkbox" name="sensor" class="mr-2" [id]="i.name+i.device_id" [(ngModel)]="i.checked">
                <label [for]="i.name+i.device_id">{{idx + 1}}</label>
              </td>
              <td (click)="i.checked = !i.checked">
                {{i.name}}
              </td>
              <td (click)="i.checked = !i.checked">{{i.create_date | date:'dd/MM/yyyy HH:mm:ss':'+0700'}}</td>
              <td (click)="i.checked = !i.checked">
                <div [ngClass]="{'btn-success': i.status==1,'btn-danger': i.status==0}" class="btn">
                  <i class="icon-social-spotify"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <!-- <div [ngClass]="{'d-none': view == 1}" class="col-12 text-left bg-gray-900 ">
        <div id="map_addsensor" class="map" style="height: 50vh;"></div>
      </div> -->
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-primary btn-sm" (click)="config.animated = !config.animated">{{config.animated ? 'Disable' : 'Enable'}} animation</button> -->
    <button type="button" class="btn btn-danger" (click)="close()">ปิด</button>
    <!-- <button type="button" class="btn btn-danger" (click)="cancel()">No</button> -->
    <button type="button" class="btn btn-info" (click)="save()"
      [disabled]="(!values||!values.length) ? 'disabled': null">ยืนยัน</button>
  </div>