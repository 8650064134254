import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { OwnerService } from '../../../services/assets-management/owner.service';
import { Owner, ReqAddOwner } from '../../../models/assets-management/owner';
import { ActionEnum, StatusEnum } from '../../../models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { permission } from 'src/app/views/assets-management/_menu';
@Component({
  selector: 'app-owner',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.scss']
})
export class OwnerComponent implements OnInit {
  @ViewChild('emailInput', { static: false }) emailInput: ElementRef;
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  statusEnum = StatusEnum;
  myDate = new Date();
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  data_list: Owner[];
  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" }
  ];
  status: any;
  selectItem: any = {
    company_name: "",
    phone_number: "",
    address: "",
    contact_name: "",
    detail: "",
    email: "",
    hashtag: "",
    status: null
  };
  public allowCustom: boolean = false;
  res_message: any;
  status_modal: any;
  search_company_name: any;
  search_hashtag: any;

  isAdd: boolean = false;
  isEdit: boolean = false;
  constructor(private commonService: CommonService, private ownerService: OwnerService) { 
    this.commonService.activityLog(ActionEnum.Get, `Assets Management ➡ Configuration ➡ Owner`).subscribe(res => { }, error => { console.log(error); });
  }

  async ngOnInit() {
    this.is_loading = true;
    this.getOwner();
    this.isAdd = await this.commonService.getPermission(permission.configuration.owner.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.configuration.owner.edit_id).toPromise();
  }

  handleValueStatus(value) {
    if (value) {
      //console.log(value);
      this.status = value;
    } else {
      this.status = null;
    }
  }
  is_loading: boolean = false;

  getOwner() {
    this.data_list = [];
    this.ownerService.getOwner(this.currentPage, this.itemsPerPage, null, this.search_company_name, this.search_hashtag).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = res.data.data;
              this.totalItems = res.data.total_items;
              console.log(this.data_list);
            }else{
              this.totalItems = 0;
            }
          }else{
            this.totalItems = 0;
          }
        }else{
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
      });
  }
  openAddModel() {
    this.selectItem = new Owner();
    this.selectItem.company_name = "";
    this.selectItem.phone_number = "";
    this.selectItem.address = "";
    this.selectItem.contact_name = "";
    this.selectItem.detail = "";
    this.selectItem.email = "";
    this.selectItem.hashtag = "";
    this.selectItem.status = null;
    this.status_modal = null;
    this.addModal.show();
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.addModal.show();
  }
  openEditModel(item?: any) {
    this.selectItem = item;
    if (item.phone_number) {
      this.phoneValidate(item.phone_number);
    }
    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.addModal.show();
  }
  openDeleteModel(item?: any) {
    this.selectItem = item;
    this.deleteModal.show();
  }
  clearData() {
    this.selectItem = new ReqAddOwner();
  }
  handleValueStatusModal(value) {
    if (value) {
      //console.log(value);
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getOwner();
  }
  addOwner() {
    this.is_loading = true;
    let req = new ReqAddOwner();
    req.company_name = this.selectItem.company_name;
    req.phone_number = this.selectItem.phone_number;
    req.address = this.selectItem.address;
    req.contact_name = this.selectItem.contact_name;
    req.detail = this.selectItem.detail;
    req.email = this.selectItem.email;
    req.hashtag = this.selectItem.hashtag;
    req.status = this.status_modal.id;
    this.res_message = "";
    this.ownerService.addOwner(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { url: `${this.ownerService.domain}/${this.ownerService.uri}`, request: req, response: res };
            this.commonService.activityLog(ActionEnum.Add, `Assets Management ➡ Configuration ➡ Owner`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `เพิ่ม Owner สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getOwner();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editOwner() {
    this.is_loading = true;
    let req = new ReqAddOwner();
    req.company_name = this.selectItem.company_name;
    req.phone_number = this.selectItem.phone_number;
    req.address = this.selectItem.address;
    req.contact_name = this.selectItem.contact_name;
    req.detail = this.selectItem.detail;
    req.email = this.selectItem.email;
    req.hashtag = this.selectItem.hashtag;
    req.status = this.status_modal.id;
    this.res_message = "";
    this.ownerService.editOwner(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { id: this.selectItem.id, request: req, response: res, url: `${this.ownerService.domain}/${this.ownerService.uri}/${this.selectItem.id}` };
            this.commonService.activityLog(ActionEnum.Edit, `Assets Management ➡ Configuration ➡ Owner`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `แก้ไข Owner สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getOwner();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  deleteOwner() {
    this.is_loading = true;
    let req = new ReqAddOwner();
    req.company_name = this.selectItem.company_name;
    req.phone_number = this.selectItem.phone_number;
    req.address = this.selectItem.address;
    req.contact_name = this.selectItem.contact_name;
    req.detail = this.selectItem.detail;
    req.email = this.selectItem.email;
    req.hashtag = this.selectItem.hashtag;
    this.res_message = "";
    this.ownerService.deleteOwnerEditStatus(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.commonService.activityLog(ActionEnum.Delete, `Assets Management ➡ Configuration ➡ Owner`, JSON.stringify({ id: this.selectItem.id, url: `${this.ownerService.domain}/${this.ownerService.uri}/${this.selectItem.id}` })).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `ลบ Owner สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getOwner();
            }, 100);
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  //phone
  valid_phone: boolean = null;
  phoneValidate(e) {
    if (e) {
      if (e.length == 10 || e.length == 9) {
        this.valid_phone = true;
      } else {
        this.valid_phone = false;
      }
    } else {
      this.valid_phone = null;
    }
  }
  valid_email: boolean = null;
  emailOnValidatorChange(e) {
    this.valid_email = this.emailInput.nativeElement.validity.valid;
  }

}
