import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiResponse, PagableData } from '../../models/common';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { Brand, ReqAddBrand, BrandModel, ReqAddBrandModel } from '../../models/assets-management/brand';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { StatusEnum } from '../../models/common-enum';
@Injectable({
  providedIn: 'root'
})
export class BrandService {
  domain: string = environment.api.domain_assets;;
  uri = `brand`;
  uri_sub = `model`;
  constructor(private http: HttpClient, private authService: AuthService) {
  }
  getBrand(page?: number, limit?: number, short?: number, brand_name?: string) {
    let params = new HttpParams();
    if (!_.isNil(page)) {
      params = params.append('page', page.toString());
    }
    if (!_.isNil(limit)) {
      params = params.append('limit', limit.toString());
    }
    if (!_.isNil(short)) {
      params = params.append('short', short.toString());
    }
    if (!_.isNil(brand_name)) {
      params = params.append('brand_name', brand_name.toString());
    }
    const uri = `${this.uri}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<Brand[]>>>(url, httpOptions);
    // let url = 'assets/data/owner.json';
    // return this.http.get<ApiResponse<PagableData<Brand[]>>>(url);
  }
  getBrandById(id?: number) {
    const uri = `${this.uri}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<Brand[]>>(url, httpOptions);
  }
  addBrand(req: ReqAddBrand): Observable<ApiResponse<any>> {
    const uri = `${this.uri}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }

  editBrand(req: ReqAddBrand, id?: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteBrand(id: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
  deleteBrandEditStatus(req: ReqAddBrand, id?: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `${this.uri}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }

  getBrandModel(page?: number, limit?: number, short?: number, brand_id?: number) {
    let params = new HttpParams();
    if (!_.isNil(page)) {
      params = params.append('page', page.toString());
    }
    if (!_.isNil(limit)) {
      params = params.append('limit', limit.toString());
    }
    if (!_.isNil(short)) {
      params = params.append('short', short.toString());
    }
    const uri = `${this.uri}/${brand_id}/${this.uri_sub}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<BrandModel[]>>>(url, httpOptions);
    // let url = 'assets/data/owner.json';
    // return this.http.get<ApiResponse<PagableData<VendorContact[]>>>(url);
  }
  getBrandModelById(id?: number, brand_id?: number) {
    const uri = `${this.uri}/${brand_id}/${this.uri_sub}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<BrandModel[]>>(url, httpOptions);
  }
  addBrandModel(req: ReqAddBrandModel, brand_id?: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${brand_id}/${this.uri_sub}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }

  editBrandModel(req: ReqAddBrandModel, id?: number, brand_id?: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${brand_id}/${this.uri_sub}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }

  deleteBrandModel(id: number, brand_id?: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${brand_id}/${this.uri_sub}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }

  deleteBrandModelEditStatus(req: ReqAddBrandModel, id?: number, brand_id?: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `${this.uri}/${brand_id}/${this.uri_sub}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
}