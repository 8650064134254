export class IncVictim {
    label?: string;
    v_dead?: number;
    v_inj?: number;
}
export class IncChart {
    label?: string;
    v_inc?: number;
}
export class VolumeClass {
    class?: number;
    volume?: string;
}
export class VolumeByClass {
    label?: string;
    v_avg?: number;
    v_max?: number;
    v_min?: number;
}

//New Dashboard
export class PercentageVolumeClass {
    percentage?: number;
    veh_class_id?: number;
    veh_class_name?: number;
    volume?: number;

    per?: string;
}

export class StatInfo {
    flow?: number;
    speed?: number;
    occupancy?: number;
}

export class TimeseriesInfo {
    occupancy?: TimeseriesItemInfo[];
    speed?: TimeseriesItemInfo[];
    volume?: TimeseriesItemInfo[];
}

export class TimeseriesItemInfo {
    hour?: string;
    current_value?: number;
    avg_old_value?: number;
    max_old_value?: number;
    min_old_value?: number;
    predict?: number;
    is_current?: number;
}

export class ReversibleTraveltimePointInfo {
    name?: string;
    code?: string;
}
export class ReversibleInfo {
    in?: ReversibleDirection;
    out?: ReversibleDirection;
}
export class ReversibleDirection {
    normal?: ReversibleData;
    reverse?: ReversibleData;
}
export class ReversibleData {
    avg_speed?: any;
    flow?: any;
    flow_graph?: TimeseriesItemInfo[];
    avg_speed_graph?: TimeseriesItemInfo[];
}