import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { BoardsignComponent } from './boardsign/boardsign.component';
import { ATISComponent } from './atis/atis.component';
import { MediaComponent } from './media/media.component';
import { PlaylistComponent } from './playlist/playlist.component';
import { SignTypeComponent } from './sign-type/sign-type.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { VSWComponent } from './vsw/vsw.component';

export let routes: Routes = [
  {
    path: '',
    redirectTo: 'sign',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'sign',
        component: BoardsignComponent
      },
      {
        path: 'sign/:id',
        component: BoardsignComponent
      },
      {
        path: 'atis',
        component: ATISComponent
      },
      {
        path: 'vsw',
        component: VSWComponent
      },
      {
        path: 'vsw/:id',
        component: VSWComponent
      },
      {
        path: 'media',
        component: MediaComponent
      },
      {
        path: 'playlist',
        component: PlaylistComponent
      },
      {
        path: 'sign-type',
        component: SignTypeComponent
      },
      {
        path: 'schedule',
        component: ScheduleComponent
      },
      {
        path: 'schedule/:boardsign_id',
        component: ScheduleComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SignControlRoutingModule { }
