<div class="d-flex">
    <div *ngFor="let menu of menuList; let i = index"
        class="p-2 flex-fill d-flex justify-content-center align-items-center disable-select"
        [ngClass]="(route == menu?.url)?'menu-item-active':'menu-item'" (click)="changeMenu(menu)">
        <i class="{{menu?.icon}} mr-1"></i>
        <span>{{menu?.name}}</span>
    </div>
</div>
<div>
    <router-outlet></router-outlet>
</div>