import { Component, OnInit } from '@angular/core';
import { configurationMenuItems } from '../_menu';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserProfile } from 'src/app/models/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-configuration-menu',
  templateUrl: './configuration-menu.component.html',
  styleUrls: ['./configuration-menu.component.scss']
})
export class ConfigurationMenuComponent implements OnInit {

  currentUser: UserProfile;
  currentUserSubscription: Subscription;
  route: any;
  menuSelect: any;
  //menuList = menuItems;
  menuList = [];
  constructor(private router: Router, private authService: AuthService) {
    router.events.subscribe((val) => {
      this.updateRouter();
    });
    this.menuList = [];
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
      if (user) {
        if (user.permission.length > 0) {
          configurationMenuItems.forEach(item => {
            const find = user.permission.find(x => x == item.id);
            if (find)
              this.menuList.push(item);
          });
        }
      }
    });
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   if(this.menuList.length>0){
    //     this.menuSelect = this.menuList[0];
    //     this.router.navigate([this.menuSelect.url]);
    //   }
    // }, 1000);
  }
  updateRouter() {
    this.route = this.router.url;
  }
  changeMenu(m?: any) {
    if (this.menuSelect != m) {
      this.menuSelect = m;
      this.router.navigate([m.url]);
    }
  }

}
