<div bsModal #settingModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" (onHide)="clearSetting()" [config]="{backdrop: 'static'}"
  [ngClass]="((sensorSignalModal?._isShown) || (matchSensorModal?._isShown) || (addTextItemModal?._isShown) || (signSpecialItemModal?._isShown) || (addSpecialItemModal?._isShown))?'modal-hide-when-second-open':''">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="fa fa-cog mr-2"></i><span>Sign setting
          </span>
        </h4>
        <button type="button" class="close text-light" (click)="clickCloseSettingModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body body-panel-h">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <div class="col-md-12 col-lg-12 col-xl-12">
              <div class="d-flex justify-content-center align-items-center h-100 flex-wrap">
                <span *ngIf="boardsignInfo?.sensor_id" class="ml-1 mr-1"> Sensor<span class="text-danger">*</span> :
                  {{boardsignInfo?.sensor_name}}</span>
                <button type="button" (click)="openSensorBoardModal()" class="btn btn-primary text-white ml-1 mr-1">
                  <span *ngIf="!boardsignInfo?.sensor_id">กรุณา</span>เลือก Sensor</button>
                <button type="button" [disabled]="!boardsignInfo?.sensor_id" (click)="openSensorSignalModal()"
                  class="btn btn-primary text-white ml-1 mr-1">
                  Edit Sensor signal</button>
              </div>
            </div>
          </div>
        </form>
        <div class="bg-gray-800 p-3 rounded">
          <div class="d-flex justify-content-center">
            <div class="mr-2">
              <div class="sign-dir-text mb-1 text-dark d-flex justify-content-center"><span
                  class="small text-light">ป้ายหน้า</span></div>
              <div *ngFor="let l of boardsignInfo?.layers;index as ii;">
                <div class="d-flex justify-content-center flex-wrap">
                  <div *ngFor="let sign of l;index as iii;">
                    <div [ngClass]="(sign?.id == selectsignItem?.id)?'sign-box-select':''"
                      *ngIf="sign?.dir == signDir.Front">
                      <div class="pointer" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height)"
                        [style.height.px]="set_sign_height" *ngIf="sign?.dir == signDir.Front" (click)="clickSign(sign)"
                        [ngClass]="(sign?.id != selectsignItem?.id)?'sign-box':''">
                        <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height"
                          [ratio]="sign?.ratio" [is_board]="true" [config_road]="config_road"
                          [rain]="boardsignInfo?.rain" [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                        </app-sign-image-item>
                        <app-playlist-item [show_count]="true" [config_road]="config_road" [rain]="boardsignInfo?.rain"
                          [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                          [playlist]="sign?.media?.items" [height]="set_sign_height"
                          [width]="get_sign_width(sign?.ratio,set_sign_height)">
                        </app-playlist-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-2">
              <div class="sign-dir-text mb-1 text-dark d-flex justify-content-center"><span
                  class="small text-light">ป้ายหลัง</span></div>
              <div *ngFor="let l of boardsignInfo?.layers;index as ii;" [style.height.px]="set_sign_height+4">
                <div class="d-flex justify-content-center flex-wrap">
                  <div *ngFor="let sign of l;index as iii;">
                    <div [ngClass]="(sign?.id == selectsignItem?.id)?'sign-box-select':''"
                      *ngIf="sign?.dir == signDir.Back">
                      <div class="pointer" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height)"
                        [style.height.px]="set_sign_height" *ngIf="sign?.dir == signDir.Back" (click)="clickSign(sign)"
                        [ngClass]="(sign?.id != selectsignItem?.id)?'sign-box':''">
                        <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height"
                          [ratio]="sign?.ratio" [is_board]="true" [config_road]="config_road"
                          [rain]="boardsignInfo?.rain" [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                        </app-sign-image-item>
                        <app-playlist-item [show_count]="true" [config_road]="config_road" [rain]="boardsignInfo?.rain"
                          [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                          [playlist]="sign?.media?.items" [height]="set_sign_height"
                          [width]="get_sign_width(sign?.ratio,set_sign_height)">
                        </app-playlist-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <button type="button" [disabled]="!boardsignInfo?.sensor_id" class="btn btn-primary text-white mr-2"
              (click)="openSuggestManagementModal()">Suggest Management</button>
            <button type="button" [disabled]="!selectsignItem || !boardsignInfo?.sensor_id"
              class="btn btn-primary text-white mr-2" (click)="openSpecialItemsModal(selectsignItem)">Special
              items</button>
            <button type="button" [disabled]="!selectsignItem || !boardsignInfo?.sensor_id"
              class="btn btn-primary text-white" (click)="openSignActionModal(selectsignItem)">Action</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #sensorSignalModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" (onHide)="clearSetting()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-800 border-0">
        <h4 class="modal-title"> <i class="fa fa-cog mr-2"></i><span>Sensor Signal
            <span *ngIf="select_sensor">[{{select_sensor?.name}}]</span>
          </span>
        </h4>
        <button type="button" class="close text-light" (click)="clickCloseSensorSignalModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row txtblog-BG">
            <div class="col-md-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">ทางหลวง</span>
                </div>
                <input type="text" class="form-control" [disabled]="true" [(ngModel)]="deviceDetail.route_id"
                  [ngModelOptions]="{standalone: true}">
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Station</span>
                </div>
                <input type="text" class="form-control" [disabled]="true" [(ngModel)]="deviceDetail.station_name"
                  [ngModelOptions]="{standalone: true}">
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">ประเภทอุปกรณ์</span>
                </div>
                <input type="text" class="form-control" [disabled]="true" [(ngModel)]="deviceDetail.type_name"
                  [ngModelOptions]="{standalone: true}">
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">จำนวนชนิดรถ</span>
                </div>
                <input type="text" class="form-control" [disabled]="true" [(ngModel)]="deviceDetail.model_name"
                  [ngModelOptions]="{standalone: true}">
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">PVR Stream</span>
                </div>
                <div class="d-flex align-items-center chkbox-form-control" style="flex: 1 1 auto;">
                  <div style="line-height: 1;" class="d-flex align-items-center ml-3 mr-3">
                    <label class="switch switch-label switch-sm switch-pill switch-success b-0 mr-1">
                      <input type="checkbox" class="switch-input" [(ngModel)]="pvr"
                        [ngModelOptions]="{standalone: true}">
                      <span class="switch-slider" data-checked="on" data-unchecked="off"></span>
                    </label>
                  </div>
                </div>

              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">จำนวนเลน<span class="text-danger">*</span></span>
                </div>
                <kendo-combobox class="form-control" [data]="laneList" [allowCustom]="allowCustom" [suggest]="true"
                  [(ngModel)]="lane_total" [filterable]="true" (valueChange)="changeLaneTotal($event)"
                  [placeholder]="'โปรดระบุ'" [ngModelOptions]="{standalone: true}">
                </kendo-combobox>
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">ทิศทาง<span class="text-danger">*</span></span>
                </div>
                <kendo-combobox class="form-control" [data]="dirList" [allowCustom]="allowCustom" [suggest]="true"
                  [(ngModel)]="dir_all" [filterable]="true" [textField]="'name'" [valueField]="'id'"
                  [placeholder]="'โปรดระบุ'" [ngModelOptions]="{standalone: true}">
                </kendo-combobox>
              </div>
            </div>
            <div class="col-md-6">

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">ช่วงกิโลเมตร</span>
                </div>
                <input type="text" class="form-control" [disabled]="true" [(ngModel)]="deviceDetail.km"
                  [ngModelOptions]="{standalone: true}">
                <div class="input-group-prepend">
                  <span class="input-group-text">+</span>
                </div>
                <input type="text" class="form-control" [disabled]="true" [(ngModel)]="deviceDetail.m"
                  [ngModelOptions]="{standalone: true}">
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"> สถานะ</span>
                </div>
                <input type="text" class="form-control" [disabled]="true" [(ngModel)]="deviceDetail.stauts_name"
                  [ngModelOptions]="{standalone: true}">
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"> ประเภทย่อยอุปกรณ์</span>
                </div>
                <input type="text" class="form-control" [disabled]="true" [(ngModel)]="deviceDetail.subtype_name"
                  [ngModelOptions]="{standalone: true}">
              </div>
              <div class="d-flex justify-content-center align-items-center mt-4 mb-2">Set '+' Degree</div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"> ระหว่าง</span>
                </div>
                <input type="number" class="form-control" [(ngModel)]="min_heading" min="-360" max="360"
                  (change)="min_heading = checkDegree($event)" [ngModelOptions]="{standalone: true}">
                <div class="input-group-prepend">
                  <span class="input-group-text">และ</span>
                </div>
                <input type="number" class="form-control" [(ngModel)]="max_heading" min="-360" max="360"
                  (change)="max_heading = checkDegree($event)" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">ประเภทถนน<span class="text-danger">*</span></span>
                </div>
                <kendo-combobox class="form-control" [data]="roadList" [allowCustom]="allowCustom" [suggest]="true"
                  [(ngModel)]="road_type" [filterable]="true" [textField]="'name'" [valueField]="'id'"
                  [placeholder]="'โปรดระบุ'" [ngModelOptions]="{standalone: true}">
                </kendo-combobox>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="d-flex">
        <div *ngFor="let l of values_list;index as i;"
          class="p-2 flex-fill d-flex justify-content-center align-items-center disable-select font-5"
          [ngClass]="(selectSmcLane == l?.lane_no)?'menu-item-active':'menu-item'" (click)="changeLaneTab(l.lane_no)">
          <span>Lane {{l?.lane_no}}</span>
        </div>
      </div>
      <div class="modal-body bg-gray-800 p-0">
        <div *ngFor="let l of values_list;index as i;trackBy:trackByIndex;">
          <div *ngIf="l.lane_no == selectSmcLane" class="table-h-bg pb-3">
            <table class="table table-outline m-0">
              <thead>
                <tr style="height: 50px;" class="align-items-center">
                  <th scope="col" rowspan="2" class="text-center text-light table-h-bg" colspan="1">
                    <h4>รถที่อนุญาติให้วิ่ง</h4>
                  </th>
                  <th class="text-center text-light table-h-bg" colspan="2">
                    <h4>ความเร็วปกติ</h4>
                  </th>
                  <th class="text-center text-light table-h-bg" colspan="2">
                    <h4>ความเร็วที่ฝนตก</h4>
                  </th>
                </tr>
                <tr>
                  <th scope="col" class="text-center table-h-bg">แนะนำ</th>
                  <th scope="col" class="text-center table-h-bg">เกิน Limit</th>
                  <th scope="col" class="text-center table-h-bg">แนะนำ</th>
                  <th scope="col" class="text-center table-h-bg">เกิน Limit</th>
                </tr>
              </thead>
              <tbody>
                <ng-template ngFor let-c [ngForOf]="values_list[i]?.veh_class" let-i="ii">
                  <tr class="table-no-border" style="border-width: 0">
                    <td colspan="1" class="d-flex justify-content-center align-items-center">
                      <div class="d-flex justify-content-start align-items-center" style="min-width: 125px;">
                        <label class="checkbox-container">
                          <span>{{c?.veh_class_name}}</span>
                          <input type="checkbox" [(ngModel)]="c.allow" [ngModelOptions]="{standalone: true}">
                          <span class="checkmark-box"></span>
                        </label>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center align-items-center w-100"><input type="number"
                          class="form-control input-text-w text-center" placeholder="" min="0" max="200"
                          (change)="c.suggest_speed = checkDegree($event)" [(ngModel)]="c.suggest_speed"
                          [ngModelOptions]="{standalone: true}"></div>

                    </td>
                    <td>
                      <div class="d-flex justify-content-center align-items-center w-100"><input type="number"
                          class="form-control input-text-w text-center" placeholder="" min="0" max="200"
                          (change)="c.normal_speed = checkDegree($event)" [(ngModel)]="c.normal_speed"
                          [ngModelOptions]="{standalone: true}"></div>

                    </td>
                    <td>
                      <div class="d-flex justify-content-center align-items-center w-100"><input type="number"
                          class="form-control input-text-w text-center" placeholder="" min="0" max="200"
                          (change)="c.rain_suggest_speed = checkDegree($event)" [(ngModel)]="c.rain_suggest_speed"
                          [ngModelOptions]="{standalone: true}"></div>

                    </td>
                    <td>
                      <div class="d-flex justify-content-center align-items-center w-100"><input type="number"
                          class="form-control input-text-w text-center" placeholder="" min="0" max="200"
                          (change)="c.rain_speed = checkDegree($event)" [(ngModel)]="c.rain_speed"
                          [ngModelOptions]="{standalone: true}"></div>

                    </td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
          <div *ngIf="l.lane_no == selectSmcLane" class="blog-foot">
            <div class="row p-3">
              <!-- <div class="col-12 d-flex justify-content-around align-items-center">
                <h4>Signal</h4>
                <div class="mb-2 d-flex align-items-center justify-content-center">
                  -
                  <label class="switch switch-label switch-sm switch-pill switch-success b-0">
                    <input type="checkbox" class="switch-input" [(ngModel)]="sensorLane.queue_dir">
                    <span class="switch-slider" data-checked="-" data-unchecked="+"></span>
                  </label>
                  +
                  ทิศการวิ่ง
                </div>
              </div> -->
              <div class="col-sm-12 col-md-6 d-flex justify-content-center align-items-center">
                <div>
                  <h4>Signal</h4>
                  <div>
                    <div>
                      <label class="checkbox-container">
                        <span>ผิดช่องทาง</span>
                        <input type="checkbox" [(ngModel)]="values_list[i].signal_wrong_way_"
                          (ngModelChange)="wrongWayChange($event,l)" [ngModelOptions]="{standalone: true}">
                        <span class="checkmark-box"></span>
                      </label>
                    </div>
                    <div>
                      <label class="checkbox-container">
                        <span>ความเร็วเกิน</span>
                        <input type="checkbox" [(ngModel)]="values_list[i].signal_over_speed_"
                          (ngModelChange)="overSpeedChange($event,l)" [ngModelOptions]="{standalone: true}">
                        <span class="checkmark-box"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 text-center">
                <div class="mb-2 d-flex align-items-center justify-content-center">
                  <label class="switch switch-label switch-sm switch-pill switch-success b-0 mr-1">
                    <input type="checkbox" class="switch-input" (ngModelChange)="switchChange($event,l)"
                      [(ngModel)]="values_list[i].signal_direction_" [ngModelOptions]="{standalone: true}">
                    <span class="switch-slider" data-checked="" data-unchecked=""></span>
                  </label>
                  ทิศการวิ่ง
                  <span class="ml-1" *ngIf="!values_list[i].signal_direction_">-</span>
                  <span class="ml-1" *ngIf="values_list[i].signal_direction_">+</span>
                </div>
                <div class="input-group mb-3 justify-content-center">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <label class="checkbox-container"
                        style="padding-bottom: 0 !important;margin-bottom: 0 !important;">
                        <span>Q</span>
                        <input type="checkbox" [(ngModel)]="values_list[i].queue_length_trigger_allow"
                          (ngModelChange)="queueallowChange($event,l)">
                        <span class="checkmark-box"></span>
                      </label></span>
                  </div>
                  <input type="text" class="form-control h-100" [(ngModel)]="values_list[i].queue_length_trigger">
                  <div class="input-group-append">
                    <span class="input-group-text">เมตร</span>
                  </div>
                </div>
              </div>
              <div class="col-12 text-right h-100 ">
                <button type="button" class="btn btn-danger mr-1" (click)="clickCloseSensorSignalModal()">ปิด</button>
                <button type="button" class="btn btn-info text-light" (click)="addConfigRoad()">ยืนยัน</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<div bsModal #matchSensorModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" (onHide)="clearSetting()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900 border-0">
        <h4 class="modal-title">
          <!-- <i class="fa fa-cog mr-2"></i> -->
          <span>เลือก Sensor ของป้ายนี้</span>
        </h4>
        <button type="button" class="close text-light" (click)="matchSensorModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body bg-gray-800">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="select1"> <span class="float-sm-left float-md-right">Sensor<span
                  class="text-danger">*</span>
                :</span></label>
            <div class="col-md-9 col-lg-9 col-xl-9">
              <kendo-combobox class="w-100" [data]="sensor_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'device_id'" [value]="select_sensor" (valueChange)="handleValueSensor($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
            <div class="col-md-12 col-lg-12 col-xl-12">
              <div class="d-flex justify-content-end align-items-center mt-3">
                <button type="button" [disabled]="!select_sensor" (click)="matchSensor()"
                  class="btn btn-primary text-white ml-1 mr-1">
                  บันทึก</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div bsModal #signActionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" [config]="{backdrop: 'static'}"
  [ngClass]="((addTextItemModal?._isShown))?'modal-hide-when-second-open':''">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="fa fa-bolt mr-2"></i><span>Action</span>
        </h4>
        <button type="button" class="close text-light" (click)="clickCloseSignActionModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body body-panel-h">
        <div class="d-flex justify-content-end align-items-center mb-2">
          <button type="button" class="btn btn-primary text-white" (click)="openAddSignActionModal()"> <i
              class="fa fa-plus"></i>
            เพิ่ม Event</button>
        </div>
        <div class="mt-3 d-flex justify-content-center" *ngIf="selectSignEvents?.length==0">
          ยังไม่มี Event
        </div>
        <div *ngFor="let item of selectSignEvents;index as i;">
          <div class="d-flex justify-content-start align-items-center mb-2">
            <div class="mr-1 rounded-circle bg-info d-flex justify-content-center align-items-center"
              style="width:auto;height:auto;min-height:32px;min-width:32px">
              <strong>{{(i+1)}}</strong></div>
            <div class="bg-gray-900 p-2 rounded w-100">
              <span *ngIf="!item?.name">-</span>
              <span *ngIf="item?.name">{{item?.name}}</span>
            </div>
            <button title="แก้ไข" (click)="openEditSignActionModal(item)" type="button"
              class="btn btn-sm btn-warning text-light ml-1">
              <i class="cui-note icons"></i>
            </button>
            <button title="ลบ" type="button" (click)="openDeleteActionModal(item)" class="btn btn-sm btn-danger ml-1">
              <i class="cui-trash icons"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #editSignActionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}"
  [ngClass]="(addTextItemModal?._isShown)?'modal-hide-when-second-open':''" (onHide)="clearDataImage()">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900 border-0 mb-2">
        <h4 class="modal-title"> <i class="fa fa-bolt mr-2"></i><span>Action</span> <span
            class="ml-2">{{selectEvent?.name}}</span>
        </h4>
        <button type="button" class="close text-light" (click)="clickCloseEditSignActionModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body body-panel-h pt-1">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-between align-items-center bg-gray-900 rounded-top p-3 font-3">Signal
              <div>{{getSignalName(event_type,veh_type,lane,rain_type,true)}}</div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="p-3 mb-2 bg-gray-800 rounded-bottom">
              <div class="row">
                <div class="col-md-4 col-lg-3">
                  <label class="float-sm-left float-md-right">ประเภทความผิด<span class="text-danger">*</span>:</label>
                </div>
                <div class="col-md-8 col-lg-9">
                  <div class="d-flex align-items-center flex-wrap">
                    <div class="d-flex justify-content-start align-items-center"
                      *ngFor="let item of event_type_list;index as i;">
                      <label class="radio-container mr-1 ml-1">{{item?.name}}
                        <input type="radio" name="radioEventType" [value]="item.id" [(ngModel)]="event_type"
                          [ngModelOptions]="{standalone: true}">
                        <span class="radio-checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-lg-3">
                  <label class="float-sm-left float-md-right">ประเภทรถ<span class="text-danger">*</span>:</label>
                </div>
                <div class="col-md-8 col-lg-9">
                  <div class="d-flex align-items-center flex-wrap">
                    <div class="d-flex justify-content-start align-items-center"
                      *ngFor="let item of veh_type_list;index as i;">
                      <label class="radio-container mr-1 ml-1">{{item?.name}}
                        <input type="radio" name="radioVehType" [value]="item.id" [(ngModel)]="veh_type"
                          [ngModelOptions]="{standalone: true}">
                        <span class="radio-checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-lg-3">
                  <span class="float-sm-left float-md-right">เลน<span class="text-danger">*</span>:</span>
                </div>
                <div class="col-md-8 col-lg-9">
                  <div class="d-flex align-items-center flex-wrap">
                    <div class="d-flex justify-content-start align-items-center"
                      *ngFor="let item of lane_list;index as i;">
                      <label class="radio-container mr-1 ml-1">{{item}}
                        <input type="radio" name="radioLaneType" [value]="item" [(ngModel)]="lane"
                          [ngModelOptions]="{standalone: true}">
                        <span class="radio-checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-lg-3">
                  <span class="float-sm-left float-md-right">ฝน<span class="text-danger">*</span>:</span>
                </div>
                <div class="col-md-8 col-lg-9">
                  <div class="d-flex align-items-center flex-wrap">
                    <div class="d-flex justify-content-start align-items-center"
                      *ngFor="let item of rain_type_list;index as i;">
                      <label class="radio-container mr-1 ml-1">{{item?.name}}
                        <input type="radio" name="radioRainType" [value]="item.id" [(ngModel)]="rain_type"
                          [ngModelOptions]="{standalone: true}">
                        <span class="radio-checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex justify-content-between align-items-center bg-gray-900 rounded-top p-3 font-3">Media
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-start bg-gray-800 rounded-bottom p-3">
              <div>
                <div class="d-flex justify-content-between align-items-center mb-2 bg-gray-900 p-2 rounded">
                  <span class="ml-1">Background</span>
                  <div title="Add Background" class="pointer btn-file-background">
                    <i class="fa fa-plus"></i>
                    <input #imInput title="Add Background" type="file" (change)="detectFiles($event)"
                      accept=".png,.jpg,.gif">
                  </div>
                </div>

                <div class="d-flex justify-content-between align-items-center bg-gray-900 p-2 rounded-top">
                  <span class="ml-1">Text Items</span>
                  <div (click)="openAddTextItem()" title="Add Text Item"
                    class="bt-rounded-circle pointer rounded-circle bg-success d-flex justify-content-center align-items-center">
                    <i class="fa fa-plus"></i></div>
                </div>
                <div class="mb-2 bg-gray-700 p-2 rounded-bottom">
                  <div *ngFor="let item of textItems;index as i;"
                    class="d-flex justify-content-between align-items-center mb-1 bg-gray-900 p-1 rounded">
                    <span *ngIf="item?.text_type == specialTextType.FreeText" class="ml-1">{{item?.text}}</span>
                    <span *ngIf="item?.text_type == specialTextType.SpeedLimit" class="ml-1">Speed Limit</span>
                    <span *ngIf="item?.text_type == specialTextType.SpeedSuggest" class="ml-1">Suggest Speed</span>
                    <span *ngIf="item?.text_type == specialTextType.SpeedSensor" class="ml-1">Sensor Speed</span>
                    <div class="d-flex justify-content-start">
                      <div (click)="openEditTextItem(item)" title="Edit Text Item"
                        class="bt-rounded-circle pointer rounded-circle bg-info d-flex justify-content-center align-items-center mr-1">
                        <i class="fa fa-pencil-square-o"></i></div>
                      <div (click)="deleteTextItem(item)" title="Delete Text Item"
                        class="bt-rounded-circle pointer rounded-circle bg-danger d-flex justify-content-center align-items-center">
                        <i class="cui-trash icons"></i></div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-start align-items-center mb-2">
                  <div class="input-group justify-content-center mr-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-gray-900 ">Duration</span>
                    </div>
                    <input type="text" class="form-control text-center" [(ngModel)]="duration" style="max-width: 60px;">
                    <div class="input-group-append">
                      <span class="input-group-text bg-gray-900 ">วินาที</span>
                    </div>
                  </div>
                  <div class="d-flex justify-content-start align-items-center mt-2">
                    <label class="checkbox-container">
                      <span>Blink</span>
                      <input type="checkbox" [(ngModel)]="selectEvent.blink" [ngModelOptions]="{standalone: true}">
                      <span class="checkmark-box"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div>
                  <div style="background-color: black;position: relative;"
                    [style.height.px]="selectsignItem?.sign_type_object?.height"
                    [style.width.px]="selectsignItem?.sign_type_object?.width">
                    <img *ngIf="background?.url" [src]="background?.url" width="100%" height="100%"
                      style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;" />
                    <img *ngIf="background?.dataUrl" [src]="background?.dataUrl | safe" width="100%" height="100%"
                      style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;" />
                    <div style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;"
                      *ngFor="let item of textItems;index as i;"
                      [ngClass]="(item?.is_center)?'d-flex align-items-center justify-content-center':''">
                      <span *ngIf="item?.is_center && (item?.text_type == specialTextType.FreeText)" [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''"
                        [style.color]="item?.color" [style.font-size.px]="item?.font_size"
                        [style.family]="item?.font_family">{{item?.text}}</span>
                      <span *ngIf="!item?.is_center && (item?.text_type == specialTextType.FreeText)" [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''"
                        style="position: absolute" [style.top.px]="item?.y" [style.left.px]="item?.x"
                        [style.color]="item?.color" [style.font-size.px]="item?.font_size"
                        [style.family]="item?.font_family">{{item?.text}}</span>
                      <span *ngIf="item?.is_center && (item?.text_type == specialTextType.SpeedLimit)"
                        [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                        [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{text_speed_limit}}</span>
                      <span *ngIf="!item?.is_center && (item?.text_type == specialTextType.SpeedLimit)"
                        [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                        [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                        [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{text_speed_limit}}</span>
                      <span *ngIf="item?.is_center && (item?.text_type == specialTextType.SpeedSuggest)"
                        [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                        [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{text_speed_suggest}}</span>
                      <span *ngIf="!item?.is_center && (item?.text_type == specialTextType.SpeedSuggest)"
                        [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                        [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                        [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{text_speed_suggest}}</span>
                        <span *ngIf="item?.is_center && (item?.text_type == specialTextType.SpeedSensor)"
                        [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                        [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{text_speed_sensor}}</span>
                      <span *ngIf="!item?.is_center && (item?.text_type == specialTextType.SpeedSensor)"
                        [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                        [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                        [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{text_speed_sensor}}</span>
                    </div>
                  </div>
                  <div class="mt-1 text-center">
                    ตัวอย่าง
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-1 mr-3 ml-3 text-center text-danger small">
            *ค่า Sensor Speed, Speed Limit และ Speed Suggest เป็นค่าตัวอย่างเท่านั้น ตัวเลขจริงขึ้นอยู่กับการตั้งค่าSensorของป้ายนั้นๆ
          </div>
          <div class="col-md-12">
            <div class="d-flex justify-content-end align-items-center mt-2">
              <button type="button" (click)="clickCloseEditSignActionModal()"
                class="btn btn-danger text-white ml-1 mr-1">
                ยกเลิก</button>
              <button *ngIf="!this.selectEvent?.id"
                [disabled]="(!background?.file) || event_type == null|| veh_type == null|| lane == null|| rain_type == null || duration == null"
                (click)="addEventAsyn(true)" type="button" style="min-width: 80px;" class="btn btn-primary text-white">
                บันทึก</button>
              <button *ngIf="this.selectEvent?.id"
                [disabled]="(!background?.file && !background.url) || event_type == null|| veh_type == null|| lane == null|| rain_type == null || duration == null"
                (click)="editEventAsyn()" type="button" style="min-width: 80px;" class="btn btn-primary text-white">
                บันทึก</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #addTextItemModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearTextItem()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title">
          <span *ngIf="!textItem?.idd"><i class="fa fa-plus mr-2"></i> Add </span>
          <span *ngIf="textItem?.idd"><i class="fa fa-pencil-square-o mr-2"></i> Edit </span>
          Text item</h4>
        <button type="button" class="close text-light" (click)="addTextItemModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Text type
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="text_type_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [(value)]="select_text_type" (valueChange)="handleValueTextType($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div *ngIf="select_text_type?.id == specialTextType.FreeText" class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Text :</span></label>
            <div class="col-md-8">
              <input type="text" class="form-control w-100" maxlength="50" [(ngModel)]="textItem.text"
                [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Font size
                <small>(px)</small>
                :</span></label>
            <div class="col-md-8">
              <input type="text" class="form-control w-100" numbersOnly maxlength="5" [(ngModel)]="textItem.font_size"
                [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Font family
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="font_family_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="select_font_family" (valueChange)="handleValueFontFamily($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Font weight
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="font_weight_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="select_font_weight" (valueChange)="handleValueFontWeight($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Color
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="font_color_list" [allowCustom]="allowCustom" [textField]="''"
                [valueField]="''" [value]="select_font_color" (valueChange)="handleValueFontColor($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">X :</span></label>
            <div class="col-md-8">
              <input type="text" class="form-control w-100" numbersOnly maxlength="5" [(ngModel)]="textItem.x"
                [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Y :</span></label>
            <div class="col-md-8">
              <input type="text" class="form-control w-100" numbersOnly maxlength="5" [(ngModel)]="textItem.y"
                [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">ตรงกลางรูป
                :</span></label>
            <div class="col-md-8 col-form-label">
              <label class="checkbox-container">
                <span></span>
                <input type="checkbox" [(ngModel)]="textItem.is_center" [ngModelOptions]="{standalone: true}">
                <span class="checkmark-box"></span>
              </label>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary mr-1" (click)="addTextItemModal.hide()">ปิด</button>
          <button *ngIf="!textItem?.idd" type="button" (click)="addTextItems()"
            class="btn btn-info text-light ml-1">เพิ่ม</button>
          <button *ngIf="textItem?.idd" type="button" (click)="editTextItems(textItem)"
            class="btn btn-info text-light ml-1">แก้ไข</button>
        </div>

      </div>
      <!-- <div class="modal-footer">
          
        </div> -->
    </div>
  </div>
</div>

<div bsModal #deleteSignActionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i>ลบ Event</h4>
        <button type="button" class="close text-light" (click)="deleteSignActionModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt-2 text-center">
          <h4>คุณต้องการลบใช่หรือไม่</h4>
        </div>
        <div class="mt-3 mb-4 text-center">
          <h5>
            {{this.getSignalName(selectEvent?.signal?.event_type, selectEvent?.signal?.class, selectEvent?.signal?.lane, selectEvent?.signal?.rain, false)}}
          </h5>
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="deleteSignActionModal.hide()">ยกเลิก</button>
          <button type="button" class="btn btn-primary text-white ml-2" (click)="deleteEvent()">ยืนยัน</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #signSpecialItemModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}"
  [ngClass]="((addSpecialItemModal?._isShown) || (deleteMediaModal?._isShown) || (confirmDeleteMediaModal?._isShown))?'modal-hide-when-second-open':''">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="fa fa-file-image-o mr-2"></i> Specials items
        </h4>
        <button type="button" class="close text-light" (click)="signSpecialItemModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body body-panel-h">
        <div class="d-flex justify-content-between align-items-center mb-2 bg-gray-900 p-2 rounded">
          <h5 class="ml-2">
            <i class="fa fa-file-image-o mr-2"></i> Specials items <span>[Sign ID : {{selectsignItem?.id}}]</span>
          </h5>
          <button type="button" class="btn btn-primary text-white" (click)="openAddSpecialItemsModal()"> <i
              class="fa fa-plus"></i>
            เพิ่ม Specials items</button>
        </div>
        <div class="mt-3 d-flex justify-content-center" *ngIf="imageSpecialItems?.length==0">
          ยังไม่มี Specials items ของป้ายนี้
        </div>
        <div *ngIf="is_loading" class="d-flex justify-content-center align-items-center w-100 h-100 ">
          <div class="lds-dual-ring"></div>
        </div>
        <div *ngIf="!is_loading" class="d-flex align-content-start flex-wrap">
          <div *ngFor="let media of imageSpecialItems">
            <div>
              <div class="p-2">
                <!-- <div class="pointer" [style.height.px]="media_sign_height" (click)="openDeleteImageModal(media)"
                  style="background-color: black;position: relative;"
                  [style.width.px]="medie_sign_width(media?.sign_type_obj?.aspect_ratio,media_sign_height)">
                  <img *ngIf="media?.path" [src]="media?.path" width="100%" height="100%"
                    style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;" />
                  <div style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;"
                    *ngFor="let item of media?.special_property?.text_items;index as i;"
                    [ngClass]="(item?.is_center)?'d-flex align-items-center justify-content-center':''">
                    <span *ngIf="item?.is_center && item?.text_type == specialTextType.FreeText"
                      [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                      [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{item?.text}}</span>
                    <span *ngIf="!item?.is_center && item?.text_type == specialTextType.FreeText"
                      [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                      [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                      [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{item?.text}}</span>
                  </div>
                </div> -->
                <app-sign-image-item class="pointer" (click)="openDeleteImageModal(media)"
                  [media]="media" [sign_height]="media_sign_height" [ratio]="media?.sign_type_obj?.aspect_ratio"
                [is_board]="true" [config_road]="config_road" [rain]="boardsignInfo?.rain"
                [resize]="(media_sign_height/media?.sign_type_obj?.height)"
                *ngIf="media?.path && (media?.media_type == mediaType.Image || media?.media_type == mediaType.Gif)">
              </app-sign-image-item>
                <div class="clearfix m0 text-center text-light"
                  [ngStyle]="getSignNameTextStyles(medie_sign_width(media?.sign_type_obj?.aspect_ratio,media_sign_height))">
                  <small title="{{media?.name}}">{{media?.name}}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #addSpecialItemModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}" (onHide)="closeAddImage()">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title">
          <span *ngIf="!imageSpecialItem?.id">เพิ่ม</span>
          <span *ngIf="imageSpecialItem?.id">แก้ไข</span>
          Specials items
        </h4>
        <button type="button" class="close text-light" (click)="addSpecialItemModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body body-panel-h">
        <div class="w-100">
          <div class="d-flex justify-content-between align-items-top bg-gray-900 p-2 rounded-top">
            <span class="ml-1">รูปภาพ<span class="text-danger">*</span></span>

          </div>
          <div class="mb-2 bg-gray-800 p-2 rounded-bottom">
            <form class="form-horizontal mt-2 mb-2">
              <div class="form-group row">
                <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">ชื่อภาพ
                    :</span></label>
                <div class="col-md-6">
                  <input type="text" [(ngModel)]="imageSpecialItem.name" class="form-control" placeholder=""
                    maxlength="50" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-4 col-form-label" for="text-input"> <span
                    class="float-sm-left float-md-right">รูปภาพ
                    :</span></label>
                <div class="col-md-6">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="pointer btn-file-background">
                      <i class="fa fa-plus"></i>
                      <input #imInput2 title="เลือกรูป" type="file" (change)="detectFiles2($event)"
                        accept=".png,.jpg,.gif">
                    </div>
                    <div class="ml-2">{{img_file?.name}}</div>
                  </div>

                </div>
              </div>
              <!-- <div class="form-group row">
                <label class="col-md-4 col-form-label" for="text-input"> <span class="float-sm-left float-md-right">
                    ขนาดภาพ
                    :</span></label>
                <label *ngIf="img_w && img_h" class="col-md-6 col-form-label" for="text-input"> <span>{{img_w}} x
                    {{img_h}}</span></label>
              </div>
              <div class="form-group row">
                <label class="col-md-4 col-form-label" for="text-input"> <span class="float-sm-left float-md-right">
                    ประเภทของภาพ
                    :</span></label>
                <label *ngIf="img_type != null" class="col-md-6 col-form-label" for="text-input">
                  <span *ngIf="img_type == mediaType.Image">ภาพนิ่ง</span>
                  <span *ngIf="img_type == mediaType.Gif">ภาพเคลื่อนไหว</span>
                </label>
              </div> -->
              <div class="form-group row">
                <label class="col-md-4 col-form-label" for="select1"> <span
                    class="float-sm-left float-md-right">ประเภทการใช้งาน
                    :</span></label>
                <div class="col-md-6">
                  <kendo-combobox class="w-100" [data]="use_type_list" [allowCustom]="allowCustom" [textField]="'name'"
                    [valueField]="'id'" [value]="use_type_modal" (valueChange)="handleValueUseTypeModal($event)"
                    [placeholder]="'โปรดระบุ'" [filterable]="true">
                  </kendo-combobox>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-4 col-form-label" for="select1"> <span class="float-sm-left float-md-right">สถานะ
                    :</span></label>
                <div class="col-md-6">
                  <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
                    [valueField]="'id'" [value]="status_modal" (valueChange)="handleValueStatusModal($event)"
                    [placeholder]="'โปรดระบุ'" [filterable]="true">
                  </kendo-combobox>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="w-100">
          <div class="d-flex justify-content-between align-items-top bg-gray-900 p-2 rounded-top">
            <span class="ml-1">Text Items<span class="text-danger">*</span></span>
            <div (click)="openAddTextItemSpecial()" title="Add Text Item"
              class="bt-rounded-circle pointer rounded-circle bg-success d-flex justify-content-center align-items-center">
              <i class="fa fa-plus"></i></div>
          </div>
          <div class="mb-2 bg-gray-800 p-2 rounded-bottom" style="min-height: 45px;">
            <div *ngFor="let item of specialTextItems;index as i;"
              class="d-flex justify-content-between align-items-center mb-1 bg-gray-900 p-1 rounded">
              <span *ngIf="item?.text_type == specialTextType.FreeText" class="ml-1">{{item?.text}}</span>
              <span *ngIf="item?.text_type == specialTextType.SpeedLimit" class="ml-1">Speed Limit {{text_speed_limit}}</span>
              <span *ngIf="item?.text_type == specialTextType.SpeedSuggest" class="ml-1">Suggest Speed {{text_speed_suggest}}</span>
              <div class="d-flex justify-content-start">
                <div (click)="openEditTextItemSpecial(item)" title="Edit Text Item"
                  class="bt-rounded-circle pointer rounded-circle bg-info d-flex justify-content-center align-items-center mr-1">
                  <i class="fa fa-pencil-square-o"></i></div>
                <div (click)="deleteTextItemSpecial(item)" title="Edit Text Item"
                  class="bt-rounded-circle pointer rounded-circle bg-danger d-flex justify-content-center align-items-center">
                  <i class="cui-trash icons"></i></div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <div>
            <div style="background-color: black;position: relative;"
              [style.height.px]="selectsignItem?.sign_type_object?.height"
              [style.width.px]="selectsignItem?.sign_type_object?.width">
              <img *ngIf="img_url" [src]="img_url" width="100%" height="100%"
                style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;" />
              <img *ngIf="img_dataUrl" [src]="img_dataUrl | safe" width="100%" height="100%"
                style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;" />
              <div style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;"
                *ngFor="let item of specialTextItems;index as i;"
                [ngClass]="(item?.is_center)?'d-flex align-items-center justify-content-center':''">
                <span *ngIf="item?.is_center && item?.text_type == specialTextType.FreeText"
                  [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                  [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{item?.text}}</span>
                <span *ngIf="!item?.is_center && item?.text_type == specialTextType.FreeText"
                  [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                  [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                  [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{item?.text}}</span>
                <span *ngIf="item?.is_center && (item?.text_type == specialTextType.SpeedLimit)"
                  [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                  [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{text_speed_limit}}</span>
                <span *ngIf="!item?.is_center && (item?.text_type == specialTextType.SpeedLimit)"
                  [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                  [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                  [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{text_speed_limit}}</span>
                <span *ngIf="item?.is_center && (item?.text_type == specialTextType.SpeedSuggest)"
                  [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                  [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{text_speed_suggest}}</span>
                <span *ngIf="!item?.is_center && (item?.text_type == specialTextType.SpeedSuggest)"
                  [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                  [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                  [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{text_speed_suggest}}</span>
              </div>
            </div>
            <div class="mt-1 text-center">
              ตัวอย่าง
            </div>
          </div>
        </div>
        <div class="mt-1 text-center text-danger small">
          *ค่า Speed Limit และ Speed Suggest เป็นค่าตัวอย่างเท่านั้น ตัวเลขจริงขึ้นอยู่กับการตั้งค่าSensorของป้ายนั้นๆ
        </div>
        <div class="w-100">
          <div class="d-flex justify-content-end align-items-center mt-2">
            <button type="button" (click)="addSpecialItemModal.hide()" class="btn btn-danger text-white ml-1 mr-1">
              ยกเลิก</button>
            <button *ngIf="!this.imageSpecialItem?.id"
              [disabled]="(!img_file) || imageSpecialItem?.name == null|| img_content_type == null|| img_type == null
                || img_w == null || img_h == null || use_type_modal == null || status_modal == null || specialTextItems?.length ==0"
              (click)="addImageAsyn(true)" type="button" style="min-width: 80px;" class="btn btn-primary text-white">
              บันทึก</button>
            <button *ngIf="this.imageSpecialItem?.id"
              [disabled]="(!img_file && !img_url) || imageSpecialItem?.name == null|| img_content_type == null|| img_type == null
                || img_w == null || img_h == null || use_type_modal == null || status_modal == null || specialTextItems?.length ==0" (click)="editImageAsyn()"
              type="button" style="min-width: 80px;" class="btn btn-primary text-white">
              บันทึก</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #addSpecialTextItemModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearTextItemSpecial()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title">
          <span *ngIf="!specialTextItem?.idd"><i class="fa fa-plus mr-2"></i> Add </span>
          <span *ngIf="specialTextItem?.idd"><i class="fa fa-pencil-square-o mr-2"></i> Edit </span>
          Special Text items</h4>
        <button type="button" class="close text-light" (click)="addSpecialTextItemModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Text type
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="text_type_special_list" [allowCustom]="allowCustom"
                [textField]="'name'" [valueField]="'id'" [(value)]="select_text_type_special"
                (valueChange)="handleValueSpecialTextType($event)" [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div
            *ngIf="(select_text_type_special?.id == specialTextType.SpeedSuggest) || (select_text_type_special?.id == specialTextType.SpeedLimit)"
            class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">เลน
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="lane_list" [allowCustom]="allowCustom" [textField]="''"
                [valueField]="''" [value]="select_text_lane_special" (valueChange)="handleValueSpecialTextLane($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div
            *ngIf="(select_text_type_special?.id == specialTextType.SpeedSuggest) || (select_text_type_special?.id == specialTextType.SpeedLimit)"
            class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">ประเภทรถ
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="veh_type_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="select_text_class_special"
                (valueChange)="handleValueSpecialTextVehClass($event)" [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div *ngIf="select_text_type_special?.id == specialTextType.FreeText" class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Text :</span></label>
            <div class="col-md-8">
              <input type="text" class="form-control w-100" maxlength="50" [(ngModel)]="specialTextItem.text"
                [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Font size
                <small>(px)</small>
                :</span></label>
            <div class="col-md-8">
              <input type="text" class="form-control w-100" numbersOnly maxlength="5"
                [(ngModel)]="specialTextItem.font_size" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Font family
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="font_family_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="select_font_family_special"
                (valueChange)="handleValueSpecialFontFamily($event)" [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Font weight
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="font_weight_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="select_font_weight_special"
                (valueChange)="handleValueSpecialFontWeight($event)" [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Color
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="font_color_list" [allowCustom]="allowCustom" [textField]="''"
                [valueField]="''" [value]="select_font_color_special"
                (valueChange)="handleValueSpecialFontColor($event)" [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">X :</span></label>
            <div class="col-md-8">
              <input type="text" class="form-control w-100" numbersOnly maxlength="5" [(ngModel)]="specialTextItem.x"
                [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Y :</span></label>
            <div class="col-md-8">
              <input type="text" class="form-control w-100" numbersOnly maxlength="5" [(ngModel)]="specialTextItem.y"
                [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">ตรงกลางรูป
                :</span></label>
            <div class="col-md-8 col-form-label">
              <label class="checkbox-container">
                <span></span>
                <input type="checkbox" [(ngModel)]="specialTextItem.is_center" [ngModelOptions]="{standalone: true}">
                <span class="checkmark-box"></span>
              </label>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary mr-1" (click)="addSpecialTextItemModal.hide()">ปิด</button>
          <button *ngIf="!specialTextItem?.idd" type="button" (click)="addTextItemsSpecial()"
            class="btn btn-info text-light ml-1">เพิ่ม</button>
          <button *ngIf="specialTextItem?.idd" type="button" (click)="editTextItemsSpecial(specialTextItem)"
            class="btn btn-info text-light ml-1">แก้ไข</button>
        </div>

      </div>
      <!-- <div class="modal-footer">
          
        </div> -->
    </div>
  </div>
</div>

<div class="modal modal1 fade1" bsModal #deleteMediaModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal2-dialog" [style.min-width.px]="250">
    <div class="modal-content">
      <div class="modal-body">
        <div class="mt-1">
          <div class="panel-body text-center">
            <!-- <img *ngIf="im_select?.path" [src]="im_select?.path" [style.height.px]="media_sign_height"
              [style.width.px]="medie_sign_width(im_select?.sign_type_obj?.aspect_ratio,media_sign_height)" /> -->
            <div class="w-100 d-flex justify-content-center">
              <!-- <div [style.height.px]="media_sign_height" style="background-color: black;position: relative;"
                [style.width.px]="medie_sign_width(im_select?.sign_type_obj?.aspect_ratio,media_sign_height)">
                <img *ngIf="im_select?.path" [src]="im_select?.path" width="100%" height="100%"
                  style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;" />
                <div style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;"
                  *ngFor="let item of im_select?.special_property?.text_items;index as i;"
                  [ngClass]="(item?.is_center)?'d-flex align-items-center justify-content-center':''">
                  <span *ngIf="item?.is_center && item?.text_type == specialTextType.FreeText"
                    [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                    [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{item?.text}}</span>
                  <span *ngIf="!item?.is_center && item?.text_type == specialTextType.FreeText"
                    [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                    [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                    [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{item?.text}}</span>
                </div>
              </div> -->
              <app-sign-image-item [media]="im_select" [sign_height]="media_sign_height" [ratio]="im_select?.sign_type_obj?.aspect_ratio"
                [is_board]="true" [config_road]="config_road" [rain]="boardsignInfo?.rain"
                [resize]="(media_sign_height/im_select?.sign_type_obj?.height)"
                *ngIf="im_select?.path && (im_select?.media_type == mediaType.Image || im_select?.media_type == mediaType.Gif)">
              </app-sign-image-item>
            </div>
            <div class="clearfix mt-1">
              <p>{{im_select?.name}}</p>
            </div>
            <button class="btn btn-danger btn-block mt-1" (click)="OpenConfirmDeleteMediaModal()">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #confirmDeleteMediaModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="mt-3">
          <div class="panel-body text-center">
            <div class="d-flex justify-content-center align-items-center" style="min-height: 100px">
              <h5>คุณต้องการลบ <span class="text-primary"> {{im_select?.name}} </span> ใช่หรือไม่</h5>
            </div>
            <div class="d-flex justify-content-end mt-1">
              <button class="btn btn-secondary mr-1" (click)="confirmDeleteMediaModal.hide()"> ยกเลิก </button>
              <button class="btn btn-primary text-light ml-1" (click)="deleteImage(im_select)"> ตกลง </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #signSuggestModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" [config]="{backdrop: 'static'}"
  [ngClass]="((addSpecialItemModal?._isShown))?'modal-hide-when-second-open':''">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="fa fa-clone mr-2"></i> Suggest Management
        </h4>
        <button type="button" class="close text-light" (click)="signSuggestModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body body-panel-h">
        <div class="bg-gray-850 p-3 rounded">
          <form class="form-horizontal mt-2 mb-2">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-3 col-form-label"> <span
                  class="float-sm-left float-md-right">Incident type<span class="text-danger">*</span>
                  :</span></label>
              <div class="col-md-8 col-lg-7 col-xl-7">
                <div class="d-flex justify-content-center align-items-center h-100 flex-wrap">
                  <kendo-combobox class="w-100" [data]="inc_type_list" [allowCustom]="allowCustom" [textField]="'name'"
                    [valueField]="'id'" [value]="select_inc_type" (valueChange)="handleValueIncType($event)"
                    [placeholder]="'โปรดระบุ'" [filterable]="true">
                  </kendo-combobox>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="select_inc_type" class="d-flex justify-content-between align-items-center mt-3">
          <div class="ml-2 mr-2"><span class="text-danger ml-2 mr-2">*** </span> %
            ความน่าจะเป็นของทุกรูปแบบรวมกันต้องเท่ากับ 100%</div>
          <button type="button" (click)="addCriteriaButton()" class="btn btn-primary text-white">
            <i class="fa fa-plus"></i> เพิ่มรูปแบบการแสดงผล</button>
        </div>
        <div *ngFor="let criteria of board_criteria_list;index as i;trackBy:trackByIndex;">
          <div *ngIf="criteria?.status == statusEnum.Active"
            class="d-flex justify-content-between align-items-center bg-gray-850 p-2 rounded-top mt-3">
            <div class="d-flex justify-content-start align-items-center">
              <div class="mr-2 rounded-circle rounded-circle1 bg-info d-flex justify-content-center align-items-center">
                <strong>{{getNum(criteria)}}</strong></div>
              <span class="mr-2">% ความน่าจะเป็น</span>
              <input type="text" class="form-control input-criteria text-center"
                [(ngModel)]="board_criteria_list[i].probability" [ngModelOptions]="{standalone: true}">
            </div>
            <div title="ลบ" (click)="deleteCriteriaButton(criteria)"
              class="rounded-circle1 pointer rounded-circle bg-danger d-flex justify-content-center align-items-center">
              <i class="fa fa-times"></i></div>
          </div>
          <div *ngIf="criteria?.status == statusEnum.Active" class="bg-gray-800 p-3 rounded-bottom">
            <div class="d-flex justify-content-center">
              <div class="mr-2">
                <div class="sign-dir-text mb-1 text-dark d-flex justify-content-center"><span
                    class="small text-light">ป้ายหน้า</span></div>
                <div *ngFor="let l of criteria?.layers;index as ii;">
                  <div class="d-flex justify-content-center flex-wrap">
                    <div *ngFor="let sign of l;index as iii;">
                      <div *ngIf="sign?.dir == signDir.Front">
                        <div class="pointer sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height)"
                          [style.height.px]="set_sign_height" *ngIf="sign?.dir == signDir.Front"
                          (click)="clickSignSuggest(sign,criteria)">
                          <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height"
                            [ratio]="sign?.ratio" [is_board]="true" [config_road]="config_road"
                            [rain]="boardsignInfo?.rain" [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                            *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                          </app-sign-image-item>
                          <app-playlist-item [show_count]="true" [config_road]="config_road"
                            [rain]="boardsignInfo?.rain" [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                            *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                            [playlist]="sign?.media?.items" [height]="set_sign_height"
                            [width]="get_sign_width(sign?.ratio,set_sign_height)">
                          </app-playlist-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ml-2">
                <div class="sign-dir-text mb-1 text-dark d-flex justify-content-center"><span
                    class="small text-light">ป้ายหลัง</span></div>
                <div *ngFor="let l of criteria?.layers;index as ii;" [style.height.px]="set_sign_height+4">
                  <div class="d-flex justify-content-center flex-wrap">
                    <div *ngFor="let sign of l;index as iii;">
                      <div *ngIf="sign?.dir == signDir.Back">
                        <div class="pointer sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height)"
                          [style.height.px]="set_sign_height" *ngIf="sign?.dir == signDir.Back"
                          (click)="clickSignSuggest(sign,criteria)">
                          <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height"
                            [ratio]="sign?.ratio" [is_board]="true" [config_road]="config_road"
                            [rain]="boardsignInfo?.rain" [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                            *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                          </app-sign-image-item>
                          <app-playlist-item [show_count]="true" [config_road]="config_road"
                            [rain]="boardsignInfo?.rain" [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                            *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                            [playlist]="sign?.media?.items" [height]="set_sign_height"
                            [width]="get_sign_width(sign?.ratio,set_sign_height)">
                          </app-playlist-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="select_inc_type" class="d-flex justify-content-between align-items-center mt-3">
          <div class="ml-2 mr-2"></div>
          <button *ngIf="signSuggestModal?._isShown" type="button" (click)="saveCriteria()" [disabled]="getDisableBt()"
            class="btn btn-primary text-white" style="min-width: 80px;">บันทึก</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #mediaListSuggestModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog  modal-xl" role="document">
    <div class="modal-content">
      <div *ngIf="select_sign_suggest != null">
        <app-media-selector-all [sign_height]="100" [sign]="select_sign_suggest" [config_road]="config_road"
          [rain]="boardsignInfo?.rain" [is_board]="true" (onComponentClosed)="closeMediaModalChangeMediaNew($event)">
        </app-media-selector-all>
      </div>
    </div>
  </div>
</div>
<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>
