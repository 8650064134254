<div class="panel-item">
  <div style="width: 100%;height:50px" class="d-flex">
    <div (click)="changeTab(mediaType?.Image)" style="width: 33%;height:50px"
      class="d-flex align-items-center justify-content-center"
      [ngClass]="(select_type_tab == mediaType?.Image)?'':'bg-gray-500 text-dark pointer'">
      <i class="fa fa-file-image-o mr-2"></i> <strong>Image</strong>
    </div>
    <div (click)="changeTab(mediaType?.Gif)" style="width: 34%;height:50px"
      class="d-flex align-items-center justify-content-center"
      [ngClass]="(select_type_tab == mediaType?.Gif)?'':'bg-gray-500 text-dark pointer'">
      <i class="fa fa-file-image-o mr-2"></i> <strong>Gif Animation</strong>
    </div>
    <div (click)="changeTab(mediaType?.Playlist)" style="width: 33%;height:50px"
      class="d-flex align-items-center justify-content-center"
      [ngClass]="(select_type_tab == mediaType?.Playlist)?'':'bg-gray-500 text-dark pointer'">
      <i class="fa fa-list mr-2"></i> <strong>Play List</strong>
    </div>
  </div>
  <div class="m-3">
    <div *ngIf="isLoading" class="d-flex align-items-center justify-content-center media-list-panel">
      <div class="lds-dual-ring"></div>
    </div>
    <div *ngIf="imgList?.length == 0 && !isLoading && select_type_tab == mediaType?.Image"
      class="d-flex align-items-center justify-content-center media-list-panel">
      <strong>ไม่พบข้อมูล</strong>
    </div>
    <div *ngIf="gifList?.length == 0 && !isLoading && select_type_tab == mediaType?.Gif"
      class="d-flex align-items-center justify-content-center media-list-panel">
      <strong>ไม่พบข้อมูล</strong>
    </div>
    <div *ngIf="playList?.length == 0 && !isLoading && select_type_tab == mediaType?.Playlist"
      class="d-flex align-items-center justify-content-center media-list-panel">
      <strong>ไม่พบข้อมูล</strong>
    </div>
    <div *ngIf="imgList?.length > 0 && !isLoading && select_type_tab == mediaType?.Image"
      class="d-flex align-content-start flex-wrap media-list-panel scrollbar-style">
      <div *ngFor="let media of imgList">
        <div [ngClass]="{ 'bg-gray-900': media?.id == selectedMedia?.id }">
          <div class="p-2">
            <!-- <img class="pointer" (click)="select(media)" [src]="media?.path" height="{{sign_height}}"
              width="{{get_sign_width(sign?.ratio,sign_height)}}" /> -->
            <!-- <div class="pointer" [style.height.px]="sign_height" (click)="select(media)"
              style="background-color: black;position: relative;"
              [style.width.px]="get_sign_width(media?.sign_type_obj?.aspect_ratio,sign_height)">
              <img *ngIf="media?.path" [src]="media?.path" width="100%" height="100%"
                style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;" />
              <div style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;"
                *ngFor="let item of media?.special_property?.text_items;index as i;"
                [ngClass]="(item?.is_center)?'d-flex align-items-center justify-content-center':''">
                <span *ngIf="item?.is_center && item?.text_type == specialTextType.FreeText"
                  [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                  [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{item?.text}}</span>
                <span *ngIf="!item?.is_center && item?.text_type == specialTextType.FreeText"
                  [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                  [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                  [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{item?.text}}</span>
              </div>
            </div> -->
            <app-sign-image-item class="pointer"  (click)="select(media)" [media]="media" [sign_height]="sign_height" [ratio]="sign?.ratio"
              [config_road]="config_road" [rain]="rain" [resize]="(sign_height/sign?.sign_type_object?.height)" [is_board]="is_board"
              *ngIf="media?.path && (media?.media_type == mediaType.Image || media?.media_type == mediaType.Gif)">
            </app-sign-image-item>
            <div class="clearfix m0 text-center"
              [ngStyle]="getSignNameTextStyles(get_sign_width(sign?.ratio,sign_height))">
              <small title="{{media?.name}}">{{media?.name}}</small>
            </div>
          </div>

        </div>

      </div>
    </div>
    <div *ngIf="gifList?.length > 0 &&!isLoading && select_type_tab == mediaType?.Gif"
      class="d-flex align-content-start flex-wrap media-list-panel scrollbar-style">
      <div *ngFor="let media of gifList">
        <div [ngClass]="{ 'bg-gray-900': media?.id == selectedMedia?.id }">
          <div class="p-2">
            <!-- <img class="pointer" (click)="select(media)" [src]="media?.path" height="{{sign_height}}"
              width="{{get_sign_width(sign?.ratio,sign_height)}}" /> -->
            <app-sign-image-item class="pointer"  (click)="select(media)" [media]="media" [sign_height]="sign_height" [ratio]="sign?.ratio"
              [config_road]="config_road" [rain]="rain" [resize]="(sign_height/sign?.sign_type_object?.height)" [is_board]="is_board"
              *ngIf="media?.path && (media?.media_type == mediaType.Image || media?.media_type == mediaType.Gif)">
            </app-sign-image-item>
            <div class="clearfix m0 text-center"
              [ngStyle]="getSignNameTextStyles(get_sign_width(sign?.ratio,sign_height))">
              <small title="{{media?.name}}">{{media?.name}}</small>
            </div>
          </div>

        </div>

      </div>
    </div>
    <div *ngIf="playList?.length > 0 &&!isLoading && select_type_tab == mediaType?.Playlist"
      class="d-flex align-content-start flex-wrap media-list-panel scrollbar-style">
      <div *ngFor="let media of playList">
        <div [ngClass]="{ 'bg-gray-900': media?.id == selectedMedia?.id }">
          <div class="p-2">
            <!-- <img class="pointer" (click)="select(media)" [src]="media?.path" height="{{media?.height}}"
                width="{{media?.width}}" />
              <div class="clearfix m0 text-center" [ngStyle]="getSignNameTextStyles(media?.width)">
                <small title="{{media?.name}}">{{media?.name}}</small>
              </div> -->
            <!-- <app-playlist-item class="pointer" [show_count]="true" *ngIf="media?.items" [playlist]="media?.items"
              [height]="sign_height" [width]="get_sign_width(sign?.ratio,sign_height)" (click)="select(media)">
            </app-playlist-item> -->
            <app-playlist-item class="pointer" (click)="select(media)" [show_count]="true" [config_road]="config_road"
              [rain]="rain" [resize]="(sign_height/sign?.sign_type_object?.height)" *ngIf="media?.items"
              [playlist]="media?.items" [is_board]="is_board" [height]="sign_height"
              [width]="get_sign_width(sign?.ratio,sign_height)">
            </app-playlist-item>
            <div class="clearfix m0 text-center"
              [ngStyle]="getSignNameTextStyles(get_sign_width(sign?.ratio,sign_height))">
              <small title="{{media?.name}}">{{media?.name}}</small>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

</div>

<!--Decision Panel-->
<div class="d-flex align-items-center justify-content-center m-3">
  <button type="button" class="btn btn-danger" (click)="closeComponent(null)" style="min-width: 100px">ยกเลิก</button>
  <button type="button" class="btn btn-primary ml-2" (click)="closeComponent(selectedMedia)" [disabled]="!selectedMedia"
    style="min-width: 100px">ตกลง</button>
</div>