import { Component, OnInit } from '@angular/core';
import { permission, session_key_databank } from 'src/app/views/information/_menu';
import { CommonService } from '../../../services/common.service';
@Component({
  selector: 'app-data-bank',
  templateUrl: './data-bank.component.html',
  styleUrls: ['./data-bank.component.scss']
})
export class DataBankComponent implements OnInit {
  permission = permission.data_bank;
  tabs_default = [
    {
      id: this.permission.data_bank_type_id,
      name: "ประเภทคลังข้อมูล",
      icon: "fa fa-clone"
    },
    {
      id: this.permission.data_bank_id,
      name: "คลังข้อมูล",
      icon: "fa fa-database"
    }

  ];
  tabs = [];
  tabSelect: any;
  sessionKey = session_key_databank;
  constructor(private commonService:CommonService) { }

  async ngOnInit() {
    
      
    const per_type = await this.commonService.getPermission(this.permission.data_bank_type_id).toPromise();
    const per_list = await this.commonService.getPermission(this.permission.data_bank_id).toPromise();
    if (per_type)
      this.tabs.push(this.tabs_default[0]);
    if (per_list)
      this.tabs.push(this.tabs_default[1]);

    const session_tab_id = sessionStorage.getItem(this.sessionKey)
    if(session_tab_id){
      const find = this.tabs.find(x=>x.id == +session_tab_id);
      if(find){
        this.tabSelect = find;
      }else{
        if (this.tabs.length > 0) {
          this.tabSelect = this.tabs[0];
        }
      }
    }else{
      if (this.tabs.length > 0) {
        this.tabSelect = this.tabs[0];
      }
    }

  }
  changeTab(t?: any) {
    if (this.tabSelect != t) {
      this.tabSelect = t;
      sessionStorage.setItem(this.sessionKey, this.tabSelect.id);
    }
  }

}
