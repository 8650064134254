import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  constructor(
    private api : ApiService,
  ) { }
    
  async addPermissionGroup(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addPermissionGroup(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  
  async getPermissionGroup(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getPermissionGroup(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getGroup(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getGroup(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addGroup(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addGroup(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addUGroup(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addUGroup(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmGroup(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmGroup(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmUGroup(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmUGroup(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
}
