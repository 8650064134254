<div bsModal #addModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" (onHide)="clearDataImage()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i>
          <span *ngIf="!isDescMa">รายละเอียดอุปกรณ์</span>
          <span *ngIf="isDescMa">รายละเอียด</span>
        </h4>
        <button type="button" class="close text-light" (click)="clickClose()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div *ngIf="view == 1 && monitor_type?.id == monitorTypeEnum.Hardware">
          <h5 class="font-2">เลือกอุปกรณ์ที่จะทำการแจ้งซ่อม</h5>
        </div>
        <div *ngIf="view == 1 " class="form-group row">
          <div class="col-md-3"> <span class="float-sm-left float-md-right">ประเภทการแจ้งซ่อม<span
                class="text-danger">*</span>
              :</span></div>
          <div class="col-md-9">
            <kendo-combobox class="w-100" [data]="monitor_type_list" [allowCustom]="allowCustom" [textField]="'name'"
              [valueField]="'id'" [value]="monitor_type" (valueChange)="handleValueMonitorType($event)"
              [filterable]="true" [placeholder]="'โปรดระบุ'">
            </kendo-combobox>
          </div>
        </div>
        <div *ngIf="view == 1  && monitor_type?.id == monitorTypeEnum.Software" class="form-group row">
          <div class="col-md-3"> <span class="float-sm-left float-md-right">เลือก Software
              :</span></div>
          <div class="col-md-9">
            <kendo-combobox class="w-100" [data]="data_list_software" [allowCustom]="allowCustom"
              [textField]="'app_name'" [valueField]="'id'" [value]="selectSoftware" [filterable]="true"
              (valueChange)="handleValueSoftware($event)" (filterChange)="handleFilterSoftware($event)"
              [placeholder]="'โปรดระบุ'">
            </kendo-combobox>
          </div>
        </div>
        <div class="form-group row" *ngIf="selectSoftware && typeId == monitorTypeEnum.Software">
          <div class="col-md-3"> <span class="float-sm-left float-md-right">ข้อมูล Software :</span></div>
          <div class="col-md-9">
            <div class="pt-2 pb-2 br-3 pl-3 bg-gray-800 rounded">
              <div>
                <span>App name : </span>
                <span class="ml-1">{{selectSoftware?.app_name}}</span>
              </div>
              <div>
                <span>Server name : </span>
                <span class="ml-1">{{selectSoftware?.server_name}}</span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="view == 1  && monitor_type?.id == monitorTypeEnum.Hardware" class="form-group row">
          <div class="col-md-3"> <span class="float-sm-left float-md-right">ทางหลวง
              :</span></div>
          <div class="col-md-9">
            <kendo-combobox class="w-100" [data]="data_route_modal" [allowCustom]="allowCustom" [textField]="''"
              [valueField]="''" [value]="route_modal" (valueChange)="handleValueRouteModal($event)" [filterable]="true"
              (filterChange)="handleFilterRouteModal($event)" [placeholder]="'โปรดระบุ'">
            </kendo-combobox>
          </div>
        </div>
        <div *ngIf="view == 1  && monitor_type?.id == monitorTypeEnum.Hardware" class="form-group row">
          <div class="col-md-3"> <span class="float-sm-left float-md-right">เลือกจุดติดตั้ง
              :</span></div>
          <div class="col-md-9">
            <kendo-combobox class="w-100" [data]="data_station_modal" [allowCustom]="allowCustom" [textField]="'name'"
              [valueField]="'id'" [value]="station_modal" (valueChange)="handleValueStationModal($event)"
              [filterable]="true" (filterChange)="handleFilterStationModal($event)" [placeholder]="'โปรดระบุ'">
            </kendo-combobox>
          </div>
        </div>
        <div *ngIf="view == 1  && monitor_type?.id == monitorTypeEnum.Hardware" class="form-group row">
          <div class="col-md-3"> <span class="float-sm-left float-md-right">เลือกอุปกรณ์<span
                class="text-danger">*</span>:</span></div>
          <div class="col-md-9">
            <kendo-combobox class="w-100" [data]="data_device_modal" [allowCustom]="allowCustom" [textField]="'name'"
              [valueField]="'device_id'" [value]="device_modal" (valueChange)="handleValueDeviceModal($event)"
              [filterable]="true" (filterChange)="handleFilterDeviceModal($event)" [placeholder]="'โปรดระบุ'">
            </kendo-combobox>
          </div>
        </div>
        <div *ngIf="view == 1  && monitor_type?.id == monitorTypeEnum.Boardsign" class="form-group row">
          <div class="col-md-3"> <span class="float-sm-left float-md-right">เลือกป้ายแสดงผล<span
                class="text-danger">*</span>:</span></div>
          <div class="col-md-9">
            <kendo-combobox class="w-100" [data]="data_board_list" [allowCustom]="allowCustom" [textField]="'name'"
              [valueField]="'id'" [value]="select_boardlist" (valueChange)="handleValueBoard($event)"
              (filterChange)="handleFilterBoard($event)" [placeholder]="'โปรดระบุ'" [filterable]="true">
            </kendo-combobox>
          </div>
        </div>
        <div class="form-group row" *ngIf="selectDevice && monitor_type?.id == monitorTypeEnum.Hardware">
          <div class="col-md-3"> <span class="float-sm-left float-md-right">ข้อมูลอุปกรณ์ :</span></div>
          <div class="col-md-9">
            <div class="pt-2 pb-2 br-3 pl-3 bg-gray-800 rounded">
              <div> <span>อุปกรณ์ :<span class="ml-1">
                    {{selectDevice?.device_name}}
                  </span></span>

              </div>
              <div>
                <span>ประเภทอุปกรณ์ :<span class="ml-1">
                    {{selectDevice?.device_type_name}}
                  </span>
                  <!-- <img class="ml-1" *ngIf="selectDevice?.device_subtype?.img_device"
                      [src]="selectDevice?.device_subtype?.img_device" width="20" height="20"> -->
                  <!-- <span class="ml-1"> {{selectDevice?.device_subtype?.subtype_name}}</span> -->
                </span>
              </div>
              <div>ทางหลวง :<span class="ml-1">
                  <span>{{selectDevice?.station_route | number:'1.0-0'}}</span>
                  ( {{selectDevice?.station_km}}+{{selectDevice?.station_m | number:'3.0-0'}} )
                </span></div>
              <div>
                <span>รหัสอุปกรณ์ : </span>
                <span class="ml-1">{{selectDevice?.device_code}}</span>
              </div>
              <div>
                <span>เขตที่รับผิดชอบ : </span>
                <span class="ml-1"> {{selectDevice?.station_district}}</span>
              </div>
              <div>
                <span>วันที่ติดตั้ง :</span>
                <span class="ml-1">{{selectDevice?.device_install_ts | date:'dd/MM/yyyy'}}</span>
              </div>
              <div>
                <span>วันสิ้นสุดการซ่อมบำรุง : </span>
                <span class="ml-1">{{selectDevice?.device_ma_expire_ts| date:'dd/MM/yyyy'}}</span>
              </div>
              <div>
                <span>ผู้ดูแลการซ่อมบำรุง : </span>
                <span class="ml-1">{{selectDevice?.contact_name}}</span>
              </div>
              <div>
                <span>เบอร์ติดต่อ : </span>
                <span class="ml-1">{{selectDevice?.contact_tel}}</span>
              </div>
              <div>
                <span>ตำแหน่ง : </span>
                <span class="ml-1">ทล.<span>{{selectDevice?.station_route | number:'1.0-0'}}</span>
                  ( {{selectDevice?.station_km}}+{{selectDevice?.station_m | number:'3.0-0'}} )
                  <span *ngIf="selectDevice?.station_dir">
                    (<span *ngIf="selectDevice?.station_dir == routeDirectionEnum.IN">ขาเข้า</span>
                    <span *ngIf="selectDevice?.station_dir == routeDirectionEnum.OUT">ขาออก</span>
                    <span *ngIf="selectDevice?.station_dir == routeDirectionEnum.INOUT">ขาเข้า-ขาออก</span>)
                  </span>

                </span>
              </div>
              <div>
                <span>ตำแหน่งจาก GPS :</span>
                <span *ngIf="selectDevice?.station_lat != null && selectDevice?.station_lng != null"
                  class="ml-1">{{selectDevice?.station_lat}} , {{selectDevice?.station_lng}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row" *ngIf="selectBoardsign && monitor_type?.id == monitorTypeEnum.Boardsign">
          <div class="col-md-3"> <span class="float-sm-left float-md-right">ข้อมูลป้าย :</span></div>
          <div class="col-md-9">
            <div class="pt-2 pb-2 br-3 pl-3 bg-gray-800 rounded">
              <div> <span>ชื่อ :<span class="ml-1">
                    {{selectBoardsign?.name}}
                  </span></span>

              </div>
              <div>
                <span>จุดติดตั้ง :<span class="ml-1">
                    {{selectBoardsign?.station?.station_name}}
                  </span>
                </span>
              </div>
              <div>
                <span>ตำแหน่ง : </span>
                <span class="ml-1">ทล.<span>{{selectBoardsign?.station?.route_id | number:'1.0-0'}}</span>
                  ( {{selectBoardsign?.station?.km}}+{{selectBoardsign?.station?.m | number:'3.0-0'}} )
                  <span *ngIf="selectBoardsign?.station?.direction">
                    (<span *ngIf="selectBoardsign?.station?.direction == routeDirectionEnum.IN">ขาเข้า</span>
                    <span *ngIf="selectBoardsign?.station?.direction == routeDirectionEnum.OUT">ขาออก</span>
                    <span *ngIf="selectBoardsign?.station?.direction == routeDirectionEnum.INOUT">ขาเข้า-ขาออก</span>)
                  </span>
                </span>
              </div>
              <div class="d-flex justify-content-around mt-3">
                <div>
                  <div class="sign-dir-text mb-1 text-light d-flex justify-content-center"><span
                      class="small">ป้ายหน้า</span></div>
                  <div *ngFor="let l of selectBoardsign?.layers;index as ii;">
                    <div class="d-flex justify-content-center flex-wrap">
                      <div *ngFor="let sign of l;index as iii;">
                        <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)"
                          [style.height.px]="set_sign_height_small" *ngIf="sign?.dir == signDir.Front">
                          <!-- <img [src]="sign?.media?.path" height="{{set_sign_height_small}}"
                            width="{{get_sign_width(sign?.ratio,set_sign_height_small)}}"
                            *ngIf="sign?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                          <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_small"
                            [ratio]="sign?.ratio" [is_board]="true" [config_road]="p?.config_road" [rain]="p?.rain"
                            [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                            *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                          </app-sign-image-item>
                          <app-playlist-item [show_count]="true"
                            *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                            [playlist]="sign?.media?.items" [height]="set_sign_height_small"
                            [width]="get_sign_width(sign?.ratio,set_sign_height_small)">
                          </app-playlist-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="sign-dir-text mb-1 text-light d-flex justify-content-center"><span
                      class="small">ป้ายหลัง</span></div>
                  <div *ngFor="let l of selectBoardsign?.layers;index as ii;"
                    [style.height.px]="set_sign_height_small+4">
                    <div class="d-flex justify-content-center flex-wrap">
                      <div *ngFor="let sign of l;index as iii;">
                        <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)"
                          [style.height.px]="set_sign_height_small" *ngIf="sign?.dir == signDir.Back">
                          <!-- <img [src]="sign?.media?.path" height="{{set_sign_height_small}}"
                            width="{{get_sign_width(sign?.ratio,set_sign_height_small)}}"
                            *ngIf="sign?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                          <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_small"
                            [ratio]="sign?.ratio" [is_board]="true" [config_road]="p?.config_road" [rain]="p?.rain"
                            [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                            *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                          </app-sign-image-item>
                          <app-playlist-item [show_count]="true"
                            *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                            [playlist]="sign?.media?.items" [height]="set_sign_height_small"
                            [width]="get_sign_width(sign?.ratio,set_sign_height_small)">
                          </app-playlist-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <form *ngIf="isAddMa" class="form-horizontal mt-2 mb-2 border-W">
          <div class="form-group row">
            <div class="col-md-3"> <span class="float-sm-left float-md-right">ผู้แจ้ง :</span></div>
            <div class="col-md-9">
              {{currentUser?.firstname}} {{currentUser?.lastname}}
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-3"> <span class="float-sm-left float-md-right">รายละเอียดแจ้งซ่อม<span
                  class="text-danger">*</span>:</span></div>
            <div class="col-md-9">
              <textarea [(ngModel)]="selectItem.detail" name="text-descc" rows="3" class="form-control"
                placeholder=""></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input"> <span class="float-sm-left float-md-right">รูปภาพ
                :</span></label>
            <div class="col-md-9">
              <div class="up-im-boder d-flex flex-wrap">
                <div *ngFor="let item of ma_images">
                  <div *ngIf="item.dataurl && item.file" class="up-img">
                    <img height="58" width="58" [src]="item?.dataurl | safe">
                    <i title="ลบ" class="fa fa-close up-im-delete text-light" (click)="deleteImageFiles(item)"></i>
                  </div>
                  <div *ngIf="item.url && item.delete != true" class="up-img">
                    <img *ngIf="item?.url" height="58" width="58" [src]="item?.url">
                    <i title="ลบ" class="fa fa-close up-im-delete text-light" (click)="deleteImageFiles(item)"></i>
                  </div>
                </div>
                <div class="btn btn-file">
                  <i title="เพิ่มรูป" class="fa fa-plus" style="padding-top: 20px;"></i>
                  <input #imInput title="เพิ่มรูป" type="file" (change)="detectFiles($event)" accept=".png,.jpg,.gif"
                    multiple>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isAddMa" class="text-right">
            <button *ngIf="this.monitor_type?.id == monitorTypeEnum.Hardware"
              [disabled]="!(selectItem?.detail && monitor_type && this.monitor_type?.id == monitorTypeEnum.Hardware && selectDevice)"
              type="button" class="btn btn-primary text-white ml-2" (click)="addMaAsyn()">บันทึกการแจ้งซ่อม</button>
            <button *ngIf="this.monitor_type?.id == monitorTypeEnum.Software"
              [disabled]="!(selectItem?.detail && monitor_type && this.monitor_type?.id == monitorTypeEnum.Software && selectSoftware)"
              type="button" class="btn btn-primary text-white ml-2" (click)="addMaAsyn()">บันทึกการแจ้งซ่อม</button>
            <button *ngIf="this.monitor_type?.id == monitorTypeEnum.Boardsign"
              [disabled]="!(selectItem?.detail && monitor_type && this.monitor_type?.id == monitorTypeEnum.Boardsign && selectBoardsign)"
              type="button" class="btn btn-primary text-white ml-2" (click)="addMaAsyn()">บันทึกการแจ้งซ่อม</button>
          </div>
        </form>
        <div *ngIf="isAddMa" class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="clickClose()">ปิด</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #addLogModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" (onHide)="clearDataImage()" [config]="{backdrop: 'static'}"
  [ngClass]="(changeStatusModal?._isShown)?'modal-hide-when-second-open':''">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-800">
        <h4 class="modal-title"> <i class="fa fa-wrench mr-2"></i><span>แจ้งซ่อม
            <span *ngIf="typeId == monitorTypeEnum.Hardware">{{selectDevice?.device_name}}</span>
            <span *ngIf="typeId == monitorTypeEnum.Software">{{selectSoftware?.app_name}}</span>
          </span>
        </h4>
        <button type="button" class="close text-light" (click)="clickCloseLog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="d-flex">
        <div *ngIf="isDescDevice || isDescMa"
          class="p-2 flex-fill d-flex justify-content-center align-items-center disable-select font-2"
          [ngClass]="(selectTab == 1)?'menu-item-active':'menu-item'" (click)="changeTab(1)">
          <i class="icon-info mr-1"></i>
          <span>รายละเอียดแจ้งซ่อม</span>
        </div>
        <div *ngIf="isMaLog"
          class="p-2 flex-fill d-flex justify-content-center align-items-center disable-select font-2"
          [ngClass]="(selectTab == 2)?'menu-item-active':'menu-item'" (click)="changeTab(2)">
          <i class="fa fa-clock-o mr-1"></i>
          <span>Activity log</span>
        </div>
      </div>

      <div class="modal-body" *ngIf="selectTab == 1 && (isDescDevice || isDescMa)">
        <div class="form-group row" *ngIf="selectSoftware && typeId == monitorTypeEnum.Software">
          <div class="col-md-3"> <span class="float-sm-left float-md-right">ข้อมูล Software :</span></div>
          <div class="col-md-9">
            <div class="pt-2 pb-2 br-3 pl-3 bg-gray-800 rounded">
              <div>
                <span>App name : </span>
                <span class="ml-1">{{selectSoftware?.app_name}}</span>
              </div>
              <div>
                <span>Server name : </span>
                <span class="ml-1">{{selectSoftware?.server_name}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row" *ngIf="selectDevice && typeId == monitorTypeEnum.Hardware">
          <div class="col-md-3"> <span class="float-sm-left float-md-right">ข้อมูลอุปกรณ์ :</span></div>
          <div class="col-md-9">
            <div class="pt-2 pb-2 br-3 pl-3 bg-gray-800 rounded">
              <div> <span>อุปกรณ์ :<span class="ml-1">
                    {{selectDevice?.device_name}}
                  </span></span>

              </div>
              <div>
                <span>ประเภทอุปกรณ์ :<span class="ml-1">
                    {{selectDevice?.device_type_name}}
                  </span>
                  <!-- <img class="ml-1" *ngIf="selectDevice?.device_subtype?.img_device"
                        [src]="selectDevice?.device_subtype?.img_device" width="20" height="20"> -->
                  <!-- <span class="ml-1"> {{selectDevice?.device_subtype?.subtype_name}}</span> -->
                </span>
              </div>
              <div>ทางหลวง :<span class="ml-1">
                  <span>{{selectDevice?.station_route | number:'1.0-0'}}</span>
                  ( {{selectDevice?.station_km}}+{{selectDevice?.station_m | number:'3.0-0'}} )
                </span></div>
              <div>
                <span>รหัสอุปกรณ์ : </span>
                <span class="ml-1">{{selectDevice?.device_code}}</span>
              </div>
              <div>
                <span>เขตที่รับผิดชอบ : </span>
                <span class="ml-1"> {{selectDevice?.station_district}}</span>
              </div>
              <div>
                <span>วันที่ติดตั้ง :</span>
                <span class="ml-1">{{selectDevice?.device_install_ts | date:'dd/MM/yyyy'}}</span>
              </div>
              <div>
                <span>วันสิ้นสุดการซ่อมบำรุง : </span>
                <span class="ml-1">{{selectDevice?.device_ma_expire_ts| date:'dd/MM/yyyy'}}</span>
              </div>
              <div>
                <span>ผู้ดูแลการซ่อมบำรุง : </span>
                <span class="ml-1">{{selectDevice?.contact_name}}</span>
              </div>
              <div>
                <span>เบอร์ติดต่อ : </span>
                <span class="ml-1">{{selectDevice?.contact_tel}}</span>
              </div>
              <div>
                <span>ตำแหน่ง : </span>
                <span class="ml-1">ทล.<span>{{selectDevice?.station_route | number:'1.0-0'}}</span>
                  ( {{selectDevice?.station_km}}+{{selectDevice?.station_m | number:'3.0-0'}} )
                  <span *ngIf="selectDevice?.station_dir">
                    (<span *ngIf="selectDevice?.station_dir == routeDirectionEnum.IN">ขาเข้า</span>
                    <span *ngIf="selectDevice?.station_dir == routeDirectionEnum.OUT">ขาออก</span>
                    <span *ngIf="selectDevice?.station_dir == routeDirectionEnum.INOUT">ขาเข้า-ขาออก</span>)
                  </span>

                </span>
              </div>
              <div>
                <span>ตำแหน่งจาก GPS :</span>
                <span *ngIf="selectDevice?.station_lat != null && selectDevice?.station_lng != null"
                  class="ml-1">{{selectDevice?.station_lat}} , {{selectDevice?.station_lng}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row" *ngIf="selectBoardsign && typeId == monitorTypeEnum.Boardsign">
          <div class="col-md-3"> <span class="float-sm-left float-md-right">ข้อมูลป้ายแสดงผล :</span></div>
          <div class="col-md-9">
            <div class="pt-2 pb-2 br-3 pl-3 bg-gray-800 rounded">
              <div> <span>ชื่อป้าย :<span class="ml-1">
                    {{selectBoardsign?.name}}
                  </span></span>
              </div>
              <div>
                <span>จุดติดตั้ง :<span class="ml-1">
                    {{selectBoardsign?.station?.station_name}}
                  </span>
                </span>
              </div>
              <div>
                <span>ตำแหน่ง : </span>
                <span class="ml-1">ทล.<span>{{selectBoardsign?.station?.route_id | number:'1.0-0'}}</span>
                  ( {{selectBoardsign?.station?.km}}+{{selectBoardsign?.station?.m | number:'3.0-0'}} )
                  <span *ngIf="selectBoardsign?.station?.direction">
                    (<span *ngIf="selectBoardsign?.station?.direction == routeDirectionEnum.IN">ขาเข้า</span>
                    <span *ngIf="selectBoardsign?.station?.direction == routeDirectionEnum.OUT">ขาออก</span>
                    <span *ngIf="selectBoardsign?.station?.direction == routeDirectionEnum.INOUT">ขาเข้า-ขาออก</span>)
                  </span>
                </span>
              </div>
              <div class="d-flex justify-content-around mt-3">
                <div>
                  <div class="sign-dir-text mb-1 text-light d-flex justify-content-center"><span
                      class="small">ป้ายหน้า</span></div>
                  <div *ngFor="let l of selectBoardsign?.layers;index as ii;">
                    <div class="d-flex justify-content-center flex-wrap">
                      <div *ngFor="let sign of l;index as iii;">
                        <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)"
                          [style.height.px]="set_sign_height_small" *ngIf="sign?.dir == signDir.Front">
                          <!-- <img [src]="sign?.media?.path" height="{{set_sign_height_small}}"
                            width="{{get_sign_width(sign?.ratio,set_sign_height_small)}}"
                            *ngIf="sign?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                          <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_small"
                            [ratio]="sign?.ratio" [is_board]="true" [config_road]="p?.config_road" [rain]="p?.rain"
                            [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                            *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                          </app-sign-image-item>
                          <app-playlist-item [show_count]="true"
                            *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                            [playlist]="sign?.media?.items" [height]="set_sign_height_small"
                            [width]="get_sign_width(sign?.ratio,set_sign_height_small)">
                          </app-playlist-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="sign-dir-text mb-1 text-light d-flex justify-content-center"><span
                      class="small">ป้ายหลัง</span></div>
                  <div *ngFor="let l of selectBoardsign?.layers;index as ii;"
                    [style.height.px]="set_sign_height_small+4">
                    <div class="d-flex justify-content-center flex-wrap">
                      <div *ngFor="let sign of l;index as iii;">
                        <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)"
                          [style.height.px]="set_sign_height_small" *ngIf="sign?.dir == signDir.Back">
                          <!-- <img [src]="sign?.media?.path" height="{{set_sign_height_small}}"
                            width="{{get_sign_width(sign?.ratio,set_sign_height_small)}}"
                            *ngIf="sign?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                          <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_small"
                            [ratio]="sign?.ratio" [is_board]="true" [config_road]="p?.config_road" [rain]="p?.rain"
                            [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                            *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                          </app-sign-image-item>
                          <app-playlist-item [show_count]="true"
                            *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                            [playlist]="sign?.media?.items" [height]="set_sign_height_small"
                            [width]="get_sign_width(sign?.ratio,set_sign_height_small)">
                          </app-playlist-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form class="form-horizontal mt-2 mb-2 border-W">
          <div class="form-group row">
            <div class="col-md-3"> <span class="float-sm-left float-md-right">ผู้แจ้ง :</span></div>
            <div class="col-md-9">
              {{selectMaItem?.username}}
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-3"> <span class="float-sm-left float-md-right">รายละเอียดแจ้งซ่อม:</span></div>
            <div class="col-md-9">
              {{selectMaItem?.detail}}
            </div>
          </div>
          <div class="form-group row m-0">
            <label class="col-md-3 col-form-label" for="text-input"> <span class="float-sm-left float-md-right">รูปภาพ
                :</span></label>
            <div class="col-md-9">
              <div *ngIf="!selectMaItem?.image_url"> - </div>
              <div *ngIf="selectMaItem?.image_url">
                <div *ngIf="selectMaItem?.image_url?.length==0"> - </div>
                <div *ngIf="selectMaItem?.image_url?.length>0" class="up-im-boder d-flex flex-wrap">
                  <div *ngFor="let im of selectMaItem?.image_url">
                    <div *ngIf="!im" class="up-img">
                    </div>
                    <div *ngIf="im" class="up-img">
                      <img *ngIf="im" height="58" width="58" [src]="im">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="clickCloseLog()">ปิด</button>
        </div>
      </div>
      <div class="modal-body" *ngIf="selectTab == 2 && isMaLog">
        <div class="animated fadeIn event-log-box rounded scrollbar-style" auto-scroll lock-y-offset="10"
          observe-attributes>
          <div class="pl-3 pr-3">
            <div class="d-flex" *ngFor="let elog of event_logs; let i = index"
              [ngClass]="{'mb-3': event_logs?.length == (i+1)}">
              <div class="d-flex justify-content-center align-items-center time-text-log">
                <div class="text-center margin-t-20">
                  <div>{{elog?.create_date| date:'dd/MM/yyyy'}}</div>
                  <div>{{elog?.create_date| date:'HH:mm'}}</div>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-end circle-text-log">
                <div class="h-100">
                  <div [ngClass]="{'line-1-none': i == 0,'line-2': i>0}"></div>
                  <div class="border bg-light rounded-circle circle-status border-3" [ngClass]="{'border-danger': elog?.status == maStatusEnum.Create,'border-warning': elog?.status == maStatusEnum.Operate
                    ,'border-success': elog?.status == maStatusEnum.Close,'border-secondary': elog?.status == null}">
                  </div>
                  <div class="line-3" *ngIf="event_logs?.length > (i+1)"></div>
                </div>
              </div>
              <div class="ml-auto w-100">
                <div class="ml-4 text-user-h d-flex align-items-end justify-content-between">
                  <div>{{elog?.username}}</div>
                  <div *ngIf="elog?.ma_price != null && elog?.status == maStatusEnum.Close" class="text-light">
                    ประเมินราคาการซ่อมแซม <span class="text-danger"> {{elog?.ma_price| number}} </span>บาท</div>
                </div>
                <div class="ml-4 messages">
                  <div class="p-3">
                    <div class="text-dark">{{elog?.message}}</div>
                    <div class="d-flex flex-wrap mt-2" *ngIf="elog?.image_url?.length>0">
                      <div *ngFor="let im of elog?.image_url; let i2 = index;trackBy:trackByIndex;">
                        <img *ngIf="im" [src]="im" width="60" height="60" class="border border-secondary rounded mr-1">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="is_loading" class="d-flex justify-content-center">
              <div class="lds-dual-ring"></div>
            </div>
          </div>
        </div>

        <div *ngIf="isAddMaLog" class="row up-im-boder mt-1">
          <div *ngFor="let item of ma_images2">
            <div *ngIf="item.dataurl && item.file" class="up-img">
              <img height="58" width="58" [src]="item?.dataurl | safe">
              <i title="ลบ" class="fa fa-close up-im-delete text-light" (click)="deleteImageFiles2(item)"></i>
            </div>
            <div *ngIf="item.url && item.delete != true" class="up-img">
              <img *ngIf="item?.url" height="58" width="58" [src]="item?.url">
              <i title="ลบ" class="fa fa-close up-im-delete text-light" (click)="deleteImageFiles2(item)"></i>
            </div>
          </div>
          <div class="btn btn-file">
            <i title="เพิ่มรูป" class="fa fa-plus" style="padding-top: 20px;"></i>
            <input #imInput2 title="เพิ่มรูป" type="file" (change)="detectFiles2($event)" accept=".png,.jpg,.gif"
              multiple>
          </div>
        </div>
        <div class="row mt-1" *ngIf="selectMessageStatus == maStatusEnum.Close && isAddMaLog">
          <div class="col-md-12">
            <div class="d-flex justify-content-start align-items-center">
              <div class="mr-1">ประเมินราคาการซ่อมแซม<span class="text-danger mr-1">*</span></div>
              <div><input type="text" [(ngModel)]="price" class="form-control" placeholder="" maxlength="20"
                  [ngModelOptions]="{standalone: true}"></div>
              <div class="ml-1"> บาท</div>
            </div>
          </div>
        </div>
        <div *ngIf="isAddMaLog" class="form-group row mt-1">
          <div class="col-md-12">
            <div class="input-group">
              <textarea [(ngModel)]="message" name="text-descc" rows="1" class="form-control form-control-lg"
                placeholder="รายละเอียด..."></textarea>
              <span class="input-group-append">
                <button *ngIf="selectMessageStatus == maStatusEnum.Close" (click)="addMaLogAsyn()"
                  [disabled]="!(this.selectMaItem != null && this.message && price)" type="button"
                  class="btn btn-primary btn-lg text-white"><i class="fa fa-paper-plane mr-2"
                    aria-hidden="true"></i>Send</button>
                <button *ngIf="selectMessageStatus != maStatusEnum.Close" (click)="addMaLogAsyn()"
                  [disabled]="!(this.selectMaItem != null && this.message)" type="button"
                  class="btn btn-primary btn-lg text-white"><i class="fa fa-paper-plane mr-2"
                    aria-hidden="true"></i>Send</button>
              </span>
            </div>
          </div>

        </div>

        <div class="form-group row mt-1">
          <div class="col-md-12">
            <div class="d-flex align-items-center justify-content-between">
              <div *ngIf="isAddMaLog" class="d-flex align-items-center">
                <div (click)="changeStatus(maStatusEnum.Create)" title="Create"
                  class="border bg-light rounded-circle circle-status border-3 border-danger m-2 pointer d-flex align-items-center justify-content-center">
                  <div *ngIf="selectMessageStatus == maStatusEnum.Create"
                    class="bg-danger rounded-circle circle-status-small pointer"></div>
                </div>
                <div (click)="changeStatus(maStatusEnum.Operate)" title="Operate"
                  class="border bg-light rounded-circle circle-status border-3 border-warning m-2 pointer d-flex align-items-center justify-content-center">
                  <div *ngIf="selectMessageStatus == maStatusEnum.Operate"
                    class="bg-warning rounded-circle circle-status-small pointer"></div>
                </div>
                <div (click)="changeStatus(maStatusEnum.Close)" title="Close"
                  class="border bg-light rounded-circle circle-status border-3 border-success m-2 pointer d-flex align-items-center justify-content-center">
                  <div *ngIf="selectMessageStatus == maStatusEnum.Close"
                    class="bg-success rounded-circle circle-status-small pointer"></div>
                </div>
                <div class="m-2 font-2">
                  <span *ngIf="selectMessageStatus == maStatusEnum.Create">Create</span>
                  <span *ngIf="selectMessageStatus == maStatusEnum.Operate">Operate</span>
                  <span *ngIf="selectMessageStatus == maStatusEnum.Close">Close</span>
                </div>
              </div>
              <div></div>
              <div class="d-flex align-items-center">
                สถานะปัจจุบัน :
                <i class="fa fa-circle fa-2x ml-1" *ngIf="selectMaItem?.status != null"
                  [ngClass]="{'text-danger':selectMaItem?.status == maStatusEnum.Create, 'text-warning':selectMaItem?.status == maStatusEnum.Operate,'text-success':selectMaItem?.status == maStatusEnum.Close}"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="clickCloseLog()">ปิด</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #changeStatusModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearChangeStatus()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> ยืนยัน
          <span *ngIf="selectMessageStatus == maStatusEnum.Create">Create</span>
          <span *ngIf="selectMessageStatus == maStatusEnum.Operate">Operate</span>
          <span *ngIf="selectMessageStatus == maStatusEnum.Close">Close</span>
        </h4>
        <button type="button" class="close text-light" (click)="changeStatusModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <div> <span class="col-md-12">เพิ่มข้อความ</span></div>
            <div class="col-md-12">
              <textarea [(ngModel)]="message" name="text-descc" rows="3" class="form-control" placeholder=""></textarea>
            </div>
          </div>
          <div class="row" *ngIf="selectMessageStatus == maStatusEnum.Close">
            <div class="col-md-12">
              <div class="d-flex justify-content-start align-items-center">
                <div class="mr-1">ประเมินราคาการซ่อมแซม<span class="text-danger mr-1">*</span></div>
                <div><input type="text" [(ngModel)]="price" class="form-control" placeholder="" maxlength="20"
                    [ngModelOptions]="{standalone: true}"></div>
                <div class="ml-1"> บาท</div>
              </div>
            </div>
          </div>
          <div>เพิ่มรูปภาพ</div>
          <div class="row up-im-boder mt-1">
            <div *ngFor="let item of ma_images2">
              <div *ngIf="item.dataurl && item.file" class="up-img">
                <img height="58" width="58" [src]="item?.dataurl | safe">
                <i title="ลบ" class="fa fa-close up-im-delete text-light" (click)="deleteImageFiles2(item)"></i>
              </div>
              <div *ngIf="item.url && item.delete != true" class="up-img">
                <img *ngIf="item?.url" height="58" width="58" [src]="item?.url">
                <i title="ลบ" class="fa fa-close up-im-delete text-light" (click)="deleteImageFiles2(item)"></i>
              </div>
            </div>
            <div class="btn btn-file">
              <i title="เพิ่มรูป" class="fa fa-plus" style="padding-top: 20px;"></i>
              <input #imInput2 title="เพิ่มรูป" type="file" (change)="detectFiles2($event)" accept=".png,.jpg,.gif"
                multiple>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button *ngIf="selectMessageStatus == maStatusEnum.Close" (click)="addMaLogAsyn()"
            [disabled]="!(this.selectMaItem != null && this.message && price)" type="button"
            class="btn btn-primary text-white ml-2">ยืนยัน</button>
          <button *ngIf="selectMessageStatus != maStatusEnum.Close" (click)="addMaLogAsyn()"
            [disabled]="!(this.selectMaItem != null && this.message)" type="button"
            class="btn btn-primary text-white ml-2">ยืนยัน</button>
        </div>

      </div>
      <!-- <div class="modal-footer">
          
        </div> -->
    </div>
  </div>
</div>

<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>
