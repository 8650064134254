import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponse, PagableData } from '../../models/common';
import { FavoriteInfo, ReqAddFavorite } from '../../models/sign-control/favorite';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { StatusEnum } from '../../models/common-enum';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  domain: any = environment.api.domain_sign_control;
  constructor(private http: HttpClient, private authService: AuthService) {

  }
  getFavorite(page?: number, limit?: number,boardsign_id?:number, structure?: string) {
    const uri = `boardsign/${boardsign_id}/fav`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();
    if (!_.isNil(page)) {
      params = params.append('page', page.toString());
    }
    if (!_.isNil(limit)) {
      params = params.append('limit', limit.toString());
    }
    if (!_.isNil(boardsign_id)) {
      params = params.append('boardsign_id', boardsign_id.toString());
    }
    if (!_.isNil(structure)) {
      params = params.append('structure', structure.toString());
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<FavoriteInfo[]>>>(url, httpOptions);
    //return this.http.get<ApiResponse<FavoriteInfo[]>>(url, httpOptions);
  }
  addFavorite(req: ReqAddFavorite, boardsign_id?: number): Observable<ApiResponse<any>> {
    const uri = `boardsign/${boardsign_id}/fav`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }
  editFavorite(req: ReqAddFavorite, fav_id?: number, boardsign_id?: number): Observable<ApiResponse<any>> {
    const uri = `boardsign/${boardsign_id}/fav/${fav_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteFavorite(fav_id: number, boardsign_id?: number): Observable<ApiResponse<any>> {
    const uri = `boardsign/${boardsign_id}/fav/${fav_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
  deleteFavoriteEditStatus(req: ReqAddFavorite, fav_id: number, boardsign_id?: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `boardsign/${boardsign_id}/fav/${fav_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
}
