import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { SocialsInfo } from 'src/app/models/dashboard/socials_event';
import { SocialsService } from 'src/app/services/dashboard/socials.service';
import * as _ from 'lodash';
import { RouteDirectionEnum } from 'src/app/models/common-enum';
import { interval } from 'rxjs';
@Component({
  selector: 'app-socials-information',
  templateUrl: './socials-information.component.html',
  styleUrls: ['./socials-information.component.scss']
})
export class SocialsInformationComponent implements OnInit, OnChanges, OnDestroy {
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() toggle: boolean;

  subscription: any;
  routeDirType = RouteDirectionEnum;
  eventAssistanceList: SocialsInfo[] = [];
  checkCollection: SocialsInfo[] = [];
  isSocialsLoading: boolean;
  constructor(private socialsService: SocialsService) { }

  ngOnInit() {
    this.subscription = interval(60000).subscribe((x) => {
      if (this.toggle) {
        this.getEventAssistance();
      }
    });
  }
  clickClose() {
    this.onClose.emit(null);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getEventAssistance();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  getEventAssistance() {
    this.isSocialsLoading = true;
    this.socialsService.getEventAssistance(null, null, null, null).subscribe(
      res => {
        if (res.code == 1) {
          this.eventAssistanceList = res.data.socials;
        }
        this.isSocialsLoading = false;
      },
      err => {
        if (err.statusText) {
          console.log(err.statusText);
        }
        this.isSocialsLoading = false;
      });
  }

  clickLocation(loc: any) {
    if (!_.isNil(loc)) {
      if (!_.isNil(loc.coordinates)) {
        const lat = loc.coordinates[1];
        const lng = loc.coordinates[0];
        const dms = this.convertDMS(lat, lng);
        const link = `https://www.google.com/maps/place/${dms}/@${lat},${lng},10.0z/data=!4m5!3m4!1s0x0:0x0!8m2!3d${lat}!4d${lng}!5m1!1e1?hl=th`;
        window.open(link);
      }
    }
  }
  toDegreesMinutesAndSeconds(coordinate) {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60.00;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = Math.floor((minutesNotTruncated - minutes) * 60.00);

    return degrees + "°" + minutes + "'" + seconds;
  }

  convertDMS(lat, lng) {
    var latitude = this.toDegreesMinutesAndSeconds(lat);
    var latitudeCardinal = Math.sign(lat) >= 0 ? "N" : "S";

    var longitude = this.toDegreesMinutesAndSeconds(lng);
    var longitudeCardinal = Math.sign(lng) >= 0 ? "E" : "W";

    return latitude + '"' + latitudeCardinal + "+" + longitude + '"' + longitudeCardinal;
  }
}
