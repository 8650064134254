import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { of, timer } from 'rxjs';
import { switchMap, repeat } from "rxjs/operators";
import { TimeService } from './time.service';
import { MediaBehaviorType, SpecialTextType } from 'src/app/models/sign-control/enum';

@Component({
  selector: 'app-playlist-item',
  templateUrl: './playlist-item.component.html',
  styleUrls: ['./playlist-item.component.scss']
})
export class PlaylistItemComponent implements OnInit, OnChanges {
  @Input() playlist: any[];
  @Input() height: any;
  @Input() width: any;
  @Input() show_count: any;
  @Input() modal: any;
  @Input() unsub: any;
  @Input() config_road: any[];
  @Input() rain: number;
  @Input() resize: any = 1;
  @Input() is_board: boolean;
  text_speed = 90;
  specialTextType = SpecialTextType;
  mediaBehaviorType = MediaBehaviorType;
  src: any;
  playlistItem: any;
  count: any;
  interval: number = 1000;
  intervals: any[] = [];
  constructor(private timeService: TimeService) { }
  subscription: any;
  i: any = 0;

  heightpx: any = 100;
  widthpx: any = 100;
  ngOnInit(): void {
    if (!this.modal) {
      this.src = null;
      if (this.height) {
        this.heightpx = +this.height;
      }
      if (this.width) {
        this.widthpx = +this.width;
      }
      if (this.playlist && this.playlist.length > 0) {
        this.count = this.playlist.length;
        if (this.count != 0 && this.count == 1) {
          if (this.playlist[0].boardsign_image) {
            this.src = this.playlist[0].boardsign_image.path;
            this.playlistItem = this.playlist[0];
          }

        } else {
          if (this.playlist[0]) {
            if (this.playlist[0].boardsign_image) {
              this.src = this.playlist[0].boardsign_image.path;
              this.playlistItem = this.playlist[0];
            }
            this.interval = (this.playlist[this.i].duration) * 1000;

            this.subscription = this.getTime$.subscribe(t => {
              if (this.playlist[this.i].boardsign_image) {
                this.src = this.playlist[this.i].boardsign_image.path;
                this.playlistItem = this.playlist[this.i];
              }
              if (this.i + 1 == this.count) {
                this.i = 0;
                this.interval = (this.playlist[this.count - 1].duration) * 1000;

              } else {
                this.interval = (this.playlist[this.i].duration) * 1000;
                this.i = this.i + 1;

              }
              if (this.modal) {
                console.log('Refresh interval is: ' + this.interval);
              }
            });
          }
        }
      }
    }

  }
  refreshObs() { return timer(this.interval) }

  getTime$ = of(null).pipe(switchMap(e => this.refreshObs())).pipe(switchMap(r => this.timeService.getTime())).pipe(repeat());

  ngOnChanges(changes: SimpleChanges) {
    //if (changes.config_road) {
      if (this.playlist && this.playlist.length > 0) {
        this.playlist.forEach(ply => {
          if (ply.boardsign_image.behavior_type == MediaBehaviorType.Special) {
            if (ply.boardsign_image.special_property) {
              if (ply.boardsign_image.special_property.text_items.length > 0) {
                ply.boardsign_image.special_property.text_items.forEach(item => {
                  if(item.text_type != SpecialTextType.FreeText && !this.is_board)
                    item.text_speed = this.text_speed;
                  if (item.text_type != SpecialTextType.FreeText && this.config_road) {
                    const config_lane = this.config_road.find(x => x.lane_no == item.lane);
                    if(config_lane){
                      const config_class = config_lane.veh_class.find(x => x.class_no == item.class);
                      if (item.text_type == SpecialTextType.SpeedLimit) {
                        if (this.rain == 1) {
                          if (config_class.rain_speed != 0 && config_class.rain_speed != null)
                            item.text_speed = parseInt(config_class.rain_speed);
                        } else {
                          if (config_class.normal_speed != 0 && config_class.normal_speed != null)
                            item.text_speed = parseInt(config_class.normal_speed);
                        }
                      } else if (item.text_type == SpecialTextType.SpeedSuggest) {
                        if (this.rain == 1) {
                          if (config_class.rain_suggest_speed != 0 && config_class.rain_suggest_speed != null)
                            item.text_speed = parseInt(config_class.rain_suggest_speed);
                        } else {
                          if (config_class.suggest_speed != 0 && config_class.suggest_speed != null)
                            item.text_speed = parseInt(config_class.suggest_speed);
                        }
                      }
                    }
                  }
                });
              }
            }
          }
        });
      }
    //}
    if (this.modal) {
      if (changes.unsub) {
        if (changes.unsub.currentValue) {
          if (this.subscription) {
            console.log("play-OnDestroy1");
            this.subscription.unsubscribe();
          }
        }
      }
      if (changes.playlist || changes.config_road || changes.unsub.currentValue == false) {
        this.src = null;
        if (this.height) {
          this.heightpx = +this.height;
        }
        if (this.width) {
          this.widthpx = +this.width;
        }
        if (this.playlist && this.playlist.length > 0) {
          this.count = this.playlist.length;
          if (this.count != 0 && this.count == 1) {
            if (this.playlist[0].boardsign_image) {
              this.src = this.playlist[0].boardsign_image.path;
              this.playlistItem = this.playlist[0];
            }

          } else {
            if (this.playlist[0]) {
              if (this.playlist[0].boardsign_image) {
                this.src = this.playlist[0].boardsign_image.path;
                this.playlistItem = this.playlist[0];
              }
              this.interval = (this.playlist[this.i].duration) * 1000;

              this.subscription = this.getTime$.subscribe(t => {
                if (this.playlist[this.i].boardsign_image) {
                  this.src = this.playlist[this.i].boardsign_image.path;
                  this.playlistItem = this.playlist[this.i];
                }
                if (this.i + 1 == this.count) {
                  this.i = 0;
                  this.interval = (this.playlist[this.count - 1].duration) * 1000;

                } else {
                  this.interval = (this.playlist[this.i].duration) * 1000;
                  this.i = this.i + 1;

                }
                if (this.modal) {
                  console.log('Refresh interval is: ' + this.interval);
                }
              });
            }
          }
        }
      }

    }
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setSpecialText(playlist?: any) {
    if (playlist) {
      if (playlist.boardsign_image.behavior_type == MediaBehaviorType.Special) {
        if (playlist.boardsign_image.special_property) {
          if (playlist.boardsign_image.special_property.text_items.length > 0) {
            playlist.boardsign_image.media.special_property.text_items.forEach(item => {
              if (item.text_type != SpecialTextType.FreeText && this.config_road) {
                const config_lane = this.config_road.find(x => x.lane_no == item.lane);
                const config_class = config_lane.veh_class.find(x => x.class_no == item.class);
                if (item.text_type == SpecialTextType.SpeedLimit) {
                  if (this.rain == 1) {
                    if (config_class.rain_speed != 0)
                      item.text_speed = parseInt(config_class.rain_speed);
                  } else {
                    if (config_class.normal_speed != 0)
                      item.text_speed = parseInt(config_class.normal_speed);
                  }
                } else if (item.text_type == SpecialTextType.SpeedSuggest) {
                  if (this.rain == 1) {
                    if (config_class.rain_suggest_speed != 0)
                      item.text_speed = parseInt(config_class.rain_suggest_speed);
                  } else {
                    if (config_class.suggest_speed != 0)
                      item.text_speed = parseInt(config_class.suggest_speed);
                  }
                }
              }
            });
          }
        }
      }
    }
  }
}
