import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
@Pipe({
  name: 'linktime'
})
export class LinkTimePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
      if(url){
        let n = url.includes('?');
        if (n == false) {
            return url+ '?t=' + new Date().getTime();
          } else {
            return url+ '&' + new Date().getTime();
          }
        //return url;
      }else{
        return null;
      }
  }

}