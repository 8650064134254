<div bsModal #infoModal="bs-modal" class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="onHide()" [config]="{backdrop: 'static'}"
  [ngClass]="(addModal?._isShown || deleteModal?._isShown)?'modal-hide-when-second-open':''">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content text-light">
      <div class="modal-header bg-gray-900 d-flex justify-content-between align-items-center">
        <h4 class="modal-title"> <i class="icon-star mr-2"></i><span>Favorite
          </span>
        </h4>
        <div class="d-flex justify-content-start align-items-center">
          <button *ngIf="isAdd" (click)="openAddFavModal()" type="button" class="btn btn-primary text-white"> <i
              class="fa fa-plus"></i>
            เพิ่ม Favorite</button>
          <button type="button" class="close text-light" (click)="closeFavModalInfo()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body modal-body-h m-0 p-0 text-white">
        <table class="table table-hover table-outline mb-0 table-no-border">
          <thead>
            <tr style="height: 50px;" class="align-items-center">
              <th class="text-center text-light"><i class="fa fa-hashtag"></i></th>
              <th class="text-center text-light">ชื่อชุดแสดงภาพ</th>
              <th class="text-center text-light">วันที่สร้าง</th>
              <th *ngIf="isEdit" class="text-center text-light">จัดการ</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="is_loading">
              <td class="text-center" colspan="7">
                <div class="d-flex align-items-center justify-content-center media-list-panel">
                  <div class="lds-dual-ring"></div>
                </div>
              </td>
            </tr>
            <tr *ngIf="favList.length <= 0 && !is_loading" class="bg-gray-900 table-no-border sign-dir-text"
              style="border-width: 0">
              <td class="text-center" colspan="7">
                <div class="">
                  <h5> ไม่พบข้อมูล </h5>
                </div>
              </td>
            </tr>
            <ng-template ngFor let-p [ngForOf]="favList" let-i="index">
              <tr class="bg-gray-900 table-no-border sign-dir-text" style="border-width: 0">
                <td class="text-center">
                  <div>
                    {{(i+1)+((this.currentPage - 1)*itemsPerPage)}}
                  </div>
                </td>
                <td>
                  <div>{{p?.name}}</div>
                </td>
                <td class="text-center">
                  <div>{{p?.lastupdate_date| date:'yyyy.MM.dd HH:mm'}}</div>
                </td>
                <td *ngIf="isEdit" class="text-center">
                  <div class="d-flex align-items-center justify-content-center">
                    <!-- <button title="รายละเอียด" type="button" class="btn btn-sm btn-success mr-1">
                            <i class="icon-info"></i>
                          </button> -->
                    <button title="แก้ไข" (click)="selectFav(p,3)" type="button"
                      class="btn btn-sm btn-warning mr-1 text-light">
                      <i class="cui-note icons"></i>
                    </button>
                    <button title="ลบ" type="button" (click)="selectFav(p,4)" class="btn btn-sm btn-danger ml-1">
                      <i class="cui-trash icons"></i>
                    </button>
                  </div>
                </td>
              </tr>
              <tr style="min-height: 150px;" class="bg-gray-500">
                <td class="text-center" colspan="7" [hidden]="!(p?.items?.length>0)">
                  <div class="d-flex justify-content-around">
                    <div>
                      <div class="sign-dir-text mb-1 text-dark d-flex justify-content-center">ป้ายหน้า</div>
                      <div *ngFor="let l of p?.layers;index as ii;">
                        <div class="d-flex justify-content-center flex-wrap">
                          <div *ngFor="let sign of l;index as iii;">
                            <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_fav)"
                              [style.height.px]="set_sign_height_fav" *ngIf="sign?.dir == signDir.Front">
                              <!-- <img [src]="sign?.media?.path" height="{{set_sign_height_fav}}"
                                width="{{get_sign_width(sign?.ratio,set_sign_height_fav)}}"
                                *ngIf="sign?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                              <app-sign-image-item [media]="sign?.media" [sign_height]="set_sign_height_fav"
                                [ratio]="sign?.ratio" [resize]="(set_sign_height_fav/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                              </app-sign-image-item>
                              <app-playlist-item class="pointer" [show_count]="true"
                                [resize]="(set_sign_height_fav/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                                [playlist]="sign?.media?.items" [height]="set_sign_height_fav"
                                [width]="get_sign_width(sign?.ratio,set_sign_height_fav)">
                              </app-playlist-item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="sign-dir-text mb-1 text-dark d-flex justify-content-center">ป้ายหลัง</div>
                      <div *ngFor="let l of p?.layers;index as ii;" [style.height.px]="set_sign_height_fav+4">
                        <div class="d-flex justify-content-center flex-wrap">
                          <div *ngFor="let sign of l;index as iii;">
                            <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_fav)"
                              [style.height.px]="set_sign_height_fav" *ngIf="sign?.dir == signDir.Back">
                              <!-- <img [src]="sign?.media?.path" height="{{set_sign_height_fav}}"
                                width="{{get_sign_width(sign?.ratio,set_sign_height_fav)}}"
                                *ngIf="sign?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                              <app-sign-image-item [media]="sign?.media" [sign_height]="set_sign_height_fav"
                                [ratio]="sign?.ratio" [resize]="(set_sign_height_fav/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                              </app-sign-image-item>
                              <app-playlist-item class="pointer" [show_count]="true"
                                [resize]="(set_sign_height_fav/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                                [playlist]="sign?.media?.items" [height]="set_sign_height_fav"
                                [width]="get_sign_width(sign?.ratio,set_sign_height_fav)">
                              </app-playlist-item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </td>
              </tr>
            </ng-template>
            <!-- <div style="width: 100%" *ngFor="let p of itemList;index as ii;"></div> -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div bsModal #addModal="bs-modal" class="modal fade  bd-example-modal-xl" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span *ngIf="!selectedFav?.id">เพิ่ม </span><span
            *ngIf="selectedFav?.id">แก้ไข </span>Favorite</h4>
        <button type="button" class="close text-light" (click)="addModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-white">
        <div class="p-3">
          <div class="form-group row row1 no-gutters">
            <label class="col-lg-2 col-md-3 col-sm-3 col-form-label"> <span
                class="float-xs-left float-sm-right float-md-right">
                ชื่อป้าย <span class="mr-2">:</span> </span></label>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-2">
              {{this.boardItem?.name}}
            </div>
          </div>
          <div class="form-group row row1 no-gutters">
            <label class="col-lg-2 col-md-3 col-sm-3 col-form-label"> <span
                class="float-xs-left float-sm-right float-md-right">
                ตำแหน่ง <span class="mr-2">:</span> </span></label>
            <div class="col-lg-9 col-md-9 col-sm-9 mt-2">
              {{this.boardItem?.station?.station_name}}
            </div>
          </div>
          <div class="form-group row row1 no-gutters">
            <label class="col-lg-2 col-md-3 col-sm-3 col-form-label"> <span
                class="float-xs-left float-sm-right float-md-right">
                ชื่อชุดแสดงภาพ <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-9 col-md-9 col-sm-9">
              <!-- <input type="text" [(ngModel)]="memoItem.detail" name="text-desc" class="form-control"
                                  placeholder=""> -->
              <input type="text" [(ngModel)]="input_fav_name" name="text-struc-name" class="form-control"
                placeholder="" />
            </div>
          </div>
          <div class="form-group row row1 no-gutters">
            <label class="col-lg-2 col-md-3 col-sm-3 col-form-label"> <span
                class="float-xs-left float-sm-right float-md-right">
                สถานะ <span class="text-danger">*</span> <span class="mr-2">:</span></span></label>
            <div class="col-lg-9 col-md-9 col-sm-9">
              <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="status_modal" (valueChange)="handleValueStatusModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-2">
            <div>
              <div class="sign-dir-text mb-1 d-flex justify-content-center">ป้ายหน้า</div>
              <div *ngFor="let l of structure_select?.layers;index as ii;">
                <div class="d-flex justify-content-center flex-wrap">
                  <div *ngFor="let sign of l;index as iii;">
                    <div class="sign-box pointer" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height)"
                      [style.height.px]="set_sign_height" *ngIf="sign?.dir == signDir.Front"
                      (click)="clickSignFavLayer(sign,boardItem)">
                      <!-- <img [src]="sign?.media?.path" height="{{set_sign_height}}"
                        width="{{get_sign_width(sign?.ratio,set_sign_height)}}"
                        *ngIf="sign?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                      <app-sign-image-item [media]="sign?.media" [sign_height]="set_sign_height" [ratio]="sign?.ratio"
                        [resize]="(set_sign_height/100)"
                        *ngIf="sign?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                      </app-sign-image-item>
                      <app-playlist-item class="pointer" [show_count]="true"
                        [resize]="(set_sign_height/100)"
                        *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                        [playlist]="sign?.media?.items" [height]="set_sign_height"
                        [width]="get_sign_width(sign?.ratio,set_sign_height)">
                      </app-playlist-item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="sign-dir-text mb-1 d-flex justify-content-center">ป้ายหลัง</div>
              <div *ngFor="let l of structure_select?.layers;index as ii;" [style.height.px]="set_sign_height+4">
                <div class="d-flex justify-content-center flex-wrap">
                  <div *ngFor="let sign of l;index as iii;">
                    <div class="sign-box pointer" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height)"
                      [style.height.px]="set_sign_height" *ngIf="sign?.dir == signDir.Back"
                      (click)="clickSignFavLayer(sign,boardItem)">
                      <!-- <img [src]="sign?.media?.path" height="{{set_sign_height}}"
                        width="{{get_sign_width(sign?.ratio,set_sign_height)}}"
                        *ngIf="sign?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                      <app-sign-image-item [media]="sign?.media" [sign_height]="set_sign_height" [ratio]="sign?.ratio"
                        [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                        *ngIf="sign?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                      </app-sign-image-item>
                      <app-playlist-item class="pointer" [show_count]="true"
                        [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                        *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                        [playlist]="sign?.media?.items" [height]="set_sign_height"
                        [width]="get_sign_width(sign?.ratio,set_sign_height)">
                      </app-playlist-item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="addModal.hide()">ยกเลิก</button>
          <!-- <button type="button" class="btn btn-primary ml-2" *ngIf="actionType==1" (click)="addType()" [disabled]="!newsTypeItem?.news_type_name">สร้าง</button>
          <button type="button" class="btn btn-primary ml-2" *ngIf="actionType==2" (click)="editType()">แก้ไข</button> -->
          <button [disabled]="!(input_fav_name != null)" *ngIf="selectedFav?.id" type="button"
            class="btn btn-primary text-white ml-2" (click)="editFavorite()">แก้ไข</button>
          <button [disabled]="!(input_fav_name != null)" *ngIf="!selectedFav?.id" type="button"
            class="btn btn-primary text-white ml-2" (click)="addFavorite()">เพิ่ม</button>
        </div>
      </div>
      <!-- <div class="modal-footer">
          
        </div> -->
    </div>
  </div>
</div>
<div bsModal #deleteModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i>ลบ Favorite</h4>
        <button type="button" class="close text-light" (click)="deleteModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt-2 text-center">
          <h4>คุณต้องการลบใช่หรือไม่</h4>
        </div>
        <div class="mt-3 mb-4 text-center">
          <h5>{{selectedFav?.name}}</h5>
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="deleteModal.hide()">ยกเลิก</button>
          <!-- <button type="button" class="btn btn-primary ml-2" (click)="deleteType(newsTypeItem.id)">ยืนยัน</button> -->
          <button type="button" class="btn btn-primary text-white ml-2" (click)="deleteFavorite()">ยืนยัน</button>
        </div>
      </div>
      <!-- <div class="modal-footer">
          
        </div> -->
    </div>
  </div>
</div>

<div bsModal #mediaListModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog  modal-xl" role="document">
    <div class="modal-content">
      <!-- <div class="modal-body"> -->
      <div *ngIf="sign_select_fav != null">
        <app-media-selector-all [sign_height]="100" [sign]="sign_select_fav"
          (onComponentClosed)="closeMediaModal($event)">
        </app-media-selector-all>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>
<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>