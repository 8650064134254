<div class="modal-body text-center">
    <div class="row">
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ลงลายเซ็นต์</p>
                </div>
                <!-- <canvas class="canvas card m-0" #cnv width="900px" height="600px"></canvas> -->
                
                <canvas #canvas class="canvas card m-0 gray-BG"></canvas>
                <div class="input-group-append">
                    <button class="btn btn-outline-danger" (click)="Clear()">
                        <i class="icon-trash"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 text-right">
            <button type="button" class="btn btn-danger mr-2" (click)="clickSave(false)">ปิด</button>
            <button type="button" class="btn btn-info" (click)="clickSave(true)">ยืนยัน</button>
        </div>
    </div>
</div>