import { Component, OnInit,EventEmitter,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash'
import { DeviceService } from 'src/app/services/user/device.service';
import { ConfigroadService } from 'src/app/services/user/configroad.service';
import { ReversibleService } from 'src/app/services/user/reversible.service';
import { FuncService } from 'src/app/services/user/func.service';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
import { ApiService } from 'src/app/services/user/api.service';
@Component({
  selector: 'app-addsensor-modal',
  templateUrl: './addsensor-modal.component.html',
  styleUrls: ['./addsensor-modal.component.scss']
})
export class AddsensorModalComponent implements OnInit {
  allowCustom : boolean = false
  values : any;
  view : number = 1
  reversible_id : number
  direction : number
  route : number
  _changed : boolean = false
  ResMessage : string
  start_km : number ; 
  start_m : number ; 
  end_km : number ; 
  end_m : number ; 
  public event: EventEmitter<any> = new EventEmitter();
  route_list: any;
  setRouteList: any;
  route_id: number;
  chked_list: any;
  constructor(
    public sensorModal: BsModalRef,
    private devS: DeviceService,
    private cfS : ConfigroadService,
    private rvS : ReversibleService,
    private func : FuncService,
    private commonService: CommonService,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.route_id = this.route
    console.log(this.values)    
    this.chked_list = _.filter(this.values, { 'checked': true, })
    this.getData();
  }
  close() {
    this.cancel()
    // this.sensorModal.hide();
  } 
  
  confirm(){
    this.event.emit(true);
    this.sensorModal.hide();
  }
  cancel(){
    this.event.emit(this._changed);
    this.sensorModal.hide();
  }  
  async save(){
    let data : any = {
      reversible_id : this.reversible_id,
      direction : this.direction,
      device_id : []
    }
    for (const i of this.values) {
      if(i.checked) {
        this.chked_list.push(i)
        data.device_id.push(i.device_id)
      }
    }
    const req1 = _.cloneDeep(data);
    let res : any = await this.rvS.addReversibleSensor(data)
    
    if(res){
      this.func.alertpopup("Success")
      this._changed = true
      // this.ResMessage = 'OK';
      // this.swalSuccess.show();

      let request_data = { url: `${this.apiService.api.reversible}/sensor`, request: req1 };
      this.commonService.activityLog(ActionEnum.Add, `Assets Management ➡ Setting ➡ Reversible ➡ Sensor`, JSON.stringify(request_data)).subscribe(res => { }, error => { console.log(error); });

    }else{
      this.func.alertpopup("Error,Please try again")
      // this.swalError.show();
    }
  }
  async search(){
    let req : any = {page: 1,route : this.route_id}
    if(this.start_km) req.st_km = Number(this.start_km)
    if(this.start_m) req.st_m = Number(this.start_m)
    if(this.end_km) req.en_km = Number(this.end_km)
    if(this.end_m) req.en_m = Number(this.end_m)

    // if(this.direction) req.direction = this.direction
    this.values = await this.cfS.getSmc(req)
    // this.values = await this.getDevice(req)
    console.log(this.values)
    for (const i of this.values) {
      let chk = _.find(this.chked_list, { 'id': i.id, })
        if(chk) i.checked = true
    }
  }
  checkDegree(evt){
    let min : number = Number(evt.target.min)
    // let max : number = Number(evt.target.max)
    let val : number = Number(evt.target.value)
    if(val<min) evt.target.value = evt.target.min
    // if(val>max) evt.target.value = evt.target.max
    return evt.target.value
  }
  async getData(){
    // let res : any = await this.getOnlyRoute()
    let res : any = await this.devS.getRoute()
    this.route_list = res
    this.setRouteList = this.route_list
  }
  FilterRoute(evt:any){
    if(evt.length>0){
      this.setRouteList = this.route_list.filter(res => res.includes(evt))
    }else if(evt.length==0){
      this.setRouteList = this.route_list
    }else{
      
    }
  }
  // -----------------------
  // async updateConfigReversibleLaneSensor(data?:any) {
  //   return new Promise((reslove,reject)=>{
  //     reslove(false)
  //   });
  // }
  
  // async getDevice(data?:any) {
  //   return new Promise((reslove,reject)=>{
  //     reslove(false)
  //   });
  // }
  
  // async getOnlyRoute() {
  //   return new Promise((reslove,reject)=>{
  //     reslove(false)
  //   });
  // }


}
