import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { MonitorComponent } from './monitor/monitor.component';
import { StationComponent } from './station/station.component';
import { DeviceComponent } from './device/device.component';
import { DeviceTypeComponent } from './device-type/device-type.component';
import { DeviceGroupComponent } from './device-group/device-group.component';
import { VendorComponent } from './vendor/vendor.component';
import { OwnerComponent } from './owner/owner.component';
import { BrandComponent } from './brand/brand.component';
import { MaTicketComponent } from './ma-ticket/ma-ticket.component';
import { ConfigurationMenuComponent } from './configuration-menu/configuration-menu.component';
import { SettingMenuComponent } from './setting-menu/setting-menu.component';
import { ReversibleLaneComponent } from './reversible-lane/reversible-lane.component';
import { SmartmicroComponent } from './smartmicro/smartmicro.component';
import { RainingConfigComponent } from './raining-config/raining-config.component';
export let routes: Routes = [
  {
    path: '',
    redirectTo: 'monitor',
    pathMatch: 'full',
  },
  {
    path: 'configuration',
    redirectTo: 'configuration/station',
    pathMatch: 'full',
  },
  {
    path: 'setting',
    redirectTo: 'setting/smartmicro',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'monitor',
        component: MonitorComponent
      },
      {
        path: 'configuration',
        component: ConfigurationMenuComponent,
        children: [
          {
            path: 'station',
            component: StationComponent
          },
          {
            path: 'device',
            component: DeviceComponent
          },
          {
            path: 'device-type',
            component: DeviceTypeComponent
          },
          {
            path: 'device-group',
            component: DeviceGroupComponent
          },
          {
            path: 'vendor',
            component: VendorComponent
          },
          {
            path: 'owner',
            component: OwnerComponent
          },
          {
            path: 'brand',
            component: BrandComponent
          }
        ]
      },
      {
        path: 'setting',
        component: SettingMenuComponent,
        children: [
          {
            path: 'smartmicro',
            component: SmartmicroComponent
          },
          {
            path: 'reversible',
            component: ReversibleLaneComponent
          },
          {
            path: 'raining_config',
            component: RainingConfigComponent
          }
        ]
      },
      {
        path: 'ma',
        component: MaTicketComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AssetsManagementRoutingModule { }
