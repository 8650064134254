import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/user/event.service';
import { FuncService } from 'src/app/services/user/func.service';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
import { ApiService } from 'src/app/services/user/api.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-raining-config',
  templateUrl: './raining-config.component.html',
  styleUrls: ['./raining-config.component.scss']
})
export class RainingConfigComponent implements OnInit {

  constructor(
    private evt_S: EventService,
    private func: FuncService,
    private commonService: CommonService,
    private apiService: ApiService,
  ) { 
    this.commonService.activityLog(ActionEnum.Get, `Assets Management ➡ Setting ➡ Raining Alert Config`).subscribe(res => { }, error => { console.log(error); });
  }

  r_light: number;
  r_moderate: number;
  r_heavy: number;
  is_r_light: number = 0;
  is_r_moderate: number = 0;
  is_r_heavy: number = 0;

  interval_time: number;

  ngOnInit(): void {
    this.getConfig()
  }
  async getConfig() {
    let res: any = await this.evt_S.getRainConfig();
    console.log(res)
    if (res) {
      this.r_light = res[0].rain_light || null;
      this.r_moderate = res[0].rain_moderate || null;
      this.r_heavy = res[0].rain_heavy || null;
      this.is_r_light = res[0].is_rain_light_alert || 0;
      this.is_r_moderate = res[0].is_rain_moderate_alert || 0;
      this.is_r_heavy = res[0].is_rain_heavy_alert || 0;
      this.interval_time = res[0].interval_time || null;
    }
  }
  async upConfig() {
    let req = {
      rain_light: this.r_light || 0,
      rain_moderate: this.r_moderate || 0,
      rain_heavy: this.r_heavy || 0,
      is_rain_light_alert: this.is_r_light || 0,
      is_rain_moderate_alert: this.is_r_moderate || 0,
      is_rain_heavy_alert: this.is_r_heavy || 0,
      interval_time: this.interval_time ? parseInt(this.interval_time.toString()) : null,
    };
    console.log(req);
    const req1 = _.cloneDeep(req);
    let res: any = await this.evt_S.updateRainConfig(req)
    if (res) { 
      this.func.alertpopup("Processing Succes");
      this.getConfig();

      let request_data = { url: `${this.apiService.api.rainConfig}`, request: req1 };
      this.commonService.activityLog(ActionEnum.Edit, `Assets Management ➡ Setting ➡ Raining Alert Config`, JSON.stringify(request_data)).subscribe(res => { }, error => { console.log(error); });
    }
  }

}
