import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
declare let videojs: any;

@Component({
  selector: 'app-display-flv',
  templateUrl: './display-flv.component.html',
  styleUrls: ['./display-flv.component.scss']
})
export class DisplayFlvComponent implements OnInit, OnChanges, OnDestroy {

  @Input() item: any;
  //poster:any = 'http://api.toc.highwaytraffic.go.th/cctv/?ip=10.2.0.157&port=11621&username=admin&password=Tr@nsc0de';
  //src:any = 'http://toc.highwaytraffic.go.th:20105/0002-0077250-2/cam.m3u8';
  poster: any;
  src: any;
  player: any;
  nn: boolean = false;
  idx: number;
  constructor() { }

  ngOnInit() {

  }
  ngOnDestroy(): void {
    if (this.player) {
      this.player.pause();
      this.player.dispose();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    //console.log(this.item);
    if (this.item) {
      //this.src = 'http://toc.highwaytraffic.go.th:20105/0002-0077250-2/cam.m3u8';
      if (this.item.cctv_flv) {
        this.src = this.item.cctv_flv;
      }
      this.idx = this.item.id;
      //this.poster = 'http://api.toc.highwaytraffic.go.th/cctv/?ip=10.2.0.157&port=11621&username=admin&password=Tr@nsc0de';
      if (this.item.cctv_link) {
        if (this.item.cctv_link.link_type == 0 && this.item.cctv_link.link) {
          this.poster = this.item.cctv_link.link;
          let n = this.item.cctv_link.link.includes('?');
          if (n == false) {
            this.poster = this.item.cctv_link.link + '?t=' + new Date().getTime();
          } else {
            this.poster = this.item.cctv_link.link + '&t=' + new Date().getTime();
          }
        }
      }
      setTimeout(() => this.ngAfterViewInit1(this.src), 1000);
    }
  }
  ngAfterViewInit1(flvUrl) {

    // ID with which to access the template's video element
    let el = 'video_x_' + this.idx;
    //var flvUrl = "http://54.169.236.7:12060/live.flv?devid=0099000060&chl=1&svrid=127.0.0.1&svrport=17891&st=1&audio=0";
    //let flvUrl = url;
    // setup the player via the unique element ID
    this.player = videojs(document.getElementById(el), {
      techOrder: ['html5', 'flvjs'],
      flvjs: {
        mediaDataSource: {
          isLive: true,
          cors: true,
          withCredentials: false,
        },
      },
      sources: [{
        src: flvUrl,
        type: 'video/mp4'
      }],
      controls: true,
      preload: "none"

    }, function onPlayerReady() {
      console.log('player ready')

      this.player.on('error', (err) => {
        console.log('first source load fail')

        this.player.src({
          src: flvUrl,
          type: 'video/x-flv'
        });

        this.player.ready(function () {
          console.log('player ready')
          this.player.load();
          this.player.play();
        });
      })
    });
  }

}