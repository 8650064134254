import { Component, OnInit,EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-alertpopup',
  templateUrl: './alertpopup.component.html',
  styleUrls: ['./alertpopup.component.scss']
})
export class AlertpopupComponent implements OnInit {
  // sa_modal: BsModalRef;
  header : any;
  body : any;
  footer : any;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    
    public sa_modal: BsModalRef
  ) { }

  list: any[] = [];
  ngOnInit(): void {
    this.list.push('!!!');
  }

  close() {
    this.sa_modal.hide();
  } 
  
  confirm(){
    this.event.emit(true);
    this.sa_modal.hide();
  }
  cancel(){
    this.event.emit(false);
    this.sa_modal.hide();
  }
}
