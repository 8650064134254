<ng-template #tolTemplateSpecial>
  <div class="m-1">
    <div class="text-danger center small">Special Item</div>
    <div class="m-1" *ngFor="let item of media?.special_property?.text_items;index as i;">
      <div class="center small" *ngIf="item?.text_type == specialTextType.FreeText">
        <span>{{item?.text}} = Free Text</span>
      </div>
      <div class="center small" *ngIf="item?.text_type == specialTextType.SpeedLimit">
        <span>{{item?.text_speed}} = Speed Limit</span>
      </div>
      <div class="center small" *ngIf="item?.text_type == specialTextType.SpeedSuggest">
        <span>{{item?.text_speed}} = Speed Suggest</span>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #tolTemplateDynamic>
  <div class="m-1">
    <div class="text-danger center small">Dynamic Image</div>
  </div>
</ng-template>
<div [style.height.px]="sign_height" style="background-color: black;position: relative;"
  [style.width.px]="medie_sign_width(ratio,sign_height)">
  <img *ngIf="media?.path" [src]="media?.path" width="100%" height="100%"
    style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;" />
  <div style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;"
    *ngFor="let item of media?.special_property?.text_items;index as i;"
    [ngClass]="(item?.is_center)?'d-flex align-items-center justify-content-center':''">
    <span *ngIf="item?.is_center && item?.text_type == specialTextType.FreeText"
      [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
      [style.font-size.px]="item?.font_size * resize" [style.family]="item?.font_family">{{item?.text}}</span>
    <span *ngIf="!item?.is_center && item?.text_type == specialTextType.FreeText"
      [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute" [style.top.px]="item?.y"
      [style.left.px]="item?.x" [style.color]="item?.color" [style.font-size.px]="item?.font_size * resize"
      [style.family]="item?.font_family">{{item?.text}}</span>
    <span
      *ngIf="item?.is_center && (item?.text_type == specialTextType.SpeedLimit || item?.text_type == specialTextType.SpeedSuggest)"
      [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
      [style.font-size.px]="item?.font_size * resize" [style.family]="item?.font_family">{{item?.text_speed}}</span>
    <span
      *ngIf="!item?.is_center && (item?.text_type == specialTextType.SpeedLimit || item?.text_type == specialTextType.SpeedSuggest)"
      [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute" [style.top.px]="item?.y"
      [style.left.px]="item?.x" [style.color]="item?.color" [style.font-size.px]="item?.font_size * resize"
      [style.family]="item?.font_family">{{item?.text_speed}}</span>
  </div>
  <div [tooltip]="tolTemplateSpecial" placement="left" container="body"
    *ngIf="media?.behavior_type == mediaBehaviorType.Special && media?.special_property?.text_items?.length > 0" class="small pointer"
    style="position: absolute;bottom:1px;left:1px;">
    <div class="badge badge-danger">S</div>
  </div>
  <div [tooltip]="tolTemplateDynamic" placement="left" container="body"
    *ngIf="media?.behavior_type == mediaBehaviorType.Dynamic" class="small pointer"
    style="position: absolute;bottom:1px;left:1px;">
    <div class="badge badge-danger">D</div>
  </div>
</div>
