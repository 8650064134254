import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild  } from '@angular/core';
declare let videojs: any;
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cctv-display-rtsp',
  templateUrl: './cctv-display-rtsp.component.html',
  styleUrls: ['./cctv-display-rtsp.component.scss']
})
export class CctvDisplayRtspComponent implements OnInit,OnChanges {
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() toggle: boolean;
  @Input() item: any;
  @Input() station: any;
  @ViewChild('infoModal', { static: false }) private infoModal: ModalDirective;
  //poster:any = 'http://api.toc.highwaytraffic.go.th/cctv/?ip=10.2.0.157&port=11621&username=admin&password=Tr@nsc0de';
  //src:any = 'http://toc.highwaytraffic.go.th:20105/0002-0077250-2/cam.m3u8';
  poster:any;
  src:any;
  player: any;
  nn: boolean = false;
  idx: number;
  constructor() { }

  ngOnInit() {
    
  }
  ngOnChanges(changes: SimpleChanges) {
    //console.log(this.item);
    if(this.item){
      //this.src = 'http://toc.highwaytraffic.go.th:20105/0002-0077250-2/cam.m3u8';
      if(this.item.rtsp_link){
        this.src = this.item.rtsp_link;
      }
      this.idx = this.item.device_id;
      //this.poster = 'http://api.toc.highwaytraffic.go.th/cctv/?ip=10.2.0.157&port=11621&username=admin&password=Tr@nsc0de';
      if (this.item.link) {
        this.poster = this.item.link;
        let n = this.item.link.includes('?');
        if (n == false) {
          this.poster = this.item.link + '?t=' + new Date().getTime();
        } else {
          this.poster = this.item.link + '&t=' + new Date().getTime();
        }
      }
      setTimeout(() => this.ngAfterViewInit2(), 1000);
      
    }
    if (changes.toggle) {
      if (changes.toggle.currentValue) {
        setTimeout(() => {
        this.infoModal.show();
        }, 100);
      }
    }
  }
  clickClose() {
    this.infoModal.hide();
    this.onClose.emit(null);
  }
  ngAfterViewInit2() {

    // ID with which to access the template's video element
    let el = 'video_rtsp_' + this.idx;

    // setup the player via the unique element ID
    this.player = videojs(document.getElementById(el), {html5: {
      hls: {
        withCredentials: false,
        overrideNative: true
      },
      nativeAudioTracks: false,
      nativeVideoTracks: false
    }}, function () {

      // Store the video object
      var myPlayer = this, id = myPlayer.id();

      // Make up an aspect ratio
      var aspectRatio = 264 / 640;

      // internal method to handle a window resize event to adjust the video player
      function resizeVideoJS() {
        var width = document.getElementById(id).parentElement.offsetWidth;
        myPlayer.width(width);
        myPlayer.height(width * aspectRatio);
      }

      // Initialize resizeVideoJS()
      resizeVideoJS();

      // Then on resize call resizeVideoJS()
      window.onresize = resizeVideoJS;
    });
  }

}
