import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private afMessaging: AngularFireMessaging) {
  }
  requestPermission() {
    this.afMessaging.requestToken
      .subscribe(
        (token) => { console.log('fb token : ', token); },
        (error) => { console.error(error); },  
      );
  }

  
  // async makeToast(message: string){
  //   const toast = await this.toastController.create({
  //     duration: 5000,
  //     message: message,
  //     position: 'top',
  //     buttons: [
  //       {
  //         side: 'end',
  //         text: 'Close',
  //         role: 'cancel',
  //         handler: () => {
  //           console.log('Favorite clicked');
  //         }
  //       }]
  //   });
  //   toast.present();
  // }

  // getPermission(){
  //   this.afm.requestToken
  //     .subscribe(
  //       (token) => { console.log('Permission granted! Save to the server!', token); this.token = token;},
  //       (error) => { console.error(error); },  
  //     );
  // }

  // showMessages(){
  //   return this.afm.messages
  //     .subscribe(
  //       (message) => {console.log(message);}
  //     );
  // }

  // subscribe(topic: string){
  //   this.aff.httpsCallable('subscribeToTopic')({topic: topic, token: this.token})
  //     .subscribe(
  //       (_) => {this.makeToast("Notifications about "+topic+" activated.")},
  //       (error) => {console.log(error)},
  //     );
  // }

  // unsubscribe(topic: string){
  //   this.aff.httpsCallable('unsubscribeFromTopic')({topic: topic, token: this.token})
  //     .subscribe(
  //       (_) => {this.makeToast("Notifications about "+topic+" deactivated.")},
  //       (error) => {console.log(error)},
  //     );
  // }
}
