<div class="animated fadeIn mt-4 ml-4 mr-4 mb-2">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h4>{{station?.station_name}}</h4>
          <i title="Close" class="fa fa-2x fa-times text-light pointer" (click)="close()"></i>
        </div>
        <div *ngIf="_isLoading" class="card-body bg-gray-900">
          <div class="text-center">
            <div class="lds-hourglass"></div>
          </div>
        </div>
        <div *ngIf="!_isLoading" class="card-body bg-gray-900">
          <div>
            <div class="row text-center">
              <div class="col-md-4">
              </div>
              <div class="col-md-4">
                <div class="d-flex justify-content-center align-items-center w-100">
                  <h3>{{ viewDate | calendarDate: view + 'ViewTitle': 'en' }}</h3>
                  <div class="ml-1 mb-2">
                    <div>
                      <i class="fa fa-chevron-up pointer" (click)="incrementYear()" title="ปีถัดไป"></i>
                    </div>
                    <div>
                      <i class="fa fa-chevron-down pointer" (click)="decrementYear()" title="ปีก่อนหน้า"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="btn-group">
                  <div class="btn btn-outline-secondary" (click)="decrement()" title="ก่อนหน้า">
                    <span class="mr-1 ml-1"> &lsaquo; </span>
                  </div>
                  <div class="btn btn-primary" (click)="today()">
                    วันนี้
                  </div>
                  <div class="btn btn-outline-secondary" (click)="increment()" title="ถัดไป">
                    <span class="mr-1 ml-1"> &rsaquo; </span>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div>
              <mwl-calendar-month-view 
              [viewDate]="viewDate" 
              [(events)]="events" 
              [locale]="'en'"
              [activeDayIsOpen]="activeDayIsOpen" 
              [weekStartsOn]="1"
              (dayClicked)="dayClicked($event.day)"
              (eventClicked)="rmlist($event.event)"
              (eventTimesChanged)="eventTimesChanged($event)">
              </mwl-calendar-month-view>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          Import Sensor Data File
        </div>
        <div class="card-body bg-gray-900" style="min-height: 100px;">
          <div>
            <input type="file" name="file-input" #Input (change)="detectFiles($event)">
            <!-- <select name="" id="" class="form-control w-50" [(ngModel)]="direction">
              <option value=null>Please select direction</option>
              <option value=1>ขาเข้า</option>
              <option value=2>ขาออก</option>
            </select> -->
          </div>
          <div class="d-flex justify-content-center align-items-start mt-3 mb-3">
            <div class="w-100 d-flex" style="flex-direction: column;">
              <div class="input-group mr-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">In actual</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="actual_in">
              </div>
              ตัวอย่าง 1,2,3
            </div>
            <div class="w-100 d-flex" style="flex-direction: column;">
              <div class="input-group mr-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">File</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]=" file_in">
              </div>
              ตัวอย่าง 1,2,3
            </div>
            <div class="w-100 d-flex" style="flex-direction: column;">
              <div class="input-group mr-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Out actual</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="actual_out">
              </div>
              ตัวอย่าง 3,2,1
            </div>
            <div class="w-100 d-flex" style="flex-direction: column;">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">File</span>
                </div>
              <input type="text" class="form-control" [(ngModel)]=" file_out">
                <div class="input-group mr-3">
              </div>
            </div>
            ตัวอย่าง 4,5,6
            <div class="d-flex justify-content-end align-items-center">
              <!-- <button type="button" class="btn btn-outline-info" style="min-width: 80px;" (click)="close()">ยกเลิก</button> -->
              <button [disabled]="!data_file || _isLoading" type="button" class="btn btn-primary" style="min-width: 80px;" (click)="save()">บันทึก</button>
            </div>
          </div>
          
          <button class="btn btn-outline-danger ml-2" (click)="openImg()"><i class="fa fa-exclamation"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>