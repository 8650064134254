<div class="panel-item modal-body-h">
    <div style="width: 100%;height:50px"
      class="w-100 d-flex align-items-center justify-content-center bg-gray-950 text-light">
      <i class="fa fa-list mr-2"></i> <strong>Favorite</strong>
    </div>
    
    <div class="m-3">
      <div *ngIf="isLoading" class="d-flex align-items-center justify-content-center media-list-panel mt-3">
        <div class="lds-dual-ring"></div>
      </div>
      <div *ngIf="favList?.length == 0 && !isLoading"
        class="d-flex align-items-center justify-content-center media-list-panel bg-gray-800">
        <strong>ไม่พบข้อมูล</strong>
      </div>
      <div *ngIf="favList?.length > 0 && !isLoading" class="media-list-panel scrollbar-style bg-gray-800">
        <div *ngFor="let s of favList;index as i;">
          <div class="pointer bg-gray-600 structures-name-panel d-flex align-items-center justify-content-between"
            (click)="select(s)">
            <div class="mr-3 ml-3">
              {{i+1}} . {{s?.name}}
            </div>
            <div class="mr-2">
              <i
                [ngClass]="(s?.id == selectedFav?.id)?'fa fa-2x fa-check-square-o text-info':'fa fa-2x fa-square-o text-dark'"></i>
            </div>
          </div>
          <div class="mr-3 ml-3 mt-1 mb-1">
            <div class="d-flex justify-content-between">
              <div>
                <div class="sign-dir-text mb-1 text-light d-flex justify-content-center">ป้ายหน้า</div>
                <div *ngFor="let l of s?.layers;index as ii;">
                  <div class="d-flex justify-content-center flex-wrap">
                    <div *ngFor="let sign of l;index as iii;">
                      <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height)"
                        [style.height.px]="set_sign_height" *ngIf="sign?.dir == signDir.Front">
                        <!-- <img [src]="sign?.media?.media?.path" height="{{set_sign_height}}"
                          width="{{get_sign_width(sign?.ratio,set_sign_height)}}"
                          *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" />
                        <app-playlist-item class="pointer" [show_count]="true"
                          *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                          [playlist]="sign?.media?.items" [height]="set_sign_height"
                          [width]="get_sign_width(sign?.ratio,set_sign_height)">
                        </app-playlist-item> -->
                        <app-sign-image-item [media]="sign?.media" [sign_height]="set_sign_height" [ratio]="sign?.ratio"
                        [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                        *ngIf="sign?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                      </app-sign-image-item>
                      <app-playlist-item class="pointer" [show_count]="true"
                        [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                        *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                        [playlist]="sign?.media?.items" [height]="set_sign_height"
                        [width]="get_sign_width(sign?.ratio,set_sign_height)">
                      </app-playlist-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="sign-dir-text mb-1 text-light d-flex justify-content-center">ป้ายหลัง</div>
                <div *ngFor="let l of s?.layers;index as ii;" [style.height.px]="set_sign_height+10">
                  <div class="d-flex justify-content-center flex-wrap">
                    <div *ngFor="let sign of l;index as iii;">
                      <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height)"
                        [style.height.px]="set_sign_height" *ngIf="sign?.dir == signDir.Back">
                        <!-- <img [src]="sign?.media?.media?.path" height="{{set_sign_height}}"
                          width="{{get_sign_width(sign?.ratio,set_sign_height)}}"
                          *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" />
                        <app-playlist-item class="pointer" [show_count]="true"
                          *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                          [playlist]="sign?.media?.items" [height]="set_sign_height"
                          [width]="get_sign_width(sign?.ratio,set_sign_height)">
                        </app-playlist-item> -->

                        <app-sign-image-item [media]="sign?.media" [sign_height]="set_sign_height" [ratio]="sign?.ratio"
                        [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                        *ngIf="sign?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                      </app-sign-image-item>
                      <app-playlist-item class="pointer" [show_count]="true"
                        [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                        *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                        [playlist]="sign?.media?.items" [height]="set_sign_height"
                        [width]="get_sign_width(sign?.ratio,set_sign_height)">
                      </app-playlist-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!--Decision Panel-->
  <div class="d-flex align-items-center justify-content-center m-3">
    <button type="button" class="btn btn-danger" (click)="closeComponent(null)" style="min-width: 100px">ยกเลิก</button>
    <button type="button" class="btn btn-primary ml-2" (click)="closeComponent(selectedFav)" [disabled]="!selectedFav"
      style="min-width: 100px">ตกลง</button>
  </div>