import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { region_id } from '../../../schematic-map/setting';
@Component({
  selector: 'app-region-menu-new',
  templateUrl: './region-menu-new.component.html',
  styleUrls: ['./region-menu-new.component.scss']
})
export class RegionMenuNewComponent implements OnInit {
  @Input() region: number;
  tha = region_id.thailand;
  n = region_id.north;
  ne = region_id.northeast;
  e = region_id.east;
  s = region_id.south;
  constructor(private router: Router) { }

  ngOnInit() {
  }

  clickRegion(region_id) {
    if (region_id != this.region) {
      if (region_id == this.tha) {
        this.router.navigate(['/dashboard/schematic-map/thailand']);
      } else if (region_id == this.n) {
        this.router.navigate(['/dashboard/schematic-map/north']);
      } else if (region_id == this.ne) {
        this.router.navigate(['/dashboard/schematic-map/northeast']);
      } else if (region_id == this.e) {
        this.router.navigate(['/dashboard/schematic-map/east']);
      } else if (region_id == this.s) {
        this.router.navigate(['/dashboard/schematic-map/south']);
      }
    }
  }
}
