import { Pipe, PipeTransform } from '@angular/core';
import { NullTemplateVisitor } from '@angular/compiler';

@Pipe({
    name: "coverLaneText"
})
export class CoverLaneTextPipe implements PipeTransform {
    transform(text: string): string {
        if (text) {
            const array = text.split(',');
            if (array.length <= 1) {
                return text;
            } else {
                const s = array.join(',');
                return s;
            }
        } else {
            return null;
        }
    }
}