<div class="modal-header">
    <h4 class="modal-title pull-left">Reversible lane</h4>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body text-center pt-0 pb-0">
    <div class="row pt-3 txtblog-BG">
      <div class="col-6">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"> Reversible name</span>
          </div>
          <input type="text" class="form-control" [(ngModel)]="reversible_name">
        </div>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">หมายเลขทางหลวง</span>
          </div>
          
          <kendo-combobox class="form-control" [data]="setRouteList"
          [allowCustom]="allowCustom" [suggest]="true" [(ngModel)]="route_id" [filterable]="true"
          (filterChange)="FilterRoute($event)"
           [placeholder]="'ทั้งหมด'">
          </kendo-combobox>
        </div>
        <!-- <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">PVR Stream</span>
          </div>
          <div class="d-flex align-items-center chkbox-form-control" style="flex: 1 1 auto;    border-radius: 5px;">
            <input class="ml-2 pointer" type="radio" name="pvr" id="pvr1" [(ngModel)]="direction" [value]="1" (change)="direction=1">
            <label style="margin-top: 5px;" class="ml-2 pointer" for="pvr1">
              ขาเข้า
            </label>
            <input class="ml-2 pointer" type="radio" name="pvr" id="pvr" [(ngModel)]="direction" [value]="2" (change)="direction=2">
            <label style="margin-top: 5px;" class="ml-2 pointer" for="pvr">
              ขาออก
            </label>
          </div>
  
        </div> -->
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">คำนวณจาก</span>
          </div>
          <div class="d-flex align-items-center chkbox-form-control" style="flex: 1 1 auto;    border-radius: 5px;">
            <input class="ml-2 pointer" type="radio" name="pvr" id="pvr1" [(ngModel)]="criteria" [value]="1" (change)="criteria=1">
            <label style="margin-top: 5px;" class="ml-2 pointer" for="pvr1">
              Sr
            </label>
            <input class="ml-2 pointer" type="radio" name="pvr" id="pvr2" [(ngModel)]="criteria" [value]="2" (change)="criteria=2">
            <label style="margin-top: 5px;" class="ml-2 pointer" for="pvr2">
              V/C
            </label>
            <input class="ml-2 pointer" type="radio" name="pvr" id="pvr3" [(ngModel)]="criteria" [value]="3" (change)="criteria=3">
            <label style="margin-top: 5px;" class="ml-2 pointer" for="pvr3">
              63STD
            </label>
          </div>
        </div>
      </div>
      <div class="col-6">
  
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">   รายละเอียด</span>
          </div>
          <input type="text" class="form-control" [(ngModel)]="description">
        </div>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">ช่วงกิโลเมตร</span>
          </div>
          <input type="number" class="form-control" [(ngModel)]="km" min="0" (change)="km = checkDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text">+</span>
          </div>
          <input type="number" class="form-control" [(ngModel)]="m" min="0" (change)="m = checkDegree($event)">
        </div>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">Calc Period</span>
          </div>
          
          <select name="" id="" class="form-control" [(ngModel)]="cal_period">
            <option value="null" selected disabled>กรุณาเลือกเวลา</option>
            <option value="5">เวลา 5 นาที</option>
            <option value="10">เวลา 10 นาที</option>
            <option value="15">เวลา 15 นาที</option>
            <option value="30">เวลา 30 นาที</option>
            <option value="60">เวลา 60 นาที</option>
          </select>
          
        </div>
      </div>
      <div class="col-12 mt-2 mb-3 d-flex direction-BG justify-content-around">
        <!-- <div class="direction-BG"> -->
          <div [ngClass]="{'active': directionBT == 1}" class="btn btn-info" (click)="directionBT = 1">ขาเข้า</div>
          <div [ngClass]="{'active': directionBT == 2}" class="btn btn-info" (click)="directionBT = 2">ขาออก</div>
        <!-- </div> -->
      </div>
      <div [ngClass]="{'d-none': directionBT == 2}" class="col-12">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"> Free Flow speed</span>
          </div>
          <input type="number" class="form-control" [(ngModel)]="direction_list[0].free_flow_speed" min="0" max="120" (change)="direction_list[0].free_flow_speed = checkminmaxDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text"> Criteria</span>
          </div>
          <input type="number" class="form-control" [(ngModel)]="direction_list[0].min_speed" min="0" max="100" (change)="direction_list[0].min_speed = checkminmaxDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text">% < Sr <</span>
          </div>
          <input type="number" class="form-control" [(ngModel)]="direction_list[0].max_speed" min="0" max="100" (change)="direction_list[0].max_speed = checkminmaxDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"> Max flow rate (Per lane)</span>
          </div>
          <input type="number" class="form-control" [(ngModel)]="direction_list[0].max_flow_rate" min="0" max="9999" (change)="direction_list[0].max_flow_rate = checkminmaxDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text"> VC/RV</span>
          </div>
          <input type="text" class="form-control" [(ngModel)]="direction_list[0].vc_rv" min="0" max="9999" (change)="direction_list[0].vc_rv = checkminmaxDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text"> Criteria</span>
          </div>
          <input type="number" class="form-control" [(ngModel)]="direction_list[0].min_rate" min="0" max="100" (change)="direction_list[0].min_rate = checkminmaxDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text">% < V/C <</span>
          </div>
          <input type="number" class="form-control" [(ngModel)]="direction_list[0].max_rate" min="0" max="100" (change)="direction_list[0].max_rate = checkminmaxDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </div>
      <div [ngClass]="{'d-none': directionBT == 1}" class="col-12">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"> Free Flow speed</span>
          </div>
          <input type="text" class="form-control" [(ngModel)]="direction_list[1].free_flow_speed" min="0" max="120" (change)="direction_list[1].free_flow_speed = checkminmaxDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text"> Criteria</span>
          </div>
          <input type="text" class="form-control" [(ngModel)]="direction_list[1].min_speed" min="0" max="100" (change)="direction_list[1].min_speed = checkminmaxDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text">% < Sr <</span>
          </div>
          <input type="text" class="form-control" [(ngModel)]="direction_list[1].max_speed" min="0" max="100" (change)="direction_list[1].max_speed = checkminmaxDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"> Max flow rate (Per lane)</span>
          </div>
          <input type="text" class="form-control" [(ngModel)]="direction_list[1].max_flow_rate" min="0" max="9999" (change)="direction_list[1].max_flow_rate = checkminmaxDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text"> VC/RV</span>
          </div>
          <input type="text" class="form-control" [(ngModel)]="direction_list[1].vc_rv" min="0" max="9999" (change)="direction_list[1].vc_rv = checkminmaxDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text"> Criteria</span>
          </div>
          <input type="text" class="form-control" [(ngModel)]="direction_list[1].min_rate" min="0" max="100" (change)="direction_list[1].min_rate = checkminmaxDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text">% < V/C <</span>
          </div>
          <input type="text" class="form-control" #item  [(ngModel)]="direction_list[1].max_rate" min="0" max="100" (change)="direction_list[1].max_rate = checkminmaxDegree($event)">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </div>
      <div *ngIf="error_text" class="col-12 text-center">
        <h5 style="color: red;">{{error_text}}</h5>
      </div>
      <div *ngIf="values && values.id" class="col-12 text-left">
        <hr>
        <h5>ขาเข้า</h5>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center mr-2" *ngIf="reversible_name&&values.sensor_in&&values.sensor_in.length">
            <div *ngFor="let i of values.sensor_in">
              <button class="btn btn-warning position-relative pointer mn-BT pr-3 mr-2" (click)="rmlist(i)">
                {{i.device_name}}
                <span class="position-absolute">x</span>
              </button>
            </div>
          </div>
          <button class="btn btn-default add-BT" [disabled]="loading" (click)="addSensor(1)">
            <i *ngIf="!loading" class="icon-plus"></i>
            <i *ngIf="loading" class="loading icon-compass"></i>
          </button>
        </div>
      </div>
      <div *ngIf="values && values.id" class="col-12 text-left">
        <h5>ขาออก</h5>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center mr-2" *ngIf="reversible_name&&values.sensor_out&&values.sensor_out.length">
            <div *ngFor="let i of values.sensor_out">
              <button class="btn btn-warning position-relative pointer mn-BT pr-3 mr-2" (click)="rmlist(i)">
                {{i.device_name}}
                <span class="position-absolute">x</span>
              </button>
            </div>
          </div>
          <button class="btn btn-default add-BT" [disabled]="loading" (click)="addSensor(2)">
            <i *ngIf="!loading" class="icon-plus"></i>
            <i *ngIf="loading" class="loading icon-compass"></i>
          </button>
        </div>
        <hr>
      </div>
      <div class="col-12 text-right mb-3">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              เริ่มต้นเวลา</span>
          </div>
          <kendo-timepicker [(value)]="st_time"></kendo-timepicker>
          <div class="input-group-prepend">
            <span class="input-group-text">
              สิ้นสุดเวลา</span>
          </div>
          <kendo-timepicker [(value)]="en_time"></kendo-timepicker>
        </div>
      </div>
      
      <div class="col-12 text-left"><h5>Set algorithm sampling time</h5></div>
      <div class="col-6">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              Normal time</span>
          </div>
          <select name="" id="" class="form-control" [(ngModel)]="samp_day">
            <option value="null" selected disabled>กรุณาเลือกเวลา</option>
            <option value="1">เวลา 1</option>
            <option value="5">เวลา 5</option>
            <option value="10">เวลา 10</option>
            <option value="15">เวลา 15</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              Night time</span>
          </div>
          <select name="" id="" class="form-control" [(ngModel)]="samp_night">
            <option value="null" selected disabled>กรุณาเลือกเวลา</option>
            <option value="1">เวลา 1</option>
            <option value="5">เวลา 5</option>
            <option value="10">เวลา 10</option>
            <option value="15">เวลา 15</option>
          </select>
        </div>
      </div>
      <div class="col-12 text-left"><h5>Set lane Idle time</h5></div>
      <div class="col-6">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              Normal time</span>
          </div>
          
          <select name="" id="" class="form-control" [(ngModel)]="Idel_day">
            <option value="null" selected disabled>กรุณาเลือกเวลา</option>
            <option value="1">เวลา 1</option>
            <option value="5">เวลา 5</option>
            <option value="10">เวลา 10</option>
            <option value="15">เวลา 15</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              Night time</span>
          </div>
          
          <select name="" id="" class="form-control" [(ngModel)]="Idel_night">
            <option value="null" selected disabled>กรุณาเลือกเวลา</option>
            <option value="1">เวลา 1</option>
            <option value="5">เวลา 5</option>
            <option value="10">เวลา 10</option>
            <option value="15">เวลา 15</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-primary btn-sm" (click)="config.animated = !config.animated">{{config.animated ? 'Disable' : 'Enable'}} animation</button> -->
    <button type="button" class="btn btn-danger" (click)="cancel()">ปิด</button>
    <!-- <button type="button" class="btn btn-danger" (click)="cancel()">No</button> -->
    <button type="button" class="btn btn-info" (click)="save()"
      [disabled]="!reversible_name||disabledSave_BT ? 'disabled': null">ยืนยัน</button>
  </div>
<!--   
  <swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success"
    html="<div class='text-light'>{{ResMessage}}</div>"></swal>
  <swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{ResMessage}}</div>"
    type="error"></swal>
    
  <swal
  #Swelrm
  title="<div class='text-light'>Delete</div>"
  html="<div class='text-light'>คุณต้องการลบใช่หรือไม่</div>"
  type="question"
  [showCancelButton]="true"
  [focusCancel]="true"
  (confirm)="deleteList()">
  </swal> -->