import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { ATISService } from '../../../services/sign-control/atis.service';
import { Station, ReqAddStation } from '../../../models/assets-management/station';
import { StatusEnum, RouteDirectionEnum, StationTypeEnum, ActionEnum } from '../../../models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { AtisInfo, ReqAddAtis } from 'src/app/models/sign-control/atis';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MediaInfo, ReqAddImage, GenMediaTemplate, GenMediaItem } from 'src/app/models/sign-control/media';
import { SignTypeService } from '../../../services/sign-control/sign-type.service';
import { BoardsignService } from '../../../services/sign-control/boardsign.service';
import { MediaService } from '../../../services/sign-control/media.service';
import { UploadService } from '../../../services/sign-control/upload.service';
import { MediaType, MediaBehaviorType, MediaSettingType } from '../../../models/sign-control/enum';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConvertActionBindingResult } from '@angular/compiler/src/compiler_util/expression_converter';
import { interval } from 'rxjs';
import { permission } from 'src/app/views/sign-control/_menu';
import { dateFieldName } from '@progress/kendo-angular-intl';
@Component({
  selector: 'app-atis',
  templateUrl: './atis.component.html',
  styleUrls: ['./atis.component.scss']
})
export class ATISComponent implements OnInit, OnDestroy {
  @ViewChild('imInput') imInputVariable: ElementRef;
  @ViewChild('imInput2') imInputVariable2: ElementRef;
  @ViewChild('excelInput') excelInputVariable: ElementRef;
  @ViewChild('excelInput2') excelInputVariable2: ElementRef;

  @ViewChild('colorModal', { static: false }) public colorModal: ModalDirective;
  @ViewChild('shapefileModal', { static: false }) public shapefileModal: ModalDirective;
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('changeModeModal', { static: false }) public changeModeModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;

  statusEnum = StatusEnum;
  routeDirectionEnum = RouteDirectionEnum;
  stationTypeEnum = StationTypeEnum;

  myDate = new Date();
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  data_list: AtisInfo[] = [];


  is_loading: boolean = false;
  search_route: string;
  data_route: Array<string> = [];
  route_list: string[] = [];

  allowCustom: boolean = false;
  res_message: any;

  search_km_st: any;
  search_km_en: any;

  connection_list = [
    { id: 1, name: "Online" },
    { id: 0, name: "Offline" }
  ];
  search_name: any;
  search_sign_type: any;
  search_connection_status: any;
  search_is_in: boolean = false;
  search_is_out: boolean = false;
  selectItem: AtisInfo = {
    name: null
  };
  sign_type_list: any[] = [];
  data_sign_type: any[] = [];
  data_sign_type_modal: any[] = [];
  sign_type_modal: any;

  boardsign_list: any[] = [];
  data_boardsign_modal: any[] = [];
  boardsign_modal: any;

  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" }
  ];
  status_modal: any;
  atis_type_list = [
    { id: 1, name: "Travel Time" },
    { id: 2, name: "ATIS/LMS" }
  ];
  atis_type_modal: any;
  config_file: File;
  img_file: File;
  img_content_type: string;
  img_w: number;
  img_h: number;
  img_src = { dataUrl: null, url: null };
  select_mode: any;
  //view: number = 1; //list = 1 , info = 2
  view: number = 1;

  //-------------------------//

  src: string;
  src_black: string;
  src_w: number;
  src_h: number;
  src_viewBox: string = "0 0 909 454";
  shapefile: any[] = [];
  resultSrc: string;
  isHideHover = false;
  colors = {
    red: '#FF0000',
    green: '#00FF00',
    orange: '#FFA500',
    yellow: '#FFFF00'
  }
  texts: any[] = [];
  weather: any[] = [];
  subscription: any;

  isAdd: boolean;
  isControl: boolean;
  isMa: boolean;
  isEdit: boolean;
  constructor(private commonService: CommonService, private atisService: ATISService,
    private signTypeService: SignTypeService, private boardsignService: BoardsignService,
    private uploadService: UploadService) { 
    this.commonService.activityLog(ActionEnum.Get, 'Sign Control ➡ ATIS').subscribe(res => { }, error => { console.log(error); });
    }

  async ngOnInit() {
    this.is_loading = true;
    this.getRoute();
    this.getSignType();
    this.getBoardsign();
    this.getAtis();
    // this.getAtisOld();
    this.subscription = interval(60000).subscribe((x) => {
      //this.getAtisOld();
      if (this.view != 2)
        this.getAtis();
    });
    this.isAdd = await this.commonService.getPermission(permission.atis.add_id).toPromise();
    this.isControl = await this.commonService.getPermission(permission.atis.control_id).toPromise();
    this.isMa = await this.commonService.getPermission(permission.atis.ma_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.atis.edit_id).toPromise();
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  async changeView(view?: number, item?: AtisInfo) {
    this.view = view;
    this.isHideHover = false;
    if (item) {
      this.selectItem = item;
      this.status_modal = this.status_list.find(x => x.id == this.selectItem.status);
      this.boardsign_modal = this.boardsign_list.find(x => x.id == this.selectItem.boardsign_id);
      this.sign_type_modal = this.sign_type_list.find(x => x.id == this.selectItem.sign_type_id);
      this.atis_type_modal = this.atis_type_list.find(x => x.id == this.selectItem.atis_type);
      if (this.selectItem.source) {
        const im: any = await this.getBase64Image(this.selectItem.source);
        this.src = im.data;
        this.img_src.url = this.selectItem.source;

      }
      if (this.selectItem.width)
        this.src_w = this.selectItem.width;
      if (this.selectItem.height)
        this.src_h = this.selectItem.height;

      if (this.src_h && this.src_w) {
        this.src_viewBox = `0 0 ${this.src_w} ${this.src_h}`;
        this.img_w = this.src_w;
        this.img_h = this.src_h;
      }
      this.shapefile = [];
      this.texts = [];
      this.weather = [];
      this.config_shapefile = [];
      this.config_texts = [];
      this.config_weather = [];
      if (this.selectItem.config_file) {
        this.commonService.getDataByUrl(this.selectItem.config_file).subscribe(
          res => {
            if (res) {
              if (res.shapefile) {
                if (res.shapefile.in && res.shapefile.out) {
                  res.shapefile.in.route.forEach(item => {
                    const key = `${item.route}_${item.km[0]}_in`;
                    const km_in = { route: item.route, km: item.km[0], name: item.name, path: item.path, key: key, dir: "in", color: this.colors.green };
                    const km_in2 = { route: item.route, km: item.km[0], name: item.name, path: item.path, key: key, dir: "in", color: this.colors.yellow };
                    this.shapefile.push(km_in);
                    this.config_shapefile.push(km_in2);
                  });
                  res.shapefile.out.route.forEach(item => {
                    const key = `${item.route}_${item.km[0]}_out`;
                    const km_out = { route: item.route, km: item.km[0], name: item.name, path: item.path, key: key, dir: "out", color: this.colors.green };
                    this.shapefile.push(km_out);
                    this.config_shapefile.push(km_out);
                  });
                }
              }
              if (res.config) {
                res.config.forEach(item => {
                  let sum = 0;
                  item.value.forEach(element => {
                    let k = element.km_e - element.km_s;
                    sum = sum + Math.abs(k);
                  });
                  const kk = { box_points: item.box_points, color: item.color, font_size: item.font_size, position: item.position, ref: item.ref, text: sum };
                  this.texts.push(kk);
                  this.config_texts.push(kk);
                });
              }
              if (res.weather) {
                this.weather = _.cloneDeep(res.weather);
                this.weather.forEach(async (item) => {
                  const im: any = await this.getBase64Image(item.image);
                  item.image_data = im.data;
                  item.check = true;
                });
                console.log(this.weather);
                
                this.config_weather = res.weather;
                this.config_weather.forEach(async (item) => {
                  item.check = true;
                });
              }
            }
          },
          error => {
            console.log(error);
          });
      }
    }
  }
  handleValueStatusModal(value) {
    if (value) {
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  handleValueAtisTypeModal(value) {
    if (value) {
      this.atis_type_modal = value;
    } else {
      this.atis_type_modal = null;
    }
  }

  handleValueConnectionStatus(value) {
    if (value) {
      //console.log(value);
      this.search_connection_status = value;
    } else {
      this.search_connection_status = null;
    }
  }
  handleFilterRoute(value) {
    if (value.length > 0) {
      this.data_route = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route = _.cloneDeep(this.route_list);
    } else {
      this.data_route = [];
    }
  }
  handleValueRoute(value) {
    if (value) {
      //console.log(value);
      this.search_route = value;
    } else {
      this.search_route = null;
    }
  }

  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.getAtis();
    }
  }

  search() {
    this.currentPage = 1;
    this.getAtis();
  }
  getRoute() {
    this.commonService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  getSignType() {
    this.signTypeService.getSignType(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.sign_type_list = _.cloneDeep(res.data);
              this.data_sign_type = _.cloneDeep(res.data);
              this.data_sign_type_modal = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => { });
  }

  handleFilterSignType(value) {
    if (value.length > 0) {
      this.data_sign_type = this.sign_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_sign_type = _.cloneDeep(this.sign_type_list);
    } else {
      this.data_sign_type = [];
    }
  }
  handleValueSignType(value) {
    if (value) {
      //console.log(value);
      this.search_sign_type = value;
    } else {
      this.search_sign_type = null;
    }
  }
  handleFilterSignTypeModal(value) {
    if (value.length > 0) {
      this.data_sign_type_modal = this.sign_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_sign_type_modal = _.cloneDeep(this.sign_type_list);
    } else {
      this.data_sign_type_modal = [];
    }
  }
  handleValueSignTypeModal(value) {
    if (value) {
      this.sign_type_modal = value;
    } else {
      this.sign_type_modal = null;
    }
  }

  getBoardsign() {
    this.boardsignService.getBoardsign(null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.boardsign_list = _.cloneDeep(res.data);
              this.data_boardsign_modal = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => { });
  }

  handleFilterBoardsignModal(value) {
    if (value.length > 0) {
      this.data_boardsign_modal = this.boardsign_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_boardsign_modal = _.cloneDeep(this.boardsign_list);
    } else {
      this.data_boardsign_modal = [];
    }
  }
  handleValueBoardsignModal(value) {
    if (value) {
      this.boardsign_modal = value;
    } else {
      this.boardsign_modal = null;
    }
  }

  getAtis() {
    this.data_list = [];
    let route = null;
    let sign_type_id = null;
    if (this.search_route)
      route = this.search_route;
    if (this.search_sign_type)
      sign_type_id = this.search_sign_type.id;
    this.atisService.getAtis(this.currentPage, this.itemsPerPage, this.search_name, sign_type_id, route, null, null, this.search_km_st, this.search_km_en, this.search_is_in, this.search_is_out).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              if (res.data.data) {
                res.data.data.forEach(item => {
                  if (item.mode == 1 || item.mode == null) {
                    item.is_auto_mode = true;
                  } else {
                    item.is_auto_mode = false;
                  }
                  this.data_list.push(item);
                  if (this.selectItem) {
                    if (item.id == this.selectItem.id) {
                      this.selectItem = _.cloneDeep(item);
                      if (this.view == 2) {
                        this.changeView(2, this.selectItem);
                      }
                    }
                  }
                });
                this.totalItems = res.data.total_items;
              } else {
                this.totalItems = 0;
              }
            } else {
              this.totalItems = 0;
            }
          } else {
            this.totalItems = 0;
          }
        } else {
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.totalItems = 0;
        this.is_loading = false;
      });
  }

  getAtisOld() {

    this.atisService.getOldAtis().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = [];
              res.data.data.forEach(item => {
                if (item.mode == 1 || item.mode == null) {
                  item.is_auto_mode = true;
                } else {
                  item.is_auto_mode = false;
                }
                this.data_list.push(item);
              });
              this.totalItems = res.data.data.length;
            } else {
              this.totalItems = 0;
            }
          } else {
            this.totalItems = 0;
          }
        } else {
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.totalItems = 0;
        this.is_loading = false;
      });
  }
  openAddModal() {
    this.img_src = { dataUrl: null, url: null };
    this.config_shapefile = [];
    this.config_texts = [];
    this.config_weather = [];
    this.selectItem = new AtisInfo();
    this.selectItem.name = null;
    this.boardsign_modal = null;
    this.sign_type_modal = null;
    this.img_file = null;
    this.config_file = null;
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.atis_type_modal = this.atis_type_list.find(x => x.id == 1);
    this.addModal.show();
  }
  openEditModal() {
    this.config_file = null;

    if (this.selectItem.source) {
      this.img_src = { dataUrl: null, url: this.selectItem.source };
    }
    if (this.selectItem.width)
      this.src_w = this.selectItem.width;
    if (this.selectItem.height)
      this.src_h = this.selectItem.height;

    if (this.src_h && this.src_w) {
      this.src_viewBox = `0 0 ${this.src_w} ${this.src_h}`;
      this.img_w = this.src_w;
      this.img_h = this.src_h;
    }
    this.config_shapefile = [];
    this.config_texts = [];
    this.config_weather = [];
    if (this.selectItem.config_file) {
      this.commonService.getDataByUrl(this.selectItem.config_file).subscribe(
        res => {
          if (res) {
            if (res.shapefile) {
              if (res.shapefile.in && res.shapefile.out) {
                res.shapefile.in.route.forEach(item => {
                  const key = `${item.route}_${item.km[0]}_in`;
                  const km_in2 = { route: item.route, km: item.km[0], name: item.name, path: item.path, key: key, dir: "in", color: this.colors.yellow };
                  this.config_shapefile.push(km_in2);
                });
                res.shapefile.out.route.forEach(item => {
                  const key = `${item.route}_${item.km[0]}_out`;
                  const km_out = { route: item.route, km: item.km[0], name: item.name, path: item.path, key: key, dir: "out", color: this.colors.green };
                  this.config_shapefile.push(km_out);
                });
              }
            }
            if (res.config) {
              res.config.forEach(item => {
                let sum = 0;
                item.value.forEach(element => {
                  let k = element.km_e - element.km_s;
                  sum = sum + Math.abs(k);
                });
                const kk = { box_points: item.box_points, color: item.color, font_size: item.font_size, position: item.position, ref: item.ref, text: sum };
                this.config_texts.push(kk);
              });
            }
            if(res.weather){
              this.config_weather = res.weather;
              this.config_weather.forEach(async (item) => {
                item.check = true;
              });
            }
          }
        },
        error => {
          console.log(error);
        });

    }
    this.clearConfigFile();
    this.addModal.show();
  }
  openDeleteModal() {
    this.deleteModal.show();
  }
  getMode(item: any) {
    return (item.is_auto_mode) ? 'Auto' : 'Manual';
  }
  clearData() {
    //this.selectItem = new ReqAddAtis();
    this.clearConfigFile();
    this.config = null;
  }
  detectFiles(event) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        this.img_file = file;
        this.img_content_type = file.type.replace('image/', '.')
        if (this.img_content_type == '.jpeg')
          this.img_content_type = '.jpg';
        let reader = new FileReader();
        reader.onload = (e: any) => {
          let img = new Image();
          img.src = e.target.result;
          this.img_src.dataUrl = e.target.result;
          img.onload = () => {
            this.img_w = img.width;
            this.img_h = img.height;
          };

        }
        reader.readAsDataURL(file);
      }
    }
    setTimeout(() => {
      if (this.imInputVariable) {
        this.imInputVariable.nativeElement.value = "";
      }
      if (this.excelInputVariable) {
        this.excelInputVariable.nativeElement.value = "";
      }
      if (this.imInputVariable2) {
        this.imInputVariable2.nativeElement.value = "";
      }
      if (this.excelInputVariable2) {
        this.excelInputVariable2.nativeElement.value = "";
      }
    }, 1000);
  }

  bucket_name = 'sign-control';
  path = 'atis/config';
  addAtisFile(is_add?: boolean) {
    const img = this.uploadService.uploadImages([this.img_file], this.bucket_name, this.path);
    const con_text = JSON.stringify(this.config);
    let f = new File([con_text], `${this.selectItem.name}_config.txt`, { type: "text/plain", lastModified: new Date().getTime() });
    const config11 = this.uploadService.uploadFile([f], this.bucket_name, this.path);

    if (is_add) {
      this.res_message = 'เพิ่ม ATIS ไม่สำเร็จ';
    } else {
      this.res_message = 'แก้ไข ATIS ไม่สำเร็จ';
    }
    const folk = forkJoin([img, config11]).pipe(
      map(([res_img, res_config]) => {
        // forkJoin returns an array of values, here we map those values to an object
        return { res_img, res_config };
      })
    ).subscribe(res => {
      if (res) {
        if (res.res_img && res.res_config) {
          const im = res.res_img.data.img_urls[0];
          const file = res.res_config.data.img_urls[0];
          if (is_add) {
            this.addAtis(im, file);
          } else {
            this.editAtis(im, file);
          }
        } else {
          setTimeout(() => {
            this.swalError.fire();
            this.is_loading = false;
          }, 1000);
        }
      } else {
        setTimeout(() => {
          this.swalError.fire();
          this.is_loading = false;
        }, 1000);
      }
    }, err => {
      console.log(err);
    });
  }
  editAtisFile() {
    this.res_message = 'แก้ไข ATIS ไม่สำเร็จ';

    if (this.img_file && this.config) {
      this.addAtisFile(false);
    } else if (this.img_file) {
      this.uploadService.uploadImages([this.img_file], this.bucket_name, this.path).subscribe(res => {
        if (res) {
          if (res.data) {
            const im = res.data.img_urls[0];
            const file = this.selectItem.config_file;
            this.editAtis(im, file);
          } else {
            setTimeout(() => {
              this.swalError.fire();
              this.is_loading = false;
            }, 1000);
          }
        } else {
          setTimeout(() => {
            this.swalError.fire();
            this.is_loading = false;
          }, 1000);
        }
      }, err => {
        console.log(err);
      });
    } else if (this.config) {
      const con_text = JSON.stringify(this.config);
      let f = new File([con_text], `${this.selectItem.name}_config.txt`, { type: "text/plain", lastModified: new Date().getTime() });
      this.uploadService.uploadFile([f], this.bucket_name, this.path).subscribe(res => {
        if (res) {
          if (res.data) {
            const im = this.selectItem.source;
            const file = res.data.img_urls[0];
            this.editAtis(im, file);
          } else {
            setTimeout(() => {
              this.swalError.fire();
              this.is_loading = false;
            }, 1000);
          }
        } else {
          setTimeout(() => {
            this.swalError.fire();
            this.is_loading = false;
          }, 1000);
        }
      }, err => {
        console.log(err);
      });
    }

  }
  addAtis(img_url?: string, config_url?: string) {
    this.is_loading = true;
    let req = new ReqAddAtis();
    req.name = this.selectItem.name;
    req.image_key = this.selectItem.name;
    req.status = this.status_modal.id;
    req.boardsign_id = this.boardsign_modal.id;
    req.sign_type_id = this.sign_type_modal.id;
    req.atis_type = this.atis_type_modal.id;
    req.width = parseInt(this.img_w.toString());
    req.height = parseInt(this.img_h.toString());
    req.content_type = this.img_content_type;
    req.source = img_url;
    req.config_file = config_url;
    this.res_message = "";
    this.atisService.addAtis(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { url: `${this.atisService.domain}/${this.atisService.uri}`, request: req, response: res };
            this.commonService.activityLog(ActionEnum.Add, `Sign Control ➡ ATIS`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `เพิ่ม ATIS สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getAtis();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editAtis(img_url?: string, config_url?: string) {
    this.is_loading = true;
    let req = new ReqAddAtis();
    req.name = this.selectItem.name;
    req.image_key = this.selectItem.name;
    req.status = this.status_modal.id;
    req.boardsign_id = this.boardsign_modal.id;
    req.sign_type_id = this.sign_type_modal.id;
    req.atis_type = this.atis_type_modal.id;
    req.width = parseInt(this.img_w.toString());
    req.height = parseInt(this.img_h.toString());
    req.content_type = this.img_content_type;
    req.source = img_url;
    req.config_file = config_url;
    this.res_message = "";
    this.atisService.editAtis(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { id: this.selectItem.id, request: req, response: res, url: `${this.atisService.domain}/${this.atisService.uri}/${this.selectItem.id}` };
            this.commonService.activityLog(ActionEnum.Edit, `Sign Control ➡ ATIS`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `แก้ไข ATIS สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getAtis();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  deleteSignType() {
    this.is_loading = true;
    let req = new ReqAddAtis();
    req.name = this.selectItem.name;
    req.status = this.status_modal.id;
    req.boardsign_id = this.boardsign_modal.id;
    req.sign_type_id = this.sign_type_modal.id;
    req.atis_type = this.atis_type_modal.id;
    req.width = parseInt(this.img_w.toString());
    req.height = parseInt(this.img_h.toString());
    req.content_type = this.img_content_type;
    this.res_message = "";
    this.atisService.deleteAtisEditStatus(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { id: this.selectItem.id, request: req, response: res, url: `${this.atisService.domain}/${this.atisService.uri}/${this.selectItem.id}` };
            this.commonService.activityLog(ActionEnum.Delete, `Sign Control ➡ ATIS`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `ลบ ATIS สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getAtis();
            }, 100);
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  openChangeModal(item?: AtisInfo) {
    this.selectItem = item;
    this.changeModeModal.show();
  }
  changeMode(mode?: number, atis_id?: number) {
    this.res_message = "";
    this.atisService.changeAitsMode(atis_id, mode).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { id: this.selectItem.id, request: { mode: mode }, response: res, url: `${this.atisService.domain}/${this.atisService.uri}/${atis_id}/mode` };
            this.commonService.activityLog(ActionEnum.Edit, `Sign Control ➡ ATIS ➡ เปลี่ยน Mode`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `เปลี่ยน Mode สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.changeModeModal.hide();

            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
        this.getAtis();
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });

  }
  switch_show = true;
  onHideChangeMode() {
    this.getAtis();
    this.switch_show = false;
    setTimeout(() => {
      this.switch_show = true;
    }, 100);
  }
  //------------------------------------------------------------//
  updateManualAitsImage(url?: string, width?: number, height?: number, content_type?: string, media_type?: number, behavior_type?: number, status?: number) {
    this.atisService.updateManualAitsImage(this.selectItem.id, url, width, height, content_type, media_type, behavior_type, status).subscribe(
      res => {
        if (res) {
          if (res.data) {
            const req1 = {
              image_manual: url,
              width: width,
              height: height,
              content_type: content_type,
              media_type: media_type,
              behavior_type: behavior_type,
              status: status
            }
            const log_req_data = { id: this.selectItem.id, request: req1, response: res, url: `${this.atisService.domain}/${this.atisService.uri}/${this.selectItem.id}/manual` };
            this.commonService.activityLog(ActionEnum.Edit, `Sign Control ➡ ATIS ➡ Upload Manual Aits Image`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `Update Manual Aits Image สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.changeModeModal.hide();

            }, 100);
          } else {
            setTimeout(() => {
              this.swalError.fire();
              this.is_loading = false;
            }, 1000);
          }
        } else {
          setTimeout(() => {
            this.swalError.fire();
            this.is_loading = false;
          }, 1000);
        }
      },
      error => {
        console.log(error);
      });
  }
  //------------------------------------------------------------//
  poly_top: any;
  poly_left: any;
  poly_is_show: any = false;
  poly_text: any = '';
  poly_points: any[] = [];
  pad: any = "0000";
  km_key: any = '';
  DIR_IN: string = 'ขาเข้า';
  DIR_OUT: string = 'ขาออก';
  clickItem: any;
  clickColor: any;

  select_min_km: any;
  select_max_km: any;
  select_km_mode: any;
  // getCriteriaColour(input: number) {
  //   const output = (input > this.criteria.green) ? '#00FF00' :
  //     ((input > this.criteria.yellow) ? '#FFFF00' : ((input > this.criteria.orange) ? 'orange' : '#FF0000'));
  //   // console.log(output);
  //   return output;
  // }
  getCriteriaColour(input: number) {
    const output = '#00FF00';
    return output;
  }

  polymouseEnter(e: any, m: any) {
    let str_route = "" + m.route;
    let route = this.pad.substring(0, this.pad.length - str_route.length) + str_route;
    let dir = '';
    if (m.dir == 'in') {
      dir = this.DIR_IN;
    } else {
      dir = this.DIR_OUT;
    }
    let text = `${route} กม.${m.km} ${dir}`;
    this.poly_is_show = true;
    this.poly_text = text;
    this.poly_top = e.clientY - 35;
    this.poly_left = e.clientX + 10;
    this.km_key = m.key;
  }
  polymouseLeave() {
    this.poly_is_show = false;
    this.poly_top = null;
    this.poly_left = null;
    this.km_key = '';
  }
  clickKm(item?: any) {
    this.clickItem = item;
    this.clickColor = item.color;
    const find_routes = this.shapefile.filter(x => x.route == item.route && x.dir == item.dir);
    const kms = find_routes.map(a => a.km);
    this.select_max_km = Math.max.apply(null, kms);
    this.select_min_km = Math.min.apply(null, kms);
    this.select_km_mode = 1;
    this.colorModal.show();
  }
  clickSelectColor(item?: string) {
    this.clickColor = item;
  }
  changeColor() {
    if (this.select_km_mode != 2) {
      this.shapefile.forEach(item => {
        if (item.route == this.clickItem.route && item.dir == this.clickItem.dir && item.km == this.clickItem.km) {
          item.color = this.clickColor;
        }
      });
    } else {
      this.shapefile.forEach(item => {
        if (item.route == this.clickItem.route && item.dir == this.clickItem.dir && item.km >= parseInt(this.select_min_km) && item.km <= parseInt(this.select_max_km)) {
          item.color = this.clickColor;
        }
      });
    }
    this.colorModal.hide();
  }
  getTextPositionX(item) {

    const right = item.box_points.right;
    const left = item.box_points.left;
    let x = left;
    if (item.position == "center") {
      x = (left + right) / 2;
    } else if (item.position == "left") {
      x = left;
    } else if (item.position == "right") {
      x = right;
    }
    return x;
  }
  getTextPositionY(item) {
    const top = item.box_points.top;
    const bottom = item.box_points.bottom;
    let y = (top + bottom) / 2;
    y = y + ((item.font_size / 8) / 2);
    return y;
  }
  getTextAnchor(item) {
    // start middle end
    let text_anchor = "start";
    if (item.position == "center") {
      text_anchor = "middle";
    } else if (item.position == "left") {
      text_anchor = "start";
    } else if (item.position == "right") {
      text_anchor = "end";
    }
    return text_anchor;
  }
  async ClickUploadManualImage() {
    this.isHideHover = true;
    setTimeout(() => {
      var svgElement = document.getElementById('source');
      let clonedSvgElement: any = svgElement.cloneNode(true);
      let outerHTML: any = clonedSvgElement.outerHTML, blob = new Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' });
      let URL: any = window.URL || window.webkitURL || window;
      let blobURL: any = URL.createObjectURL(blob);
      let image = new Image();
      image.onload = () => {
        let canvas = document.createElement('canvas');
        canvas.width = this.src_w;
        canvas.height = this.src_h;
        let context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, this.src_w, this.src_h);
        let png = canvas.toDataURL();
        //console.log(png);
        const myFile = this.dataURItoBlob(png);
        this.isHideHover = false;
        //window.open(URL.createObjectURL(myFile));
        this.res_message = `ไม่สำเร็จ`;
        const path1 = 'atis/image/manual';
        this.uploadService.uploadATISImages(myFile, this.bucket_name, path1, this.selectItem.name).toPromise().then((res) => {
          if (res) {
            if (res.data) {
              console.log(res.data.img_urls[0]);
              this.updateManualAitsImage(res.data.img_urls[0], this.src_w, this.src_h, '.png', MediaType.Image, MediaBehaviorType.Dynamic, StatusEnum.Active);
            } else {
              setTimeout(() => {
                this.swalError.fire();
                this.is_loading = false;
              }, 1000);
            }
          } else {
            setTimeout(() => {
              this.swalError.fire();
              this.is_loading = false;
            }, 1000);
          }
        }
        ).catch(error => {
          setTimeout(() => {
            this.swalError.fire();
            this.is_loading = false;
          }, 1000);
        });
      };
      image.src = blobURL;
    }, 200);
  }

  dataURItoBlob(dataURI: any) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    const type = dataURI.split(',')[0];

    return new Blob([new Uint8Array(array)], {
      type: (type.split(';')[0]).split(':')[1]
    });
  }
  async getBase64Image(imgUrl: string) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        return resolve({ code: 1, data: dataURL });
      };
      img.onerror = function (err) {
        return reject({ code: 0, msg: err });
      };
      // set attributes and src
      img.setAttribute('crossOrigin', 'anonymous'); //
      img.src = imgUrl;
    }).catch(err => {
      return err;
    });
  }
  //------------------------------------------------------------------------------//
  row_line = [];
  row_circle = [];
  row_font = [];
  row_weather = [];
  all_row = [];

  shapefile_line = [];
  shapfile_circle = [];
  config_font = [];
  config: any;
  config_shapefile = [];
  config_texts = [];
  config_weather = [];
  detectExcelFiles(event) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        this.config_file = file;
        const wb = new Excel.Workbook();
        const reader = new FileReader()

        reader.readAsArrayBuffer(file)
        reader.onload = () => {
          const buffer: any = reader.result;
          this.all_row = [];
          wb.xlsx.load(buffer).then(workbook => {
            workbook.eachSheet((sheet, id) => {
              sheet.eachRow((row: any, rowIndex) => {
                let r1 = JSON.stringify(row.values);
                let r2 = JSON.parse(r1);
                this.all_row.push(r2)
              })

              this.genRow();
            })
          })
        }
      }
    }

    setTimeout(() => {
      if (this.imInputVariable) {
        this.imInputVariable.nativeElement.value = "";
      }
      if (this.excelInputVariable) {
        this.excelInputVariable.nativeElement.value = "";
      }
      if (this.imInputVariable2) {
        this.imInputVariable2.nativeElement.value = "";
      }
      if (this.excelInputVariable2) {
        this.excelInputVariable2.nativeElement.value = "";
      }
    }, 1000);
  }
  async genRow() {
    this.row_line = [];
    this.row_circle = [];
    this.row_font = [];
    this.row_weather = [];
    let fonts = [];
    this.all_row.forEach(item => {
      if (item.length >= 20) {
        this.row_circle.push(item);
      } else if (item.length >= 15) {
        this.row_line.push(item);
      } else if (item.length == 13) {
        this.row_weather.push(item);
      } else if (item.length == 9) {
        item[0] = this.row_font.length;
        let tt = _.uniq(item);
        if (tt.length == 2) {
          fonts.push(tt)
        }
        this.row_font.push(item);
      }
    });
    for (let i = 0; i < fonts.length; i++) {
      const f = fonts[i];
      if (i == fonts.length - 1) {
        f.push(this.row_font.length - 1)
      } else {
        f.push(fonts[i + 1][0] - 1)
      }
    }
    let weather = [];
    console.log(this.row_weather);

    if (this.row_weather.length > 1) {
      this.row_weather.forEach((item, index) => {
        if (Number.isInteger(item[2])) {
          let img = null;
          if(item[8]){
            if(item[8].text){
              img = item[8].text;
            }else{
              img = item[8];
            }
          }
          const weather_item = { sign: item[1], weather_id: parseInt(item[2]), type: item[3], condition: item[4], text: item[5], text_size: item[6], text_color: item[7], image: img, img_width: item[9], img_height: item[10], x: item[11], y: item[12] };
          weather.push(weather_item);
        }
      });
    }
    let rows_f = [];
    fonts.forEach(item => {
      let values = [];
      const filter = this.row_font.filter(x => x[0] > (item[0] + 1) && x[0] <= item[2]);
      filter.forEach(ele => {
        let st = ele[1].split(' ');
        let en = ele[2].split(' ');
        let st_r = parseInt(st[0].split('.')[1]);
        //let en_r = parseInt(en[0].split('.')[1]);
        let st_kmm = st[1].split('.')[1];
        let en_kmm = en[1].split('.')[1];
        let st_km = parseInt(st_kmm.split('+')[0]);
        let en_km = parseInt(en_kmm.split('+')[0]);

        let dir = '';
        if ((st_km - en_km) >= 0) {
          dir = 'in';
        } else {
          dir = 'out';
        }
        const val = { route: st_r, km_s: st_km, km_e: en_km, dir: dir };
        values.push(val);
      });
      const fil = filter[0];
      const left_top = fil[4].split(',');
      const right_bottom = fil[5].split(',');
      const left = parseInt(left_top[0]);
      const top = parseInt(left_top[1]);
      const right = parseInt(right_bottom[0]);
      const bottom = parseInt(right_bottom[1]);
      const box_points = { left: left, top: top, right: right, bottom: bottom };
      const pos = fil[6];
      let pos_text = '';
      if (pos == 1) {
        pos_text = 'left';
      } else if (pos == 2) {
        pos_text = 'center';
      } else if (pos == 3) {
        pos_text = 'right';
      }
      const con = { ref: item[1], default: "", value: values, font_size: fil[7], color: fil[8], box_points: box_points, position: pos_text };
      rows_f.push(con);
    });

    let line = await this.dataRowToObject(this.row_line);
    let data_line = await this.lineToObjectPolyline(line);
    let circle = await this.dataRowToObjectCircle(this.row_circle);
    let data_circle = await this.circleToObjectPolyline(circle);
    let data = data_line.concat(data_circle);
    let route_in = [];
    let route_out = [];
    data.forEach(item => {
      if (item.dir == "in") {
        const km = [item.km, item.km];
        const f = {
          route: item.route.toString(),
          path: item.path,
          name: item.name_str,
          km: km
        }
        route_in.push(f);
      }
      else if (item.dir == "out") {
        const km = [item.km, item.km];
        const f = {
          route: item.route.toString(),
          path: item.path,
          name: item.name_str,
          km: km
        }
        route_out.push(f);
      }
    });
    let r_in = { route: route_in };
    let r_out = { route: route_out };
    let shapefile = { in: r_in, out: r_out };
    this.config = { shapefile: shapefile, config: rows_f, weather: weather };
    this.config_shapefile = [];
    this.config_texts = [];
    this.config_weather = weather;
    if (this.config.shapefile) {
      if (this.config.shapefile.in && this.config.shapefile.out) {
        this.config.shapefile.in.route.forEach(item => {
          const key = `${item.route}_${item.km[0]}_in`;
          const km_in = { route: item.route, km: item.km[0], name: item.name, path: item.path, key: key, dir: "in", color: this.colors.yellow };
          this.config_shapefile.push(km_in);
        });
        this.config.shapefile.out.route.forEach(item => {
          const key = `${item.route}_${item.km[0]}_out`;
          const km_out = { route: item.route, km: item.km[0], name: item.name, path: item.path, key: key, dir: "out", color: this.colors.green };
          this.config_shapefile.push(km_out);
        });
      }
    }
    if (this.config.config) {
      this.config.config.forEach(item => {
        let sum = 0;
        item.value.forEach(element => {
          let k = element.km_e - element.km_s;
          sum = sum + Math.abs(k);
        });
        const kk = { box_points: item.box_points, color: item.color, font_size: item.font_size, position: item.position, ref: item.ref, text: sum };
        this.config_texts.push(kk);
      });
    }
    console.log(this.config);

  }
  async dataRowToObject(rows): Promise<any> {
    let list = [];
    await this.asyncForEach(rows, async (item, index) => {

      if (index != 0) {
        let read = 1;
        for (let i = 1; i <= 14; i++) {
          let bb = item[i];
          if (bb == null) {
            read = 0;
          }
        }
        if (read == 1) {
          let ex = {
            name1: item[1].toString(),
            xy_in1: await this.splitString(item[2].toString()),
            xy_mid1: await this.splitString(item[3].toString()),
            xy_out1: await this.splitString(item[4].toString()),
            route1: parseInt(item[5].toString()),
            km1: parseInt(item[6].toString()),
            distance: parseInt(item[7].toString()),
            name2: item[8].toString(),
            xy_in2: await this.splitString(item[9].toString()),
            xy_mid2: await this.splitString(item[10].toString()),
            xy_out2: await this.splitString(item[11].toString()),
            route2: parseInt(item[12].toString()),
            km2: parseInt(item[13].toString()),
            line_type: parseInt(item[14].toString())
          }
          if (ex.line_type == 1) {
            list.push(ex);
          }
        }
      }
    });
    console.log(list);

    return list;
  }
  async dataRowToObjectCircle(rows): Promise<any> {
    let list = [];
    await this.asyncForEach(rows, async (item, index) => {

      if (index != 0) {
        let read = 1;
        for (let i = 1; i <= 19; i++) {
          let bb = item[i];
          if (bb == null) {
            read = 0;
          }
        }
        if (read == 1) {
          let ex = {
            name1: item[1].toString(),
            route1: parseInt(item[2].toString()),
            km1: parseInt(item[3].toString()),
            q1: parseInt(item[4].toString()),
            cells: parseInt(item[5].toString()),
            distance: parseInt(item[6].toString()),
            name2: item[7].toString(),
            route2: parseInt(item[8].toString()),
            km2: parseInt(item[9].toString()),
            q2: parseInt(item[10].toString()),
            center: await this.splitString(item[11].toString()),
            r_in: parseInt(item[16].toString()),
            r_mid: parseInt(item[17].toString()),
            r_out: parseInt(item[18].toString()),
            line_type: parseInt(item[19].toString())
          }
          list.push(ex);
        }
      }
    });
    return list;
  }
  async asyncForEach<T>(array: Array<T>, callback: (item: T, index: number) => void) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index);
    }
  }
  async lineToObjectPolyline(lst?: Array<any>): Promise<any> {
    let list = [];
    await this.asyncForEach(lst, async (item) => {
      let in_x = await this.calLine(item.xy_in1[0], item.xy_in2[0], item.distance, 1);
      let in_y = await this.calLine(item.xy_in1[1], item.xy_in2[1], item.distance, 2)

      let out_x = await this.calLine(item.xy_out1[0], item.xy_out2[0], item.distance, 1);
      let out_y = await this.calLine(item.xy_out1[1], item.xy_out2[1], item.distance, 2);
      let km_type = item.km1 - item.km2;
      let f = 1;
      let f_dir = "out";
      if (km_type > 0) { f = -1; f_dir = "in"; item.km1 = item.km1 - 1; }
      for (let i = 0; i < item.distance; i++) {
        let poly_in: any = {
          km: null,
          route: null,
          name_str: null,
          name_dir: null,
          dir: null,
          line_type: null,
          name_route: null,
          line1: null,
          line2: null,
          angle: null,
          path: null
        };

        poly_in.km = item.km1 + (i * f);
        poly_in.route = item.route1;
        poly_in.name_str = item.name1 + " - " + item.name2;
        poly_in.name_dir = item.route1 + "_" + (item.km1 + (i * f)) + "_" + f_dir;
        poly_in.dir = f_dir;
        poly_in.line_type = 1;
        poly_in.name_route = item.route1 + "_" + (item.km1 + (i * f));

        let line_in = [];
        let xy_line1_in_1 = [];
        xy_line1_in_1.push(in_x[i]);
        xy_line1_in_1.push(in_y[i]);
        let xy_line1_in_2 = [];
        xy_line1_in_2.push(in_x[i + 1]);
        xy_line1_in_2.push(in_y[i + 1]);
        line_in.push(xy_line1_in_1);
        line_in.push(xy_line1_in_2);
        poly_in.line1 = line_in;

        let line_out = [];
        let xy_line3_out_1 = [];
        xy_line3_out_1.push(out_x[i]);
        xy_line3_out_1.push(out_y[i]);
        let xy_line3_out_2 = [];
        xy_line3_out_2.push(out_x[i + 1]);
        xy_line3_out_2.push(out_y[i + 1]);
        line_out.push(xy_line3_out_1);
        line_out.push(xy_line3_out_2);
        poly_in.line2 = line_out;
        let c_in_x = parseInt(((in_x[i] + in_x[i + 1]) / 2).toString());
        let c_in_y = parseInt(((in_y[i] + in_y[i + 1]) / 2).toString());
        let c_out_x = parseInt(((out_x[i] + out_x[i + 1]) / 2).toString());
        let c_out_y = parseInt(((out_y[i] + out_y[i + 1]) / 2).toString());

        var inRads_in = Math.atan2(c_in_y - c_out_y, c_in_x - c_out_x);
        if (inRads_in < 0) {
          inRads_in = Math.abs(inRads_in);
        }
        else {
          inRads_in = 2 * Math.PI - inRads_in;
        }


        let ang_in = inRads_in * 180 / Math.PI;
        //double ang_out = inRads_out * 180 / Math.PI;
        if (ang_in == 360) {
          ang_in = 0;
        }

        poly_in.angle = parseInt(ang_in.toString());
        poly_in.path = await this.getSVGPath(poly_in.line1, poly_in.line2);
        list.push(poly_in);
      }
    });
    return list;
  }
  async circleToObjectPolyline(lst?: Array<any>): Promise<any> {
    let list = [];
    await this.asyncForEach(lst, async (item) => {
      let mod = item.cells % item.distance;
      let count_cells = parseInt((item.cells / item.distance).toString());
      if (mod > 0) {
        if (count_cells < 1) {
          count_cells = 1;
        }
        else {
          count_cells = count_cells + 1;
        }
      }
      var xy_in = await this.calCircle(item.center[0], item.center[1], item.r_in, item.q1, item.q2, item.distance * count_cells);
      var xy_out = await this.calCircle(item.center[0], item.center[1], item.r_out, item.q1, item.q2, item.distance * count_cells);

      let km_type = item.km1 - item.km2;
      let f = 1;
      let f_dir = "out";
      if (km_type > 0) { f = -1; f_dir = "in"; item.km1 = item.km1 - 1; }

      let q_type = item.q1 - item.q2;
      let q = 1;
      if (q_type > 0) { q = -1; }

      for (let i = 0; i < item.distance * count_cells; i++) {
        let poly_in: any = {
          km: null,
          route: null,
          name_str: null,
          name_dir: null,
          dir: null,
          line_type: null,
          name_route: null,
          line1: null,
          line2: null,
          angle: null,
          path: null
        };
        let ii = parseInt((i / count_cells).toString());
        poly_in.km = item.km1 + (ii * f);
        poly_in.route = item.route1;
        poly_in.name_str = item.name1 + " - " + item.name2;
        poly_in.name_dir = item.route1 + "_" + (item.km1 + (ii * f)) + "_" + f_dir;
        poly_in.dir = f_dir;
        poly_in.line_type = 2;
        poly_in.name_route = item.route1 + "_" + (item.km1 + (ii * f));

        let line_in = new Array<Array<number>>();
        line_in.push(xy_in[i]);
        line_in.push(xy_in[i + 1]);
        poly_in.line1 = line_in;

        let line_out = new Array<Array<number>>();
        line_out.push(xy_out[i]);
        line_out.push(xy_out[i + 1]);
        poly_in.line2 = line_out;
        let c_in_x = parseInt(((xy_in[i][0] + xy_in[i + 1][0]) / 2).toString());
        let c_in_y = parseInt(((xy_in[i][1] + xy_in[i + 1][1]) / 2).toString());
        let c_out_x = parseInt(((xy_out[i][0] + xy_out[i + 1][0]) / 2).toString());
        let c_out_y = parseInt(((xy_out[i][1] + xy_out[i + 1][1]) / 2).toString());
        var inRads_in = Math.atan2(c_in_y - c_out_y, c_in_x - c_out_x);
        if (inRads_in < 0) {
          inRads_in = Math.abs(inRads_in);
        }
        else {
          inRads_in = 2 * Math.PI - inRads_in;
        }

        let ang_in = inRads_in * 180 / Math.PI;
        if (ang_in == 360) {
          ang_in = 0;
        }

        poly_in.angle = parseInt(ang_in.toString());
        poly_in.path = await this.getSVGPath(poly_in.line1, poly_in.line2);
        list.push(poly_in);
      }
    });
    return list;
  }
  async splitString(text): Promise<any> {
    let list = [];
    let xy = []
    xy = text.split(',');
    for (let i = 0; i < xy.length; i++) {
      let ss = parseFloat(xy[i]);
      list.push(ss);
    }
    return list;


  }
  async calLine(x1?: number, x2?: number, d?: number, type?: number): Promise<any> {
    let dd = d;
    let list = [];
    let x = x1 - x2;
    let v = x / (parseFloat(dd.toString()));
    let vv = Math.abs(v);
    for (let i = 0; i <= d; i++) {
      if (x == 0) {
        list.push(x1);
      }
      else if (x < 0) {
        let bb = x1 + (vv * (i));
        list.push(bb);
      }
      else if (x > 0) {
        let bb = x1 - (vv * (i));
        list.push(bb);
      }
    }
    return list;
  }
  async calCircle(cx?: number, cy?: number, r?: number, q1?: number, q2?: number, d?: number): Promise<any> {

    let list = new Array<Array<number>>();
    let q = q1 - q2;
    let v = q / (parseFloat(d.toString()));
    let vv = Math.abs(v);
    for (let i = 0; i <= d; i++) {
      if (q < 0) {
        let dd = q1 + (vv * (i));
        var cc = await this.calcurve(cx, cy, r, dd);
        list.push(cc);
      }
      else if (q > 0) {
        let dd = q1 - (vv * (i));
        var cc = await this.calcurve(cx, cy, r, dd);
        list.push(cc);
      }
    }

    return list;
  }
  async calcurve(cx?: number, cy?: number, r?: number, degrees?: number): Promise<any> {
    let angle = (Math.PI * degrees * (-1)) / 180;
    let X = cx + (r * Math.cos(angle));
    let Y = cy + (r * Math.sin(angle));
    let xy = new Array<number>();
    xy.push(X);
    xy.push(Y);
    return xy;
  }
  input_im_resize = '1';
  async getSVGPath(line1?: any, line2?: any): Promise<any> {
    var p1 = line1[0];
    var p2 = line1[1];
    var p3 = line2[1];
    var p4 = line2[0];
    //var pathData = "M150 0 L150 150 L0 150 L0 0 Z";
    let size = parseFloat(this.input_im_resize);
    var path = "M" + p1[0] * size + " " + p1[1] * size + " L" + p2[0] * size + " " + p2[1] * size + " L" + p3[0] * size + " " + p3[1] * size + " L" + p4[0] * size + " " + p4[1] * size + " Z";
    return path;
  }
  detectConfigFile(event) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        this.config_file = file;
      }
    }
  }
  clearConfigFile() {
    this.config_file = null;
    this.img_file = null;
    if (this.imInputVariable) {
      this.imInputVariable.nativeElement.value = "";
    }
    if (this.imInputVariable2) {
      this.imInputVariable2.nativeElement.value = "";
    }
    if (this.excelInputVariable) {
      this.excelInputVariable.nativeElement.value = "";
    }
    if (this.excelInputVariable2) {
      this.excelInputVariable2.nativeElement.value = "";
    }
  }
  clearConfigFile2() {
    // this.config_shapefile = [];
    // this.config_texts = [];
    // if (this.imInputVariable2) {
    //   this.imInputVariable2.nativeElement.value = "";
    // }
    // if (this.excelInputVariable2) {
    //   this.excelInputVariable2.nativeElement.value = "";
    // }
  }
  //------------------------------------------------------------------------//
  poly_is_show_config = true;
  poly_text_config: any;
  poly_top_config: any;
  poly_left_config: any;
  km_key_config: any;
  checkShapefile() {
    this.shapefileModal.show();
  }

  getViewBox(): string {
    // return '#'+Math.floor(Math.random()*16777215).toString(16);
    if (this.img_w && this.img_h) {
      return `0 0 ${this.img_w} ${this.img_h}`;
    } else {
      return `0 0 0 0`;
    }
  }
  polymouseEnterConfig(e: any, m: any) {
    let str_route = "" + m.route;
    let route = this.pad.substring(0, this.pad.length - str_route.length) + str_route;
    let dir = '';
    if (m.dir == 'in') {
      dir = this.DIR_IN;
    } else {
      dir = this.DIR_OUT;
    }
    let text = `${route} กม.${m.km} ${dir}`;
    this.poly_is_show_config = true;
    this.poly_text_config = text;
    this.poly_top_config = e.clientY - 35;
    this.poly_left_config = e.clientX + 10;
    this.km_key_config = m.key;
  }
  polymouseLeaveConfig() {
    this.poly_is_show_config = false;
    this.poly_top_config = null;
    this.poly_left_config = null;
    this.km_key_config = '';
  }
}