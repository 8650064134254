<div class="d-flex align-items-center flex-column bd-highlight region-panel">
  <div class="mt-auto mb-auto text-center rounded region-menu-button"
    [ngClass]="{'bg-primary': region == tha, 'pointer': region != tha}" (click)="clickRegion(tha)">
    <div class="m-2">
      <img src="assets/img/map/region/thailand-active1.png" width="50" height="50" />
      <div class="small">Thailand</div>
    </div>
  </div>
  <div class="mt-auto mb-auto text-center rounded region-menu-button"
    [ngClass]="{'bg-primary': region == n, 'pointer': region != n}" (click)="clickRegion(n)">
    <div class="m-2">
      <img src="assets/img/map/region/north-active1.png" width="50" height="50" />
      <div class="small">North</div>
    </div>
  </div>
  <div class="mt-auto mb-auto text-center rounded region-menu-button"
    [ngClass]="{'bg-primary': region == ne, 'pointer': region != ne}" (click)="clickRegion(ne)">
    <div class="m-2">
      <img src="assets/img/map/region/northeast-active1.png" width="50" height="50" />
      <div class="small">North East</div>
    </div>
  </div>
  <div class="mt-auto mb-auto text-center rounded region-menu-button"
    [ngClass]="{'bg-primary': region == e, 'pointer': region != e}" (click)="clickRegion(e)">
    <div class="m-2">
      <img src="assets/img/map/region/east-active1.png" width="50" height="50" />
      <div class="small">East</div>
    </div>
  </div>
  <div class="mt-auto mb-auto text-center rounded region-menu-button"
    [ngClass]="{'bg-primary': region == s, 'pointer': region != s}" (click)="clickRegion(s)">
    <div class="m-2">
      <img src="assets/img/map/region/south-active1.png" width="50" height="50" />
      <div class="small">South</div>
    </div>
  </div>
</div>