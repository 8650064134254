import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { StatusEnum, MonitorTypeEnum, MaStatusEnum, RouteDirectionEnum, ActionEnum } from 'src/app/models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/services/common.service';
import { BoardsignInfo, SignInfo, SensorConfig } from 'src/app/models/sign-control/boardsign';
import { ConfigRoadByLane, BoardSuggestCriteria, SignEventInfo, SignEventMedia, SignEventMediaSpecial, SignEventMediaTextItem, SignEventSignal, ReqAddSuggest, ReqSignMedia } from 'src/app/models/sign-control/sign-setting';
import { MediaInfo, SpecialTextItem, ReqAddImage } from 'src/app/models/sign-control/media';
import { MediaType, SignDirection, SpecialTextType, MediaBehaviorType } from 'src/app/models/sign-control/enum';
import { BoardsignService } from '../../../../services/sign-control/boardsign.service';
import { SignSettingService } from '../../../../services/sign-control/sign-setting.service';
import { UploadService } from '../../../../services/sign-control/upload.service';
import { MediaService } from '../../../../services/sign-control/media.service';
import { DeviceService } from '../../../../services/assets-management/device.service';
import { DeviceInfo } from 'src/app/models/assets-management/device';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-sign-setting',
  templateUrl: './sign-setting.component.html',
  styleUrls: ['./sign-setting.component.scss']
})
export class SignSettingComponent implements OnInit, OnChanges {
  @Input() clickOpen: boolean;
  @Input() boardsignInfo: BoardsignInfo;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('imInput') imInputVariable: ElementRef;
  @ViewChild('imInput2') imInputVariable2: ElementRef;
  @ViewChild('settingModal', { static: false }) public settingModal: ModalDirective;
  @ViewChild('signSpecialItemModal', { static: false }) public signSpecialItemModal: ModalDirective;
  @ViewChild('addSpecialItemModal', { static: false }) public addSpecialItemModal: ModalDirective;
  @ViewChild('addTextItemModal', { static: false }) public addTextItemModal: ModalDirective;
  @ViewChild('addSpecialTextItemModal', { static: false }) public addSpecialTextItemModal: ModalDirective;
  @ViewChild('sensorSignalModal', { static: false }) public sensorSignalModal: ModalDirective;
  @ViewChild('matchSensorModal', { static: false }) public matchSensorModal: ModalDirective;
  @ViewChild('signActionModal', { static: false }) public signActionModal: ModalDirective;
  @ViewChild('editSignActionModal', { static: false }) public editSignActionModal: ModalDirective;
  @ViewChild('deleteSignActionModal', { static: false }) public deleteSignActionModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;

  @ViewChild('addImageModal', { static: false }) public addImageModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('deleteMediaModal') public deleteMediaModal: ModalDirective;
  @ViewChild('confirmDeleteMediaModal') public confirmDeleteMediaModal: ModalDirective;
  @ViewChild('signSuggestModal') public signSuggestModal: ModalDirective;
  @ViewChild('mediaListSuggestModal') public mediaListSuggestModal: ModalDirective;

  mediaType = MediaType;
  signDir = SignDirection;
  statusEnum = StatusEnum;
  res_message: string;
  set_sign_height = 65;
  sensor_list = [
    { device_id: 1, name: "SMC01" },
    { device_id: 2, name: "SMC02" },
    { device_id: 3, name: "SMC03" }
  ];
  select_sensor: any;
  allowCustom: boolean = false;
  selectsignItem: SignInfo;
  config_road: any;
  //sensorLane: SensorConfig;

  background = {
    url: null,
    file: null,
    dataUrl: null,
  }
  textItems: SpecialTextItem[] = [
    {
      id: 1,
      text_type: 1,
      text: '90',
      font_size: 50,
      color: 'White',
      font_family: 'Arial',
      font_weight: 'Bold',
      x: 0,
      y: 0,
      is_center: true
    },
    {
      id: 2,
      text_type: 1,
      text: 'ทดสอบ',
      font_size: 12,
      color: 'Orange',
      font_family: 'Highway',
      font_weight: 'Bold',
      x: 30,
      y: 15,
      is_center: false
    }
  ];
  textItem: SpecialTextItem = {
    text_type: null,
    text: 'ข้อความ',
    font_size: 30,
    color: 'Orange',
    font_family: 'Arial',
    font_weight: 'Bold',
    x: 0,
    y: 0,
    is_center: true
  };
  is_loading: boolean = false;
  // sensorConfigList: SensorConfig[] = [{
  //   lane: 1,
  //   queue_dir: 1,
  //   queue_length: 60,
  //   is_over_speed: true,
  //   is_wrong_lane: true,
  //   class_list: [{
  //     class: 4,
  //     class_name: "รถยนต์",
  //     is_allow: true,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   }, {
  //     class: 7,
  //     class_name: "รถบรรทุก",
  //     is_allow: true,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   },
  //   {
  //     class: 2,
  //     class_name: "รถจักรยานยนต์",
  //     is_allow: true,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   },
  //   {
  //     class: 6,
  //     class_name: "รถโดยสาร",
  //     is_allow: true,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   }]
  // }, {
  //   lane: 2,
  //   queue_dir: 1,
  //   queue_length: 80,
  //   is_over_speed: true,
  //   is_wrong_lane: false,
  //   class_list: [{
  //     class: 4,
  //     class_name: "รถยนต์",
  //     is_allow: true,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   }, {
  //     class: 7,
  //     class_name: "รถบรรทุก",
  //     is_allow: true,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   },
  //   {
  //     class: 2,
  //     class_name: "รถจักรยานยนต์",
  //     is_allow: true,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   },
  //   {
  //     class: 6,
  //     class_name: "รถโดยสาร",
  //     is_allow: true,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   }]
  // }, {
  //   lane: 3,
  //   queue_dir: 2,
  //   queue_length: 60,
  //   is_over_speed: true,
  //   is_wrong_lane: false,
  //   class_list: [{
  //     class: 4,
  //     class_name: "รถยนต์",
  //     is_allow: true,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   }, {
  //     class: 7,
  //     class_name: "รถบรรทุก",
  //     is_allow: false,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   },
  //   {
  //     class: 2,
  //     class_name: "รถจักรยานยนต์",
  //     is_allow: true,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   },
  //   {
  //     class: 6,
  //     class_name: "รถโดยสาร",
  //     is_allow: false,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   }]
  // }, {
  //   lane: 4,
  //   queue_dir: 2,
  //   queue_length: 80,
  //   is_over_speed: true,
  //   is_wrong_lane: false,
  //   class_list: [{
  //     class: 4,
  //     class_name: "รถยนต์",
  //     is_allow: true,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   }, {
  //     class: 7,
  //     class_name: "รถบรรทุก",
  //     is_allow: false,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   },
  //   {
  //     class: 2,
  //     class_name: "รถจักรยานยนต์",
  //     is_allow: true,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   },
  //   {
  //     class: 6,
  //     class_name: "รถโดยสาร",
  //     is_allow: false,
  //     suggest_speed: 80,
  //     over_speed: 100,
  //     rain_suggest_speed: 60,
  //     rain_over_speed: 90
  //   }]
  // },
  // ];
  selectEvent: SignEventInfo = {
    blink: false,
    duration: null
  };
  selectSignEvents: SignEventInfo[] = [
    {
      id: 1,
      sign_id: 1,
      name: "Event 1",
      blink: true,
      duration: 3,
      signal: {
        event_type: 1,
        lane: 1,
        class: 4,
        rain: 1
      },
      media: {
        url: "https://boardsign-mng.shw.transcodeglobal.com/file?p=/app/uploads/images/boardsign_image/1592378434700.png",
        behavior_type: 1
      },
      sign: {
        width: 500,
        height: 200,
        x: 0,
        y: 0
      }
    },
    {
      id: 2,
      sign_id: 1,
      name: "Event 2",
      blink: true,
      duration: 3,
      signal: {
        event_type: 1,
        lane: 1,
        class: 4,
        rain: 0
      },
      media: {
        url: "https://boardsign-mng.shw.transcodeglobal.com/file?p=/app/uploads/images/boardsign_image/1592453529213.png",
        behavior_type: 2,
        special_property: {
          text_items: [
            {
              text_type: 2,
              text: "",
              font_size: 38,
              color: "White",
              font_family: "Arial",
              font_weight: "Bold",
              x: 15,
              y: 25,
              is_center: true
            }
          ]
        }
      },
      sign: {
        width: 100,
        height: 100,
        x: 0,
        y: 0
      }
    }
  ];

  event_type: number;
  event_type_list = [
    { id: 1, name: "ความเร็วเกิน" },
    { id: 2, name: "ผิดช่องจราจร" }
  ];
  veh_type: number;
  veh_type_list = [
    { id: 4, name: "รถยนต์" },
    { id: 7, name: "รถบรรทุก" },
    { id: 2, name: "รถจักรยานยนต์" },
    { id: 6, name: "รถโดยสาร" }
  ];
  lane: number;
  lane_list = [1, 2, 3, 4, 5, 6, 7, 8];
  duration = 3;
  rain_type: number;
  rain_type_list = [
    { id: 1, name: "ตก" },
    { id: 0, name: "ไม่ตก" }
  ];
  specialTextType = SpecialTextType;
  select_text_type: any;
  text_type_list = [
    { id: SpecialTextType.FreeText, name: "Free Text" },
    { id: SpecialTextType.SpeedLimit, name: "Speed Limit" },
    { id: SpecialTextType.SpeedSuggest, name: "Suggest Speed" },
    { id: SpecialTextType.SpeedSensor, name: "Sensor Speed" }
  ];
  text_type_special_list = [
    { id: SpecialTextType.FreeText, name: "Free Text" },
    { id: SpecialTextType.SpeedLimit, name: "Speed Limit" },
    { id: SpecialTextType.SpeedSuggest, name: "Suggest Speed" }
  ];

  select_font_family: any;
  font_family_list = [
    { id: 'Arial', name: "Arial" },
    { id: 'Highway', name: "Highway" }
  ];
  select_font_weight: any;
  font_weight_list = [
    { id: 'Normal', name: "ปกติ" },
    { id: 'Bold', name: "ตัวหนา" }
  ];
  select_font_color: any;
  font_color_list = ['Orange', 'White', 'Red'];
  constructor(private commonService: CommonService, private signSettingService: SignSettingService,
    private boardsignService: BoardsignService, private uploadService: UploadService,
    private mediaService: MediaService, private deviceService: DeviceService) { }

  ngOnInit(): void {

  }
  async ngOnChanges(changes: SimpleChanges) {
    if (this.clickOpen) {
      this.getSmcByStation(this.boardsignInfo.station_id);
      if (this.boardsignInfo.signs.length > 0) {
        this.selectsignItem = this.boardsignInfo.signs[0];
        if (this.boardsignInfo.sensor_id) {
          this.getSmcDevice(this.boardsignInfo.sensor_id);
          let res = await this.signSettingService.getConfigRoad(this.boardsignInfo.sensor_id).toPromise();
          if (res) {
            if (res.data) {
              if (res.data.length !== 0) {
                this.config_road = _.cloneDeep(res.data);
              }
            }

          }
        }
      }
      this.openSettingModal();
    }

  }
  openSettingModal() {
    this.settingModal.show();
  }
  clearSetting() {

  }
  clickCloseSettingModal() {
    this.settingModal.hide();
    this.onClose.emit(null);
  }

  openSignActionModal(sign: SignInfo) {
    // console.log(sign);
    // this.selectSignEvents.forEach(item => {
    //   item.sign.width = sign.sign_type_object.width;
    //   item.sign.height = sign.sign_type_object.height;
    //   item.sign.x = sign.position_x;
    //   item.sign.y = sign.position_y;
    // });
    this.getEvent(this.boardsignInfo.id, sign.id);
    this.signActionModal.show();
  }
  clickCloseSignActionModal() {
    this.signActionModal.hide();
  }
  openEditSignActionModal(item?: SignEventInfo) {
    this.selectEvent = _.cloneDeep(item);
    if (this.selectEvent.signal) {
      this.event_type = this.selectEvent.signal.event_type;
      this.veh_type = this.selectEvent.signal.class;
      this.lane = this.selectEvent.signal.lane;
      this.rain_type = this.selectEvent.signal.rain;
    }
    if (this.selectEvent.duration != null) {
      this.duration = parseFloat((this.selectEvent.duration / 1000) + '');
    } else {
      this.duration = null;
    }

    this.textItems = [];
    if (this.selectEvent.media) {
      this.background = { url: this.selectEvent.media.url, file: null, dataUrl: null };
      if (this.selectEvent.media.behavior_type == MediaBehaviorType.Special) {
        if (this.selectEvent.media.special_property) {
          if (this.selectEvent.media.special_property.text_items.length > 0) {
            this.textItems = this.selectEvent.media.special_property.text_items;
            this.textItems.forEach((element, index) => {
              element.idd = index + 1;
            });
          }
        }
      }
    }
    this.editSignActionModal.show();
  }
  openAddSignActionModal() {
    console.log(this.selectsignItem);

    this.selectEvent = {
      blink: false,
      duration: null
    };
    this.duration = 3;
    this.event_type = null;
    this.veh_type = null;
    this.lane = null;
    this.rain_type = null;
    this.background = { url: null, file: null, dataUrl: null };
    this.textItems = [];
    this.editSignActionModal.show();
  }
  clickCloseEditSignActionModal() {
    this.editSignActionModal.hide();
  }
  get_sign_width(ratio: string, sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return sign_height * r;
    } else {
      return 0;
    }
  }
  handleValueSensor(value) {
    if (value) {
      this.select_sensor = value;
    } else {
      this.select_sensor = null;
    }
  }

  clickSign(item?: SignInfo) {
    console.log(item);

    this.selectsignItem = item;
  }
  clickClearSign() {
    this.selectsignItem = null;
  }

  //------------------------------------------------------//
  //text item
  openAddTextItem() {
    this.textItem = {
      id: null,
      idd: null,
      text_type: 1,
      text: 'ข้อความ',
      font_size: 30,
      color: 'White',
      font_family: 'Arial',
      font_weight: 'Bold',
      x: 0,
      y: 0,
      is_center: true
    }
    this.select_text_type = this.text_type_list.find(x => x.id == 1);
    this.select_font_family = this.font_family_list.find(x => x.id == "Arial");
    this.select_font_weight = this.font_weight_list.find(x => x.id == "Bold");
    this.select_font_color = 'White';
    this.addTextItemModal.show();
  }
  openEditTextItem(item?: SpecialTextItem) {
    this.textItem = _.cloneDeep(item);
    this.select_text_type = this.text_type_list.find(x => x.id == item.text_type);
    this.select_font_family = this.font_family_list.find(x => x.id == item.font_family);
    this.select_font_weight = this.font_weight_list.find(x => x.id == item.font_weight);
    this.select_font_color = this.font_color_list.find(x => x == item.color);
    this.addTextItemModal.show();
  }
  clearTextItem() {

  }
  deleteTextItem(item?: SpecialTextItem) {
    const index: number = this.textItems.indexOf(item);
    if (index !== -1) {
      this.textItems.splice(index, 1);
    }
  }
  addTextItems() {
    this.textItem.text_type = this.select_text_type.id;
    this.textItem.font_family = this.select_font_family.id;
    this.textItem.font_weight = this.select_font_weight.id;
    let text = this.textItem;
    text.idd = this.textItems.length + 1;
    text.color = this.select_font_color;
    console.log(this.select_text_type);
    console.log(this.select_font_family);
    console.log(this.select_font_weight);
    console.log(text);
    this.textItems.push(text);
    this.addTextItemModal.hide();
  }
  editTextItems(e?: SpecialTextItem) {
    this.textItems.forEach(item => {
      if (item.idd == e.idd) {
        item.text = e.text;
        item.id = e.id;
        item.color = this.select_font_color;;
        item.font_family = this.select_font_family.id;
        item.font_size = e.font_size;
        item.font_weight = this.select_font_weight.id;
        item.is_center = e.is_center;
        item.text_type = this.select_text_type.id;
        item.x = e.x;
        item.y = e.y;
      }
    });
    this.addTextItemModal.hide();
  }
  handleValueTextType(value) {
    if (value) {
      this.select_text_type = value;
    } else {
      this.select_text_type = null;
    }
  }
  handleValueFontFamily(value) {
    if (value) {
      this.select_font_family = value;
    } else {
      this.select_font_family = null;
    }
  }
  handleValueFontWeight(value) {
    if (value) {
      this.select_font_weight = value;
    } else {
      this.select_font_weight = null;
    }
  }
  handleValueFontColor(value) {
    if (value) {
      this.select_font_color = value;
    } else {
      this.select_font_color = null;
    }
  }

  //--------------------------Event-------------------------------//
  openDeleteActionModal(item) {
    this.selectEvent = _.cloneDeep(item);
    this.deleteSignActionModal.show();
  }
  addEvent(url?: string) {
    this.is_loading = true;
    let req = new SignEventInfo();
    req.status = StatusEnum.Active;
    req.sign_id = this.selectsignItem.id;
    req.blink = this.selectEvent.blink;
    if (this.duration) {
      req.duration = (parseFloat(this.duration + '')) * 1000;
    }
    let media = new SignEventMedia();
    media.url = url;
    let content_type = url.split('.');
    media.content_type = `.${content_type[content_type.length - 1]}`;
    if (this.textItems.length > 0) {
      let spe = new SignEventMediaSpecial();
      spe.text_items = this.textItems;
      media.special_property = spe;
      media.behavior_type = MediaBehaviorType.Special;
    } else {
      media.behavior_type = MediaBehaviorType.Static;
    }
    let signal = new SignEventSignal;
    signal.event_type = this.event_type;
    signal.class = this.veh_type;
    signal.lane = this.lane;
    signal.rain = this.rain_type;
    req.name = this.getSignalName(signal.event_type, signal.class, signal.lane, signal.rain);
    req.media = media;
    req.signal = signal;
    this.signSettingService.addEvent(req, this.boardsignInfo.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { url: `${this.signSettingService.domain}/boardsign/${this.boardsignInfo.id}/event`, request: req, response: res };
            this.commonService.activityLog(ActionEnum.Add, `Sign Control ➡ ป้ายแสดงผล ➡ Sign setting ➡ Event`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `เพิ่ม Event สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getEvent(this.boardsignInfo.id, this.selectsignItem.id);
            }, 100);
            this.editSignActionModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editEvent(url?: string) {
    this.is_loading = true;
    let req = new SignEventInfo();
    req.status = StatusEnum.Active;
    req.sign_id = this.selectsignItem.id;
    if (this.selectEvent.blink) {
      req.blink = true;
    } else {
      req.blink = false;
    }
    if (this.duration) {
      req.duration = (parseFloat(this.duration + '')) * 1000;
    }
    let media = new SignEventMedia();
    media.url = url;
    let content_type = url.split('.');
    media.content_type = `.${content_type[content_type.length - 1]}`;
    if (this.textItems.length > 0) {
      let spe = new SignEventMediaSpecial();
      this.textItems.forEach(item => {
        item.idd = null;
      });
      spe.text_items = this.textItems;
      media.special_property = spe;
      media.behavior_type = MediaBehaviorType.Special;
    } else {
      media.behavior_type = MediaBehaviorType.Static;
    }
    let signal = new SignEventSignal;
    signal.event_type = this.event_type;
    signal.class = this.veh_type;
    signal.lane = this.lane;
    signal.rain = this.rain_type;
    req.name = this.getSignalName(signal.event_type, signal.class, signal.lane, signal.rain);
    req.media = media;
    req.signal = signal;
    this.signSettingService.editEvent(req, this.boardsignInfo.id, this.selectEvent.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { url: `${this.signSettingService.domain}/boardsign/${this.boardsignInfo.id}/event/${this.selectEvent.id}`, request: req, response: res };
            this.commonService.activityLog(ActionEnum.Edit, `Sign Control ➡ ป้ายแสดงผล ➡ Sign setting ➡ Event`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `แก้ไข Event สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getEvent(this.boardsignInfo.id, this.selectsignItem.id);
            }, 100);
            this.editSignActionModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {
          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  deleteEvent() {
    this.is_loading = true;
    this.signSettingService.deleteEventEditStatus(this.boardsignInfo.id, this.selectEvent.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { url: `${this.signSettingService.domain}/boardsign/${this.boardsignInfo.id}/event/${this.selectEvent.id}`, request: { status: StatusEnum.Delete }, response: res };
            this.commonService.activityLog(ActionEnum.Delete, `Sign Control ➡ ป้ายแสดงผล ➡ Sign setting ➡ Event`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `ลบ Event สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getEvent(this.boardsignInfo.id, this.selectsignItem.id);
            }, 100);
            this.deleteSignActionModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {
          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  bucket_name = 'sign-control';
  path = 'event';
  async addEventAsyn(is_add?: boolean) {
    let f = [this.background.file];
    if (is_add) {
      this.res_message = 'เพิ่ม event ไม่สำเร็จ';
    } else {
      this.res_message = 'แก้ไข event ไม่สำเร็จ';
    }
    await this.uploadService.uploadImages(f, this.bucket_name, this.path).toPromise().then((res) => {
      if (res) {
        if (res.data) {
          if (is_add) {
            this.addEvent(res.data.img_urls[0]);
          } else {
            this.editEvent(res.data.img_urls[0]);
          }
        } else {
          setTimeout(() => {
            this.swalError.fire();
            this.is_loading = false;
          }, 1000);
        }
      } else {
        setTimeout(() => {
          this.swalError.fire();
          this.is_loading = false;
        }, 1000);
      }
    }
    ).catch(error => {
      setTimeout(() => {
        this.swalError.fire();
        this.is_loading = false;
      }, 1000);
    });
  }
  async editEventAsyn() {
    if (this.background.file) {
      await this.addEventAsyn(false);
    } else {
      this.editEvent(this.background.url);
    }
  }
  getSignalName(event_type1?: number, class1?: number, lane1?: number, rain1?: number, is_add?: boolean) {
    let array = [];
    let e_type = this.event_type_list.find(x => x.id == event_type1);
    if (e_type)
      array.push(e_type.name);
    let v_type = this.veh_type_list.find(x => x.id == class1);
    if (v_type)
      array.push(v_type.name);
    if (lane1)
      array.push(`เลน${lane1}`);
    let r_type = this.rain_type_list.find(x => x.id == rain1);
    if (r_type)
      array.push(`ฝน${r_type.name}`);
    if (array.length > 0) {
      return array.join('-');
    } else {
      if (!is_add) {
        return '-';
      } else {
        return '';
      }
    }
  }
  getEvent(boardsign_id?: number, sign_id?: number) {
    this.selectSignEvents = [];
    this.signSettingService.getEventByBoardsign(boardsign_id, sign_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              if (res.data.length > 0) {
                this.selectSignEvents = res.data;
                this.selectSignEvents.forEach(item => {
                  if (item.signal) {
                    item.name = this.getSignalName(item.signal.event_type, item.signal.class, item.signal.lane, item.signal.rain, false);
                  }
                });
              }
            }
          }
        }
      },
      error => { });
  }
  acceptedImageType = ['.jpeg', '.png', '.jpg', '.gif'];
  detectFiles(event) {
    let acceptedType = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        if (acceptedType.includes(file.type)) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            this.background.file = file;
            this.background.dataUrl = e.target.result;
          }
          reader.readAsDataURL(file);
        } else {
          const error_text = "รองรับไฟล์นามกุล JPEG,PNG,JPG,GIF เท่านั้น";
          this.res_message = error_text;
          this.is_loading = false
          setTimeout(() => { this.swalError.fire() }, 500);
        }
      }
    }
  }
  clearDataImage() {
    if (this.imInputVariable) {
      this.imInputVariable.nativeElement.value = "";
    }
  }
  //----------------------------------SMC---------------------------------//
  selectSmcDevice: DeviceInfo;
  openSensorSignalModal() {
    this.getConfigRoadByDevice(this.boardsignInfo.sensor_id);
    this.selectSmcLane = 1;
    this.sensorSignalModal.show();
  }
  clickCloseSensorSignalModal() {
    this.sensorSignalModal.hide();
  }

  openSensorBoardModal() {
    this.select_sensor = null;
    if (this.boardsignInfo.sensor_id) {
      this.select_sensor = this.sensor_list.find(x => x.device_id == this.boardsignInfo.sensor_id);
    }
    this.matchSensorModal.show();
  }
  clickCloseSensorBoardModal() {
    this.matchSensorModal.hide();
  }
  getSmcByStation(station_id?: number) {
    this.sensor_list = [];
    this.signSettingService.getSmc(station_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              if (res.data.length > 0) {
                this.sensor_list = res.data;
              }
            }
          }
        }
      },
      error => { });
  }
  matchSensor() {
    this.is_loading = true;
    this.signSettingService.matchSmc(this.boardsignInfo.id, this.select_sensor.device_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { request: { device_id: this.select_sensor.device_id}, response: res, url: `${this.boardsignService.domain}/boardsign/${this.boardsignInfo.id}/matchSMC` };
            this.commonService.activityLog(ActionEnum.Edit, `Sign Control ➡ ป้ายแสดงผล ➡ Sign setting ➡ เลือก Sensor`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `จับคู่ป้ายกับ Sensor สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.boardsignInfo.sensor_id = this.select_sensor.device_id;
              this.boardsignInfo.sensor_name = this.select_sensor.name;
            }, 100);
            this.matchSensorModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  getSmcDevice(device_id?: number) {
    this.selectSmcDevice = null;
    this.deviceDetail = {
      route_id: null,
      station_name: null,
      type_name: null,
      model_name: null,
      km: null,
      m: null,
      stauts_name: null,
      subtype_name: null
    }
    this.deviceService.getSmcDevice(device_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              if (res.data.data) {
                if (res.data.data.length > 0) {
                  this.selectSmcDevice = res.data.data[0];
                  this.deviceDetail.route_id = this.boardsignInfo.station.route_id;
                  this.deviceDetail.station_name = this.selectSmcDevice.station_str;
                  this.deviceDetail.type_name = this.selectSmcDevice.device_type_str;
                  this.deviceDetail.model_name = this.selectSmcDevice.num_class;
                  this.deviceDetail.km = this.boardsignInfo.station.km;
                  this.deviceDetail.m = this.boardsignInfo.station.m;
                  this.deviceDetail.subtype_name = this.selectSmcDevice.device_sub_type_str;
                  if (this.selectSmcDevice.status != null) {
                    let sta = this.status_list.find(x => x.id == this.selectSmcDevice.status);
                    if (sta)
                      this.deviceDetail.stauts_name = sta.name;
                  }
                  this.veh_type_list = [];
                  if (this.selectSmcDevice.veh_class.length > 0)
                    this.selectSmcDevice.veh_class.forEach(item => {
                      this.veh_type_list.push({ id: item.veh_class_id, name: item.veh_class_name });
                    });
                }
              }
            }
          }
        }
      },
      error => { });
  }
  //---------------------------------Traffic Tool---------------------------------------//
  values: any[] = [];
  values_list: any[];
  deviceDetail: any = {
    route_id: null,
    station_name: null,
    type_name: null,
    model_name: null,
    km: null,
    m: null,
    stauts_name: null,
    subtype_name: null
  };
  pvr: boolean = true;;
  laneList = [1, 2, 3, 4, 5, 6, 7, 8];
  dirList = [
    { id: 1, name: "หน้า" },
    { id: 2, name: "หลัง" },
    { id: 3, name: "ไหล่ทาง" }
  ];
  roadList = [
    { id: 1, name: "ทางหลัก (MD)" },
    { id: 2, name: "ทางขนาน (FT)" }
  ];
  lane_total: number = 4;
  dir_all: any;
  road_type: any;
  min_heading: number;
  max_heading: number;
  selectSmcLane: number;
  checkDegree(evt) {
    let min: number = Number(evt.target.min)
    let max: number = Number(evt.target.max)
    let val: number = Number(evt.target.value)
    if (val < min) evt.target.value = evt.target.min
    if (val > max) evt.target.value = evt.target.max
    return +evt.target.value
  }
  changeLaneTab(l?: number) {
    this.selectSmcLane = l;
  }
  changeLaneTotal(e) {
    this.genConfigAllLane();
    if (this.lane_total < this.selectSmcLane) {
      this.selectSmcLane = 1;
    }
  }
  addConfigRoad() {
    this.values_list.forEach(item => {
      item.pvr_stream = this.pvr;
      if (this.min_heading != null) {
        item.min_heading = this.min_heading;
      } else {
        item.min_heading = null;
      }
      if (this.max_heading != null) {
        item.max_heading = this.max_heading;
      } else {
        item.max_heading = null;
      }
      if (this.dir_all != null) {
        item.direction = this.dir_all.id;
      } else {
        item.direction = null;
      }
      if (this.road_type != null) {
        item.road_type = this.road_type.id;
      } else {
        item.road_type = null;
      }
      item.lane_total = this.lane_total;
    });
    console.log(this.values_list);

    this.signSettingService.addConfigRoad(this.boardsignInfo.sensor_id, this.values_list).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { request: { config: this.values_list }, response: res, url: `${this.deviceService.domain}/device/${this.boardsignInfo.sensor_id}/smc-config-road` };
            this.commonService.activityLog(ActionEnum.Edit, `Sign Control ➡ ป้ายแสดงผล ➡ Sign setting ➡ Sensor Signal`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `แก้ไข Config ของ Sensor สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getConfigRoadByDevice(this.boardsignInfo.sensor_id);
            }, 100);
            //this.sensorSignalModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  genConfigAllLane() {
    this.values_list = [];

    for (let i = 0; i < this.lane_total; i++) {
      let v_lane = this.values.find(x => x.lane_no == (i + 1));
      v_lane.signal_wrong_way_ = (v_lane.signal.wrong_way) ? true : false;
      v_lane.signal_over_speed_ = (v_lane.signal.over_speed) ? true : false;
      v_lane.signal_direction_ = (v_lane.signal.direction) ? true : false;
      if (v_lane.veh_class) {
        if (!v_lane.veh_class[0].id) {
          v_lane.veh_class = _.cloneDeep(this.values[0].veh_class);
          v_lane.veh_class.forEach(item => {
            item.normal_speed = null;
            item.rain_suggest_speed = null;
            item.rain_speed = null;
            item.suggest_speed = null;
            item.allow = true;
          });
          v_lane.signal_wrong_way_ = false;
          v_lane.signal_over_speed_ = false;
          v_lane.signal_direction_ = false;
          v_lane.signal.wrong_way = false;
          v_lane.signal.over_speed = false;
          v_lane.signal.direction = 0;
          v_lane.queue_length_trigger_allow = false;
          v_lane.queue_length_trigger = null;
        }
      }
      this.values_list.push(v_lane);
    }

  }
  setSmcLaneData() {
    if (this.values.length > 0) {
      this.lane_total = this.values[0].lane_total ? this.values[0].lane_total : 4;
    }
    this.genConfigAllLane();
    setTimeout(() => {
      if (this.values_list.length > 0) {
        this.pvr = this.values_list[0].pvr_stream;

        this.min_heading = this.values_list[0].min_heading;
        this.max_heading = this.values_list[0].max_heading;
        if (this.values_list[0].direction) {
          this.dir_all = this.dirList.find(x => x.id == this.values_list[0].direction);
        }
        if (this.values_list[0].road_type) {
          this.road_type = this.roadList.find(x => x.id == this.values_list[0].road_type);
        } else {
          this.road_type = this.roadList.find(x => x.id == 1);
        }
      }
    }, 500);
  }
  async getConfigRoadByDevice(device_id?: number) {
    let veh_class: any[] = []
    this.values = []
    for (const veh of this.selectSmcDevice.veh_class) {
      veh_class.push({
        class_no: veh.veh_class_id,
        allow: true,
        veh_class_name: veh.veh_class_name,
        suggest_speed: null,
        normal_speed: null,
        rain_suggest_speed: null,
        rain_speed: null,
      });
    }
    for (let i = 1; i <= (this.laneList.length); i++) {
      this.values.push({
        lane_no: i,
        min_heading: null,
        max_heading: null,
        veh_class: veh_class,
        lane_total: null,
        direction: null,
        road_type: null,
        pvr_stream: null,
        queue_length_trigger: null,
        queue_length_trigger_allow: false,
        signal: {
          wrong_way: false,
          over_speed: false,
          direction: 0,
        },
      })
    }
    let res = await this.signSettingService.getConfigRoad(device_id).toPromise();
    if (res) {
      if (res.data) {
        if (res.data.length !== 0) {
          this.config_road = _.cloneDeep(res.data);
          this.values.forEach(item => {
            let find_lane = res.data.find(x => x.lane_no == item.lane_no);
            if (find_lane) {
              item.min_heading = find_lane.min_heading;
              item.max_heading = find_lane.max_heading;
              item.queue_length_trigger = find_lane.queue_length_trigger;
              item.queue_length_trigger_allow = find_lane.queue_length_trigger_allow;
              item.veh_class = find_lane.veh_class;
              item.lane_total = find_lane.lane_total;
              item.direction = find_lane.direction;
              item.road_type = find_lane.road_type;
              item.pvr_stream = find_lane.pvr_stream;
              item.signal = find_lane.signal;
            }
          });
        }
      }

    }
    setTimeout(() => {
      this.setSmcLaneData();
    }, 500);
  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }
  switchChange(e, lane) {
    this.values_list.forEach(item => {
      if (item.lane_no == lane.lane_no) {
        item.signal_direction_ = e;
        item.signal.direction = (e) ? 1 : 0;
      }
    });
  }
  wrongWayChange(e, lane) {
    this.values_list.forEach(item => {
      if (item.lane_no == lane.lane_no) {
        item.signal_wrong_way_ = e;
        item.signal.wrong_way = (e) ? true : false;
      }
    });
  }
  overSpeedChange(e, lane) {
    this.values_list.forEach(item => {
      if (item.lane_no == lane.lane_no) {
        item.signal_over_speed_ = e;
        item.signal.over_speed = (e) ? true : false;
      }
    });
  }
  queueallowChange(e, lane) {
    this.values_list.forEach(item => {
      if (item.lane_no == lane.lane_no) {
        item.queue_length_trigger_allow = e;
      }
    });
  }
  //---------------------------------Special items--------------------------------------//
  text_speed_limit = 100;
  text_speed_suggest = 90;
  text_speed_sensor = 80;
  imageSpecialItems: MediaInfo[] = [];
  //imageSpecialItem:MediaInfo;
  img_h = null;
  img_w = null;
  img_type = null;
  img_content_type = null;
  img_file: File = null;
  img_dataUrl: string = null;
  img_url: string = null;

  sign_type_modal: any;
  status_modal: any;
  use_type_modal: any;

  is_loading_add: boolean = false;

  imageSpecialItem: MediaInfo = {
    name: null
  };
  im_select: any;
  media_sign_height: number = 100;
  use_type_list = [
    { id: 1, name: "ประกาศ" },
    { id: 2, name: "แจ้งเตือน" }
  ];
  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" }
  ];
  select_font_color_special: any;
  select_font_weight_special: any;
  select_font_family_special: any;
  select_text_type_special: any;
  select_text_lane_special: any;
  select_text_class_special: any;
  specialTextItems: SpecialTextItem[] = [];
  specialTextItem: SpecialTextItem = {
    id: null,
    idd: null,
    text_type: SpecialTextType.FreeText,
    text: 'ข้อความ',
    font_size: 30,
    color: 'White',
    font_family: 'Arial',
    font_weight: 'Bold',
    x: 0,
    y: 0,
    is_center: true
  };
  openSpecialItemsModal(sign: SignInfo) {
    this.getImageSpecialItems(sign.id);
    this.signSpecialItemModal.show();
  }
  openAddSpecialItemsModal() {
    console.log(this.selectsignItem);
    this.imageSpecialItem = {
      name: null
    };
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.addSpecialItemModal.show();
  }
  openEditSpecialItemsModal(item?: MediaInfo) {
    console.log(this.selectsignItem);
    this.img_url = item.path;
    this.img_type = item.media_type;
    this.img_content_type = item.content_type;
    this.img_h = item.width;
    this.img_w = item.height;
    this.imageSpecialItem = item;
    this.imageSpecialItem = {
      name: null
    };
    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.addSpecialItemModal.show();
  }

  getImageSpecialItems(sign_id?: number) {
    this.imageSpecialItems = [];
    this.mediaService.getImage(null, null, null, null, null, null, null, null, null, sign_id, MediaBehaviorType.Special).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              if (res.data.data.length > 0) {
                this.imageSpecialItems = res.data.data;
                console.log(this.imageSpecialItems);

              }
            }
          }
        }
        this.is_loading = false;
      },
      error => { this.is_loading = false; });
    console.log(this.imageSpecialItems);

  }

  openAddImageModal() {
    this.imageSpecialItem = {
      name: null
    };
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.addSpecialItemModal.show();
  }
  detectFiles2(event) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        this.img_file = file;
        if (file.type == 'image/gif') {
          this.img_type = MediaType.Gif;
        } else {
          this.img_type = MediaType.Image;
        }
        this.img_content_type = file.type.replace('image/', '.')
        if (this.img_content_type == '.jpeg')
          this.img_content_type = '.jpg';

        let reader = new FileReader();
        reader.onload = (e: any) => {
          let img = new Image();
          img.src = e.target.result;
          this.img_dataUrl = e.target.result;
          img.onload = () => {
            this.img_w = img.width;
            this.img_h = img.height;
          };

        }
        reader.readAsDataURL(file);
      }
    }
  }
  closeAddImage() {
    this.img_h = null;
    this.img_w = null;
    this.img_type = null;
    this.img_content_type = null;
    this.img_dataUrl = null;
    this.imInputVariable2.nativeElement.value = "";
    this.img_file = null;
  }
  addImage(url?: string) {
    this.is_loading = true;
    let req = new ReqAddImage();
    req.name = this.imageSpecialItem.name;
    req.img_url = url;
    req.sign_type_id = this.selectsignItem.sign_type_id;
    req.sign_id = this.selectsignItem.id;
    req.boardsign_id = this.boardsignInfo.id;
    if (this.use_type_modal) {
      req.use_type = this.use_type_modal.id;
    }
    req.width = parseInt(this.img_w.toString());
    req.height = parseInt(this.img_h.toString());
    req.content_type = this.img_content_type;
    req.media_type = this.img_type;

    req.status = this.status_modal.id;
    req.behavior_type = MediaBehaviorType.Special;

    let text: SpecialTextItem[] = [];
    this.specialTextItems.forEach(item => {
      item.idd = null;
      text.push(item);
    });
    console.log(text);

    this.res_message = "";
    this.mediaService.addImage(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { request: req, response: res, url: `${this.mediaService.domain}/image`, text:text };
            this.commonService.activityLog(ActionEnum.Add, `Sign Control ➡ ป้ายแสดงผล ➡ Sign setting ➡ Specials items`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            // this.res_message = `เพิ่มรูป Special สำเร็จ`;
            // setTimeout(() => {
            //   this.swalSuccess.fire();
            //   this.is_loading = false;
            //   this.getImageSpecialItems(this.selectsignItem.id);
            // }, 100);
            // this.addSpecialItemModal.hide();
            this.addSpecialTextItems(text, res.data.id);
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  addSpecialTextItems(req?: SpecialTextItem[], image_id?: number) {
    this.mediaService.addImageTexts(req, image_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `เพิ่มรูป Special สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getImageSpecialItems(this.selectsignItem.id);
            }, 100);
            this.addSpecialItemModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editImage(url?: string) {
    this.is_loading = true;
    let req = new ReqAddImage();
    req.name = this.imageSpecialItem.name;
    req.img_url = url;
    req.sign_type_id = this.selectsignItem.sign_type_id;
    req.sign_id = this.selectsignItem.id;
    req.boardsign_id = this.boardsignInfo.id;
    if (this.use_type_modal) {
      req.use_type = this.use_type_modal.id;
    }
    req.width = parseInt(this.img_w.toString());
    req.height = parseInt(this.img_h.toString());
    req.content_type = this.img_content_type;
    req.media_type = this.img_type;

    req.status = this.status_modal.id;
    req.behavior_type = MediaBehaviorType.Special;
    this.res_message = "";
    this.mediaService.editImage(req, this.imageSpecialItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { request: req, response: res, url: `${this.mediaService.domain}/image/${this.imageSpecialItem.id}` };
            this.commonService.activityLog(ActionEnum.Edit, `Sign Control ➡ ป้ายแสดงผล ➡ Sign setting ➡ Specials items`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `แก้ไขรูป Special สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getImageSpecialItems(this.selectsignItem.id);
            }, 100);
            this.addSpecialItemModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  bucket_name_image = 'sign-control';
  path_image = 'media';
  async addImageAsyn() {
    let f = [this.img_file];

    this.res_message = 'เพิ่มรูป Special ไม่สำเร็จ';
    await this.uploadService.uploadImages(f, this.bucket_name_image, this.path_image).toPromise().then((res) => {
      if (res) {
        if (res.data) {
          this.addImage(res.data.img_urls[0]);
        } else {
          setTimeout(() => {
            this.swalError.fire();
            this.is_loading = false;
          }, 1000);
        }
      } else {
        setTimeout(() => {
          this.swalError.fire();
          this.is_loading = false;
        }, 1000);
      }
    }
    ).catch(error => {
      setTimeout(() => {
        this.swalError.fire();
        this.is_loading = false;
      }, 1000);
    });
  }
  async editImageAsyn() {

  }
  openDeleteImageModal(im: MediaInfo) {
    this.im_select = im;
    this.deleteMediaModal.show();
  }

  OpenConfirmDeleteMediaModal() {
    this.confirmDeleteMediaModal.show();
    this.deleteMediaModal.hide();
  }
  medie_sign_width(ratio: string, set_sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return set_sign_height * r;
    } else {
      return 0;
    }
  }
  getSignNameTextStyles(w?: number) {
    let styles = {
      'width': w + 'px',
      'max-width': w + 'px',
      'white-space': 'nowrap',
      'overflow': 'hidden',
      'text-overflow': 'ellipsis'
    };
    return styles;
  }
  handleValueStatusModal(value) {
    if (value) {
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  handleValueUseTypeModal(value) {
    if (value) {
      this.use_type_modal = value;
    } else {
      this.use_type_modal = null;
    }
  }
  deleteImage(img: MediaInfo) {
    this.is_loading = true;
    let req = new ReqAddImage();
    req.status = StatusEnum.Delete;
    this.mediaService.deleteImageEditStatus(req, img.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { request: req, response: res, url: `${this.mediaService.domain}/image/${img.id}` };
            this.commonService.activityLog(ActionEnum.Delete, `Sign Control ➡ ป้ายแสดงผล ➡ Sign setting ➡ Specials items`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `ลบรูป Special สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getImageSpecialItems(this.selectsignItem.id);
            }, 100);
            this.confirmDeleteMediaModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  //text item
  openAddTextItemSpecial() {
    //this.specialTextItems = [];
    this.specialTextItem = {
      id: null,
      idd: null,
      text_type: SpecialTextType.FreeText,
      text: 'ข้อความ',
      font_size: 30,
      color: 'White',
      font_family: 'Arial',
      font_weight: 'Bold',
      x: 0,
      y: 0,
      is_center: true
    }
    this.select_text_type_special = this.text_type_list.find(x => x.id == 1);
    this.select_font_family_special = this.font_family_list.find(x => x.id == "Arial");
    this.select_font_weight_special = this.font_weight_list.find(x => x.id == "Bold");
    this.select_font_color_special = 'White';
    if (this.selectsignItem.cover_lane) {
      let lanes = this.selectsignItem.cover_lane.split('|');
      if (lanes.length > 0) {
        this.select_text_lane_special = this.lane_list.find(x => x == +lanes[0]);
      }
    }
    let v_class = this.veh_type_list.find(x => x.name.includes('รถยนต์'));
    if (v_class) {
      this.select_text_class_special = v_class.id;
    }

    this.addSpecialTextItemModal.show();
  }
  openEditTextItemSpecial(item?: SpecialTextItem) {
    this.specialTextItem = _.cloneDeep(item);
    this.select_text_type_special = this.text_type_list.find(x => x.id == item.text_type);
    this.select_font_family_special = this.font_family_list.find(x => x.id == item.font_family);
    this.select_font_weight_special = this.font_weight_list.find(x => x.id == item.font_weight);
    this.select_font_color_special = this.font_color_list.find(x => x == item.color);
    if (this.select_text_type_special.id != SpecialTextType.FreeText) {
      this.select_text_lane_special = item.lane;
      this.select_text_class_special = this.veh_type_list.find(x => x.id == item.class);
    }
    this.addSpecialTextItemModal.show();
  }
  clearTextItemSpecial() {

  }
  deleteTextItemSpecial(item?: SpecialTextItem) {
    const index: number = this.specialTextItems.indexOf(item);
    if (index !== -1) {
      this.specialTextItems.splice(index, 1);
    }
  }
  addTextItemsSpecial() {
    this.specialTextItem.text_type = this.select_text_type_special.id;
    this.specialTextItem.font_family = this.select_font_family_special.id;
    this.specialTextItem.font_weight = this.select_font_weight_special.id;
    if (this.specialTextItem.text_type != SpecialTextType.FreeText) {
      this.specialTextItem.lane = this.select_text_lane_special;
      this.specialTextItem.class = this.select_text_class_special.id;
    }
    let text = this.specialTextItem;
    text.idd = this.specialTextItems.length + 1;
    text.color = this.select_font_color_special;
    console.log(this.select_text_type);
    console.log(this.select_font_family);
    console.log(this.select_font_weight);
    console.log(text);
    this.specialTextItems.push(text);
    this.addSpecialTextItemModal.hide();
  }
  editTextItemsSpecial(e?: SpecialTextItem) {
    console.log(e);
    this.specialTextItems.forEach(item => {
      if (item.idd == e.idd) {
        item.text = e.text;
        item.id = e.id;
        item.color = this.select_font_color_special;
        item.font_family = this.select_font_family_special.id;
        item.font_size = e.font_size;
        item.font_weight = this.select_font_weight_special.id;
        item.is_center = e.is_center;
        item.text_type = this.select_text_type_special.id;
        item.x = e.x;
        item.y = e.y;
        if (item.text_type != SpecialTextType.FreeText) {
          item.lane = this.select_text_lane_special;
          item.class = this.select_text_class_special.id;
        }
      }
    });
    console.log(this.specialTextItems);
    this.addSpecialTextItemModal.hide();
  }
  handleValueSpecialTextLane(value) {
    if (value) {
      this.select_text_lane_special = value;
    } else {
      this.select_text_lane_special = null;
    }
  }
  handleValueSpecialTextVehClass(value) {
    if (value) {
      this.select_text_class_special = value;
    } else {
      this.select_text_class_special = null;
    }
  }
  handleValueSpecialTextType(value) {
    if (value) {
      this.select_text_type_special = value;
    } else {
      this.select_text_type_special = null;
    }
  }
  handleValueSpecialFontFamily(value) {
    if (value) {
      this.select_font_family_special = value;
    } else {
      this.select_font_family_special = null;
    }
  }
  handleValueSpecialFontWeight(value) {
    if (value) {
      this.select_font_weight_special = value;
    } else {
      this.select_font_weight_special = null;
    }
  }
  handleValueSpecialFontColor(value) {
    if (value) {
      this.select_font_color_special = value;
    } else {
      this.select_font_color_special = null;
    }
  }
  //--------------------------------Suggest Management-------------------------------------//
  inc_type_list: any[] = [];
  select_inc_type: any;
  select_sign_suggest: any;
  select_criteria: BoardSuggestCriteria;
  board_criteria_list: BoardSuggestCriteria[] = [];
  openSuggestManagementModal() {
    this.getIncType();
    this.signSuggestModal.show();
  }
  handleValueIncType(value) {
    if (value) {
      this.select_inc_type = value;
      this.getSuggest(this.boardsignInfo.id, this.select_inc_type.id);
    } else {
      this.select_inc_type = null;
      this.board_criteria_list = [];
    }
  }
  getIncType() {
    this.select_inc_type = null;
    this.inc_type_list = [];
    this.signSettingService.getSuggestIncType().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.inc_type_list = res.data;
              if (this.inc_type_list.length > 0) {
                this.select_inc_type = this.inc_type_list[0];
                this.getSuggest(this.boardsignInfo.id, this.select_inc_type.id);
              }

            }
          }
        }
      },
      error => { });
  }
  getSuggest(boardsign_id?: number, incident_type?: number) {
    this.board_criteria_list = [];
    this.signSettingService.getSuggestManagement(boardsign_id, incident_type).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.board_criteria_list = res.data.criteria;
              this.board_criteria_list.forEach((item, index) => {
                item.index = index + 1;
                if (item.signs) {
                  item.signs = item.signs.filter(x => x.status == StatusEnum.Active);
                  if (item.signs.length > 0 && this.boardsignInfo.station.boardsign_structure) {
                    // layers
                    //const layers_text = this.boardsignInfo.station.boardsign_structure.split("|");
                    const layer_no_list = _.sortedUniq(item.signs.map(a => a.layer_no));
                    let layers = [];
                    layer_no_list.forEach((l, index) => {
                      // signs
                      let signs = [];
                      let find_sign = item.signs.filter(x => x.layer_no == l);

                      if (find_sign) {
                        find_sign.forEach(s => {
                          if (s.media) {
                            let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: s.media };
                            signs.push(d);
                          } else {
                            let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: null };
                            signs.push(d);
                          }
                        });
                        signs = _.sortBy(signs, ['sign_no']);
                        layers.push(signs);
                      }

                    });
                    item.layers = layers;
                  } else {
                    item.signs = [];
                    item.layers = [];
                  }
                }
              });
            }
          }
        }
      },
      error => { });
  }
  addCriteriaButton() {
    let cri = new BoardSuggestCriteria();
    cri.id = null;
    cri.index = this.board_criteria_list.length + 1;
    cri.status = 1;
    cri.boardsign_id = this.boardsignInfo.id;
    cri.create_date = new Date();
    cri.incident_type = this.select_inc_type.id;

    // layers
    //const layers_text = this.boardsignInfo.station.boardsign_structure.split("|");
    this.boardsignInfo.signs = this.boardsignInfo.signs.filter(x => x.status == StatusEnum.Active);
    const layer_no_list = _.sortedUniq(this.boardsignInfo.signs.map(a => a.layer_no));
    let layers = [];
    layer_no_list.forEach((l, index) => {
      // signs
      let signs = [];
      let find_sign = this.boardsignInfo.signs.filter(x => x.layer_no == l);

      if (find_sign) {
        find_sign.forEach(s => {
          let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: null };
          signs.push(d);
        });
        signs = _.sortBy(signs, ['sign_no']);
        layers.push(signs);
      }
    });
    cri.layers = layers;
    this.board_criteria_list.push(cri);
  }
  deleteCriteriaButton(e?: BoardSuggestCriteria) {
    this.board_criteria_list.forEach(item => {
      if (item.index == e.index) {
        item.status = 0;
      }
    });
  }
  clickSignSuggest(sign?: SignInfo, criteria?: BoardSuggestCriteria) {
    this.select_criteria = criteria;
    this.select_sign_suggest = sign;
    this.mediaListSuggestModal.show();
    console.log(this.select_criteria);

  }
  getNum(item?: BoardSuggestCriteria) {
    let find = this.board_criteria_list.filter(x => x.index < item.index && x.status == StatusEnum.Active);
    return find.length + 1;
  }
  getDisableBt() {
    let find_null = this.board_criteria_list.filter(x => x.probability == null && x.status == StatusEnum.Active);
    let find = this.board_criteria_list.filter(x => x.status == StatusEnum.Active);
    find.forEach(item => {
      if (item.probability) {
        item.probability = +item.probability;
      }
    });
    let sum = _.sumBy(find, 'probability');
    if (find_null.length > 0 || sum != 100) {
      return true;
    } else {
      return false;
    }
  }
  async saveCriteria() {
    const add = this.board_criteria_list.filter(x => x.id == null && x.status == StatusEnum.Active);
    const edit = this.board_criteria_list.filter(x => x.id != null);
    console.log(edit);
    let req_add = [];
    let req_edit = [];
    if (add.length > 0 && edit.length > 0) {
      req_add = await this.genMediaReq(add);
      req_edit = await this.genMediaReq(edit);
      this.saveUpdateCriteria(req_add, req_edit);
    } else if (add.length > 0) {
      req_add = await this.genMediaReq(add);
      this.saveAddCriteria(req_add);
    } else if (edit.length > 0) {
      req_edit = await this.genMediaReq(edit);
      this.saveEditCriteria(req_edit);
    }
    console.log(req_add);
    console.log(req_edit);
    console.log(JSON.stringify(req_edit));
  }
  async genMediaReq(list?: BoardSuggestCriteria[]) {
    let reqs: ReqAddSuggest[] = [];
    list.forEach(c => {
      let req = new ReqAddSuggest();
      let signs: ReqSignMedia[] = [];
      c.layers.forEach(l => {
        l.forEach(s => {
          let sign = new ReqSignMedia();
          sign.sign_id = s.id;
          if (s.media) {
            sign.media_type = s.media.media_type;
            sign.media_id = s.media.id;
          }
          signs.push(sign);
        });
      });
      if (c.id) {
        req.criteria_id = c.id;
      }
      if (c.probability != null) {
        req.probability = +c.probability;
      }
      req.boardsign_id = c.boardsign_id;
      req.status = c.status;
      req.incident_type = c.incident_type;
      req.signs = signs;
      reqs.push(req);
    });
    return reqs;
  }
  async saveUpdateCriteria(req_add?: ReqAddSuggest[], req_edit?: ReqAddSuggest[]) {
    const addCri = this.signSettingService.addSuggest(req_add);
    const editCri = this.signSettingService.editSuggest(req_edit);
    const x = forkJoin([addCri, editCri]).pipe(
      map(([add, edit]) => {
        // forkJoin returns an array of values, here we map those values to an object
        return { add, edit };
      })
    ).subscribe(res => {
      if (res) {
        if (res.add.code === 1 && res.edit.code === 1) {
          const log_req_data = { request: { add: req_add, edit: req_edit}, response: res, url: `${this.boardsignService.domain}/boardsign/suggest/criteria` };
          this.commonService.activityLog(ActionEnum.Edit, `Sign Control ➡ ป้ายแสดงผล ➡ Sign setting ➡ Suggest Management`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
          this.res_message = `แก้ไขสำเร็จ`;
          setTimeout(() => {
            this.swalSuccess.fire();
            this.is_loading = false;
            this.getSuggest(this.boardsignInfo.id, this.select_inc_type.id);
          }, 100);
        } else {
          this.res_message = res.add.message;
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      } else {
        setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
      }
    },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  saveAddCriteria(req?: ReqAddSuggest[]) {
    this.is_loading = true;
    this.signSettingService.addSuggest(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { request: req, response: res, url: `${this.boardsignService.domain}/boardsign/suggest/criteria` };
            this.commonService.activityLog(ActionEnum.Add, `Sign Control ➡ ป้ายแสดงผล ➡ Sign setting ➡ Suggest Management`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `เพิ่มสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getSuggest(this.boardsignInfo.id, this.select_inc_type.id);
            }, 100);
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  saveEditCriteria(req?: ReqAddSuggest[]) {
    this.is_loading = true;
    this.signSettingService.editSuggest(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { request: req, response: res, url: `${this.boardsignService.domain}/boardsign/suggest/criteria` };
            this.commonService.activityLog(ActionEnum.Edit, `Sign Control ➡ ป้ายแสดงผล ➡ Sign setting ➡ Suggest Management`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `แก้ไขสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getSuggest(this.boardsignInfo.id, this.select_inc_type.id);
            }, 100);
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  closeMediaModalChangeMediaNew(e) {
    if (e) {
      this.board_criteria_list.forEach(c => {
        if (c.index == this.select_criteria.index) {
          c.layers.forEach(l => {
            l.forEach(s => {
              if (s.id == this.select_sign_suggest.id) {
                s.media = e;
                if (e.media_type != MediaType.Playlist) {
                  s.media.media = e;
                }
              }
            });
          });
        }
      });
    }
    this.mediaListSuggestModal.hide();
    this.select_sign_suggest = null;
  }
}
