import { Component, OnInit, Input, Pipe, PipeTransform, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-atis-image',
  templateUrl: './atis-image.component.html',
  styleUrls: ['./atis-image.component.scss']
})
export class AtisImageComponent implements OnInit, OnDestroy, OnChanges {

  @Input() link: string;
  x = 'xxx';
  src: any;
  count: any;
  interval: any = 5000;
  intervals: any[] = [];
  subscription: any;
  i: any = 0;
  link_withoutpass: any;
  heightpx: any;
  widthpx: any;
  constructor() { }
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    const n = this.link.includes('?');
    this.link_withoutpass = this.link;
    if (this.link) {
      this.src = null;
      this.src = this.link_withoutpass;
      if (n === false) {
        this.src = this.link_withoutpass + '?t=' + new Date().getTime();
      } else {
        this.src = this.link_withoutpass + '&t=' + new Date().getTime();
      }
    } else {
      this.updateUrl();
    }
  }

  updateUrl() {
    this.src = 'assets/img/camera_not_working.png';
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
