import { Component, OnInit, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DeviceService } from 'src/app/services/user/device.service';
import { EventService } from 'src/app/services/user/event.service';
import * as _ from 'lodash'
import * as moment from 'moment'
import { FuncService } from 'src/app/services/user/func.service';
// import * as Rx from 'rxjs/Rx'
// import { Rx } from 'rxjs/Rx';
@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  allowCustom: boolean = false;
  changed: boolean = false
  evtType : number = 1;
  route_list: any
  search_route: any
  route: any
  km1: number;
  m1: number;
  st_dt: any;
  src_type: number;
  veh_dmg: any = null;
  veh_dmg_type: number  = null;
  veh_dmg_list: any = []
  images_Evtdetail: any = []
  imagesAll_Evtdetail: any = []
  size_Img : number = 100
  outofImg : boolean = false
  
  event_group : number = 2
  src_type_etc: any
  evt_subg: number = 1
  evtTypeList: any = []
  evtSubTypeList: any = []
  evtConfirmList: any = []
  
  evtConfirmMenu : number = 1
  evtConfirm : number = 1

  lane : number = 1
  _Imgloading : boolean = false;
// event_group
// - event = 1
// - security event = 2
// - rain event = 3
// - vsw event = 4
  constructor(
    private devS: DeviceService,
    private detail_close: BsModalRef,
    private evt_S: EventService,
    private func: FuncService,
  ) { }

  ngOnInit(): void {
    if(!this.values) this.event_group = 1
    console.log(this.values)
    if(this.values){
      this.evtConfirm = this.values.event_confirm
      this.event_group = this.values.event_group_id
      this.imagesAll_Evtdetail = _.cloneDeep(this.values.img_evt)
      this.images_Evtdetail = _.slice(this.imagesAll_Evtdetail,0,this.size_Img)
    }
    this.getData()
    if(this.event_group == 2){
      // this.getImg()
      this.veh_dmg = 2
      this.src_type_etc = 'ตรวจพบการบุกรุก'
    }
  }
  confirm() {
    this.event.emit(true);
    this.detail_close.hide();
  }
  close(){this.detail_close.hide();}
  async save(num:number){
    let data : any = {
      event_id:this.values.id,
      event_confirm:this.evtConfirm,
    }
    switch(this.evtConfirm) {
      case 1:
      case 4:
      case 6:
        data.status = 2;data.approved = 1;
        data.status = 2;data.approved = 1;
        break;
      case 2:
        data.status = 4;data.approved = 1;
        break;
      case 3:
      case 7:
      case 5:
        data.status = 4;data.approved = 0;
        break;
      default:
    }
    let res : any = await this.evt_S.updateEvtApprove(data)
    if(res){
      if(data.status) this.values.status = data.status
      this.func.alertpopup("Success");this.event.emit({approved:data.approved});
    }
  }
  async getData() {
    this.getConfirmList();
    this.getEvtType();
    this.route_list = await this.devS.getRoute()
    this.route = this.route_list
  }
  async getConfirmList() {
    this.evtConfirmList = await this.evt_S.getEvtConfirm(this.values.event_type_id)
  }
  async getEvtType() {
    this.evtTypeList = await this.evt_S.getEvtType({event_group_id:this.event_group})
  }
  async getEvtSubType() {
    this.evtSubTypeList = await this.evt_S.getEvtSubType({event_type_id:this.veh_dmg_type})
  }
  FilterRoute(evt: any) {
    if (evt.length > 0) {
      this.route_list = this.route_list.filter(res => res.includes(evt))
    } else if (evt.length == 0) {
      this.route_list = this.route
    } else {

    }
  }
  async ChageRoute(evt) {
    // console.log(evt)
  }
  
  async rmImage(path: string) {
    let res: any = await this.evt_S.rmImages(path)
    if (res) {
      this.images_Evtdetail = _.pull(this.images_Evtdetail, path);
    }
  }
  _selectedFile: ImageSnippet;
  async uploadImg(imageInput: any, type?: any) {
    
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    // const test_data1 = this.data
    reader.addEventListener('load', async (event: any) => {
      this._selectedFile = new ImageSnippet(event.target.result, file);
      let res: any = await this.evt_S.uploadImages(this._selectedFile.file, this.values.id)
      if (res && res.img_urls.length) {
        for (const i of res.img_urls) {
          if (type == 1) {
            this.images_Evtdetail.push(i)
          } else {
            this.images_Evtdetail.push(i)
          }
        }
      }

    });

    reader.readAsDataURL(file);
  }
  getImg(){
    if(this.size_Img < this.imagesAll_Evtdetail.length){
      this.size_Img += 100
      this.images_Evtdetail = _.slice(this.imagesAll_Evtdetail,0,this.size_Img)
    }else{
      this.outofImg=true
    }
  }
  
  clickImg(path:string){
    this.func.alertpopup(`<img src="${path}" alt"event" onerror="this.src='assets/noimage.png'">`,"Image")
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height 
    if(this.event_group==2){
      if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
        this._Imgloading = true
        this.getImg();
      }
    }
  }
}

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
