import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiResponse, PagableData } from '../../models/common';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { NoteTypeInfo, ReqAddNoteType } from '../../models/information/note-type';
import { StatusEnum } from '../../models/common-enum';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoteTypeService {
  domain: string;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.domain = environment.api.domain_information;
  }
  getNoteType(name?: string, status?: number, st_time?: string, en_time?: string, page?: number, limit?: number, short?: number) {
    let params = new HttpParams();
    if (!_.isNil(page)) {
      params = params.append('page', page.toString());
    }
    if (!_.isNil(limit)) {
      params = params.append('limit', limit.toString());
    }
    if (!_.isNil(name)) {
      params = params.append('name', name.toString());
    }
    if (!_.isNil(st_time)) {
      params = params.append('st_time', st_time.toString());
    }
    if (!_.isNil(en_time)) {
      params = params.append('en_time', en_time.toString());
    }
    if (!_.isNil(status)) {
      params = params.append('status', status.toString());
    }
    if (!_.isNil(short)) {
      params = params.append('short', short.toString());
    }
    const uri = `note-type`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<NoteTypeInfo[]>>>(url, httpOptions);
    // let url = 'assets/data/note_type.json';
    // return this.http.get<ApiResponse<PagableData<NoteTypeInfo[]>>>(url);
  }
  getNoteTypeById(note_type_id?: number) {
    const uri = `note-type/${note_type_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<NoteTypeInfo[]>>(url, httpOptions);
  }
  addNoteType(req: ReqAddNoteType): Observable<ApiResponse<any>> {
    const uri = `note-type`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }

  editNoteType(req: ReqAddNoteType, note_type_id?: number): Observable<ApiResponse<any>> {
    const uri = `note-type/${note_type_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteNoteType(note_type_id: number): Observable<ApiResponse<any>> {
    const uri = `note-type/${note_type_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
  deleteNoteTypeEditStatus(req: ReqAddNoteType, note_type_id?: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `note-type/${note_type_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
}
