import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { BrandService } from '../../../services/assets-management/brand.service';
import { Brand, ReqAddBrand, BrandModel, ReqAddBrandModel } from '../../../models/assets-management/brand';
import { ActionEnum, StatusEnum, VendorContactTypeEnum } from '../../../models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { permission } from 'src/app/views/assets-management/_menu';
@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('addModalSub', { static: false }) public addModalSub: ModalDirective;
  @ViewChild('deleteModalSub', { static: false }) public deleteModalSub: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  view: number = 1; //1= brand,2=model
  statusEnum = StatusEnum;
  vendorContactTypeEnum =VendorContactTypeEnum;
  myDate = new Date();
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  totalSubItems: any = 0;
  data_list: Brand[];
  data_sub_list: BrandModel[];
  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" }
  ];
  status: any;
  selectItem: any = {
    brand_name: "",
    status: null
  };

  public allowCustom: boolean = false;
  res_message: any;
  status_modal: any;
  status_modal_sub: any;
  search_brand: any;

  selectSubItem: any = {
    model_id: null,
    model_name: "",
    status: null,
    brand_id: null
  };

  isAdd: boolean = false;
  isEdit: boolean = false;
  constructor(private commonService: CommonService, private brandService: BrandService) { 
    this.commonService.activityLog(ActionEnum.Get, `Assets Management ➡ Configuration ➡ ยี่ห้อ/รุ่น`).subscribe(res => { }, error => { console.log(error); });
  }

  async ngOnInit() {
    this.is_loading = true;
    this.getBrand();
    this.isAdd = await this.commonService.getPermission(permission.configuration.brand.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.configuration.brand.edit_id).toPromise();
  }

  handleValueStatus(value) {
    if (value) {
      //console.log(value);
      this.status = value;
    } else {
      this.status = null;
    }
  }
  is_loading: boolean = false;

  changeView(view_type?: number, brand?: Brand) {
    if (this.view != view_type) {
      this.view = view_type;
      if (view_type == 1) {
        this.getBrand();
        this.data_sub_list = [];
      } else {
        this.selectItem = brand;
        this.status_modal = this.status_list.find(x => x.id == brand.status);
        this.getBrandModel(this.selectItem.brand_id);
      }
    }

  }
  getBrand() {
    this.data_list = [];
    this.brandService.getBrand(this.currentPage, this.itemsPerPage, null, this.search_brand).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = res.data.data;
              this.totalItems = res.data.total_items;
            }else{
              this.totalItems = 0;
            }
          }else{
            this.totalItems = 0;
          }
        }else{
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
      });
  }

  getBrandById(brand_id) {
    this.data_list = [];
    this.brandService.getBrandById(brand_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.selectItem = res.data;
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  openAddModal() {
    this.selectItem = new Brand();
    this.selectItem.brand_name = "";
    this.selectItem.status = null;
    this.status_modal = null;
    this.addModal.show();
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.addModal.show();
  }
  openEditModal(item?: any) {
    this.selectItem = item;
    console.log(item);
    
    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.addModal.show();
  }
  openDeleteModal(item?: any) {
    this.selectItem = item;
    this.deleteModal.show();
  }
  clearData() {
    if(this.view == 1){
      this.selectItem = new ReqAddBrand();
    }
  }
  handleValueStatusModal(value) {
    if (value) {
      //console.log(value);
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getBrand();
  }
  search(){
    this.currentPage = 1;
    this.getBrand();
  }
  addBrand() {
    this.is_loading = true;
    let req = new ReqAddBrand();
    req.brand_name = this.selectItem.brand_name;
    req.status = this.status_modal.id;
    this.res_message = "";
    this.brandService.addBrand(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { url: `${this.brandService.domain}/${this.brandService.uri}`, request: req, response: res };
            this.commonService.activityLog(ActionEnum.Add, `Assets Management ➡ Configuration ➡ ยี่ห้อ`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `เพิ่ม ยี่ห้อ สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getBrand();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editBrand() {
    this.is_loading = true;
    let req = new ReqAddBrand();
    req.brand_name = this.selectItem.brand_name;
    req.status = this.status_modal.id;
    this.res_message = "";
    this.brandService.editBrand(req, this.selectItem.brand_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { brand_id: this.selectItem.brand_id, request: req, response: res, url: `${this.brandService.domain}/${this.brandService.uri}/${this.selectItem.brand_id}` };
            this.commonService.activityLog(ActionEnum.Edit, `Assets Management ➡ Configuration ➡ ยี่ห้อ`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `แก้ไข ยี่ห้อ สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getBrand();
              if(this.view == 2){
                this.getBrandById(this.selectItem.brand_id);
              }
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  deleteBrand() {
    this.is_loading = true;
    let req = new ReqAddBrand();
    req.brand_name = this.selectItem.brand_name;
    this.res_message = "";
    this.brandService.deleteBrandEditStatus(req, this.selectItem.brand_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.commonService.activityLog(ActionEnum.Delete, `Assets Management ➡ Configuration ➡ ยี่ห้อ`, JSON.stringify({ brand_id: this.selectItem.brand_id, url: `${this.brandService.domain}/${this.brandService.uri}/${this.selectItem.brand_id}` })).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `ลบ ยี่ห้อ สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getBrand();
            }, 100);
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  //------------------------------------------------------------//

  handleValueStatusModalSub(value) {
    if (value) {
      //console.log(value);
      this.status_modal_sub = value;
    } else {
      this.status_modal_sub = null;
    }
  }

  getBrandModel(brand_id) {
    this.data_sub_list = [];
    this.totalSubItems = 0;
    this.brandService.getBrandModel(null, null, null, brand_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_sub_list = res.data.data;
              this.totalSubItems = res.data.data.length;
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  openAddModalSub() {
    this.selectSubItem = new BrandModel();
    this.selectSubItem.model_name = "";
    this.selectSubItem.status = null;
    this.status_modal_sub = this.status_list.find(x => x.id == StatusEnum.Active);
    this.addModalSub.show();
  }
  openEditModalSub(item?: BrandModel) {
    this.selectSubItem = item;
    this.status_modal_sub = this.status_list.find(x => x.id == item.status);
    this.addModalSub.show();
  }
  openDeleteModalSub(item?: any) {
    this.selectSubItem = item;
    this.deleteModalSub.show();
  }
  clearDataSub() {
    this.selectSubItem = new ReqAddBrandModel();
  }

  addBrandModel() {
    this.is_loading = true;
    let req = new ReqAddBrandModel();
    req.model_name = this.selectSubItem.model_name;
    req.status = this.status_modal_sub.id;

    this.res_message = "";
    this.brandService.addBrandModel(req,this.selectItem.brand_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { url: `${this.brandService.domain}/${this.brandService.uri}/${this.selectItem.brand_id}/${this.brandService.uri_sub}`, request: req, response: res };
            this.commonService.activityLog(ActionEnum.Add, `Assets Management ➡ Configuration ➡ ยี่ห้อ ➡ รุ่น`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `เพิ่ม รุ่น สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getBrandModel(this.selectItem.brand_id);
            }, 100);
            this.addModalSub.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editBrandModel() {
    this.is_loading = true;
    let req = new ReqAddBrandModel();
    req.model_name = this.selectSubItem.model_name;
    req.status = this.status_modal_sub.id;
    this.res_message = "";
    this.brandService.editBrandModel(req, this.selectSubItem.model_id, this.selectItem.brand_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { brand_id: this.selectItem.brand_id, model_id: this.selectSubItem.model_id, url: `${this.brandService.domain}/${this.brandService.uri}/${this.selectItem.brand_id}/${this.brandService.uri_sub}/${this.selectSubItem.model_id}`, request: req, response: res };
            this.commonService.activityLog(ActionEnum.Edit, `Assets Management ➡ Configuration ➡ ยี่ห้อ ➡ รุ่น`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `แก้ไข รุ่น สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getBrandModel(this.selectItem.brand_id);
            }, 100);
            this.addModalSub.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  deleteBrandModel() {
    this.is_loading = true;
    let req = new ReqAddBrandModel();
    req.model_name = this.selectSubItem.model_name;
    this.res_message = "";
    this.brandService.deleteBrandModelEditStatus(req, this.selectSubItem.model_id, this.selectItem.brand_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { brand_id: this.selectItem.brand_id, model_id: this.selectSubItem.model_id, url: `${this.brandService.domain}/${this.brandService.uri}/${this.selectItem.brand_id}/${this.brandService.uri_sub}/${this.selectSubItem.model_id}`, request: req, response: res };
            this.commonService.activityLog(ActionEnum.Delete, `Assets Management ➡ Configuration ➡ ยี่ห้อ ➡ รุ่น`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `ลบ รุ่น สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getBrandModel(this.selectItem.brand_id);
            }, 100);
            this.deleteModalSub.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

}

