<div class="row p-3">
    <div class="col-lg-3 col-md-3 col-sm-6">
        <div>
            ทางหลวง :</div>
        <div class="d-flex align-items-center">
            <kendo-combobox class="w-100" [data]="data_route" [allowCustom]="allowCustom" [textField]="''"
                [valueField]="''" [(ngModel)]="route_id" (valueChange)="handleValueRoute($event)" [filterable]="true"
                (filterChange)="handleFilterRoute($event)" [placeholder]="'ทั้งหมด'">
            </kendo-combobox>
        </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
        <div>ช่วงกิโลเมตร :</div>
        <div class="d-flex align-items-center">
            <div class="flex-fill"><span class="nowrap-txt mr-1">เริ่มต้น :</span></div>
            <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly
                    maxlength="4" [(ngModel)]="start_km" [ngModelOptions]="{standalone: true}"></div>
            <div class="flex-fill ml-1 mr-1"> + </div>
            <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly
                    maxlength="3" [(ngModel)]="start_m" [ngModelOptions]="{standalone: true}"></div>
            <div class="flex-fill"><span class="nowrap-txt ml-1 mr-1">สิ้นสุด :</span></div>
            <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly
                    maxlength="4" [(ngModel)]="end_km" [ngModelOptions]="{standalone: true}"></div>
            <div class="flex-fill ml-1 mr-1"> + </div>
            <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly
                    maxlength="3" [(ngModel)]="end_m" [ngModelOptions]="{standalone: true}"></div>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6">
        <div>Station :</div>
        <div>
            <kendo-combobox class="w-100" [data]="station_search_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="sta" (valueChange)="handleValueStation($event)"
                (filterChange)="handleFilterStation($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
            </kendo-combobox>
        </div>
    </div>
    <div class="col-lg-12 col-md-12 d-flex align-items-end justify-content-end">
        <div class="mt-1 ml-1">
            <button type="button" (click)="search()" class="btn btn-primary pull-right text-white"> <i
                    class="fa fa-search"></i>
                ค้นหา </button>
        </div>
    </div>
    <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">

        <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn text-white pointer" [ngClass]="(viewType == 1)?'btn-primary':'btn-dark'">
                <input type="radio" name="options" id="option1" autocomplete="off" [value]="1" [(ngModel)]="viewType"
                    (click)="changeView(1)"> <i class="fa fa-bars mr-1"></i>List
            </label>
            <label class="btn text-white pointer" [ngClass]="(viewType == 2)?'btn-primary':'btn-dark'">
                <input type="radio" name="options" id="option1" autocomplete="off" [value]="2" [(ngModel)]="viewType"
                    (click)="changeView(2)"><i class="fa fa-map mr-1"></i> Map
            </label>
        </div>
        <div>
            <button *ngIf="isAdd" (click)="openModal()" type="button" class="btn btn-primary text-white">
                <i class="fa fa-plus"></i>
                เพิ่ม Reversible lane</button>
        </div>

        <!-- <button class="btn btn-warning" (click)="openModal()">เพิ่ม Reversible lane</button> -->
    </div>
    <!-- List view -->
    <div *ngIf="viewType == 1" class="col-12 mt-3">
        <table class="table-main table table-outline mb-0">
            <thead class="thead-dark" style="background-color: black;">
                <tr>
                    <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
                    <th class="text-center text-light table-header">Station</th>
                    <th class="text-center text-light table-header">ตำแหน่ง</th>
                    <!-- <th class="text-center text-light table-header">ประเภทย่อยอุปกรณ์</th> -->
                    <th class="text-center text-light table-header">Create date</th>
                    <th *ngIf="isEdit" class="text-center text-light table-header">Action</th>
                </tr>
            </thead>
            <tbody>

                <tr *ngIf="!is_loading&&(!data_list || data_list?.length < 1)">
                    <td colspan="6" style="height: 50px;">
                        ไม่พบข้อมูล
                    </td>
                </tr>
                <tr *ngIf="is_loading">
                    <td colspan="6" style="height: 50px;">
                        กำลังโหลด ..
                    </td>
                </tr>
                <tr *ngFor="let i of data_list | slice: (currentPage-1) * itemsPerPage : (currentPage-1) * itemsPerPage + itemsPerPage;index as idx">
                <!-- <tr *ngFor="let i of data_list;index as idx"> -->
                <td>
                    {{(idx+1)+(itemsPerPage*(currentPage-1))}}
                </td>
                <td>{{i.reversible_name||''}}</td>
                <td>{{i.km||''}} {{(i.m)? '+ '+i.m:''}}</td>
                <!-- <td>{{i.device_type_str||''}} {{(i.device_sub_type_str)? '('+i.device_sub_type_str+')':''}}</td> -->
                <td>{{i.createdate | date:'dd/MM/yyyy HH:mm:ss':'+0700'}}</td>
                <td *ngIf="isEdit" >
                    <button class="ml-1 mr-1 btn btn-warning" (click)="openModal(i)">
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                    <button class="ml-1 mr-1 btn btn-danger" (click)="rmlist(i.id)">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-footer">
                        <div class="table-footer-inside d-flex align-items-center justify-content-between">
                            <div class="ml-3 pt-3">

                                <pagination [(ngModel)]="currentPage" [totalItems]="data_list.length"
                                    [itemsPerPage]="itemsPerPage" [rotate]="false" [maxSize]="20"
                                    previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true"
                                    firstText="&laquo;" lastText="&raquo;">></pagination>

                            </div>
                            <div></div>
                            <div class="mr-3 ml-2">total: {{data_list.length||0}}</div>

                        </div>
                    </td>
                </tr>
               
            </tbody>
        </table>
    </div>
</div>

<!-- Map view -->
<div class="col-12" style="position: relative;">
    <div id="map_rv" class="map" style="height: 50vh;" [ngClass]="(viewType == 2 && !is_loading) ? 'map' : 'map-hide'"></div>

    <div *ngIf="is_loading && viewType == 2" class="lds-circle"><div></div></div>
</div>

<!-- <div class="mt-4">
    <div class="row">
        <div class="col-12">

            <div *ngIf="view == 1" class="card bg-gray-900 ">
                <div class="card-header bg-black d-flex justify-content-between align-items-center">
                    Reversible lane setting

                    <button class="btn btn-warning" (click)="openModal()">เพิ่ม Reversible lane</button>
                </div>
                <div class="card-body table-BG">
                    <table class="table table-bordered table-no-border table-hover table-outline">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Station</th>
                                <th>ตำแหน่ง</th>
                                <th>Create date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="values && no_data">
                            <tr>
                                <td colspan="5">Nodata</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="values && !no_data">

                            <tr *ngFor="let i of values;index as idx;">
                                <td>{{idx+1}}</td>
                                <td>{{i.reversible_name}}</td>
                                <td>{{i.km}}{{(i.m)? ' + '+i.m:''}}</td>
                                <td>{{i.createdate | date:'dd/MM/yyyy HH:mm:ss':'+0700'}}</td>
                                <td>
                                    <button class="btn btn-warning" (click)="openModal(i)"><i
                                            class="cui-note icons"></i></button>
                                    <button class="btn btn-danger ml-2" (click)="rmlist(i)"><i
                                            class="icon-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div [ngClass]="{'d-none': view == 1}" class="card bg-gray-900 ">
                <div class="card-header bg-black d-flex justify-content-between align-items-center">
                    Reversible lane setting
                    <button class="btn btn-warning" (click)="openModal()">เพิ่ม Reversible lane</button>
                </div>
                <div class="card-body">
                    <div id="map_rv" class="map" style="height: 50vh;"></div>
                </div>
            </div>
        </div>
    </div>
</div> -->