
<div class="modal-body text-center">
    <div class="row">
        <div class="col-12">
            <div class="ml-0 mr-0 modal-header menu-BG">
                <button class="btn menu-BT" [ngClass]="{'active': effect_group_id == 1}" (click)="effect_group_id = 1">กีดขวาง</button>
                <button class="btn menu-BT" [ngClass]="{'active': effect_group_id == 2}" (click)="effect_group_id = 2">ไม่กีดขวาง</button>
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text d-block">กีดขวางช่องทาง</p>
                </div>
                <div style="flex: 1 1 auto;">
                    <div *ngFor="let i of effList" class="input-group mb-3">
                        <div class="input-group-prepend">
                            <p class="input-group-text d-flex" style="flex-direction: column;">
                                <button class="btn btn-outline-info mb-2">
                                    <i class="icon-pencil"></i>
                                </button>
                                <button class="btn btn-outline-danger" (click)="rmEff(i.id)">
                                    <i class="icon-trash"></i>
                                </button>
                            </p>
                        </div>
                        <div class="text-left" style="flex: 1 1 auto;">
                            <label class="ml-3 src">
                                <input type="radio" name="dir{{i.id}}" id="" [value]="1" [(ngModel)]="i.direction">
                                ทุกช่องทาง
                            </label>
                            <label class="ml-3 src">
                                <input type="radio" name="dir{{i.id}}" id="" [value]="2" [(ngModel)]="i.direction">
                                ขาเข้า
                            </label>
                            <label class="ml-3 src">
                                <input type="radio" name="dir{{i.id}}" id="" [value]="3" [(ngModel)]="i.direction">
                                ขาออก
                            </label>
                            <br>
                            {{i.effect_desc}}
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">เริ่ม</p>
                                </div>
                                <kendo-combobox class="form-control" [data]="route_list" [allowCustom]="allowCustom" 
                                [suggest]="true" [(ngModel)]="i.st_route"[filterable]="true" [placeholder]="'ทั้งหมด'" 
                                (filterChange)="FilterRoute($event)"  (valueChange)="ChageRoute($event)"></kendo-combobox>
                                <div class="input-group-prepend">
                                    <p class="input-group-text">กิโลเมตร</p>
                                </div>
                                <input type="text" [(ngModel)]="i.st_km" class="form-control" placeholder="กม." numbersOnly maxlength="4">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">เมตร</p>
                                </div>
                                <input type="text" [(ngModel)]="i.st_m" class="form-control" placeholder="ม." numbersOnly maxlength="4">
                            </div>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">สิ้นสุด</p>
                                </div>
                                <kendo-combobox class="form-control" [data]="route_list" [allowCustom]="allowCustom" 
                                [suggest]="true" [(ngModel)]="i.en_route"[filterable]="true" [placeholder]="'ทั้งหมด'" 
                                (filterChange)="FilterRoute($event)"  (valueChange)="ChageRoute($event)"></kendo-combobox>
                                <div class="input-group-prepend">
                                    <p class="input-group-text">กิโลเมตร</p>
                                </div>
                                <input type="text" [(ngModel)]="i.en_km" class="form-control" placeholder="กม." numbersOnly maxlength="4">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">เมตร</p>
                                </div>
                                <input type="text" [(ngModel)]="i.en_m" class="form-control" placeholder="ม." numbersOnly maxlength="4">
                            </div>
                        </div>

                    </div>
                    <div *ngFor="let i of newList;index as idx" class="input-group mt-3">
                        <div class="input-group-prepend">
                            <p class="input-group-text">
                                <button class="btn btn-outline-info mr-2" (click)="addNewEfflist()">
                                    <i class="icon-plus"></i>
                                </button>
                            </p>
                        </div>
                        <div class="text-left" style="flex: 1 1 auto;">
                            Add ช่องทางที่ไม่สามารถใช้บริการได้
                            
                            <br>
                            <label class="ml-3 src">
                                <input type="radio" name="adddir{{idx}}" id="" [value]="1" [(ngModel)]="i.direction">
                                ทุกช่องทาง
                            </label>
                            <label class="ml-3 src">
                                <input type="radio" name="adddir{{idx}}" id="" [value]="2" [(ngModel)]="i.direction">
                                ขาเข้า
                            </label>
                            <label class="ml-3 src">
                                <input type="radio" name="adddir{{idx}}" id="" [value]="3" [(ngModel)]="i.direction">
                                ขาออก
                            </label>
                            <input type="text" class="form-control mb-3" [(ngModel)]="i.effect_desc">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">เริ่ม</p>
                                </div>
                                <kendo-combobox class="form-control" [data]="route_list" [allowCustom]="allowCustom" 
                                [suggest]="true" [(ngModel)]="i.st_route"[filterable]="true" [placeholder]="'ทั้งหมด'" 
                                (filterChange)="FilterRoute($event)"  (valueChange)="ChageRoute($event)"></kendo-combobox>
                                <div class="input-group-prepend">
                                    <p class="input-group-text">กิโลเมตร</p>
                                </div>
                                <input type="text" [(ngModel)]="i.st_km" class="form-control" placeholder="กม." numbersOnly maxlength="4">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">เมตร</p>
                                </div>
                                <input type="text" [(ngModel)]="i.st_m" class="form-control" placeholder="ม." numbersOnly maxlength="4">
                            </div>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">สิ้นสุด</p>
                                </div>
                                <kendo-combobox class="form-control" [data]="route_list" [allowCustom]="allowCustom" 
                                [suggest]="true" [(ngModel)]="i.en_route"[filterable]="true" [placeholder]="'ทั้งหมด'" 
                                (filterChange)="FilterRoute($event)"  (valueChange)="ChageRoute($event)"></kendo-combobox>
                                <div class="input-group-prepend">
                                    <p class="input-group-text">กิโลเมตร</p>
                                </div>
                                <input type="text" [(ngModel)]="i.en_km" class="form-control" placeholder="กม." numbersOnly maxlength="4">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">เมตร</p>
                                </div>
                                <input type="text" [(ngModel)]="i.en_m" class="form-control" placeholder="ม." numbersOnly maxlength="4">
                            </div>
                        </div>

                    </div>
                    <!-- <div class="input-group">
                        <div class="input-group-prepend">
                            <p class="input-group-text">
                                <button class="btn btn-outline-info mr-2">
                                    <i class="icon-plus"></i>
                                </button>
                            </p>
                        </div>
                        <div class="text-left" style="flex: 1 1 auto;">
                            Add ช่องทางที่ไม่สามารถใช้บริการได้
                            
                            <br>
                            <label class="ml-3 src">
                                <input type="radio" name="closelane" id="" [value]="1" [(ngModel)]="dir">
                                ทุกช่องทาง
                            </label>
                            <label class="ml-3 src">
                                <input type="radio" name="closelane" id="" [value]="2" [(ngModel)]="dir">
                                ขาเข้า
                            </label>
                            <label class="ml-3 src">
                                <input type="radio" name="closelane" id="" [value]="3" [(ngModel)]="dir">
                                ขาออก
                            </label>
                            <input type="text" class="form-control mb-3" value="พื้นที่ปิดช่องทาง">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">เริ่ม</p>
                                </div>
                                <kendo-combobox class="form-control" [data]="route_list" [allowCustom]="allowCustom" 
                                [suggest]="true" [(ngModel)]="st_route"[filterable]="true" [placeholder]="'ทั้งหมด'" 
                                (filterChange)="FilterRoute($event)"  (valueChange)="ChageRoute($event)"></kendo-combobox>
                                <div class="input-group-prepend">
                                    <p class="input-group-text">กิโลเมตร</p>
                                </div>
                                <input type="text" [(ngModel)]="st_km" class="form-control" placeholder="กม." numbersOnly maxlength="4">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">เมตร</p>
                                </div>
                                <input type="text" [(ngModel)]="st_m" class="form-control" placeholder="ม." numbersOnly maxlength="4">
                            </div>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">สิ้นสุด</p>
                                </div>
                                <kendo-combobox class="form-control" [data]="route_list" [allowCustom]="allowCustom" 
                                [suggest]="true" [(ngModel)]="en_route"[filterable]="true" [placeholder]="'ทั้งหมด'" 
                                (filterChange)="FilterRoute($event)"  (valueChange)="ChageRoute($event)"></kendo-combobox>
                                <div class="input-group-prepend">
                                    <p class="input-group-text">กิโลเมตร</p>
                                </div>
                                <input type="text" [(ngModel)]="en_km" class="form-control" placeholder="กม." numbersOnly maxlength="4">
                                <div class="input-group-prepend">
                                    <p class="input-group-text">เมตร</p>
                                </div>
                                <input type="text" [(ngModel)]="en_m" class="form-control" placeholder="ม." numbersOnly maxlength="4">
                            </div>
                        </div>

                    </div> -->

                </div>
            </div>
        </div>
        <div class="col-12 text-right">
            <button type="button" class="btn btn-danger mr-2" (click)="EvtOutput(false)">ปิด</button>
            <button type="button" class="btn btn-info" (click)="save()">ยืนยัน</button>
        </div>
    </div>
</div>