<div *ngIf="_Add" class="evtType-BG">
    <label class="ml-2 mr-2 {{evtType==1? 'pri-txt':''}}">
        <input type="radio" name="type" [checked]="evtType==1">
        <i class="fa fa-{{evtType==1? 'check-circle':'circle-o'}}"></i> แจ้งเตือน
    </label>
</div>
<div *ngIf="!_Add" class="evtType-BG">
    <label class="pointer ml-2 mr-2 {{evtType==1? 'pri-txt':''}}" (click)="addNewItem(1);evtType=1">
        <input type="radio" name="type" [checked]="evtType==1">
        <i class="fa fa-{{evtType==1? 'check-circle':'circle-o'}}"></i> แจ้งเตือน
    </label>
    <label *ngIf="event_group == 1" class="pointer ml-2 mr-2 {{evtType==2? 'pri-txt':''}}" (click)="addNewItem(2);evtType=2">
        <input type="radio" name="type" [checked]="evtType==2">
        <i class="fa fa-{{evtType==2? 'check-circle':'circle-o'}}"></i> ตรวจสอบแล้ว
    </label>
    <label *ngIf="event_group == 1" class="pointer ml-2 mr-2 {{evtType==3? 'pri-txt':''}}" (click)="addNewItem(3);evtType=3">
        <input type="radio" name="type" [checked]="evtType==3">
        <i class="fa fa-{{evtType==3? 'check-circle':'circle-o'}}"></i> ระหว่างดำเนินการ
    </label>
    <label *ngIf="event_group == 1 || event_group == 2" class="pointer ml-2 mr-2 {{evtType==4? 'pri-txt':''}}" (click)="addNewItem(4);evtType=4">
        <input type="radio" name="type" [checked]="evtType==4">
        <i class="fa fa-{{evtType==4? 'check-circle':'circle-o'}}"></i> แนบภาพประกอบ
    </label>
    <label *ngIf="event_group != 1" class="pointer ml-2 mr-2 {{evtType==3? 'pri-txt':''}}" (click)="addNewItem(3);evtType=3">
        <input type="radio" name="type" [checked]="evtType==3">
        <i class="fa fa-{{evtType==3? 'check-circle':'circle-o'}}"></i> ระหว่างดำเนินการ
    </label>
    <label class="pointer ml-2 mr-2 {{evtType==5? 'pri-txt':''}}" (click)="addNewItem(5);evtType=5">
        <input type="radio" name="type" [checked]="evtType==5">
        <i class="fa fa-{{evtType==5? 'check-circle':'circle-o'}}"></i> สิ้นสุด
    </label>
</div>