import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: "signDirToText"
})
export class SignDirectionToTextPipe implements PipeTransform {
    transform(dir_id: number): string {
        if (dir_id == 0) {
            return 'Front';
        } else {
            return 'Back';
        }
    }
}