import { StationInfo } from './boardsign';

// export class AtisInfo {
//     id?: number;
//     station_id?: number;
//     mode?: number;
//     name?: string;
//     image_key?: string;
//     path?: string;
//     image?: string;
//     image_manual?: string;
//     source_path?: string;
//     config_file_path?: string;
//     content_type?: string;
//     width?: number;
//     height?: number;
//     atis_type?: number;
//     status?: number;
//     create_date?: Date;
//     lastupdate_date?: Date;
//     station?: StationInfo;
//     is_auto_mode?:boolean;

//     board_type?: number;
//     image_type?: number;
//     shape_file_path?: string;

// }
export class AtisInfo {
    id?: number;
    boardsign_id?: number;
    mode?: number;
    sign_type_id?: number;
    name?: string;
    image_key?: string;
    image?: string;
    image_auto?: string;
    image_manual?: string;
    image_ts?: Date;
    image_auto_ts?: Date;
    image_manual_ts?: Date;
    source?: string;
    config_file?: string;
    content_type?: string;
    width?: number;
    height?: number;
    atis_type?: number;
    status?: number;
    create_date?: Date;
    lastupdate_date?: Date;
    station?: StationInfo;
    is_auto_mode?: boolean;
    path?: string;

}

export class ReqAddAtis {
    id?: number;
    name?: string;
    image_key?: string;
    boardsign_id?: number;
    sign_type_id?: number;
    source?: string;
    content_type?: string;
    config_file?: string;
    atis_type?: number;
    width?: number;
    height?: number;
    status?: number;
}