import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ConfigroadService {
  constructor(
    private api : ApiService,
  ) { }
      
  async getSmc(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getSmc(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getSmcConfigRoad(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getSmcConfigRoad(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addSmcConfigRoad(device_id?:any,req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addSmcConfigRoad(device_id,{config:req}).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
}