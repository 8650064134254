import { Component, OnInit ,Input,Output, EventEmitter  } from '@angular/core';
import { DeviceService } from 'src/app/services/user/device.service';
import { EventService } from 'src/app/services/user/event.service';
import * as _ from 'lodash'
import * as moment from 'moment'
import { FuncService } from 'src/app/services/user/func.service';

@Component({
  selector: 'app-begin',
  templateUrl: './begin.component.html',
  styleUrls: ['./begin.component.scss']
})
export class BeginComponent implements OnInit {
  @Input() event_group : any;
  @Input() values : any;
  @Output() result = new EventEmitter<string>();

  allowCustom: boolean = false;
  changed: boolean = false
  evtType : number = 1;
  route_list: any
  search_route: any
  route: any
  km1: number;
  m1: number;
  st_dt: any;
  src_type: number;
  veh_dmg: any = null;
  veh_dmg_type: number  = null;
  veh_dmg_list: any = []
  images_Evtdetail: any = []
  imagesAll_Evtdetail: any = []
  
  src_type_etc: any
  evt_subg: number = 1
  evtTypeList: any = []
  evtSubTypeList: any = []

  constructor(
    private devS: DeviceService,
    private evt_S: EventService,
    private func: FuncService,
  ) { }

  ngOnInit(): void {
    console.log(this.values)
    this.getData()
  }
  EvtOutput(value: any) {
    this.result.emit(value);
  }

  async save(){
    let data : any = {
      event_group_id : this.event_group,
      event_subtype_id:[],
    }
    if(this.values) data.id = this.values.id
    if(this.st_dt) data.occur_datetime = moment(this.st_dt).format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ')
    if(this.search_route) data.route = Number(this.search_route)
    if(this.km1) data.km = Number(this.km1)
    if(this.m1) data.m = Number(this.m1)
    if(this.src_type) data.src_type_id = this.src_type
    if(this.src_type == 8) data.src_type_desc = this.src_type_etc
    if(this.veh_dmg) data.src_group_id = Number(this.veh_dmg)
    if(this.veh_dmg_type) data.event_type_id = Number(this.veh_dmg_type)
    if(this.veh_dmg_list.length) {
      for (const i in this.veh_dmg_list) {
        if(this.veh_dmg_list[i]) data.event_subtype_id.push(Number(i))
      }
      data.event_subtype_id = data.event_subtype_id.toString()
    }
    if(this.evt_subg) data.event_subgroup_id = Number(this.evt_subg)
    let res : any = await this.evt_S.addevt(data)
    if(res){this.func.alertpopup("Success");this.EvtOutput(true)}
  }
  async getData() {
    if(!this.values) this.getEvtType();
    this.route_list = await this.devS.getRoute()
    this.route = this.route_list
    if(this.values) {
      if(this.event_group==2 && this.values.img_evt.length){
        this.imagesAll_Evtdetail = this.values.img_evt
        this.images_Evtdetail = _.slice(this.values.img_evt,0,4)
      }
      await this.getEvtType()
      if(this.values.occur_datetime) this.st_dt = new Date(this.values.occur_datetime)
      this.search_route = (this.values.route+'').padStart(4,'0')
      this.km1 = this.values.km
      this.m1 = this.values.m
      this.src_type = this.values.src_type_id
      this.src_type_etc = this.values.src_type_desc
      this.veh_dmg = this.values.src_group_id
      this.veh_dmg_type = this.values.event_type_id
      await this.getEvtSubType();
      if(this.values.event_subtype_id){
        let arr = (this.values.event_subtype_id.match(/,/g))? this.values.event_subtype_id.split(',') : this.values.event_subtype_id
        if(arr.length){
          for (const i of arr) {
            this.veh_dmg_list[i]=true
          }
        }
      }
    }
  }
  async getEvtType() {
    this.evtTypeList = await this.evt_S.getEvtType({event_group_id:this.event_group})
  }
  async getEvtSubType() {    
    this.evtSubTypeList = await this.evt_S.getEvtSubType({event_type_id:this.veh_dmg_type})
  }
  FilterRoute(evt: any) {
    if (evt.length > 0) {
      this.route_list = this.route_list.filter(res => res.includes(evt))
    } else if (evt.length == 0) {
      this.route_list = this.route
    } else {

    }
  }
  async ChageRoute(evt) {
    // console.log(evt)
  }
  
}
