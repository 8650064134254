import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { BoardcastComponent } from './boardcast/boardcast.component';
import { DataBankComponent } from './data-bank/data-bank.component';
import { NoteComponent } from './note/note.component';


export const routes: Routes = [{
  path: '',
  redirectTo: 'boardcast',
  pathMatch: 'full',
},
{
  path: '',
  component: MainComponent,
  children: [
    {
      path: 'boardcast',
      component: BoardcastComponent,
    },
    {
      path: 'data-bank',
      component: DataBankComponent,
    },
    {
      path: 'note',
      component: NoteComponent,
    },
    
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InformationRoutingModule { }
