export class UserProfile {
    id: number;
    firstname: string;
    lastname: string;
    tel: string;
    email: string;
    ins_id: number;
    rank: string;
    ins_name: string;
    permission?: number[];

    img_url?: string;
}
export class LoginResponse {
    access_token?: string;
    profile?: UserProfile;
    server?: LoginServerResponse;
    permission?: number[];
    permission_road?: any;
}
export class LoginServerResponse {
    time?: Date;
    ref?: string;
}

export class PermissionRoadInfo {
    id?: number;
    group_id?: number;
    route?: number;
    st_km?: number;
    en_km?: number;
    created_datetime?: Date;
    updated_datetime?: Date;
    updated_by?: number;
}
export class ReqAddPermissionRoad{
    id?: number;
    group_id?: number;
    route?: number;
    st_km?: number;
    en_km?: number;
}