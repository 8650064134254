import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { StationService } from '../../../services/assets-management/station.service';
import { Station, ReqAddStation } from '../../../models/assets-management/station';
import { MonitorService } from '../../../services/assets-management/monitor.service';
import { MonitorStation, MonitorStationDevice, SoftwareResponse, MonitorDevice } from '../../../models/assets-management/monitor';
import { StatusEnum, RouteDirectionEnum, StationTypeEnum, ConnectionStatusEnum, MaStatusEnum, ActionEnum } from '../../../models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Owner } from 'src/app/models/assets-management/owner';
import { Vendor } from 'src/app/models/assets-management/vendor';
import { DeviceGroup } from 'src/app/models/assets-management/device-group';
import { OwnerService } from '../../../services/assets-management/owner.service';
import { VendorService } from '../../../services/assets-management/vendor.service';
import { DeviceGroupService } from '../../../services/assets-management/device-group.service';
import { DeviceTypeService } from '../../../services/assets-management/device-type.service';
import { DeviceType } from 'src/app/models/assets-management/device-type';
import { Province, District, ItemName } from 'src/app/models/common';
import * as mapboxgl from 'mapbox-gl';
import { AttributeMarker } from '@angular/compiler/src/core';
import { permission } from 'src/app/views/assets-management/_menu';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent implements OnInit {
  permission = permission.monitor;
  selectMaView = 2;
  refId: any;
  typeId: any;
  public clickOpenAdd: boolean = false;

  public data: any[] = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0,
  ];
  viewType = 2; //1:Device,2:Station,3:map
  view = {
    device: 1,
    station: 2,
    map: 3
  }
  tabs_default = [
    {
      id: this.permission.hardware_id,
      name: "Hardware",
      icon: "fa fa-microchip"
    },
    {
      id: this.permission.software_id,
      name: "Software",
      icon: "fa fa-code"
    }

  ];
  tabs = [];
  tabSelect: any;
  map: mapboxgl.Map;
  map_station: mapboxgl.Map;
  marker: mapboxgl.Marker;
  map_lat: number;
  map_lng: number;
  statusEnum = StatusEnum;
  routeDirectionEnum = RouteDirectionEnum;
  stationTypeEnum = StationTypeEnum;
  connectionStatusEnum = ConnectionStatusEnum;
  myDate = new Date();
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;

  currentPageSoftware: any = 1;
  totalItemsSoftware: any = 3;

  data_list: MonitorStation[] = [];
  data_list_device: MonitorDevice[] = [];
  data_list_software: SoftwareResponse[] = [];

  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" }
  ];
  dir_list = [
    { id: RouteDirectionEnum.IN, name: "ขาเข้า" },
    { id: RouteDirectionEnum.OUT, name: "ขาออก" },
    { id: RouteDirectionEnum.INOUT, name: "ขาเข้า-ขาออก" }
  ];
  station_type_list = [
    { id: StationTypeEnum.Static, name: "Static" },
    { id: StationTypeEnum.Mobility, name: "Mobility" }
  ];
  search_status: any;
  search_status_software: any;
  selectItem: any = {
    name: null,
    km: null,
    m: null,
    lat: null,
    lng: null,
    license_plate_number: null,
    chassis_no: null,
    mdvr_no: null
  };

  public allowCustom: boolean = false;
  res_message: any;

  search_km_st: any;
  search_km_en: any;
  search_m_st: any;
  search_m_en: any;
  search_station: any;
  data_station_search: Station[];
  station_search_list: Station[];
  search_owner: any;
  data_owner: Owner[];
  data_owner_modal: Owner[];
  owner_list: Owner[];
  search_vendor: any;
  data_vendor: Vendor[];
  vendor_list: Vendor[];
  search_device_group: any;
  data_device_group: DeviceGroup[];
  device_group_list: DeviceGroup[];
  search_device_type: any;
  data_device_type: DeviceType[];
  device_type_list: DeviceType[];

  station_type_modal: any;
  status_modal: any;
  dir_modal: any;
  route_modal: any;
  prov_modal: Province;
  dist_modal: District;
  owner_modal: Owner;
  lic_prov_modal: Province;

  is_loading: boolean = false;
  search_route: string;
  public data_route: Array<string>;
  public data_route_modal: Array<string>;
  route_list: string[];

  data_prov_modal: Province[];
  data_lic_prov_modal: Province[];
  prov_list: Province[];

  data_dist_modal: District[];
  dist_list: District[];
  server_list: ItemName[] = [];
  data_server: ItemName[] = [];
  search_server: ItemName;
  algorithm_type_list: ItemName[] = [];
  data_algorithm_type: ItemName[] = [];
  search_algorithm_type: ItemName;
  algorithm_group_list: ItemName[] = [];
  data_algorithm_group: ItemName[] = [];
  search_algorithm_group: ItemName;

  isDescDevice: boolean = false;
  isDescMa: boolean = false;
  isAddMa: boolean = false;
  isMaLog: boolean = false;
  isAddMaLog: boolean = false;
  isDescHardware: boolean = false;
  isDescSoftware: boolean = false;

  vsw_group_id = environment.vsw_group_id;
  vsw_device_subtype = environment.vsw_device_subtype;
  security_device_subtype = environment.security_device_subtype;
  constructor(private commonService: CommonService, private stationService: StationService, private ownerService: OwnerService, private monitorService: MonitorService,
    private vendorService: VendorService, private deviceGroupService: DeviceGroupService, private deviceTypeService: DeviceTypeService) {
      mapboxgl.accessToken = environment.mapbox.accessToken;
  }
  graph1: any[] = [];
  async ngOnInit() {
    const per_hard = await this.commonService.getPermission(this.permission.hardware_id).toPromise();
    const per_soft = await this.commonService.getPermission(this.permission.software_id).toPromise();
    if (per_hard)
      this.tabs.push(this.tabs_default[0]);
    if (per_soft)
      this.tabs.push(this.tabs_default[1]);
    if (this.tabs.length > 0) {
      this.tabSelect = this.tabs[0];
      this.is_loading = true;
      this.getRoute();
      this.getMonitorStation();
      this.getStationSearch();
      this.getOwner();
      this.getVendor();
      this.getDeviceGroup();
      this.getDeviceType();
      this.initStationMap();
      this.getServer();
      this.getAlgorithmType();
      this.getAlgorithmGroup();
      //this.getMonitorSoftware();
      this.isDescHardware = await this.commonService.getPermission(this.permission.hardware.desc_id).toPromise();
      this.isDescSoftware = await this.commonService.getPermission(this.permission.software.desc_id).toPromise();
      this.commonService.activityLog(ActionEnum.Get, `Assets Management ➡ Monitor ➡ ${this.tabSelect.name}`).subscribe(res => { }, error => { console.log(error); });
    }
  }
  public style: string = 'step'; //'normal', 'step', 'smooth'
  public labelContent(e: any): string {
    return `${e.category}\n${e.value} %`;
    //return `${e.value} %`;
  }
  align = 'center';
  red = '#fd4141';
  green = '#67c100';
  yellow = '#ffc107';
  blue = '#4ad5fc';
  public data1: any[] = [{
    id: 1, type: 'Online', value: 75, color: this.green
  }, {
    id: 2, type: 'Offline', value: 25, color: this.red
  }];


  search() {
    if (this.viewType == this.view.station) {
      this.currentPage = 1;
      this.getMonitorStation();
    } else if (this.viewType == this.view.device) {
      this.currentPage = 1;
      this.getMonitorDevice();
    } else {
      this.getMonitorStationMap();
      setTimeout(() => { this.updateMap(); this.is_loading = false; }, 1000);
    }
  }
  searchSoftware() {
    this.currentPageSoftware = 1;
    this.getMonitorSoftware();
  }
  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      if (this.viewType == this.view.station) {
        this.getMonitorStation();
      }else if (this.viewType == this.view.device) {
        this.getMonitorDevice();
      }
    }
  }
  pageChangedSoftware(event: any): void {
    if (this.currentPageSoftware != event.page) {
      this.currentPageSoftware = event.page;
      this.getMonitorSoftware();
    }
  }
  changeTab(t?: number) {
    if (this.tabSelect != t) {
      this.tabSelect = t;
      if (this.tabSelect == this.permission.hardware_id) {
        this.getMonitorStation();

      } else {
        this.getMonitorSoftware();
      }
      this.commonService.activityLog(ActionEnum.Get, `Assets Management ➡ Monitor ➡ ${this.tabSelect.name}`).subscribe(res => { }, error => { console.log(error); });
    }
  }
  changeView(id, station_id?: number) {
    if (station_id) {
      this.search_station = this.station_search_list.find(x => x.id == station_id);
    }
    if (id != this.viewType) {
      this.viewType = id;
      if (id == this.view.station) {
        this.currentPage = 1;
        this.getMonitorStation();
      } else if (id == this.view.device) {
        this.currentPage = 1;
        this.getMonitorDevice();
      } else {
        this.getMonitorStationMap();
        setTimeout(() => { this.updateMap(); this.is_loading = false; this.map_station.resize(); }, 1000);
      }
    }
  }
  async openAddMa(type?: number, ref?: number, status?: number) {
    this.typeId = type;
    if (this.typeId == 1) {
      this.isDescDevice = await this.commonService.getPermission(this.permission.hardware.desc.desc_devcie_id).toPromise();
      this.isDescMa = await this.commonService.getPermission(this.permission.hardware.desc.desc_ma_id).toPromise();
      this.isAddMa = await this.commonService.getPermission(this.permission.hardware.desc.add_ma_id).toPromise();
      this.isMaLog = await this.commonService.getPermission(this.permission.hardware.desc.ma_log_id).toPromise();
      this.isAddMaLog = await this.commonService.getPermission(this.permission.hardware.desc.add_ma_log_id).toPromise();
    } else {
      this.isDescDevice = await this.commonService.getPermission(this.permission.software.desc.desc_devcie_id).toPromise();
      this.isDescMa = await this.commonService.getPermission(this.permission.software.desc.desc_ma_id).toPromise();
      this.isAddMa = await this.commonService.getPermission(this.permission.software.desc.add_ma_id).toPromise();
      this.isMaLog = await this.commonService.getPermission(this.permission.software.desc.ma_log_id).toPromise();
      this.isAddMaLog = await this.commonService.getPermission(this.permission.software.desc.add_ma_log_id).toPromise();
    }
    this.refId = ref;
    if (status == StatusEnum.Ma) {
      this.selectMaView = 3;
    } else {
      this.selectMaView = 2;
    }
    this.clickOpenAdd = true;
  }
  closeAddMa() {
    this.typeId = null;
    this.refId = null;
    this.clickOpenAdd = false;
  }
  addMaSuccess() {
    if (this.viewType == this.view.station) {
      this.currentPage = 1;
      this.getMonitorStation();
    } else if (this.viewType == this.view.device) {
      this.currentPage = 1;
      this.getMonitorDevice();
    } else {
      this.getMonitorStationMap();
      setTimeout(() => { this.updateMap(); this.is_loading = false; this.map_station.resize(); }, 1000);
    }
  }
  handleValueStatus(value) {
    if (value) {
      //console.log(value);
      this.search_status = value;
    } else {
      this.search_status = null;
    }
  }
  handleValueStatusSoftware(value) {
    if (value) {
      //console.log(value);
      this.search_status_software = value;
    } else {
      this.search_status_software = null;
    }
  }

  getRoute() {
    this.commonService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route = _.cloneDeep(res.data);
              this.data_route_modal = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }

  handleFilterRoute(value) {
    if (value.length > 0) {
      this.data_route = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route = _.cloneDeep(this.route_list);
    } else {
      this.data_route = [];
    }
  }
  handleValueRoute(value) {
    if (value) {
      //console.log(value);
      this.search_route = value;
    } else {
      this.search_route = null;
    }
  }

  getStationSearch() {
    this.stationService.getStation(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.station_search_list = _.cloneDeep(res.data.data);
              this.data_station_search = _.cloneDeep(res.data.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterStation(value) {
    if (value.length > 0) {
      this.data_station_search = this.station_search_list.filter(s=>s.name != null).filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_station_search = _.cloneDeep(this.station_search_list);
    } else {
      this.data_station_search = [];
    }
  }
  handleValueStation(value) {
    if (value) {
      this.search_station = value;
    } else {
      this.search_station = null;
    }
  }

  getServer() {
    this.monitorService.getServer().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.server_list = _.cloneDeep(res.data);
              this.data_server = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterServer(value) {
    if (value.length > 0) {
      this.data_server = this.server_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_server = _.cloneDeep(this.server_list);
    } else {
      this.data_server = [];
    }
  }
  handleValueServer(value) {
    if (value) {
      this.search_server = value;
    } else {
      this.search_server = null;
    }
  }

  getAlgorithmType() {
    this.monitorService.getAlgorithmType().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.algorithm_type_list = _.cloneDeep(res.data);
              this.data_algorithm_type = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterAlgorithmType(value) {
    if (value.length > 0) {
      this.data_algorithm_type = this.algorithm_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_algorithm_type = _.cloneDeep(this.algorithm_type_list);
    } else {
      this.data_algorithm_type = [];
    }
  }
  handleValueAlgorithmType(value) {
    if (value) {
      this.search_algorithm_type = value;
    } else {
      this.search_algorithm_type = null;
    }
  }

  getAlgorithmGroup() {
    this.monitorService.getAlgorithmGroup().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.algorithm_group_list = _.cloneDeep(res.data);
              this.data_algorithm_group = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterAlgorithmGroup(value) {
    if (value.length > 0) {
      this.data_algorithm_group = this.algorithm_group_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_algorithm_group = _.cloneDeep(this.algorithm_group_list);
    } else {
      this.data_algorithm_group = [];
    }
  }
  handleValueAlgorithmGroup(value) {
    if (value) {
      this.search_algorithm_group = value;
    } else {
      this.search_algorithm_group = null;
    }
  }

  getOwner() {
    this.ownerService.getOwner(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.owner_list = _.cloneDeep(res.data.data);
              this.data_owner = _.cloneDeep(res.data.data);
              this.data_owner_modal = _.cloneDeep(res.data.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterOwner(value) {
    if (value.length > 0) {
      this.data_owner = this.owner_list.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_owner = _.cloneDeep(this.owner_list);
    } else {
      this.data_owner = [];
    }
  }
  handleValueOwner(value) {
    if (value) {
      this.search_owner = value;
    } else {
      this.search_owner = null;
    }
  }

  getVendor() {
    this.vendorService.getVendor(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.vendor_list = _.cloneDeep(res.data);
              this.data_vendor = _.cloneDeep(res.data);

            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterVendor(value) {
    if (value.length > 0) {
      this.data_vendor = this.vendor_list.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_vendor = _.cloneDeep(this.vendor_list);
    } else {
      this.data_vendor = [];
    }
  }
  handleValueVendor(value) {
    if (value) {
      this.search_vendor = value;
    } else {
      this.search_vendor = null;
    }
  }

  getDeviceGroup() {
    this.deviceGroupService.getDeviceGroup(null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.device_group_list = _.cloneDeep(res.data.data);
              this.data_device_group = _.cloneDeep(res.data.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterDeviceGroup(value) {
    if (value.length > 0) {
      this.data_device_group = this.device_group_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_device_group = _.cloneDeep(this.device_group_list);
    } else {
      this.data_device_group = [];
    }
  }
  handleValueDeviceGroup(value) {
    if (value) {
      this.search_device_group = value;
    } else {
      this.search_device_group = null;
    }
  }

  getDeviceType() {
    this.deviceTypeService.getDeviceType(null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.device_type_list = _.cloneDeep(res.data);
              this.data_device_type = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterDeviceType(value) {
    if (value.length > 0) {
      this.data_device_type = this.device_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_device_type = _.cloneDeep(this.device_type_list);
    } else {
      this.data_device_type = [];
    }
  }
  handleValueDeviceType(value) {
    if (value) {
      this.search_device_type = value;
    } else {
      this.search_device_type = null;
    }
  }

  getMonitorStation() {
    this.data_list = [];
    let status = null;
    let route = null;
    let station = null;
    let owner = null;
    let vendor = null;
    let device_type = null;
    let device_group = null;
    if (this.search_status)
      status = this.search_status.id;
    if (this.search_route)
      route = this.search_route;
    if (this.search_station)
      station = this.search_station.id;
    if (this.search_owner)
      owner = this.search_owner.id;
    if (this.search_vendor)
      vendor = this.search_vendor.vendor_id;
    if (this.search_device_group)
      device_group = this.search_device_group.id;
    if (this.search_device_type)
      device_type = this.search_device_type.type_id;
    this.monitorService.getHardwareStation(this.currentPage, this.itemsPerPage, null, route, this.search_km_st, this.search_km_en, this.search_m_st, this.search_m_en, status, station, owner, vendor, device_group, device_type).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = res.data.data;
              this.totalItems = res.data.total_items;
            }else{
              this.totalItems = 0;
            }
          }else{
            this.totalItems = 0;
          }
        }else{
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
      });
  }
  getMonitorDevice() {
    this.is_loading = true;
    this.data_list = [];
    let status = null;
    let route = null;
    let station = null;
    let owner = null;
    let vendor = null;
    let device_type = null;
    let device_group = null;
    if (this.search_status)
      status = this.search_status.id;
    if (this.search_route)
      route = this.search_route;
    if (this.search_station)
      station = this.search_station.id;
    if (this.search_owner)
      owner = this.search_owner.id;
    if (this.search_vendor)
      vendor = this.search_vendor.vendor_id;
    if (this.search_device_group)
      device_group = this.search_device_group.id;
    if (this.search_device_type)
      device_type = this.search_device_type.type_id;
    this.monitorService.getHardwareDevice(this.currentPage, this.itemsPerPage, null, route, this.search_km_st, this.search_km_en, this.search_m_st, this.search_m_en, status, station, owner, vendor, device_group, device_type).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list_device = res.data.data;
              this.totalItems = res.data.total_items;
              this.data_list_device.forEach(item => {
                item.pie_chart.forEach(chart => {
                  if (chart.label == "online") {
                    chart.color = this.green;
                  } else {
                    chart.color = this.red;
                  }
                  item.graph_data = item.graph.map(x => x.value);
                });
              });
            }else{
              this.totalItems = 0;
              this.data_list_device = [];
            }
          }else{
            this.totalItems = 0;
            this.data_list_device = [];
          }
        }else{
          this.totalItems = 0;
          this.data_list_device = [];
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
        this.data_list_device = [];
      });
  }

  getMonitorSoftware() {
    this.data_list_software = [];
    let status = null;
    let server = null;
    let algo_type = null;
    let algo_group = null;
    if (this.search_status)
      status = this.search_status.id;
    if (this.search_server)
      server = this.search_server.id;
    if (this.search_algorithm_type)
      algo_type = this.search_algorithm_type.id;
    if (this.search_algorithm_group)
      algo_group = this.search_algorithm_group.id;
    this.monitorService.getSoftware(this.currentPageSoftware, this.itemsPerPage, null, server, algo_type, algo_group, status).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list_software = res.data.data;
              this.data_list_software.forEach(item => {
                item.pie_chart.forEach(chart => {
                  if (chart.label == "online") {
                    chart.color = this.green;
                  } else {
                    chart.color = this.red;
                  }
                  item.graph_data = item.graph.map(x => x.value);
                });
              });
              this.totalItemsSoftware = res.data.total_items;

            }else{
              this.totalItemsSoftware = 0;
            }
          }else{
            this.totalItemsSoftware = 0;
          }
        }else{
          this.totalItemsSoftware = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItemsSoftware = 0;
      });
  }

  //--------------------------------------------------------------------------------------//

  station_layer: any = 'WeightStationLayer';
  station_src: any = 'WeightStationSrc';
  station_src_url: any = '/assets/data/station.json';
  stationListFeatures: any[] = [];
  stationList: any = [];
  getMonitorStationMap() {
    this.is_loading = true;
    this.stationList = [];
    this.stationListFeatures = [];
    this.data_list = [];
    let status = null;
    let route = null;
    let station = null;
    let owner = null;
    let vendor = null;
    let device_type = null;
    let device_group = null;
    if (this.search_status)
      status = this.search_status.id;
    if (this.search_route)
      route = this.search_route;
    if (this.search_station)
      station = this.search_station.id;
    if (this.search_owner)
      owner = this.search_owner.id;
    if (this.search_vendor)
      vendor = this.search_vendor.vendor_id;
    if (this.search_device_group)
      device_group = this.search_device_group.id;
    if (this.search_device_type)
      device_type = this.search_device_type.type_id;
    this.stationService.getStation(null, null, null, route, this.search_km_st, this.search_km_en, this.search_m_st, this.search_m_en, status, station, owner, vendor, device_group, device_type).subscribe(
      res => {
        if (res) {
          res.data.data.forEach(item => {
            if (item.lng && item.lat) {
              const coordinates = [item.lng, item.lat];
              const geometry = { type: 'Point', coordinates: coordinates };
              const feature = { type: 'Feature', geometry: geometry, properties: item };
              this.stationListFeatures.push(feature);
            }
          });
          this.stationList = res.data.data;
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }
  initStationMap() {
    this.map_station = new mapboxgl.Map({
      container: 'map-monitor',
      style: environment.mapbox.style,
      zoom: 7,
      center: [100.523186, 13.736717],
    });
    this.map_station.dragRotate.disable();
    this.map_station.on('load', (event: mapboxgl.MapEvent) => {
      this.map_station.setPaintProperty('water', 'fill-color', '#aad3df');

      this.map_station.loadImage('assets/img/marker/pin-red.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage(`pin-${ConnectionStatusEnum.Offline}`, image);
      });
      this.map_station.loadImage('assets/img/marker/pin-green.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage(`pin-${ConnectionStatusEnum.Online}`, image);
      });
      this.map_station.loadImage('assets/img/marker/pin-yellow.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage(`pin-${ConnectionStatusEnum.TmpOffline}`, image);
      });
      this.map_station.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage('pin-null', image);
      });
      this.map_station.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage('pin-', image);
      });
      //------------------------------------------------------------------------------------------//

      this.map_station.addSource(this.station_src, {
        "type": 'geojson', "data": {
          "type": "FeatureCollection",
          "features": this.stationListFeatures
        }
      });

      //icon w-40 h-40
      this.map_station.addLayer({
        id: this.station_layer,
        source: this.station_src,
        type: 'symbol',
        layout: {
          'icon-allow-overlap': true,
          'icon-image': 'pin-{connection_status}',
          "icon-size": 0.8,
          "icon-offset": [0, 0], //[x,y]
          'text-offset': [0, 0],
          'text-field': '{route}',
          'text-size': 12,
          'text-allow-overlap': true,
          'text-transform': 'uppercase',
        },
        paint: {
          "text-color": "#ffffff"
        },
        "filter": ["==", "$type", "Point"],
      });

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false, anchor: 'top', offset: [0, -52]
      });

      this.map_station.on('click', this.station_layer, (event: mapboxgl.MapEvent) => {
        this.map_station.flyTo({ center: event.features[0].geometry.coordinates, zoom: 15 });
        const station_prop = event.features[0].properties;
        this.changeView(this.view.device, station_prop.id);
      });
      this.map_station.on('mouseenter', this.station_layer, (e: mapboxgl.MapEvent) => {
        this.map_station.getCanvas().style.cursor = 'pointer';
        const station_prop = e.features[0].properties;
        const htmltext = `<div class="font-3" style="padding-top: 10px;padding-left: 10px;padding-right: 10px;"><h6>${station_prop.name}</h6></div>`;
        popup.setLngLat(e.features[0].geometry.coordinates)
          .setHTML(htmltext)
          .addTo(this.map_station);
      });
      this.map_station.on('mouseleave', this.station_layer, (e: mapboxgl.MapEvent) => {
        this.map_station.getCanvas().style.cursor = '';
        popup.remove();
      });
      this.updateMap();
    });

  }
  updateMap() {
    if (this.map_station.getSource(this.station_src) != null) {
      this.map_station.getSource(this.station_src).setData({
        'type': 'FeatureCollection',
        'features': this.stationListFeatures
      });
    }
  }
}
