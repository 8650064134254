export class Brand {
    brand_id: number;
    brand_name: string;
    status: number;
    create_date: Date;
    update_date: Date;
}
export class ReqAddBrand {
    brand_name: string;
    status: number;
}

export class BrandModel {
    model_id: number;
    brand_id: number;
    model_name: string;
    status: number;
    create_date: Date;
    update_date: Date;
}
export class ReqAddBrandModel {
    model_name: string;
    status: number;
}