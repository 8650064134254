export class SignTypeInfo {
    id?: number;
    name?: string;
    width?: number;
    height?: number;
    pit?: number;//mm
    status?: number;
    aspect_ratio?: string;
    create_date?: Date;
    lastupdate_date?: Date;
}

export class ReqAddSignType {
    id?: number;
    name?: string;
    width?: number;
    height?: number;
    status?: number;
    pit?: number;//mm
    aspect_ratio?: string;
}