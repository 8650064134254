<!-- <div class="camera-panel bg-gray-700">
  <div class="w-100">
    <div class="header-info bg-gray-900 d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center ml-3">
          <h5> {{item?.view}} ทล.{{station?.route_id}}
              ( {{station?.km}}+{{station?.m | number:'3.0-0'}} )</h5>
      </div>
      <div>
        <i title="Close" class="fa fa-close fa-2x text-light pull-right mr-3 pointer close" (click)="clickClose()"></i>
      </div>
    </div>
    <video *ngIf="src" id="video_flv_{{idx}}" class="video-js vjs-big-play-centered video-panel" controls preload="auto"
      poster="{{poster}}" data-setup="{}">
      <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
      </p>
    </video>
  </div>
</div> -->

<div bsModal #infoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-body body-panel-h">
        <div class="camera-panel" style="background-color: black;">
          <div class="w-100">
            <div class="header-info bg-gray-900 d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center p-3">
                <h5> {{item?.view}} ทล.{{station?.route_id}}
                  ( {{station?.km}}+{{station?.m | number:'3.0-0'}} )</h5>
              </div>
              <div>
                <i title="Close" class="fa fa-close fa-2x text-light pull-right mr-3 pointer close"
                  (click)="clickClose()"></i>
              </div>
            </div>
            <video *ngIf="src" id="video_flv_{{idx}}" class="video-js vjs-big-play-centered video-panel" controls
              preload="auto" poster="{{poster}}" data-setup="{}">
              <p class="vjs-no-js">
                To view this video please enable JavaScript, and consider upgrading to a web browser that
                <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
              </p>
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>