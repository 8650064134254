export enum SignDirection {
    Front = 0,
    Back = 1
}
export enum ScheduleMode {
    NotRepeat = 1,
    EveryDay = 2,
    EveryWeek = 3,
    EveryWeekDay = 4,
    EveryWeekEnd = 5
}
export enum DayOfWeek {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7
}
export enum ImageFilterType {
    Image = 0, //ภาพนิ่ง
    Gif = 1 //ภาพเคลื่อนไหว
}

export enum MediaSettingType {
    None = 0,
    Text = 1,
    Image = 2
}
export enum ConnectionStatus {
    Offline = 0,
    Online = 1
}

// Board View
// 1 = Board List , 2 = Board Map, 3 = Board Info, 4 = Lms Suggest, 5 = Mange Favorite By Board ID, 6 = MA Board

export enum BoardViewLayer {
    BoardInfo = 3,
    Lms = 4,
    Favorite = 5,
    MA = 6
}

export enum BoardStaionType {
    LMS = 0, // อยู่กับที่
    PVMS = 1 // เคลื่อนที่
}
export enum MediaType {
    Image = 0,
    Gif = 1,
    Playlist = 2
}
export enum MediaContentType {
    JPEG = 1,
    PNG = 2,
    GIF = 3,
    HTML = 4,
    Video = 5
}
export enum MediaBehaviorType {
    Static = 1,
    Special = 2,
    Dynamic = 3
}

export enum SpecialTextType {
    FreeText = 1,
    SpeedLimit = 2,
    SpeedSuggest = 3,
    SpeedSensor = 4
}