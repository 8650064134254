<div class="row p-3">
  <div class="col-lg-5 col-md-5 col-sm-6">
    <div class="row">
      <div
        class="col-xl-5 col-lg-6 col-md-6 col-sm-12 justify-content-md-end justify-content-lg-end justify-content-xl-end align-items-center d-flex">
        Company name :</div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="k-textbox form-control w-100"
          [(ngModel)]="search_company_name" [ngModelOptions]="{standalone: true}" />
      </div>
    </div>
  </div>

  <div class="col-lg-5 col-md-5 col-sm-6">
    <div class="row">
      <div
        class="col-xl-5 col-lg-6  col-md-6 col-sm-12 justify-content-md-end justify-content-lg-end justify-content-xl-end  align-items-center d-flex">
        Hashtag :</div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <input autocomplete="nope" type="text" placeholder="" maxlength="50" class="k-textbox form-control w-100"
          [(ngModel)]="search_hashtag" [ngModelOptions]="{standalone: true}" />
      </div>
    </div>
  </div>

  <div class="col-lg-2 col-md-2 d-flex align-items-end justify-content-end">
    <div class="mt-1">
      <button type="button" (click)="getOwner()" class="btn btn-primary pull-right text-white"> <i class="fa fa-search"></i>
        ค้นหา </button>
    </div>
  </div>
  <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
    <div class="font-2">
      <i class="fa fa-bars mr-2"></i> Owner
    </div>
    <button *ngIf="isAdd" (click)="openAddModel()" type="button" class="btn btn-primary text-white"> <i class="fa fa-plus"></i>
      เพิ่ม Owner</button>
  </div>
  <div class="col-12 mt-3">
    <table class="table table-outline mb-0">
      <thead class="thead-dark" style="background-color: black;">
        <tr>
          <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
          <th class="text-center text-light table-header">สถานะ</th>
          <th class="text-center text-light table-header">Company</th>
          <th class="text-center text-light table-header">Hashtag</th>
          <th class="text-center text-light table-header">Create date</th>
          <th *ngIf="isEdit" class="text-center text-light table-header">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!this.is_loading && data_list?.length == 0">
          <td colspan="10" style="height: 50px;">
            ไม่พบข้อมูล
          </td>
        </tr>
        <tr *ngIf="this.is_loading">
          <td colspan="10" style="height: 50px;">
            กำลังโหลด ..
          </td>
        </tr>
        <tr *ngFor="let item of data_list;index as i;">
          <td class="text-center">
            <div>
              {{(i+1)+((this.currentPage - 1)*itemsPerPage)}}
            </div>
          </td>
          <td class="text-center">
            <!-- <span *ngIf="item?.status == 0" class="badge badge-danger">{{item?.status_str}}</span>
                <span *ngIf="item?.status == 1" class="badge badge-success">{{item?.status_str}}</span> -->
            <!-- <i [ngClass]="{ 'text-success': item?.status == 1,'text-danger': item?.status == 0 }"
                  class="fa fa-circle mr-1"></i> <span>{{item?.status_str}}</span> -->

            <i tooltip="Active" placement="top" *ngIf="item?.status == statusEnum.Active"
              class="fa fa-circle text-success"></i>
            <i tooltip="Inctive" placement="top" *ngIf="item?.status == statusEnum.Inactive"
              class="fa fa-circle text-danger"></i>
          </td>
          <td class="text-center">
            {{item?.company_name}}
          </td>
          <td class="text-center">
            {{item?.hashtag}}
          </td>
          <td class="text-center">
            <div style="font-size: 12px;">{{item?.created_datetime | date: 'dd/MM/yyyy'}}</div>
          </td>

          <td *ngIf="isEdit" class="text-center">
            <div class="d-flex align-items-center justify-content-center">
              <button (click)="openEditModel(item)" title="แก้ไข" type="button"
                class="btn btn-sm btn-warning mr-1 text-light">
                <i class="cui-note icons"></i>
              </button>
              <button (click)="openDeleteModel(item)" title="ลบ" type="button" class="btn btn-sm btn-danger ml-1">
                <i class="cui-trash icons"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="table-footer">
            <div class="table-footer-inside d-flex align-items-center justify-content-between">
              <div class="ml-3 pt-3">
                <pagination [totalItems]="totalItems" [maxSize]="5" [itemsPerPage]="itemsPerPage"
                  [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" class="pagination"
                  previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true" firstText="&laquo;"
                  lastText="&raquo;"></pagination>
              </div>
              <div></div>
              <div class="mr-3 ml-2">total: {{totalItems}}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<div bsModal #addModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span *ngIf="!selectItem?.id">เพิ่ม Owner</span><span
            *ngIf="selectItem?.id">แก้ไข Owner</span></h4>
        <button type="button" class="close text-light" (click)="addModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span class="float-sm-left float-md-right">Company
                name<span class="text-danger">*</span> :</span></label>
            <div class="col-md-8">
              <input type="text" [(ngModel)]="selectItem.company_name"  class="form-control"
                placeholder="" maxlength="50" [ngModelOptions]="{standalone: true}">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-form-label" > <span class="float-sm-left float-md-right">Phone
                number<span class="text-danger">*</span> :</span></label>
            <div class="col-md-8">
              <input #phoneInput type="text" mask="0000000000" [(ngModel)]="selectItem.phone_number" class="form-control"
                placeholder="" maxlength="10" [ngModelOptions]="{standalone: true}" 
                (ngModelChange)="phoneValidate($event)" [ngClass]="{'invalid-input1':valid_phone == false}">
            </div>
          </div>
          <div *ngIf="this.selectItem?.id" class="form-group row">
            <label class="col-md-4 col-form-label" > <span class="float-sm-left float-md-right">สถานะ<span
                  class="text-danger">*</span>
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="status_modal" (valueChange)="handleValueStatusModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" > <span class="float-sm-left float-md-right">Detail
                :</span></label>
            <div class="col-md-8">
              <!-- <input type="text" [(ngModel)]="newsTypeItem.news_type_name_en" name="text-input-en" class="form-control"
                  placeholder="" maxlength="50">
                <span class="help-block pull-right small text-muted">({{newsTypeItem?.news_type_name_en?.length}}/50)</span> -->
              <textarea [(ngModel)]="selectItem.detail"  rows="3" class="form-control"
                placeholder="" [ngModelOptions]="{standalone: true}"></textarea>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-form-label" > <span class="float-sm-left float-md-right">Address
                :</span></label>
            <div class="col-md-8">
              <input type="text" [(ngModel)]="selectItem.address" name="text-input-address" class="form-control"
                placeholder="" maxlength="50" [ngModelOptions]="{standalone: true}">
            </div>
          </div>


          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span class="float-sm-left float-md-right">Contact
                name :</span></label>
            <div class="col-md-8">
              <input type="text" [(ngModel)]="selectItem.contact_name" name="text-input-contact_name"
                class="form-control" placeholder="" maxlength="50" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">Email :</span></label>
            <div class="col-md-8">
              <input #emailInput type="email" [(ngModel)]="selectItem.email" name="text-input-email"
              class="form-control" placeholder="" maxlength="50" [ngModelOptions]="{standalone: true}"
              [ngClass]="{'invalid-input1':valid_email == false}" (ngModelChange)="emailOnValidatorChange($event)">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span class="float-sm-left float-md-right">Hashtag
                :</span></label>
            <div class="col-md-8">
              <input type="text" [(ngModel)]="selectItem.hashtag" name="text-input-hashtag" class="form-control"
                placeholder="" maxlength="50" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="addModal.hide()">ยกเลิก</button>
            <button [disabled]="!(selectItem.company_name && valid_phone && status_modal != null && ((selectItem?.email && valid_email) || !selectItem?.email))" *ngIf="selectItem?.id" type="button"
            class="btn btn-primary text-white ml-2"
            (click)="editOwner()">แก้ไข</button>
          <button [disabled]="!(selectItem.company_name && valid_phone && ((selectItem?.email && valid_email) || !selectItem?.email))" *ngIf="!selectItem?.id" type="button"
            class="btn btn-primary text-white ml-2"
            (click)="addOwner()">เพิ่ม</button>
        </div>

      </div>
      <!-- <div class="modal-footer">
            
          </div> -->
    </div>
  </div>
</div>
<div bsModal #deleteModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i>ลบ Owner</h4>
        <button type="button" class="close text-light" (click)="deleteModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt-2 text-center">
          <h4>คุณต้องการลบใช่หรือไม่</h4>
        </div>
        <div class="mt-2 mb-4 text-center">
          <!-- <h4>{{newsTypeItem?.news_type_name}}</h4> -->
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="deleteModal.hide()">ยกเลิก</button>
          <!-- <button type="button" class="btn btn-primary ml-2" (click)="deleteType(newsTypeItem.id)">ยืนยัน</button> -->
          <button type="button" class="btn btn-primary text-white ml-2" (click)="deleteOwner()">ยืนยัน</button>
        </div>

      </div>
      <!-- <div class="modal-footer">
            
          </div> -->
    </div>
  </div>
</div>
<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>