import { Component, OnInit } from '@angular/core';
import { permission, session_key_news } from 'src/app/views/information/_menu';
import { CommonService } from '../../../services/common.service';
@Component({
  selector: 'app-boardcast',
  templateUrl: './boardcast.component.html',
  styleUrls: ['./boardcast.component.scss']
})
export class BoardcastComponent implements OnInit {
  perm = permission.boardcast;
  tabs_default = [
    {
      id: this.perm.news_type_id,
      name: "ประเภทข่าวสาร",
      icon: "fa fa-clone"
    },
    {
      id: this.perm.news_id,
      name: "ข่าวสาร",
      icon: "fa fa-newspaper-o"
    }

  ];
  tabs = [];
  tabSelect: any;
  sessionKey = session_key_news;
  constructor(private commonService: CommonService) { }

  async ngOnInit() {
    const per_type = await this.commonService.getPermission(this.perm.news_type_id).toPromise();
    const per_list = await this.commonService.getPermission(this.perm.news_id).toPromise();
    if (per_type)
      this.tabs.push(this.tabs_default[0]);
    if (per_list)
      this.tabs.push(this.tabs_default[1]);


    const session_tab_id = sessionStorage.getItem(this.sessionKey)
    if (session_tab_id) {
      const find = this.tabs.find(x => x.id == +session_tab_id);
      if (find) {
        this.tabSelect = find;
      } else {
        if (this.tabs.length > 0) {
          this.tabSelect = this.tabs[0];
        }
      }
    } else {
      if (this.tabs.length > 0) {
        this.tabSelect = this.tabs[0];
      }
    }
  }
  changeTab(t?: any) {
    if (this.tabSelect != t) {
      this.tabSelect = t;
      sessionStorage.setItem(this.sessionKey, this.tabSelect.id);
    }
  }

}
