export class DataBankInfo {
    id?: number;
    created_datetime?:  Date;
    lastupdated_datetime?: Date;
    title?: string;
    data_bank_type_id?: number;
    hashtag?: string;
    ref_id?: number;
    ref_desc?: number;
    route_id?: number;
    province_id?: number;
    district_id?: number;
    noti_type_id?: number;
    status?: number;
    desc?: string;
    files?: DataBankFile[];
}
export class ReqAddDataBank {
    title?: string;
    data_bank_type_id?: number;
    hashtag?: string;
    ref_id?: number;
    ref_desc?: number;
    route_id?: number;
    province_id?: number;
    district_id?: number;
    noti_type_id?: number;
    status?: number;
    desc?: string;
    files?: DataBankFile[];
}

export class DataBankFile {
    id?: number;
    created_datetime?:  Date;
    lastupdated_datetime?: Date;
    name?: string;
    url?: string;
    data_bank_id?: number;
    status?: number;

    //view
    file?:File;
    index?:number;
}