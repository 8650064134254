import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DateInputCustomFormatPlaceholder } from '@progress/kendo-angular-dateinputs';
import { StatusEnum } from 'src/app/models/common-enum';
import { DataBankFile, DataBankInfo, ReqAddDataBank } from 'src/app/models/information/data-bank';
import { DataBankService } from 'src/app/services/information/data-bank.service';
import { DataBankTypeService } from 'src/app/services/information/data-bank-type.service';
import { InfoUploadService } from 'src/app/services/information/info-upload.service';
import { CommonService } from 'src/app/services/common.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { permission, session_key_databank } from 'src/app/views/information/_menu';
import { DataBankTypeInfo } from 'src/app/models/information/data-bank-type';
import { District, Province } from 'src/app/models/common';
import { ActionEnum } from 'src/app/models/common-enum';
@Component({
  selector: 'app-data-bank-list',
  templateUrl: './data-bank-list.component.html',
  styleUrls: ['../data-bank.component.scss']
})
export class DataBankListComponent implements OnInit {
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  @ViewChild('fileInput') fileInputVariable: ElementRef;
  public formatPlaceholder: DateInputCustomFormatPlaceholder = {
    day: 'วัน',
    month: 'เดือน',
    year: 'ปี',
    hour: 'ชั่วโมง',
    minute: 'นาที',
    second: 'วินาที'
  };
  public allowCustom: boolean = false;
  statusEnum = StatusEnum;
  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" }
  ];
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  search_status: any;
  search_st_date: Date;
  search_en_date: Date;
  search_hashtag: any;
  search_title: any;
  search_ref: any;
  ref_list = [
    { id: 1, name: "จศ100" },
    { id: 2, name: "Line" },
    { id: 3, name: "Facebook" },
    { id: 4, name: "สวพ91" },
    { id: 5, name: "Twitter" },
    { id: 6, name: "จนท.ในพื้นที่" },
    { id: 7, name: "ข้อมูลจากส่วนกลาง" },
    { id: 99, name: "อื่นๆ" }
  ];

  noti_type: any;
  noti_type_list = [
    { id: 1, name: "ไม่ยิงแจ้งเตือน" },
    { id: 2, name: "เจาะจงพื้นที่" },
    { id: 3, name: "Broadcast" }
  ];
  data_data_bank_type: DataBankTypeInfo[] = [];
  data_bank_type_list: DataBankTypeInfo[] = [];
  search_data_bank_type: DataBankTypeInfo;
  is_loading = false;
  data_list: any[] = [];
  selectItem: DataBankInfo = {
    title: null,
    ref_id: null,
    ref_desc: null,
    hashtag: null,
    desc: null
  };
  res_message: any;
  status_modal: any;
  data_data_bank_type_modal: DataBankTypeInfo[] = [];
  data_bank_type_modal: DataBankTypeInfo;

  data_route_modal: Array<string> = [];
  route_list: string[] = [];
  route_modal: any;

  data_prov_modal: Province[] = [];
  prov_list: Province[] = [];
  prov_modal: Province;

  data_dist_modal: District[] = [];
  dist_list: District[] = [];
  dist_modal: District;

  files: DataBankFile[] = [];

  isAdd: boolean = false;
  isEdit: boolean = false;
  isAddInc: boolean = false;
  constructor(private dataBankService: DataBankService,
    private dataBankTypeService: DataBankTypeService,
    private commonService: CommonService,
    private infoUploadService: InfoUploadService) {
    this.commonService.activityLog(ActionEnum.Get, 'Information ➡ คลังข้อมูล ➡ คลังข้อมูล').subscribe(res => { }, error => { console.log(error); });
  }

  async ngOnInit() {
    this.getDataBankType();
    this.getData();
    this.getRoute();
    this.getProvince();
    this.isAdd = await this.commonService.getPermission(permission.data_bank.data_bank.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.data_bank.data_bank.edit_id).toPromise();
    this.isAddInc = await this.commonService.getPermission(permission.data_bank.data_bank.add_inc_id).toPromise();

  }
  search() {
    this.currentPage = 1;
    this.getData();
  }
  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.getData();
    }
  }
  handleValueStatus(value) {
    if (value) {
      //console.log(value);
      this.search_status = value;
    } else {
      this.search_status = null;
    }
  }
  handleValueStatusModal(value) {
    if (value) {
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  handleValueNotiTypeModal(value) {
    if (value) {
      this.noti_type = value;
    } else {
      this.noti_type = null;
    }
  }
  handleValueReference(value) {
    if (value) {
      //console.log(value);
      this.search_ref = value;
    } else {
      this.search_ref = null;
    }
  }
  getDataBankType() {
    this.dataBankTypeService.getDataBankType(null, null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_bank_type_list = _.cloneDeep(res.data);
              this.data_data_bank_type = _.cloneDeep(res.data);
              this.data_data_bank_type_modal = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => {
      });
  }
  handleFilterDataBankType(value) {
    if (value.length > 0) {
      this.data_data_bank_type = this.data_bank_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_data_bank_type = _.cloneDeep(this.data_bank_type_list);
    } else {
      this.data_data_bank_type = [];
    }
  }
  handleValueDataBankType(value) {
    if (value) {
      this.search_data_bank_type = value;
    } else {
      this.search_data_bank_type = null;
    }
  }

  handleFilterDataBankTypeModal(value) {
    if (value.length > 0) {
      this.data_data_bank_type_modal = this.data_bank_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_data_bank_type_modal = _.cloneDeep(this.data_bank_type_list);
    } else {
      this.data_data_bank_type_modal = [];
    }
  }
  handleValueDataBankTypeModal(value) {
    if (value) {
      this.data_bank_type_modal = value;
    } else {
      this.data_bank_type_modal = null;
    }
  }

  handleFilterRouteModal(value) {
    if (value.length > 0) {
      this.data_route_modal = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route_modal = _.cloneDeep(this.route_list);
    } else {
      this.data_route_modal = [];
    }
  }

  getRoute() {
    this.commonService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route_modal = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  getProvince() {
    this.commonService.getProvince().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.prov_list = _.cloneDeep(res.data);
              this.data_prov_modal = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }

  getDistrict(prov_id?: number, is_modal?: boolean) {
    this.commonService.getDistrict(prov_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.dist_list = _.cloneDeep(res.data);
              this.data_dist_modal = _.cloneDeep(res.data);
              if (is_modal) {
                if (this.selectItem.district_id)
                  this.dist_modal = _.cloneDeep(this.dist_list.find(x => x.dist_id == this.selectItem.district_id));
              }
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }

  handleValueRouteModal(value) {
    console.log(value);

    if (value) {
      this.route_modal = value;
    } else {
      this.route_modal = null;
    }
  }

  handleFilterProvModal(value) {
    if (value.length > 0) {
      this.data_prov_modal = this.prov_list.filter((s) => s.province_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_prov_modal = _.cloneDeep(this.prov_list);
    } else {
      this.data_prov_modal = [];
    }
  }
  handleValueProvModal(value) {
    if (value) {
      this.prov_modal = value;
      this.getDistrict(this.prov_modal.province_id);
    } else {
      this.prov_modal = null;
    }
  }

  handleFilterDistModal(value) {
    if (value.length > 0) {
      this.data_dist_modal = this.dist_list.filter((s) => s.dist_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_dist_modal = _.cloneDeep(this.dist_list);
    } else {
      this.data_dist_modal = [];
    }
  }
  handleValueDistModal(value) {
    if (value) {
      this.dist_modal = value;
    } else {
      this.dist_modal = null;
    }
  }

  getData() {
    this.is_loading = true;
    this.data_list = [];
    let sta = null;
    let hash = null;
    let title = null;
    let st = null;
    let en = null;
    let type_id = null;
    let ref_id = null;
    if (this.search_status)
      sta = this.search_status.id;
    if (this.search_title)
      title = this.search_title;
    if (this.search_hashtag)
      hash = this.search_hashtag;
    if (this.search_st_date) {
      st = new Date(this.search_st_date.getFullYear(), this.search_st_date.getMonth(), this.search_st_date.getDate(), 0, 0, 0).toISOString();
    }
    if (this.search_en_date) {
      en = new Date(this.search_en_date.getFullYear(), this.search_en_date.getMonth(), this.search_en_date.getDate(), 23, 59, 59).toISOString();
    }
    if (this.search_data_bank_type)
      type_id = this.search_data_bank_type.id;
    if (this.search_ref)
      ref_id = this.search_ref.id
    this.dataBankService.getDataBank(title, sta, st, en, type_id, ref_id, hash, this.currentPage, this.itemsPerPage).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = res.data.data;
              this.totalItems = res.data.total_items;
            } else {
              this.totalItems = 0;
            }
          } else {
            this.totalItems = 0;
          }
        } else {
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
      });
  }
  openAddModal() {
    this.selectItem = {
      title: null,
      ref_id: null,
      ref_desc: null,
      hashtag: null,
      desc: null
    };
    this.files = [];
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.data_bank_type_modal = null;
    this.prov_modal = null;
    this.dist_modal = null;
    this.route_modal = null;
    this.noti_type = null;
    this.addModal.show();
  }
  openEditModal(item?: any) {
    this.selectItem = item;
    this.files = [];
    this.data_bank_type_modal = null;
    this.prov_modal = null;
    this.dist_modal = null;
    this.route_modal = null;
    this.noti_type = null;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    if (item.files)
      this.files = _.cloneDeep(item.files);
    if (this.selectItem.data_bank_type_id)
      this.data_bank_type_modal = this.data_bank_type_list.find(x => x.id == this.selectItem.data_bank_type_id);
    if (this.selectItem.province_id) {
      this.prov_modal = this.prov_list.find(x => x.province_id == this.selectItem.province_id);
      this.getDistrict(this.selectItem.province_id, true);
    }
    if (this.selectItem.route_id)
      this.route_modal = this.route_list.find(x => parseInt(x) == +this.selectItem.route_id);
    if (this.selectItem.noti_type_id)
      this.noti_type = this.noti_type_list.find(x => x.id == +this.selectItem.noti_type_id);
    this.addModal.show();
  }
  openDeleteModal(item?: any) {
    this.selectItem = item;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.deleteModal.show();
  }
  clearData() {
    this.selectItem = new DataBankInfo();
  }
  openAddIncident() {

  }

  openPreview(html?: any) {
    console.log(html);
    let htm = `
    <html dir="ltr">
      <head>
        <title>Preview</title>
        <link type="text/css" rel="stylesheet" href="https://cdn.ckeditor.com/4.6.1/full/contents.css?t=GB8C">
        <link type="text/css" rel="stylesheet" href="https://cdn.ckeditor.com/4.6.1/full/plugins/copyformatting/styles/copyformatting.css">
      </head>
      <body class="cke_editable cke_editable_themed cke_contents_ltr cke_show_borders">
      ${html}
      </body>
    </html>`
    let newWindow = window.open();
    newWindow.document.write(htm);
  }
  detectFiles(event) {
    let acceptedType = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          let f = new DataBankFile();
          f.file = file;
          f.status = 1;
          this.files.push(f);
        }
        reader.readAsDataURL(file);
      }
    }
  }
  deleteFileItem(item?: DataBankFile) {
    if (item.id) {
      const index: number = this.files.indexOf(item);
      if (index !== -1) {
        this.files[index].status = 0;
      }
    } else {
      const index: number = this.files.indexOf(item);
      if (index !== -1) {
        this.files.splice(index, 1);
      }
    }
  }
  clearDataFiles() {
    if (this.fileInputVariable) {
      this.fileInputVariable.nativeElement.value = "";
    }
  }

  addData(file_list?: DataBankFile[]) {
    this.res_message = "";
    this.is_loading = true;
    let req = new ReqAddDataBank();
    req.title = this.selectItem.title;
    req.desc = this.selectItem.desc;
    req.hashtag = this.selectItem.hashtag;
    req.ref_desc = this.selectItem.ref_desc;
    req.status = this.status_modal.id;
    req.files = file_list;
    if (this.selectItem.ref_id)
      req.ref_id = +this.selectItem.ref_id;
    if (this.data_bank_type_modal)
      req.data_bank_type_id = this.data_bank_type_modal.id;
    if (this.prov_modal)
      req.province_id = this.prov_modal.province_id;
    if (this.dist_modal)
      req.district_id = this.dist_modal.dist_id;
    if (this.route_modal)
      req.route_id = parseInt(this.route_modal);
    if (this.noti_type)
      req.noti_type_id = this.noti_type.id;
    this.dataBankService.addDataBank(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { request: req, response: res }; 
            this.commonService.activityLog(ActionEnum.Add, 'Information ➡ คลังข้อมูล ➡ คลังข้อมูล', JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `เพิ่มคลังข้อมูลสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editData(file_list?: DataBankFile[]) {
    this.res_message = "";
    this.is_loading = true;
    let req = new ReqAddDataBank();
    req.title = this.selectItem.title;
    req.desc = this.selectItem.desc;
    req.hashtag = this.selectItem.hashtag;
    req.ref_desc = this.selectItem.ref_desc;
    req.status = this.status_modal.id;
    req.files = file_list;
    if (this.selectItem.ref_id)
      req.ref_id = +this.selectItem.ref_id;
    if (this.data_bank_type_modal)
      req.data_bank_type_id = this.data_bank_type_modal.id;
    if (this.prov_modal)
      req.province_id = this.prov_modal.province_id;
    if (this.dist_modal)
      req.district_id = this.dist_modal.dist_id;
    if (this.route_modal)
      req.route_id = parseInt(this.route_modal);
    if (this.noti_type)
      req.noti_type_id = this.noti_type.id;
    this.dataBankService.editDataBank(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { id: this.selectItem.id, request: req, response: res };
            this.commonService.activityLog(ActionEnum.Edit, 'Information ➡ คลังข้อมูล ➡ คลังข้อมูล', JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `แก้ไขคลังข้อมูลสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  deleteData() {
    this.is_loading = true;
    let req = new ReqAddDataBank();
    req.status = this.status_modal.id;
    this.res_message = "";
    this.dataBankService.deleteDataBankEditStatus(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { id: this.selectItem.id };
            this.commonService.activityLog(ActionEnum.Delete, 'Information ➡ คลังข้อมูล ➡ คลังข้อมูล', JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `ลบคลังข้อมูลสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  bucket_name = 'information';
  path = 'data-bank';
  async addDataAsyn() {
    let add_files: DataBankFile[] = this.files.filter(x => x.file != null);
    if (add_files.length > 0) {
      const add_file_list = this.files.map(x => x.file)
      let res_fs = await this.infoUploadService.uploadFile(add_file_list, this.bucket_name, this.path).toPromise();
      if (res_fs) {
        if (res_fs.data) {
          let fs: DataBankFile[] = [];
          add_files.forEach(async (item, index) => {
            let f = new DataBankFile;
            f.status = StatusEnum.Active;
            f.url = res_fs.data.file_urls[index];
            f.name = item.file.name;
            await fs.push(f);
          });
          await this.addData(fs);
        } else {
          this.addData();
        }
      } else {
        this.addData();
      }
    } else {
      this.addData();
    }
  }

  async editDataAsyn() {
    this.files.forEach((item, index) => {
      item.index = index;
    });
    let add_files: DataBankFile[] = await this.files.filter(x => x.file != null);

    if (add_files.length > 0) {
      const add_file_list = this.files.map(x => x.file)
      let res_fs = await this.infoUploadService.uploadFile(add_file_list, this.bucket_name, this.path).toPromise();
      if (res_fs) {
        if (res_fs.data) {
          await add_files.forEach(async (item, index) => {
            item.url = res_fs.data.file_urls[index];
          });
          let fs: DataBankFile[] = [];
          for (const item of this.files) {
            let f = new DataBankFile;
            if (item.file) {
              const find_index = await add_files.find(x => x.index == item.index);
              f.status = StatusEnum.Active;
              f.url = find_index.url;
              f.name = item.file.name;
            } else {
              f.id = item.id;
              f.status = item.status;
              f.url = item.url;
              f.name = item.name;
            }
            await fs.push(f);
          }
          await this.editData(fs);
        } else {
          const files_all = await this.mapRequestDataFile();
          this.editData(files_all);
        }
      } else {
        const files_all = await this.mapRequestDataFile();
        this.editData(files_all);
      }
    } else {
      const files_all = await this.mapRequestDataFile();
      this.editData(files_all);
    }
  }

  async mapRequestDataFile() {
    let fs: DataBankFile[] = [];
    if (this.files.length > 0) {
      for (const item of this.files) {
        let f = new DataBankFile;
        f.id = item.id;
        f.status = item.status;
        f.url = item.url;
        f.name = item.name;
        await fs.push(f);
      }
    }
    return fs;
  }

  clickFile(url?: string) {
    window.open(url)
  }
}