export class NoteInfo {
    id?: number;
    created_datetime?:  Date;
    lastupdated_datetime?: Date;
    title?: string;
    note_type_id?: number;
    hashtag?: string;
    desc?: string;
    note_by?: string;
    note_by_id?: number;
    note_to?: string;
    note_to_id?: number;
    noti_user_id?: number;
    is_noti?: boolean;
    noti_time?: Date;
    status?: number;
    images?: NoteImage[];
    logs?: NoteLogInfo[];
}
export class ReqAddNote {
    title?: string;
    note_type_id?: number;
    hashtag?: string;
    desc?: string;
    note_by?: string;
    note_by_id?: number;
    note_to?: string;
    note_to_id?: number;
    noti_user_id?: number;
    is_noti?: boolean;
    noti_time?: Date;
    status?: number;
    images?: NoteImage[];
}

export class NoteImage {
    id?: number;
    created_datetime?:  Date;
    lastupdated_datetime?: Date;
    name?: string;
    url?: string;
    note_id?: number;
    status?: number;

    //view
    file?:File;
    dataUrl?: string;
    index?:number;
}

export class NoteLogInfo {
    id?: number;
    note_id?: number;
    created_datetime?:  Date;
    lastupdated_datetime?: Date;
    title?: string;
    note_type_id?: number;
    hashtag?: string;
    desc?: string;
    note_by?: string;
    note_by_id?: number;
    note_to?: string;
    note_to_id?: number;
    noti_user_id?: number;
    is_noti?: boolean;
    noti_time?: Date;
    status?: number;
    images?: NoteLogImage[];
}

export class NoteLogImage {
    id?: number;
    created_datetime?:  Date;
    lastupdated_datetime?: Date;
    name?: string;
    url?: string;
    note_log_id?: number;
    status?: number;
}