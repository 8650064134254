export const information_url = "information";
export const id = 900;
export const session_key_databank = "data_bank_tab_id";
export const session_key_news = "news_tab_id";
export const session_key_note = "note_tab_id";
// Mockup id,Nodata in Doc
export const menuItems = [
    {
        id: 901,
        name: 'Broadcast information',
        url: `/${information_url}/boardcast`,
        icon: 'icon-info',
        parent_id: id
    },
    {
        id: 909,
        name: 'คลังข้อมูล',
        url: `/${information_url}/data-bank`,
        icon: 'fa fa-database',
        parent_id: id
    },
    {
        id: 917,
        name: 'บันทึก',
        url: `/${information_url}/note`,
        icon: 'fa fa-download',
        parent_id: id
    },

];
export const permission = {
    boardcast: {
        news_type_id: 902,
        news_type: {
            add_id: 903,
            edit_id: 904
        },
        news_id: 905,
        news: {
            add_id: 906,
            add_inc_id: 907,
            edit_id: 908
        }
    },
    data_bank: {
        data_bank_type_id: 910,
        data_bank_type: {
            add_id: 911,
            edit_id: 912
        },
        data_bank_id: 913,
        data_bank: {
            add_id: 914,
            add_inc_id: 915,
            edit_id: 916
        }
    },
    note: {
        note_type_id: 918,
        note_type: {
            add_id: 919,
            edit_id: 920
        },
        note_id: 921,
        note: {
            add_id: 922,
            edit_id: 923
        }
    }
};