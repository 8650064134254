import { Component, ElementRef, EventEmitter, AfterViewInit, Output, ViewChild, Input } from '@angular/core';
import { fromEvent } from 'rxjs';
import { pairwise, switchMap, takeUntil } from 'rxjs/operators';

import { EventService } from 'src/app/services/user/event.service';
import { FuncService } from 'src/app/services/user/func.service';
@Component({
  selector: 'app-evt-complete',
  templateUrl: './evt-complete.component.html',
  styleUrls: ['./evt-complete.component.scss']
})
export class EvtCompleteComponent implements AfterViewInit {

  @Input() values : any;

  @Output() CkEvt = new EventEmitter<string>();
  @ViewChild('canvas') public canvas: ElementRef | undefined;

  width = 900;
  height = 600;

  private cx: CanvasRenderingContext2D | null | undefined;
  
  constructor(
    private evt_S: EventService,
    private func: FuncService,
  ) { }

  ngOnInit(): void {
  }
  EvtOutput(value: any) {
    this.CkEvt.emit(value);
  }
  async clickSave(d?:any){
    if(d){
      console.log(this.canvas.nativeElement.toDataURL("image/png"))
      let resSign : any = await this.evt_S.updateEvtSignature({
        event_id:this.values.id,
        img_signature:this.canvas.nativeElement.toDataURL("image/png"),
      })
      if(resSign) {
        this.func.alertpopup("Success");
      }
    }
    this.CkEvt.emit(d);
  }
  Clear() {
    this.cx =null
    this.ngAfterViewInit()
  }

  public ngAfterViewInit() {
    const canvasEl: HTMLCanvasElement = this.canvas?.nativeElement;
    
    this.cx = canvasEl.getContext('2d');

    canvasEl.width = this.width;
    canvasEl.height = this.height;

    if (!this.cx) throw 'Cannot get context';

    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    // this.cx.strokeStyle = '#ffd299';
    this.cx.strokeStyle = '#ff8f00';

    this.captureEvents(canvasEl);
  }

  private captureEvents(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap(e => {
          // after a mouse down, we'll record all mouse moves
          return fromEvent(canvasEl, 'mousemove').pipe(
            // we'll stop (and unsubscribe) once the user releases the mouse
            // this will trigger a 'mouseup' event
            takeUntil(fromEvent(canvasEl, 'mouseup')),
            // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
            takeUntil(fromEvent(canvasEl, 'mouseleave')),
            // pairwise lets us get the previous value to draw a line from
            // the previous point to the current point
            pairwise()
          );
        })
      )
      .subscribe((res) => {
        const rect = canvasEl.getBoundingClientRect();
        const prevMouseEvent = res[0] as MouseEvent;
        const currMouseEvent = res[1] as MouseEvent;

        // previous and current position with the offset
        const prevPos = {
          x: prevMouseEvent.clientX - rect.left,
          y: prevMouseEvent.clientY - rect.top
        };

        const currentPos = {
          x: currMouseEvent.clientX - rect.left,
          y: currMouseEvent.clientY - rect.top
        };

        // this method we'll implement soon to do the actual drawing
        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  private drawOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    if (!this.cx) {
      return;
    }

    this.cx.beginPath();

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
    }
  }
}
