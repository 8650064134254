<div class="map-body">
  <div class="position-relative" [ngClass]="is_toggle ? 'map' : 'map-full'">
    <div class="map-container scrollbar-style">
      <div [ngStyle]="setImSize">
        <svg version="1.1" viewBox="0 0 8700 6000" class="svg-content center" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" oncontextmenu="return false;">
          <image [attr.href]="url" x="0" y="0" height="6000" width="8700" />
          <path *ngFor="let m of polyList;" class="fill-none" [attr.d]='m.path'
            [ngClass]="{'highlight-fill': m?.name_dir == nname}" (mouseenter)="polymouseEnter($event,m,null,null)"
            (mouseleave)="polymouseLeave()">
          </path>
          <defs>
            <filter x="0" y="0" width="1" height="1" id="solid">
              <feFlood flood-color="white" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
    <div class="toggle-box-panel d-flex p-2">
      <div class="pointer text-primary" [ngClass]="is_toggle?'fa fa-arrow-right':'fa fa-arrow-left'"
        (click)="toggleBox()">
      </div>
    </div>
    <div class="map-menu-control d-flex bg-gray-700 rounded p-2 mr-1">
      <div class="icon-magnifier-remove icon ml-1 mr-1 pointer" (click)="setSizeStylesMinus()"></div>
      <div class="icon-magnifier-add icon ml-1 mr-1 pointer" (click)="setSizeStylesPlus()"></div>
      <div class="icon-size-actual ml-1 mr-1 icon pointer" (click)="setSizeStylesDefault()"></div>
    </div>
  </div>
  <app-region-menu-new [region]="region_id"></app-region-menu-new>
  <div [ngClass]="{'filter-box': is_toggle, 'filter-box-none': !is_toggle}" class="bg-gray-700 border-dark border-left">
    <div class="filter-box-panel">
      <div class="d-flex border-dark-top">
        <div class="p-2 flex-fill d-flex justify-content-center align-items-center disable-select"
          [ngClass]="tab == 1?'menu-item-active':'menu-item'" (click)="changeTab(1)">
          <i class="icon icon-clock mr-1"></i>
          <span>Tab1</span>
        </div>
        <div class="p-2 flex-fill d-flex justify-content-center align-items-center disable-select"
          [ngClass]="tab == 2?'menu-item-active':'menu-item'" (click)="changeTab(2)">
          <i class="fa fa-map-pin mr-1"></i>
          <span>Tab2</span>
        </div>
      </div>
      <div *ngIf="tab == 1">
      </div>
      <div *ngIf="tab == 2">
      </div>
    </div>
  </div>
  <div *ngIf="is_first_load" class="map-body-first-load">
    <div class="d-flex justify-content-center align-items-center w-100 h-100 ">
      <div class="lds-dual-ring"></div>
    </div>
  </div>
</div>
<div class="tooltip-poly" [hidden]="!poly_is_show" [style.top.px]="poly_top" [style.left.px]="poly_left">
  <div class="text">
    <div class="m-1">{{poly_text}}</div>
  </div>
</div>