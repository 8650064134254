<div class="animated fadeIn m-4">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body bg-gray-900">
          <div class="d-flex justify-content-center align-items-center flex-wrap">
            <div class="d-flex align-items-center ml-2 flex-wrap">
              <div class="mr-2 text-right" style="width: 80px;">
                จุด :
              </div>
              <kendo-combobox class="wwww" [data]="data_sta" [allowCustom]="allowCustom" [suggest]="true"
                [textField]="'name'" [valueField]="'id'" [value]="sta" (filterChange)="handleFilter($event)"
                [filterable]="true" (valueChange)="handleValue($event)" [placeholder]="'โปรดระบุ'">
              </kendo-combobox>
              <div class="ml-2">
                <div class="d-flex justify-content-center align-content-center">
                  <div class="custom-control custom-radio mr-1">
                    <input type="radio" id="controlRadiopcu1" name="controlRadiopcu" class="custom-control-input"
                      [value]="pcu0" [(ngModel)]="pcu" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="controlRadiopcu1">Normal</label>
                  </div>
                  <div class="custom-control custom-radio ml-1">
                    <input type="radio" id="controlRadiopcu2" name="controlRadiopcu" class="custom-control-input"
                      [value]="pcu1" [(ngModel)]="pcu" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="controlRadiopcu2">PCU</label>
                  </div>
                </div>
              </div>

              <div class="ml-2 text-right">
                <button [disabled]="!(sta)" type="button" class="btn btn-primary" style="min-width: 80px;"
                  (click)="search()">ค้นหา</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-12 col-xl-12">
      <div class="row">
        <div class="col-md-5 col-lg-5 col-xl-4">
          <div class="row">
            <div class="col-md-3">
              <div class="card rounded" style="height: 1042px;">
                <div class="card-body p-0 clearfix bg-success rounded d-flex justify-content-center align-items-center">
                  <div class="h5 text-white text-uppercase font-weight-bold">IN</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card rounded" style="height: 510px;">
                <div class="card-body p-0 clearfix bg-primary rounded d-flex justify-content-center align-items-center">
                  <div class="h5 text-white text-uppercase font-weight-bold">
                    <div>N</div>
                    <div>o</div>
                    <div>r</div>
                    <div>m</div>
                    <div>a</div>
                    <div>l</div>
                  </div>
                </div>
              </div>
              <div class="card rounded" style="height: 510px;">
                <div class="card-body p-0 clearfix bg-danger rounded d-flex justify-content-center align-items-center">
                  <div class="h5 text-white text-uppercase font-weight-bold">
                    <div>R</div>
                    <div>e</div>
                    <div>v</div>
                    <div>e</div>
                    <div>r</div>
                    <div>s</div>
                    <div>e</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-sm-12">
                  <div class="card text-white" style="background-color: #009a9c;height: 242px;">
                    <div class="card-body">
                      <div class="h1 text-muted text-right mb-4">
                        <i class="fa fa-road"></i>
                      </div>
                      <div *ngIf="this.data.normal_flow_in != null" class="h4 mb-0">
                        {{this.data.normal_flow_in | number:'0.0-2'}}</div>
                      <div *ngIf="this.data.normal_flow_in == null" class="h4 mb-0">-</div>
                      <div class="d-flex">
                        <div class="text-muted text-uppercase font-weight-bold">Flow</div> <span
                          class="text-muted ml-1">(Vec/h)</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="card text-white" style="background-color: #006999;height: 242px;">
                    <div class="card-body">
                      <div class="h1 text-muted text-right mb-4">
                        <i class="icon-speedometer"></i>
                      </div>
                      <div *ngIf="this.data.normal_speed_in != null" class="h4 mb-0">
                        {{this.data.normal_speed_in | number:'0.0-2'}}</div>
                      <div *ngIf="this.data.normal_speed_in == null" class="h4 mb-0">-</div>
                      <div class="d-flex">
                        <div class="text-muted text-uppercase font-weight-bold">Avg Speed</div> <span
                          class="text-muted ml-1">(Km/h)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="card text-white" style="background-color: #009a9c;height: 242px;">
                    <div class="card-body">
                      <div class="h1 text-muted text-right mb-4">
                        <i class="fa fa-road"></i>
                      </div>
                      <div *ngIf="this.data.reverse_flow_in != null" class="h4 mb-0">
                        {{this.data.reverse_flow_in | number:'0.0-2'}}</div>
                      <div *ngIf="this.data.reverse_flow_in == null" class="h4 mb-0">-</div>
                      <div class="d-flex">
                        <div class="text-muted text-uppercase font-weight-bold">Flow</div> <span
                          class="text-muted ml-1">(Vec/h)</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="card text-white" style="background-color: #006999;height: 242px;">
                    <div class="card-body">
                      <div class="h1 text-muted text-right mb-4">
                        <i class="icon-speedometer"></i>
                      </div>
                      <div *ngIf="this.data.reverse_speed_in != null" class="h4 mb-0">
                        {{this.data.reverse_speed_in | number:'0.0-2'}}</div>
                      <div *ngIf="this.data.reverse_speed_in == null" class="h4 mb-0">-</div>
                      <div class="d-flex">
                        <div class="text-muted text-uppercase font-weight-bold">Avg Speed</div> <span
                          class="text-muted ml-1">(Km/h)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7 col-lg-7 col-xl-8">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="card text-white bg-gray-900">
                <div class="card-body">
                  <kendo-chart>
                    <kendo-chart-area background="none" [margin]="0" [height]="200">
                    </kendo-chart-area>
                    <kendo-chart-title text="Flow"></kendo-chart-title>
                    <kendo-chart-value-axis>
                      <kendo-chart-value-axis-item [min]="0">
                      </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                    <kendo-chart-category-axis>
                      <kendo-chart-category-axis-item [categories]="data_normal_flow_in?.categories"
                        [plotBands]="categoryPlotBands" [title]="{ text: '' }">
                      </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-series>
                      <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                        [markers]="markers" [data]="data_normal_flow_in.max" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                        [markers]="markers" [data]="data_normal_flow_in.avg" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                        [markers]="markers" [data]="data_normal_flow_in.min" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8"
                        [markers]="markers" [data]="data_normal_flow_in.current">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                    </kendo-chart-series>
                    <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                    <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
                  </kendo-chart>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12">
              <div class="card text-white bg-gray-900">
                <div class="card-body">
                  <kendo-chart>
                    <kendo-chart-area background="none" [margin]="0" [height]="200">
                    </kendo-chart-area>
                    <kendo-chart-title text="Avg Speed"></kendo-chart-title>
                    <kendo-chart-value-axis>
                      <kendo-chart-value-axis-item [min]="0" [max]="120">
                      </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                    <kendo-chart-category-axis>
                      <kendo-chart-category-axis-item [categories]="data_normal_speed_in?.categories"
                        [plotBands]="categoryPlotBands" [title]="{ text: '' }">
                      </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-series>
                      <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                        [markers]="markers" [data]="data_normal_speed_in.max" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                        [markers]="markers" [data]="data_normal_speed_in.avg" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                        [markers]="markers" [data]="data_normal_speed_in.min" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8"
                        [markers]="markers" [data]="data_normal_speed_in.current">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                    </kendo-chart-series>
                    <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                    <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
                  </kendo-chart>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="card text-white bg-gray-900">
                <div class="card-body">
                  <kendo-chart>
                    <kendo-chart-area background="none" [margin]="0" [height]="200">
                    </kendo-chart-area>
                    <kendo-chart-title text="Flow"></kendo-chart-title>
                    <kendo-chart-value-axis>
                      <kendo-chart-value-axis-item [min]="0" >
                      </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                    <kendo-chart-category-axis>
                      <kendo-chart-category-axis-item [categories]="data_reverse_flow_in?.categories"
                        [plotBands]="categoryPlotBands" [title]="{ text: '' }">
                      </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-series>
                      <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                        [markers]="markers" [data]="data_reverse_flow_in.max" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                        [markers]="markers" [data]="data_reverse_flow_in.avg" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                        [markers]="markers" [data]="data_reverse_flow_in.min" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8"
                        [markers]="markers" [data]="data_reverse_flow_in.current">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                    </kendo-chart-series>
                    <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                    <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
                  </kendo-chart>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12">
              <div class="card text-white bg-gray-900">
                <div class="card-body">
                  <kendo-chart>
                    <kendo-chart-area background="none" [margin]="0" [height]="200">
                    </kendo-chart-area>
                    <kendo-chart-title text="Avg Speed"></kendo-chart-title>
                    <kendo-chart-value-axis>
                      <kendo-chart-value-axis-item [min]="0" [max]="120">
                      </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                    <kendo-chart-category-axis>
                      <kendo-chart-category-axis-item [categories]="data_reverse_speed_in?.categories"
                        [plotBands]="categoryPlotBands" [title]="{ text: '' }">
                      </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-series>
                      <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                        [markers]="markers" [data]="data_reverse_speed_in.max" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                        [markers]="markers" [data]="data_reverse_speed_in.avg" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                        [markers]="markers" [data]="data_reverse_speed_in.min" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8"
                        [markers]="markers" [data]="data_reverse_speed_in.current">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                    </kendo-chart-series>
                    <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                    <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
                  </kendo-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-12 col-xl-12">
      <div class="row">
        <div class="col-md-5 col-lg-5 col-xl-4">
          <div class="row">
            <div class="col-md-3">
              <div class="card rounded" style="height: 1042px;">
                <div class="card-body p-0 clearfix bg-warning rounded d-flex justify-content-center align-items-center">
                  <div class="h5 text-white text-uppercase font-weight-bold">OUT</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card rounded" style="height: 510px;">
                <div class="card-body p-0 clearfix bg-primary rounded d-flex justify-content-center align-items-center">
                  <div class="h5 text-white text-uppercase font-weight-bold">
                    <div>N</div>
                    <div>o</div>
                    <div>r</div>
                    <div>m</div>
                    <div>a</div>
                    <div>l</div>
                  </div>
                </div>
              </div>
              <div class="card rounded" style="height: 510px;">
                <div class="card-body p-0 clearfix bg-danger rounded d-flex justify-content-center align-items-center">
                  <div class="h5 text-white text-uppercase font-weight-bold">
                    <div>R</div>
                    <div>e</div>
                    <div>v</div>
                    <div>e</div>
                    <div>r</div>
                    <div>s</div>
                    <div>e</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-sm-12">
                  <div class="card text-white" style="background-color: #009a9c;height: 242px;">
                    <div class="card-body">
                      <div class="h1 text-muted text-right mb-4">
                        <i class="fa fa-road"></i>
                      </div>
                      <div *ngIf="this.data.normal_flow_out != null" class="h4 mb-0">
                        {{this.data.normal_flow_out | number:'0.0-2'}}</div>
                      <div *ngIf="this.data.normal_flow_out == null" class="h4 mb-0">-</div>
                      <div class="d-flex">
                        <div class="text-muted text-uppercase font-weight-bold">Flow</div> <span
                          class="text-muted ml-1">(Vec/h)</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="card text-white" style="background-color: #006999;height: 242px;">
                    <div class="card-body">
                      <div class="h1 text-muted text-right mb-4">
                        <i class="icon-speedometer"></i>
                      </div>
                      <div *ngIf="this.data.normal_speed_out != null" class="h4 mb-0">
                        {{this.data.normal_speed_out | number:'0.0-2'}}</div>
                      <div *ngIf="this.data.normal_speed_out == null" class="h4 mb-0">-</div>
                      <div class="d-flex">
                        <div class="text-muted text-uppercase font-weight-bold">Avg Speed</div> <span
                          class="text-muted ml-1">(Km/h)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="card text-white" style="background-color: #009a9c;height: 242px;">
                    <div class="card-body">
                      <div class="h1 text-muted text-right mb-4">
                        <i class="fa fa-road"></i>
                      </div>
                      <div *ngIf="this.data.reverse_flow_out != null" class="h4 mb-0">
                        {{this.data.reverse_flow_out | number:'0.0-2'}}</div>
                      <div *ngIf="this.data.reverse_flow_out == null" class="h4 mb-0">-</div>
                      <div class="d-flex">
                        <div class="text-muted text-uppercase font-weight-bold">Flow</div> <span
                          class="text-muted ml-1">(Vec/h)</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="card text-white" style="background-color: #006999;height: 242px;">
                    <div class="card-body">
                      <div class="h1 text-muted text-right mb-4">
                        <i class="icon-speedometer"></i>
                      </div>
                      <div *ngIf="this.data.reverse_speed_out != null" class="h4 mb-0">
                        {{this.data.reverse_speed_out | number:'0.0-2'}}</div>
                      <div *ngIf="this.data.reverse_speed_out == null" class="h4 mb-0">-</div>
                      <div class="d-flex">
                        <div class="text-muted text-uppercase font-weight-bold">Avg Speed</div> <span
                          class="text-muted ml-1">(Km/h)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7 col-lg-7 col-xl-8">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="card text-white bg-gray-900">
                <div class="card-body">
                  <kendo-chart>
                    <kendo-chart-area background="none" [margin]="0" [height]="200">
                    </kendo-chart-area>
                    <kendo-chart-title text="Flow"></kendo-chart-title>
                    <kendo-chart-value-axis>
                      <kendo-chart-value-axis-item [min]="0" >
                      </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                    <kendo-chart-category-axis>
                      <kendo-chart-category-axis-item [categories]="data_normal_flow_out?.categories"
                        [plotBands]="categoryPlotBands" [title]="{ text: '' }">
                      </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-series>
                      <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                        [markers]="markers" [data]="data_normal_flow_out.max" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                        [markers]="markers" [data]="data_normal_flow_out.avg" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                        [markers]="markers" [data]="data_normal_flow_out.min" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8"
                        [markers]="markers" [data]="data_normal_flow_out.current">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                    </kendo-chart-series>
                    <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                    <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
                  </kendo-chart>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12">
              <div class="card text-white bg-gray-900">
                <div class="card-body">
                  <kendo-chart>
                    <kendo-chart-area background="none" [margin]="0" [height]="200">
                    </kendo-chart-area>
                    <kendo-chart-title text="Avg Speed"></kendo-chart-title>
                    <kendo-chart-value-axis>
                      <kendo-chart-value-axis-item [min]="0" [max]="120">
                      </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                    <kendo-chart-category-axis>
                      <kendo-chart-category-axis-item [categories]="data_normal_speed_out?.categories"
                        [plotBands]="categoryPlotBands" [title]="{ text: '' }">
                      </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-series>
                      <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                        [markers]="markers" [data]="data_normal_speed_out.max" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                        [markers]="markers" [data]="data_normal_speed_out.avg" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                        [markers]="markers" [data]="data_normal_speed_out.min" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8"
                        [markers]="markers" [data]="data_normal_speed_out.current">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                    </kendo-chart-series>
                    <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                    <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
                  </kendo-chart>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="card text-white bg-gray-900">
                <div class="card-body">
                  <kendo-chart>
                    <kendo-chart-area background="none" [margin]="0" [height]="200">
                    </kendo-chart-area>
                    <kendo-chart-title text="Flow"></kendo-chart-title>
                    <kendo-chart-value-axis>
                      <kendo-chart-value-axis-item [min]="0" >
                      </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                    <kendo-chart-category-axis>
                      <kendo-chart-category-axis-item [categories]="data_reverse_flow_out?.categories"
                        [plotBands]="categoryPlotBands" [title]="{ text: '' }">
                      </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-series>
                      <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                        [markers]="markers" [data]="data_reverse_flow_out.max" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                        [markers]="markers" [data]="data_reverse_flow_out.avg" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                        [markers]="markers" [data]="data_reverse_flow_out.min" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8"
                        [markers]="markers" [data]="data_reverse_flow_out.current">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                    </kendo-chart-series>
                    <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                    <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
                  </kendo-chart>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12">
              <div class="card text-white bg-gray-900">
                <div class="card-body">
                  <kendo-chart>
                    <kendo-chart-area background="none" [margin]="0" [height]="200">
                    </kendo-chart-area>
                    <kendo-chart-title text="Avg Speed"></kendo-chart-title>
                    <kendo-chart-value-axis>
                      <kendo-chart-value-axis-item [min]="0" [max]="120">
                      </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                    <kendo-chart-category-axis>
                      <kendo-chart-category-axis-item [categories]="data_reverse_speed_out?.categories"
                        [plotBands]="categoryPlotBands" [title]="{ text: '' }">
                      </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-series>
                      <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                        [markers]="markers" [data]="data_reverse_speed_out.max" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                        [markers]="markers" [data]="data_reverse_speed_out.avg" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                        [markers]="markers" [data]="data_reverse_speed_out.min" [dashType]="dashType">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                      <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8"
                        [markers]="markers" [data]="data_reverse_speed_out.current">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                      </kendo-chart-series-item>
                    </kendo-chart-series>
                    <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                    <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
                  </kendo-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>