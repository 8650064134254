export class DataBankTypeInfo {
    id: number;
    created_datetime:  Date;
    lastupdated_datetime: Date;
    name:  string;
    status:  number;
    desc: string;
    hashtag: string;
}
export class ReqAddDataBankType {
    name: string;
    status: number;
    desc: string;
    hashtag: string;
}