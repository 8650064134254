import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  currentTime: any;

  constructor() { }

  getTime() { return of(this.getDate()); }

  getDate() {
    let d = new Date();
    return d.toString();
  }
}
