import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {
  constructor(
    private api : ApiService,
  ) { }
  async getIns(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getIns(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addIns(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addIns(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmIns(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmIns(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
}
