<div class="row p-3">
    <div class="col-lg-4 col-md-4 col-sm-6">
        <div>
            ทางหลวง :</div>
        <div class="d-flex align-items-center">
            <kendo-combobox class="w-100" [data]="data_route" [allowCustom]="allowCustom" [textField]="''"
                [valueField]="''" [(ngModel)]="search_route" (valueChange)="handleValueRoute($event)" [filterable]="true"
                (filterChange)="handleFilterRoute($event)" [placeholder]="'ทั้งหมด'">
            </kendo-combobox>
        </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
        <div>ช่วงกิโลเมตร :</div>
        <div class="d-flex align-items-center">
            <div class="flex-fill"><span class="nowrap-txt mr-1">เริ่มต้น :</span></div>
            <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly
                    maxlength="4" [(ngModel)]="search_km_st" [ngModelOptions]="{standalone: true}"></div>
            <div class="flex-fill ml-1 mr-1"> + </div>
            <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly
                    maxlength="3" [(ngModel)]="search_m_st" [ngModelOptions]="{standalone: true}"></div>
            <div class="flex-fill"><span class="nowrap-txt ml-1 mr-1">สิ้นสุด :</span></div>
            <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly
                    maxlength="4" [(ngModel)]="search_km_en" [ngModelOptions]="{standalone: true}"></div>
            <div class="flex-fill ml-1 mr-1"> + </div>
            <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly
                    maxlength="3" [(ngModel)]="search_m_en" [ngModelOptions]="{standalone: true}"></div>
        </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
        <div>จำนวนชนิดรถ :</div>
        <div>
            <kendo-combobox class="form-control" [data]="model_list" [allowCustom]="allowCustom" [suggest]="true" [textField]="'name'" [valueField]="'id'" [(ngModel)]="model" [filterable]="true" [placeholder]="'ทั้งหมด'">
            </kendo-combobox>
        </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
        <div>Station :</div>
        <div>
            <kendo-combobox class="w-100" [data]="station_search_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="search_station" (valueChange)="handleValueStation($event)"
                (filterChange)="handleFilterStation($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
            </kendo-combobox>
        </div>
    </div>
    <!-- <div class="col-lg-4 col-md-4 col-sm-6">
        <div>Owner :</div>
        <div>
            <kendo-combobox class="w-100" [data]="data_owner" [allowCustom]="allowCustom" [textField]="'company_name'"
                [valueField]="'id'" [value]="search_owner" (valueChange)="handleValueOwner($event)"
                (filterChange)="handleFilterOwner($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
            </kendo-combobox>
        </div>
    </div> -->
    <!-- <div class="col-lg-4 col-md-4 col-sm-6">
        <div>Vendor :</div>
        <div>
            <kendo-combobox class="w-100" [data]="data_vendor" [allowCustom]="allowCustom" [textField]="'company_name'"
                [valueField]="'vendor_id'" [value]="search_vendor" (valueChange)="handleValueVendor($event)"
                (filterChange)="handleFilterVendor($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
            </kendo-combobox>
        </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
        <div>กลุ่มอุปกรณ์ :</div>
        <div>
            <kendo-combobox class="w-100" [data]="data_device_group" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="search_device_group" (valueChange)="handleValueDeviceGroup($event)"
                (filterChange)="handleFilterDeviceGroup($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
            </kendo-combobox>
        </div>
    </div> -->
    <div class="col-lg-8 col-md-8 d-flex align-items-end justify-content-end">
        <!-- <div class="mt-1">
            <button type="button" (click)="search()" class="btn btn-success pull-right text-white"> <i
                    class="fa fa-search"></i>
                Map View </button>
        </div> -->
        <div class="mt-1 ml-1">
            <button *ngIf="isAdd" type="button" (click)="search()" class="btn btn-primary pull-right text-white"> <i
                    class="fa fa-search"></i>
                ค้นหา </button>
        </div>
    </div>

    <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">

        <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn text-white pointer" [ngClass]="(viewType == 1)?'btn-primary':'btn-dark'">
                <input type="radio" name="options" id="option1" autocomplete="off" [value]="1" [(ngModel)]="viewType"
                    (click)="changeView(1)"> <i class="fa fa-bars mr-1"></i>List
            </label>
            <label class="btn text-white pointer" [ngClass]="(viewType == 2)?'btn-primary':'btn-dark'">
                <input type="radio" name="options" id="option1" autocomplete="off" [value]="2" [(ngModel)]="viewType"
                    (click)="changeView(2)"><i class="fa fa-map mr-1"></i> Map
            </label>
        </div>
        <!-- <button (click)="openModal()" type="button" class="btn btn-primary text-white">
            <i class="fa fa-plus"></i>
            เพิ่ม Smart micro</button> -->
    </div>

    <!-- List view -->
    <div *ngIf="viewType == 1" class="col-12 mt-3">
        <table class="table-main table table-outline mb-0">
            <thead class="thead-dark" style="background-color: black;">
                <tr>
                    <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
                    <th class="text-center text-light table-header">อุปกรณ์</th>
                    <th class="text-center text-light table-header">Station</th>
                    <th class="text-center text-light table-header">ประเภทกลุ่มอุปกรณ์</th>
                    <th class="text-center text-light table-header">ประเภทย่อยอุปกรณ์</th>
                    <th class="text-center text-light table-header">Create date</th>
                    <th *ngIf="isEdit" class="text-center text-light table-header">Action</th>
                </tr>
            </thead>
            <tbody>

                <tr *ngIf="!is_loading&&(!data_list || data_list?.length < 1)">
                    <td colspan="7" style="height: 50px;">
                        ไม่พบข้อมูล
                    </td>
                </tr>
                <tr *ngIf="is_loading">
                    <td colspan="7" style="height: 50px;">
                        กำลังโหลด ..
                    </td>
                </tr>
                <tr *ngFor="let i of data_list | slice: (currentPage-1) * itemsPerPage : (currentPage-1) * itemsPerPage + itemsPerPage;index as idx">
                    <td>
                        {{(idx+1)+(itemsPerPage*(currentPage-1))}}
                    </td>
                    <td>{{i.name||''}}</td>
                    <td>{{i.station_str||''}}</td>
                    <td>{{i.device_type_str||''}}</td>
                    <td>{{i.device_sub_type_str||''}}</td>
                    <td>{{i.create_date | date:'dd/MM/yyyy HH:mm:ss':'+0700'}}</td>
                    <td *ngIf="isEdit">
                        <button class="ml-1 mr-1 btn btn-warning" (click)="openModal(i)">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </button>
                        <button class="ml-1 mr-1 btn btn-danger" (click)="rmlist(i.id)">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-footer">
                        <div class="table-footer-inside d-flex align-items-center justify-content-between">
                            <div class="ml-3 pt-3">

                                <pagination [(ngModel)]="currentPage" [totalItems]="data_list.length"
                                    [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"  [rotate]="false" [maxSize]="20"
                                    previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true"
                                    firstText="&laquo;" lastText="&raquo;">></pagination>

                            </div>
                            <div></div>
                            <div class="mr-3 ml-2">total: {{totalItems||0}}</div>

                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Map view -->
    <div class="col-12" style="position: relative;">
        <div class="map" id="map_sm" [ngClass]="(viewType == 2 && !is_loading) ? 'map' : 'map-hide'"></div>
        
        <div *ngIf="is_loading && viewType == 2" class="lds-circle"><div></div></div>
    </div>
</div>