import { Component, OnInit ,Input,Output, EventEmitter  } from '@angular/core';

import { DeviceService } from 'src/app/services/user/device.service';
import { EventService } from 'src/app/services/user/event.service';
import * as _ from 'lodash'
import * as moment from 'moment'
import { FuncService } from 'src/app/services/user/func.service';
@Component({
  selector: 'app-effect',
  templateUrl: './effect.component.html',
  styleUrls: ['./effect.component.scss']
})
export class EffectComponent implements OnInit {
  @Input() event_group : any;
  @Input() values : any;
  @Output() result = new EventEmitter<string>();

  allowCustom: boolean = false;
  changed: boolean = false
  evtType : number = 1;
  route_list: any
  route: any
  effect_group_id : number = 1
  
  effList : any = []
  
  dir:number=1
  st_route: any
  st_km:number
  st_m:number
  en_route: any
  en_km:number
  en_m:number

  effAdd = {
    id:null,
    direction: 1,
    st_route: null,
    st_km: null,
    st_m: null,
    en_route: null,
    en_km: null,
    en_m: null,
    effect_desc: ""
  }
  newList : any[] = []
  constructor(
    private devS: DeviceService,
    private evt_S: EventService,
    private func: FuncService,
  ) { }

  ngOnInit(): void {
    console.log(this.values)
    this.addNewEfflist()
    this.getData()
  }
  EvtOutput(value: any) {
    this.result.emit(value);
  }

  async save(){
    console.log(this.effList)
    let data : any = _.concat(this.effList,this.newList)
    let arr : any = []
    /**
     *  {
            "id":15,
            "direction": 1,
            "st_route": 118,
            "st_km": 12,
            "st_m": 13,
            "en_route": 305,
            "en_km": 32,
            "en_m": 21,
            "effect_desc": "'?.0=0.?'"
        },
     */
    for (const i of data) {
      let chkVal : boolean = false
      for (const key in i) {
        console.log(key)
        if(i[key] && (key != 'direction')) chkVal = true
        // if (Object.prototype.hasOwnProperty.call(i, key)) {
        //   const element = i[key];
          
        // }
      }
      if(chkVal){
        i.st_route = Number(i.st_route)
        i.st_km = Number(i.st_km)
        i.st_m = Number(i.st_m)
        i.en_route = Number(i.en_route)
        i.en_km = Number(i.en_km)
        i.en_m = Number(i.en_m)
        arr.push(i)
      }
    }
    let res : any = await this.evt_S.addevtEff({
      event_id:this.values.id,
      effect_list:arr,
    })
    if(res) {
      this.newList = []
      this.addNewEfflist();
      this.func.alertpopup("Success");
      this.getEffect();
    }
  }
  async getData() {
    this.route_list = await this.devS.getRoute()
    this.route = this.route_list
    this.getEffect()
  }
  async getEffect(){
    this.effList = []
    let res : any = await this.evt_S.getEvtEff({event_id:this.values.id})
    if(res.length) {
      console.log(this.route_list.length)
      for (const i of res) {
        i.st_route = (i.st_route+'').padStart(4,'0')
        i.en_route = (i.en_route+'').padStart(4,'0')
      }
      this.effList = res
    }
  }

  FilterRoute(evt: any) {
    if (evt.length > 0) {
      this.route_list = this.route_list.filter(res => res.includes(evt))
    } else if (evt.length == 0) {
      this.route_list = this.route
    } else {

    }
  }
  async ChageRoute(evt) {
    // console.log(evt)
  }
  async rmEff(id){
    console.log(">>",id)
    let result = await this.func.alertpopup('<h4>คุณยืนยันการลบใช่หรือไม่</h4>', `<i class="icon-info mr-2"></i>ลบผลการทบ`, 1);
    if (result) {
      let res : any = await this.evt_S.rmevtEff({id:id})
      if(res) {
        this.func.alertpopup("Success");
        this.getEffect();
      }
    }
    
  }
  addNewEfflist(){this.newList.push(_.clone(this.effAdd))}
}
