export class Vendor {
    vendor_id: number;
    company_name: string;
    phone_number: string;
    status: number;
    address: number;
    detail: string;
    hashtag: string;
    email: string;
    create_date: Date;
    update_date: Date;
}
export class ReqAddVendor {
    company_name: string;
    phone_number: string;
    status: number;
    address: number;
    detail: string;
    email:string;
    hashtag: string;
}

export class VendorContact {
    contact_id: number;
    vendor_id:number;
    contact_name: string;
    contact_tel: string;
    contact_email: string;
    contact_detail: string;
    contact_type: number;
    status: number;
    create_date: Date;
    update_date: Date;
}
export class ReqAddVendorContact {
    contact_id: number;
    contact_name: string;
    contact_tel: string;
    contact_email: string;
    contact_detail: string;
    status: number;
    contact_type: number;
}