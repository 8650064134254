import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-firmware',
  templateUrl: './firmware.component.html',
  styleUrls: ['./firmware.component.scss']
})
export class FirmwareComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
