import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiResponse, PagableData, ImgUploadResponse } from '../../models/common';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { SignTypeInfo, ReqAddSignType } from '../../models/sign-control/sign-type';
import { StatusEnum } from '../../models/common-enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UploadService {
  
  domain: string;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.domain = environment.api.domain_sign_control;
  }

  uploadImages(image: File[], bucket_name: string, path: string, image_name?: string): Observable<ApiResponse<ImgUploadResponse>> {
    const uri = `upload/image`;
    const url = `${this.domain}/${uri}`;
    let formData = new FormData();
    formData.append('bucket_name', bucket_name.toString());
    formData.append('path', path.toString());
    if(image_name)
      formData.append('image_name', image_name.toString());
    
    image.forEach(item => {
      formData.append('image', item);
    });
    return this.http.post<ApiResponse<ImgUploadResponse>>(url, formData);
  }
  uploadATISImages(image: any, bucket_name: string, path: string, image_name?: string): Observable<ApiResponse<ImgUploadResponse>> {
    const uri = `upload/image`;
    const url = `${this.domain}/${uri}`;
    let formData = new FormData();
    formData.append('bucket_name', bucket_name.toString());
    formData.append('path', path.toString());
    formData.append('image_name', image_name.toString());
    formData.append('image', image, image_name + '.png');
    return this.http.post<ApiResponse<ImgUploadResponse>>(url, formData);
  }

  deleteImage(link: string): Observable<ApiResponse<any>> {
    let params = new HttpParams();
    if (!_.isNil(link)) {
      params = params.append('link', link.toString());
    }
    const uri = `upload/image`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      params: params
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }

  uploadFile(files: File[], bucket_name: string, path: string): Observable<ApiResponse<ImgUploadResponse>> {
    const uri = `upload/file`;
    const url = `${this.domain}/${uri}`;
    let formData = new FormData();
    formData.append('bucket_name', bucket_name.toString());
    formData.append('path', path.toString());
    files.forEach(item => {
      formData.append('file', item);
    });
    return this.http.post<ApiResponse<ImgUploadResponse>>(url, formData);
  }
}
