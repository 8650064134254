export class Owner {
    id: number;
    company_name: string;
    phone_number: string;
    status: number;
    address: string;
    contact_name: string;
    detail: string;
    hashtag: string;
    email: string;
    created_datetime?: Date;
    lastupdated_datetime?: Date;
}
export class ReqAddOwner {
    company_name: string;
    phone_number: string;
    status: number;
    address: string;
    contact_name: string;
    detail: string;
    email: string;
    hashtag: string;
}