export const mainRouteUrl = "previous-version";
export const id = 1400;

export const menuItems = [
    {
        id: 1400,
        name: 'Previous Version',
        url: '/previous-version',
        icon: 'fa fa-clone',
        parent_id: id
    }
];