import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { NewsTypeService } from 'src/app/services/information/news-type.service';
import { NewsTypeInfo, ReqAddNewsType } from 'src/app/models/information/news-type';
import { ActionEnum, StatusEnum } from 'src/app/models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { permission } from 'src/app/views/information/_menu';

@Component({
  selector: 'app-news-type',
  templateUrl: './news-type.component.html',
  styleUrls: ['../boardcast.component.scss']
})
export class NewsTypeComponent implements OnInit {
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  statusEnum = StatusEnum;
  myDate = new Date();
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  data_list: NewsTypeInfo[];
  category_list = [
    { id: 1, name: "Incident" },
    { id: 2, name: "Information" }
  ];
  category: any;
  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" }
  ];
  status: any;
  selectItem: any = {
    name: "",
    desc: "",
    code: "",
    status: null
  };
  public allowCustom: boolean = false;
  res_message: any;
  category_modal: any;
  status_modal: any;
  search_name: any;

  isAdd: boolean = false;
  isEdit: boolean = false;
  constructor(private commonService: CommonService, private newsTypeService: NewsTypeService) { 
    this.commonService.activityLog(ActionEnum.Get, 'Information ➡ Broadcast ➡ ประเภทข่าวสาร').subscribe(res => { }, error => { console.log(error); });
  }

  async ngOnInit() {
    //this.getRoute();
    this.is_loading = true;
    this.getData();
    this.isAdd = await this.commonService.getPermission(permission.boardcast.news_type.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.boardcast.news_type.edit_id).toPromise();
  }

  handleValueStatus(value) {
    if (value) {
      //console.log(value);
      this.status = value;
    } else {
      this.status = null;
    }
  }
  handleValueCategory(value) {
    if (value) {
      this.category = value;
    } else {
      this.category = null;
    }
  }
  is_loading: boolean = false;
  route: string;
  public data_route: Array<string>;
  route_list: string[];
  getRoute() {
    this.commonService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }

  handleFilterHub(value) {
    if (value.length > 0) {
      this.data_route = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route = _.cloneDeep(this.route_list);
    } else {
      this.data_route = [];
    }
  }
  handleValueHub(value) {
    if (value) {
      //console.log(value);
      this.route = value;
    } else {
      this.route = null;
    }
  }
  search() {
    this.currentPage = 1;
    this.getData();
  }
  getData() {
    this.is_loading = true;
    this.data_list = [];
    let sta = null;
    if (this.status)
      sta = this.status.id;
    let cate = null;
    if (this.category)
      cate = this.category.id;
    this.newsTypeService.getNewsType(this.search_name, sta, cate, this.currentPage, this.itemsPerPage).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = res.data.data;
              this.totalItems = res.data.total_items;
            } else {
              this.totalItems = 0;
            }
          } else {
            this.totalItems = 0;
          }
        } else {
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
      });
  }
  openAddModal() {
    this.selectItem = new NewsTypeInfo();
    this.selectItem.name = "";
    this.category_modal = null;
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.addModal.show();
  }
  openEditModal(item?: any) {
    this.selectItem = item;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.category_modal = this.category_list.find(x => x.id == item.category_id);
    this.addModal.show();
  }
  openDeleteModal(item?: any) {
    this.selectItem = item;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.category_modal = this.category_list.find(x => x.id == item.category_id);
    this.deleteModal.show();
  }
  clearData() {
    this.selectItem = new NewsTypeInfo();
  }
  handleValueStatusModal(value) {
    if (value) {
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  handleValueCategoryModal(value) {
    if (value) {
      this.category_modal = value;
    } else {
      this.category_modal = null;
    }
  }
  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.getData();
    }
  }
  addData() {
    this.is_loading = true;
    let req = new ReqAddNewsType();
    req.name = this.selectItem.name;
    if (this.category_modal)
      req.category_id = this.category_modal.id;
    req.status = this.status_modal.id;
    this.res_message = "";
    this.newsTypeService.addNewsType(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { request: req, response: res };
            this.commonService.activityLog(ActionEnum.Add, 'Information ➡ Broadcast ➡ ประเภทข่าวสาร', JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `เพิ่มประเภทข่าวสารสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editData() {
    this.is_loading = true;
    let req = new ReqAddNewsType();
    req.name = this.selectItem.name;
    if (this.category_modal)
      req.category_id = this.category_modal.id;
    req.status = this.status_modal.id;
    this.res_message = "";
    this.newsTypeService.editNewsType(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { id: this.selectItem.id, request: req, response: res };
            this.commonService.activityLog(ActionEnum.Edit, 'Information ➡ Broadcast ➡ ประเภทข่าวสาร', JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `แก้ไขประเภทข่าวสารสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  deleteData() {
    this.is_loading = true;
    let req = new ReqAddNewsType();
    req.name = this.selectItem.name;
    req.category_id = this.category_modal.id;
    req.status = this.status_modal.id;
    this.res_message = "";
    this.newsTypeService.deleteNewsTypeEditStatus(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.commonService.activityLog(ActionEnum.Delete, 'Information ➡ Broadcast ➡ ประเภทข่าวสาร', JSON.stringify({ id: this.selectItem.id})).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `ลบประเภทข่าวสารสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

}
