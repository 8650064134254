<div class="card m-3">
    <div class="card-header table-header">รายงานข้อมูลรายคันจากอุปกรณ์ ตรวจวัด</div>
    <div class="card-body">
        <div class="row">
            <div class="col-4">
                Station
                <kendo-combobox class="form-control" [data]="setRouteList"
                [allowCustom]="allowCustom" [suggest]="true" [(ngModel)]="route_id" [filterable]="true"
                (filterChange)="FilterRoute($event)"
                [placeholder]="'ทั้งหมด'">
                </kendo-combobox>
            </div>
            <div class="col-7">
                ช่วงกิโลเมตร
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">เริ่มต้น</span>
                    </div>
                    <input type="text" class="form-control" placeholder="กม." [(ngModel)]="start_km">
                    <div class="input-group-prepend">
                        <span class="input-group-text">+</span>
                    </div>
                    <input type="text" class="form-control" placeholder="ม." [(ngModel)]="start_m">
                    <div class="input-group-prepend">
                        <span class="input-group-text">สิ้นสุด</span>
                    </div>
                    <input type="text" class="form-control" placeholder="กม." [(ngModel)]="end_km">
                    <div class="input-group-prepend">
                        <span class="input-group-text">+</span>
                    </div>
                    <input type="text" class="form-control" placeholder="ม." [(ngModel)]="end_m">
                </div>
            </div>
            <div class="col-1 mt-3 text-right">
                <button class="btn btn-info" (click)="getPerStation()">ค้นหา</button>
            </div>
        </div>
    </div>
</div>

<div class="table-header mb-3 text-right">
    <button class="btn btn-success m-3 pl-4 pr-4" (click)="openModal()">Add</button>
</div>
<div class="ml-3 mr-3" *ngIf="station_list.length">
    <div class="card-header mb-3 action-BG" *ngFor="let i of station_list | slice: (currentPage-1) * itemsPerPage : (currentPage-1) * itemsPerPage + itemsPerPage;index as idx">
        {{i.survey||''}} ทล.{{i.route | number:'4.0-0' | noComma}} ตอนควบคุม {{i.control||'-'}} {{i.name||''}} ม. {{i?.km}}+{{i?.m| number:'3.0-0'}}{{i?.m? '':'000'}}
        <div>
            <button class="btn btn-warning" (click)="gotoSensor(i)">View</button>
            <button class="btn btn-info" (click)="openModal(i)">Edit</button>
            <button class="btn btn-danger" (click)="openImportfile(i)">Import</button>
            <button class="btn btn-outline-danger" (click)="rmlist(i.id)">Delete</button>
        </div>
    </div>
</div>
<div class="table-header p-3 d-flex justify-content-between align-items-center">
    <pagination [(ngModel)]="currentPage" [totalItems]="station_list.length"
    [itemsPerPage]="itemsPerPage" [rotate]="false" [maxSize]="20"
    previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true"
    firstText="&laquo;" lastText="&raquo;">></pagination>
    total : {{total||0}}
</div>