<div class="menu-head gray-BG pl-4 pr-4 pt-4">
    <h5 class="d-flex">
        <i class="fa fa-plus-circle pri-txt mr-2" style="line-height: 1.3;"></i>
        {{!values? 'Add':'Edit'}} Event 
        <app-evt-status [event_group]="event_group" [_Add]="values? false:true" (newEvent)="evtType = $event"></app-evt-status>
    </h5>
</div>
<!-- Event status 1 -->
<app-begin [values]="values" [event_group]="event_group" (result)="$event ? confirm():close()" *ngIf="evtType==1"></app-begin>

<!-- Event status 2 -->
<app-effect [values]="values" [event_group]="event_group" (result)="$event ? confirm():close()" *ngIf="evtType==2"></app-effect>

<!-- Event status 3 -->
<div *ngIf="evtType==3" class="modal-body text-center">
    <div class="row">
        <div class="col-12">
            <div class="pr-0 pl-0 modal-header menu-BG">
                <button class="btn menu-BT" [ngClass]="{'active': evtConfirmMenu == 1}" (click)="evtConfirmMenu = 1">ยืนยันเหตุการณ์</button>
                <button class="btn menu-BT" [ngClass]="{'active': evtConfirmMenu == 2}" (click)="evtConfirmMenu = 2">ไม่พบเหตุการณ์</button>
            </div>
        </div>
        <div class="col-12 mt-3">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ยืนยันเหตุการณ์</p>
                </div>
                <label *ngFor="let i of evtConfirmList" class="ml-3 src">
                    <input type="radio" name="confirm" id="" [value]="i.id" [(ngModel)]="evtConfirm" [disabled]="values.status!=1">
                    {{i.name}}
                </label>
                <!-- <label class="ml-3 src">
                    <input type="radio" name="confirm" id="" [value]="2" [(ngModel)]="evtConfirm" [disabled]="values.status!=1">
                    สิ้นสุดแล้ว
                </label> -->
            </div>
        </div>
        <div class="col-12 text-right">
            <button type="button" class="btn btn-danger mr-2" (click)="close()">ปิด</button>
            <button type="button" class="btn btn-info" (click)="save()">ยืนยัน</button>
        </div>
    </div>
</div>
<!-- Event status 4 -->
<div *ngIf="evtType==4" class="modal-body text-center">
    <div class="row">
        <div class="col-12 moreImg-BG" (scroll)="event_group == 2 ? onScroll($event):''">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text titleImg">รูปภาพประกอบ</p>
                </div>
                <div class="allImg-BG">
                    <div *ngFor="let i of images_Evtdetail" class="addimg-BG position-relative">
                        <img [src]="i" alt="images_Evtdetail" style="max-height: 60px;" onerror="this.src='assets/noimage.png'" (click)="clickImg(i)">
                        <button *ngIf="!disabled" (click)="rmImage(i)">X</button>
                    </div>
                    <input type="file" name="" id="addImage" accept="image/*" (change)="uploadImg(ImagesEvt,1)" #ImagesEvt>
                    <Label *ngIf="!disabled && (event_group !== 2)" for="addImage" class="ml-3 mb-0 btn btn-info addImg-BT">
                        <i class="icon-plus"></i>
                    </Label>    
                </div>
            </div>
            <div *ngIf="!outofImg">
                <div *ngIf="event_group == 2" class="ml-3 mb-0 btn btn-info loadImg-BT viewmore-BT" style="width: auto;">
                    <i *ngIf="!_Imgloading" class="icon-options"></i>  
                    
                    <div *ngIf="_Imgloading" class="loader"></div>
                    <span *ngIf="!_Imgloading" class="white-txt ml-2">View more</span>
                </div>
            </div>
        </div>
        <div class="col-12 text-right">
            <button type="button" class="btn btn-danger mr-2" (click)="close()">ปิด</button>
            <button type="button" class="btn btn-info mr-2" (click)="save()">ยืนยัน</button>
            <button type="button" class="btn btn-outline-info" (click)="save()">สิ้นสุดเหตุการณ์</button>
        </div>
    </div>
</div>
<!-- Event status 5 -->
<app-evt-complete [values]="values" *ngIf="evtType==5" (CkEvt)="($event)? confirm():close()"> </app-evt-complete>

<!-- <div class="modal-footer">
</div> -->