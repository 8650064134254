export class MaInfo {
    ma_id?: number;
    ref_id?: number; //device_id or software_id
    type_id?: number;//hardware:1 , software:2
    station_id?: number;
    user_id?: number;
    username?: string;
    detail?: string;
    status?: number;
    connection_status?: number;
    image_url?: string[];
    create_date?: Date;
    update_date?: Date;
    item_name?: string;
    lat?: number;
    lng?: number;
}
export class MaLogInfo {
    chat_id?: number;
    ma_id?: number;
    type_id?: number;//hardware:1 , software:2
    ma_price?: number;
    station_id?: number;
    user_id?: number;
    username?: string;
    message?: string;
    status?: number;
    image_url?: string[];
    create_date?: Date;
    update_date?: Date;
}