import { VehClassInfo } from "../assets-management/device";
import { BoardsignInfo, SignInfo, StructureLayerInfo } from "./boardsign";
export class SignEventInfo {
    id?: number;
    name?:string;
    sign_id?: number;
    blink?: boolean;
    duration?: number;
    status?: number;
    signal?: SignEventSignal;
    media?: SignEventMedia;
    sign?: SignEvent;
}

export class SignEventSignal {
    event_type?: number; // 1:ความเร็วเกิน 2:ผิดช่องจราจร
    class?: number; // ประเภทรถ
    lane?: number;
    rain?: number;
}
export class SignEvent {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
}
export class SignEventMedia {
    url?: string;
    content_type?: string;
    behavior_type?: number;
    special_property?: SignEventMediaSpecial;
}
export class SignEventMediaSpecial {
    text_items?: SignEventMediaTextItem[];
}

export class SignEventMediaTextItem {
    text_type?: number;
    text?: string;
    font_size?: number;
    color?: string;
    font_family?: string;
    font_weight?: string;
    x?: number;
    y?: number;
    is_center?: boolean;

    text_speed?: number;
    lane?: number;
    class?: number;
}

export class ConfigRoadByLane {
    lane_no?: number;
    queue_length_trigger_allow?: boolean;
    queue_length_trigger?: number;
    min_heading?: number;
    max_heading?: number;
    pvr_stream?: boolean;
    lane_total?: number;
    direction?: number;
    road_type?: number;
    veh_class?: VehClass[];
    signal?: ConfigRoadSignal;
}

export class ConfigRoadSignal {
    wrong_way?: boolean;
    over_speed?: boolean;
    direction?: number;
}

export class VehClass {
    id?: number;
    class_no?: number;
    normal_speed?: number;
    rain_speed?: number;
    suggest_speed?: number;
    rain_suggest_speed?: number;
    allow?: boolean;
    veh_class_name?: string;
}

export class ReqAddSuggest {
    criteria_id?: number;
    boardsign_id?: number;
    probability?: number;
    incident_type?: number;
    status?: number;
    signs?: ReqSignMedia[];
}
export class ReqSignMedia {
    sign_id?: number;
    media_id?: number;
    media_type?: number;
}
export class ResSuggest {
    inc_type?: number;
    boardsign?: BoardsignInfo;
    criteria?: BoardSuggestCriteria[];
}
export class BoardSuggestCriteria {
    id?: number;
    create_date?: Date;
    lastupdate_date?: Date;
    boardsign_id?: number;
    probability?: number;
    incident_type?: number;
    status?: number;
    signs?: SignInfo[];

    //view
    layers?: Array<StructureLayerInfo[]>;
    index?: number;
}
