import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { FuncService } from 'src/app/services/user/func.service';
import * as _ from 'lodash'
import { ConfigroadService } from 'src/app/services/user/configroad.service';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
import { ApiService } from 'src/app/services/user/api.service';
@Component({
  selector: 'app-smartmicro-modal',
  templateUrl: './smartmicro-modal.component.html',
  styleUrls: ['./smartmicro-modal.component.scss']
})
export class SmartmicroModalComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  values_list: any[];
  deviceDetail: any = {
    device_name : ''
  }
  _changed: boolean = false;
  _Wrongway: boolean = false;
  _Overspeed: boolean = false;
  qqM: number;
  saveBT: any = false;
  lane: number = 1;
  _Prv: boolean = true
  _Direction: boolean = true
  QMchecked: boolean = false
  _Notedit: boolean = true
  res_data: any;
  ResMessage: any;
  _isNew: boolean = true
  allowCustom = true;
  laneList = [1, 2, 3, 4, 5, 6, 7, 8];
  dirList = [
    { id: 1, name: "หน้า" },
    { id: 2, name: "หลัง" },
    { id: 3, name: "ไหล่ทาง" }
  ];
  roadList = [
    { id: 1, name: "ทางหลัก (MD)" },
    { id: 2, name: "ทางขนาน (FT)" }
  ];
  lane_total: number = 4;
  dir_all: any;
  road_type: any;
  _min_heading: number;
  _max_heading: number;
  disabledSave_BT : boolean = false
  constructor(
    private func: FuncService,
    public bsModalRef: BsModalRef,
    private confS: ConfigroadService,
    private commonService: CommonService,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    console.log(this.values)
    this.deviceDetail = this.values
    // this.deviceDetail.device_id = 1691
    if (this.deviceDetail) {
      if (this.deviceDetail.status) this.deviceDetail.stauts_name = (this.deviceDetail.status == 1) ? 'Active' : 'Inactive'
      if (this.deviceDetail.model_id == 20) {
        this.deviceDetail.model_name = 4
      } else if (this.deviceDetail.model_id == 21) {
        this.deviceDetail.model_name = 8
      }
    }
    this.getData()
  }
  async getData() {
    this.res_data = await this.confS.getSmcConfigRoad({device_id: this.deviceDetail.device_id })
    //this.setValuesfromLane();
    this.setValues();
    this.genConfigAllLane();
  }
  setValuesfromLane() {
    this.values = _.filter(this.res_data, { "lane_no": this.lane })
    if (this.values.length) {
      this.values = this.values[0]
      this._Wrongway = (this.values.signal.wrong_way) ? true : false
      this._Overspeed = (this.values.signal.over_speed) ? true : false
      this._Direction = (this.values.signal.direction === 1 ) ? true : false;
      //this._Prv = this.values.pvr_stream
    }
  }
  setValues() {
    if (this.res_data.length) {
      this._Prv = this.res_data[0].pvr_stream;
      this.lane_total = this.res_data[0].lane_total ? this.res_data[0].lane_total : 4;
      this._min_heading = this.res_data[0].min_heading;
      this._max_heading = this.res_data[0].max_heading;
      if(this.res_data[0].direction){
        this.dir_all = this.dirList.find(x => x.id == this.res_data[0].direction);
      }
      if(this.res_data[0].road_type){
        this.road_type = this.roadList.find(x => x.id == this.res_data[0].road_type);
      }else{
        this.road_type = this.roadList.find(x => x.id == 1);
      }
    }
  }
  close() {
    this.deviceDetail = this.values
    if (this._changed && !_.isEmpty(this.values)) {
      this.cancel()
    } else {
      this.bsModalRef.hide();
    }
  }

  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  cancel() {
    console.log(this._changed)
    this.event.emit(this._changed);
    this.bsModalRef.hide();
  }
  changeLane(l_id?: number) {
    this.lane = l_id
    this.setValuesfromLane()
  }
  changeLaneTab(l?: number) {
    this.lane = l;
  }
  changeLaneTotal(e) {
    this.genConfigAllLane();
    if(this.lane_total<this.lane){
      this.lane = 1;
    }
  }
  genConfigAllLane() {
    this.values_list = [];
    console.log(this.lane_total,this.values_list)
    for (let i = 0; i < this.lane_total; i++) {
      let v_lane = this.res_data.find(x => x.lane_no == (i + 1));
      if(!v_lane) v_lane={
        signal:{
          wrong_way:false,
          over_speed:false,
          direction:1,
        },
        lane_no:i+1,
        veh_class:[this.res_data[0].veh_class]
      }
      console.log(v_lane)
      v_lane.signal_wrong_way_ = (v_lane.signal.wrong_way) ? true : false;
      v_lane.signal_over_speed_ = (v_lane.signal.over_speed) ? true : false;
      v_lane.signal_direction_ = (v_lane.signal.direction === 1) ? true : false;
      if(v_lane.veh_class){
        if(!v_lane.veh_class[0].id){
          v_lane.veh_class = _.cloneDeep(this.res_data[0].veh_class);
          v_lane.veh_class.forEach(item => {
            item.normal_speed = null;
            item.rain_suggest_speed = null;
            item.rain_speed = null;
            item.suggest_speed = null;
            item.allow = true;
          });
          v_lane.signal_wrong_way_ = false;
          v_lane.signal_over_speed_ = false;
          v_lane.signal_direction_ = false;
          v_lane.signal.wrong_way = 0;
          v_lane.signal.over_speed = 0;
          v_lane.signal.direction = 0;
          v_lane.queue_length_trigger_allow = false;
          v_lane.queue_length_trigger = null;
        }
      }
      // if(v_lane.veh_class){
      //   v_lane.veh_class.forEach(item => {
      //     item.id =  +`${item.class_no}${i}00`;
      //     item.device_id = this.deviceDetail.device_id;
      //     item.status = 1;
      //   });
      // }
      this.values_list.push(v_lane);
    }

  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }
  switchChange(e, lane) {
    this.values_list.forEach(item => {
      if(item.lane_no == lane.lane_no){
        item.signal_direction_ = e;
        item.signal.direction = (e) ?  1 : -1;
      }
    });
  }
  wrongWayChange(e, lane) {
    this.values_list.forEach(item => {
      if(item.lane_no == lane.lane_no){
        item.signal_wrong_way_ = e;
        item.signal.wrong_way = (e) ? 1 : 0;
      }
    });
  }
  overSpeedChange(e, lane) {
    this.values_list.forEach(item => {
      if(item.lane_no == lane.lane_no){
        item.signal_over_speed_ = e;
        item.signal.over_speed = (e) ? 1 : 0;
      }
    });
  }
  queueallowChange(e, lane) {
    this.values_list.forEach(item => {
      if(item.lane_no == lane.lane_no){
        item.queue_length_trigger_allow = e;
      }
    });
  }
  save() {
    let data: any = {
      config: [
        {
          queue_length_trigger : this.qqM||'',
          lane_no: this.lane,
          veh_class: [

          ]
        }
      ]
    }
    data.config[0].veh_class.push({
      class_no: 1,
      normal_speed: 50,
      rain_speed: 40,
      suggest_speed: 50,
      rain_suggest_speed: 50,
      allow: true
    })
    console.log(data)
  }
  async save2(){
    this.disabledSave_BT = true
    this.values_list.forEach(item => {
      item.pvr_stream = this._Prv;
      if(this._min_heading != null){
        item.min_heading = this._min_heading;
      }else{
        item.min_heading = null;
      }
      if(this._max_heading != null){
        item.max_heading = this._max_heading;
      }else{
        item.max_heading = null;
      }
      if(this.dir_all != null){
        item.direction = this.dir_all.id;
      }else{
        item.direction = null;
      }
      if(this.road_type != null){
        item.road_type = this.road_type.id;
      }else{
        item.road_type = null;
      }
      item.lane_total = this.lane_total;
    });

    const req1 = _.cloneDeep(this.values_list);
    let txt_req = _.cloneDeep(req1);

    let res: any = await this.confS.addSmcConfigRoad(this.deviceDetail.device_id,this.values_list)
    this.disabledSave_BT = false
    if (res) {
      this._changed = true
      this.func.alertpopup("ดำเนินการสำเร็จ")
      // this.ResMessage = 'OK';
      // this.swalSuccess.show();
      this.getData()

      let request_data = { url: `${this.apiService.api.configRoad}/${this.deviceDetail.device_id}${this.apiService.api.smc}`, request: req1 };
      this.commonService.activityLog(ActionEnum.Edit, `Assets Management ➡ Setting ➡ Smart Micro`, JSON.stringify(request_data)).subscribe(res => { }, error => { console.log(error); });
    }
  }
  checkDegree(evt) {
    let min: number = Number(evt.target.min)
    let max: number = Number(evt.target.max)
    let val: number = Number(evt.target.value)
    if (val < min) evt.target.value = evt.target.min
    if (val > max) evt.target.value = evt.target.max
    return +evt.target.value
  }
  // ==========================================
}
