export class NewsInfo {
    id?: number;
    created_datetime?:  Date;
    lastupdated_datetime?: Date;
    title?: string;
    news_type_id?: number;
    hashtag?: string;
    route_id?: number;
    province_id?: number;
    district_id?: number;
    noti_type_id?: number;
    status?: number;
    desc?: string;
    noti_text?: string;
    short_message?: string;
    noti_user_id?: number;
    lat?: number;
    lng?: number;
    location_name?: string;
    broadcast_channel?: string;
    broadcast_time?: Date;
    is_ask_confirm?: boolean;
    is_confirm?: boolean;
    is_broadcast?: boolean;
    images?: NewsImage[];
}
export class ReqAddNews {
    title?: string;
    news_type_id?: number;
    hashtag?: string;
    route_id?: number;
    province_id?: number;
    district_id?: number;
    noti_type_id?: number;
    status?: number;
    desc?: string;
    noti_text?: string;
    short_message?: string;
    noti_user_id?: number;
    lat?: number;
    lng?: number;
    location_name?: string;
    broadcast_channel?: string;
    broadcast_time?: Date;
    is_ask_confirm?: boolean;
    is_confirm?: boolean;
    is_broadcast?: boolean;
    images?: NewsImage[];
}

export class NewsImage {
    id?: number;
    created_datetime?:  Date;
    lastupdated_datetime?: Date;
    name?: string;
    url?: string;
    news_id?: number;
    status?: number;

    //view
    file?:File;
    dataUrl?: string;
    index?:number;
}