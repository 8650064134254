import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { interval } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-cctv-display-img',
  templateUrl: './cctv-display-img.component.html',
  styleUrls: ['./cctv-display-img.component.scss']
})
export class CctvDisplayImgComponent implements OnInit {
  
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() toggle: boolean;
  @Input() item: any;
  @Input() station: any;
  @ViewChild('infoModal', { static: false }) private infoModal: ModalDirective;
  src: any;
  interval: any = 60000;
  constructor() { }
  subscription: any;
  link_withoutpass: any;

  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges) {

    //this.link_withoutpass = this.link.replace('admin:iTs@dm1n', '');
    let n = this.item.link.includes('?');
    this.link_withoutpass = this.item.link;
    if (this.item) {
      this.src = null;
      if (n == false) {
        this.src = this.link_withoutpass + '?t=' + new Date().getTime();
      } else {
        this.src = this.link_withoutpass + '&t=' + new Date().getTime();
      }
      this.subscription = interval(this.interval).subscribe((x) => {
        if (n == false) {
          this.src = this.link_withoutpass + '?t=' + new Date().getTime();
        } else {
          this.src = this.link_withoutpass + '&t=' + new Date().getTime();
        }

      });
    }
    if (changes.toggle) {
      if (changes.toggle.currentValue) {
        setTimeout(() => {
        this.infoModal.show();
        }, 100);
      }
    }
  }
  ngOnDestroy() {
    // console.log("play-OnDestroy");
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  clickClose() {
    this.infoModal.hide();
    this.onClose.emit(null);
  }
}
