<div *ngIf="toggle" tile="Close" (click)="clickClose()"
  class="close-socials-info rounded-circle bg-gray-600 pointer d-flex justify-content-center align-items-center">
  <i class="fa fa-close"></i>
</div>
<div *ngIf="toggle" class="socials-info-panel bg-gray-800">
  <div class="d-flex justify-content-center align-items-center socials-info-header bg-gray-950">
    <img class="mr-1" src="assets/img/socials-logo.png" width="40" height="40">
    <h3 class="mt-1">Socials Information</h3>
  </div>
  <div class="socials-info-body">
    <div class="row justify-content-md-center">
      <div class="col-md-10 col-lg-6 col-sm-12">
        <div class="d-flex align-items-center justify-content-center tw-header-panel"
          style="background-color: #1da1f2;font-size: 20px;">
          <i class="fa fa-twitter mr-2"></i>
          <h5 class="mt-1 text-white"> Twitter </h5>
        </div>
        <div class="socials-item-body-height scrollbar-style border bg-gray-700">
          <div *ngFor="let item of eventAssistanceList">
            <div *ngIf="item.src_type == 3" class="border-top">
              <div class="pull-right mt-1 mr-1">
                <i *ngIf="item?.tw?.nlp?.sentiment == 'pos'" class="fa fa-thumbs-o-up retweeted-text-color"
                  style="font-size: 18px;" tooltip="Positive" triggers="mouseenter:mouseleave"></i>
                <i *ngIf="item?.tw?.nlp?.sentiment == 'neg'" class="fa fa-thumbs-o-down retweeted-text-color"
                  style="font-size: 18px;" tooltip="Negative" triggers="mouseenter:mouseleave"></i>
              </div>

              <div class="mt-2 mb-2 mr-3 ml-2">
                <div class="retweeted-text-color ml-4 mb-1" *ngIf="item?.tw?.retweeted_status"><i
                    class="fa fa-retweet"></i>
                  <span class="small">{{ item?.tw?.user?.name }} </span> <span class="small">ReTweeted</span></div>
                <div *ngIf="item?.tw?.retweeted_status">
                  <img *ngIf="item?.tw?.retweeted_status?.user?.profile_image_url_https" width="37" height="37"
                    [src]="item?.tw?.retweeted_status?.user?.profile_image_url_https" class="rounded-circle">
                  <span>
                    <span class="tw-username-text"> {{item?.tw?.retweeted_status?.user?.name}}</span>
                    <span class="small" class="retweeted-text-color">
                      <span class="small text-gray-400 ml-1">@{{item?.tw?.retweeted_status?.user?.screen_name}}</span>
                      <i class="fa fa-circle ml-1 mr-1" style="font-size: 3px;padding-bottom: 5px"></i>
                      <span class="small text-gray-400"
                        tooltip="{{item?.tw?.retweeted_status?.created_at | date:'HH:mm:ss dd/MM/yyyy'}}"
                        triggers="mouseenter:mouseleave">{{item?.tw?.retweeted_status?.created_at | amTimeAgo}}</span>
                    </span>
                  </span>
                  <div class="ml-5">
                    <div class="text-auto">
                      {{item?.tw?.retweeted_status?.full_text}}
                    </div>
                    <div class="row">
                      <div class="col-12 text-tag-color"
                        *ngIf="item?.tw?.nlp?.loc?.prov && item?.tw?.nlp?.loc?.prov?.length>0">
                        <i class="fa fa-tag" aria-hidden="true"></i> <span class="ml-2 mr-1">จังหวัด</span>
                        <span class="badge badge-success ml-1" *ngFor="let pro of item?.tw?.nlp?.loc?.prov"
                          (click)="clickLocation(pro?.loc)">
                          <i class="fa fa-map-marker mr-1" aria-hidden="true"></i><span
                            class="small">{{pro?.key}}</span>
                        </span>
                      </div>

                      <div class="col-12 text-tag-color"
                        *ngIf="item?.tw?.nlp?.loc?.amp&& item?.tw?.nlp?.loc?.amp?.length>0">
                        <i class="fa fa-tag" aria-hidden="true"></i> <span class="ml-2 mr-1">อำเภอ</span>
                        <span class="badge badge-primary ml-1" *ngFor="let amp of item?.tw?.nlp?.loc?.amp"
                          (click)="clickLocation(amp?.loc)">
                          <i class="fa fa-map-marker mr-1" aria-hidden="true"></i><span
                            class="small">{{amp?.key}}</span>
                        </span>
                      </div>
                      <div class="col-12 text-tag-color"
                        *ngIf="item?.tw?.nlp?.loc?.route&& item?.tw?.nlp?.loc?.route?.length>0">
                        <i class="fa fa-tag" aria-hidden="true"></i> <span class="ml-2 mr-1">หมายเลขถนน</span>
                        <span class="badge badge-info ml-1" *ngFor="let route of item?.tw?.nlp?.loc?.route"
                          (click)="clickLocation(route?.loc)">
                          <i class="fa fa-map-marker mr-1" aria-hidden="true"></i><span
                            class="small">{{route?.key}}</span>
                        </span>
                        <span *ngIf="item?.tw?.nlp?.loc?.km" class="badge blue-badge-bg-color ml-1">
                          <span *ngIf="item?.tw?.nlp?.loc?.dir==this.routeDirType.IN"
                            class="small">({{item?.tw?.nlp?.loc?.km}}+{{item?.tw?.nlp?.loc?.m | number:'3.0-0'}}
                            ขาเข้า)</span>
                          <span *ngIf="item?.tw?.nlp?.loc?.dir==this.routeDirType.OUT"
                            class="small">({{item?.tw?.nlp?.loc?.km}}+{{item?.tw?.nlp?.loc?.m | number:'3.0-0'}}
                            ขาออก)</span>
                        </span>
                      </div>
                      <div *ngIf="item?.tw?.nlp?.loc?.suggest?.text" class="col-12">
                        <div class="d-flex justify-content-start align-items-center mt-1 mb-1">
                          <div style="font-size: 18px;" class="fa fa-lightbulb-o"></div>
                          <div class="badge badge-secondary ml-3">
                            {{item?.tw?.nlp?.loc?.suggest?.text}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="item?.tw?.retweeted_status?.media">
                      <div *ngFor="let med of item?.tw?.retweeted_status?.media">
                        <div class="mt-1" *ngIf="med?.type=='photo'">
                          <img style="width:100%" [src]="med?.media_url_https" class="rounded">
                        </div>
                        <div class="mt-1" *ngIf="med?.type == 'video'">
                          <video style="width:100%" controls>
                            <source [src]="med?.video_info?.variants[0]?.url" type="video/mp4">
                          </video>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div *ngIf="!item?.tw?.retweeted_status">
                  <img *ngIf="item?.tw?.user?.profile_image_url_https" width="37" height="37"
                    [src]="item?.tw?.user?.profile_image_url_https" alt="item?.tw?.user?.profile_image_url_https"
                    class="rounded-circle">
                  <span>
                    <span class="tw-username-text"> {{item?.tw?.user?.name}}</span>
                    <span class="small" class="retweeted-text-color">
                      <span class="small text-gray-400 ml-1">@{{item?.tw?.user?.screen_name}}</span>
                      <i class="fa fa-circle ml-1 mr-1" style="font-size: 3px;padding-bottom: 5px"></i>
                      <span class="small text-gray-400" tooltip="{{item?.tw?.created_at | date:'HH:mm:ss dd/MM/yyyy'}}"
                        triggers="mouseenter:mouseleave">{{item?.tw?.created_at
                                | amTimeAgo}}</span>
                    </span>
                  </span>
                  <div class="ml-5">
                    <div class="text-auto">
                      {{item?.tw?.full_text}}
                    </div>
                    <div class="row">
                      <div class="col-12 text-tag-color"
                        *ngIf="item?.tw?.nlp?.loc?.prov && item?.tw?.nlp?.loc?.prov?.length>0">
                        <i class="fa fa-tag" aria-hidden="true"></i> <span class="ml-2 mr-1">จังหวัด</span>
                        <span class="badge badge-success ml-1" *ngFor="let pro of item?.tw?.nlp?.loc?.prov"
                          (click)="clickLocation(pro?.loc)">
                          <i class="fa fa-map-marker mr-1" aria-hidden="true"></i><span
                            class="small">{{pro?.key}}</span>
                        </span>
                      </div>

                      <div class="col-12 text-tag-color"
                        *ngIf="item?.tw?.nlp?.loc?.amp&& item?.tw?.nlp?.loc?.amp?.length>0">
                        <i class="fa fa-tag" aria-hidden="true"></i> <span class="ml-2 mr-1">อำเภอ</span>
                        <span class="badge badge-primary ml-1" *ngFor="let amp of item?.tw?.nlp?.loc?.amp"
                          (click)="clickLocation(amp?.loc)">
                          <i class="fa fa-map-marker mr-1" aria-hidden="true"></i><span
                            class="small">{{amp?.key}}</span>
                        </span>
                      </div>
                      <div class="col-12 text-tag-color"
                        *ngIf="item?.tw?.nlp?.loc?.route&& item?.tw?.nlp?.loc?.route?.length>0">
                        <i class="fa fa-tag" aria-hidden="true"></i> <span class="ml-2 mr-1">หมายเลขถนน</span>
                        <span class="badge badge-info ml-1" *ngFor="let route of item?.tw?.nlp?.loc?.route"
                          (click)="clickLocation(route?.loc)">
                          <i class="fa fa-map-marker mr-1" aria-hidden="true"></i><span
                            class="small">{{route?.key}}</span>
                        </span>
                        <span *ngIf="item?.tw?.nlp?.loc?.km" class="badge blue-badge-bg-color ml-1">
                          <span *ngIf="item?.tw?.nlp?.loc?.dir==routeDirType.IN"
                            class="small">({{item?.tw?.nlp?.loc?.km}}+{{item?.tw?.nlp?.loc?.m | number:'3.0-0'}}
                            ขาเข้า)</span>
                          <span *ngIf="item?.tw?.nlp?.loc?.dir==routeDirType.OUT"
                            class="small">({{item?.tw?.nlp?.loc?.km}}+{{item?.tw?.nlp?.loc?.m | number:'3.0-0'}}
                            ขาออก)</span>
                        </span>
                      </div>
                      <div *ngIf="item?.tw?.nlp?.loc?.suggest?.text" class="col-12">
                        <div class="d-flex justify-content-start align-items-center mt-1 mb-1">
                          <div style="font-size: 18px;" class="fa fa-lightbulb-o"></div>
                          <div class="badge badge-secondary ml-3">
                            {{item?.tw?.nlp?.loc?.suggest?.text}}
                          </div>
                        </div>
                      </div>

                    </div>
                    <div *ngIf="item?.tw?.media">
                      <div *ngFor="let med of item?.tw?.media">
                        <div class="mt-1" *ngIf="med?.type=='photo'">
                          <img style="width:100%" [src]="med?.media_url_https" class="rounded">
                        </div>
                        <div class="mt-1" *ngIf="med?.type == 'video'">
                          <video style="width:100%" controls>
                            <source [src]="med?.video_info?.variants[0]?.url" type="video/mp4">
                          </video>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="height:15px"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>
