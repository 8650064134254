export const timeInterval = 1*60000; // 1 minute
export const region_id = {
    thailand: 1,
    north: 2,
    northeast: 3,
    east: 4,
    south: 5
};

export const region_code = {
    thailand: '02',
    north: '01',
    northeast: '03',
    east: '05',
    south: '06'
};