import { BoardsignInfo } from "./boardsign";

export class ScheduleInfo {
    id?: number;
    mode?: number;
    name?: string;
    color?: string;
    start_day?: number;
    stop_day?: number;
    start_datetime?: Date;
    stop_datetime?: Date;
    is_ask?: boolean;
    status?: number;
    board_list?: BoardsignInfo[];
}

export class AddSchedule {
    id?: number;
    mode?: number;
    name?: string;
    color?: string;
    start_day?: number;
    stop_day?: number;
    start_datetime?: Date;
    stop_datetime?: Date;
    is_ask?: boolean;
    status?: number;
    board_list?: AddBoardsignSchedule[];
}
export class AddBoardsignSchedule {
    id?: number;
    boardsign_structure?: string;
    boardsign_id?: number;
    items?: AddScheduleItem[];
}
export class AddScheduleItem {
    media_id?: number;
    media_type?: number;
    sign_id?: number;
    sign_no?: number;
    media?: any;
}