export const user_url = "user-management";
export const id = 1000;
export const menuItems = [
    {
        id: 1001,
        name: 'ผู้ใช้งาน',
        url: `/${user_url}/people`,
        icon: 'fa fa-user',
        parent_id: id
    },
    {
        id: 1004,
        name: 'กลุ่มผู้ใช้งาน',
        url: `/${user_url}/group`,
        icon: 'fa fa-users',
        parent_id: id
    },
    {
        id: 1008,
        name: 'หน่วยงาน',
        url: `/${user_url}/institution`,
        icon: 'fa fa-university',
        parent_id: id
    },
];
export const permission = {
    user: {
        add_id: 1002,
        edit_id: 1003,
    },
    user_group: {
        add_id: 1005,
        edit_id: 1006,
        add_user_id: 1007,
    },
    institution: {
        add_id: 1009,
        edit_id: 1010,
    }
};