<div class="row p-3">
  <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
    <div><i class="fa fa-skyatlas mr-2 pri-txt" aria-hidden="true"></i> Rain Criteria</div>

  </div>
  <div class="col-lg-4 col-md-4 col-sm-12">
    <div class="d-flex align-items-center justify-content-center mt-5">
      <span style="white-space: nowrap;" class="mr-2">Interval Time (Minute)</span>
      <input style="width: 120px;" type="text" numbersOnly class="form-control" [(ngModel)]="interval_time">
    </div>
  </div>
  <div class="configB col-lg-5 col-md-5 col-sm-12">
    <div class="mt-4 text-center">Rainfall (mm/hr.)</div>
    <div>
      <div class="d-flex align-items-center">
        <div class="input-group mb-3 mt-2 w-100">
          <div class="input-group-prepend">
            <span class="mw-txtB">Light rain</span>
          </div>
          <input type="text" numbersOnly class="form-control" [(ngModel)]="r_light">
        </div>
        <label class="checkbox-container ml-4 mt-1">
          <span>Alert</span>
          <input type="checkbox" [(ngModel)]="is_r_light" [ngModelOptions]="{standalone: true}">
          <span class="checkmark-box"></span>
        </label>
      </div>
      <div class="d-flex align-items-center">
        <div class="input-group mb-3 mt-2 w-100">
          <div class="input-group-prepend">
            <span class="mw-txtB">Moderate rain</span>
          </div>
          <input type="text" numbersOnly class="form-control" [(ngModel)]="r_moderate">
        </div>
        <label class="checkbox-container ml-4 mt-1">
          <span>Alert</span>
          <input type="checkbox" [(ngModel)]="is_r_moderate" [ngModelOptions]="{standalone: true}">
          <span class="checkmark-box"></span>
        </label>
      </div>

      <div class="d-flex align-items-center">
        <div class="input-group mb-3 mt-2 w-100">
          <div class="input-group-prepend">
            <span class="mw-txtB">Heavy rain</span>
          </div>
          <input type="text" numbersOnly class="form-control" [(ngModel)]="r_heavy">
        </div>
        <label class="checkbox-container ml-4 mt-1">
          <span>Alert</span>
          <input type="checkbox" [(ngModel)]="is_r_heavy" [ngModelOptions]="{standalone: true}">
          <span class="checkmark-box"></span>
        </label>
      </div>

    </div>
  </div>
</div>

<div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-end">
  <button class="btn btn-danger text-white" (click)="getConfig()">ยกเลิก</button>
  <button class="btn btn-primary text-white ml-3" (click)="upConfig()">ยืนยัน</button>
</div>