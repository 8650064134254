import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiResponse, PagableData } from '../../models/common';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { StatusEnum } from '../../models/common-enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PermissionRoadInfo, ReqAddPermissionRoad } from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class PermissionRoadService {

  domain: string = environment.api.domain_auth;
  per_road_uri = 'permission/road';
  per_road_url = `${this.domain}/${this.per_road_uri}`;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.domain = environment.api.domain_auth;
  }
  getPermissionRoad(group_id?: number) {
    let params = new HttpParams();
    if (!_.isNil(group_id)) {
      params = params.append('group_id', group_id.toString());
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PermissionRoadInfo[]>>(this.per_road_url, httpOptions);
  }

  addPermissionRoad(req: ReqAddPermissionRoad): Observable<ApiResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(this.per_road_url, req, httpOptions);
  }

  editPermissionRoad(req: ReqAddPermissionRoad): Observable<ApiResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(this.per_road_url, req, httpOptions);
  }
  deletePermissionRoad(id: number): Observable<ApiResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      body: { id: id }
    };
    return this.http.delete<ApiResponse<any>>(this.per_road_url, httpOptions);
  }
}