export class NewsTypeInfo {
    id: number;
    created_datetime:  Date;
    lastupdated_datetime: Date;
    name:  string;
    status:  number;
    category_id: number;
}
export class ReqAddNewsType {
    name: string;
    status: number;
    category_id: number;
}