<div class="d-flex border-dark-top">
  <div *ngFor="let tab of tabs; let i = index"
    class="p-2 flex-fill d-flex justify-content-center align-items-center disable-select"
    [ngClass]="(tabSelect == tab)?'menu-item-active':'menu-item'" (click)="changeTab(tab)">
    <i class="{{tab?.icon}} mr-1"></i>
    <span>{{tab?.name}}</span>
  </div>
</div>
<div>

</div>
<div [ngClass]="(tabSelect?.id != permission.hardware_id) ? 'hide-panel' : ''">
  <div class="row p-3">
    <div class="col-lg-4 col-md-4 col-sm-6">
      <div>ทางหลวง :</div>
      <div>
        <kendo-combobox class="w-100" [data]="data_route" [allowCustom]="allowCustom" [textField]="" [valueField]=""
          [value]="search_route" (valueChange)="handleValueRoute($event)" (filterChange)="handleFilterRoute($event)"
          [placeholder]="'ทั้งหมด'" [filterable]="true">
        </kendo-combobox>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <div>ช่วงกิโลเมตร :</div>
      <div class="d-flex align-items-center">
        <div class="flex-fill"><span class="nowrap mr-1">เริ่มต้น :</span></div>
        <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly maxlength="4"
            [(ngModel)]="search_km_st" [ngModelOptions]="{standalone: true}"></div>
        <div class="flex-fill ml-1 mr-1"> + </div>
        <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly maxlength="3"
            [(ngModel)]="search_m_st" [ngModelOptions]="{standalone: true}"></div>
        <div class="flex-fill"><span class="nowrap ml-1 mr-1">สิ้นสุด :</span></div>
        <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly maxlength="4"
            [(ngModel)]="search_km_en" [ngModelOptions]="{standalone: true}"></div>
        <div class="flex-fill ml-1 mr-1"> + </div>
        <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly maxlength="3"
            [(ngModel)]="search_m_en" [ngModelOptions]="{standalone: true}"></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <div>สถานะ :</div>
      <div>
        <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
          [valueField]="'id'" [value]="search_status" (valueChange)="handleValueStatus($event)"
          [placeholder]="'ทั้งหมด'" [filterable]="true">
        </kendo-combobox>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <div>Station :</div>
      <div>
        <kendo-combobox class="w-100" [data]="station_search_list" [allowCustom]="allowCustom" [textField]="'name'"
          [valueField]="'id'" [value]="search_station" (valueChange)="handleValueStation($event)"
          (filterChange)="handleFilterStation($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
        </kendo-combobox>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <div>Owner :</div>
      <div>
        <kendo-combobox class="w-100" [data]="data_owner" [allowCustom]="allowCustom" [textField]="'company_name'"
          [valueField]="'id'" [value]="search_owner" (valueChange)="handleValueOwner($event)"
          (filterChange)="handleFilterOwner($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
        </kendo-combobox>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <div>Vendor :</div>
      <div>
        <kendo-combobox class="w-100" [data]="data_vendor" [allowCustom]="allowCustom" [textField]="'company_name'"
          [valueField]="'vendor_id'" [value]="search_vendor" (valueChange)="handleValueVendor($event)"
          (filterChange)="handleFilterVendor($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
        </kendo-combobox>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <div>กลุ่มอุปกรณ์ :</div>
      <div>
        <kendo-combobox class="w-100" [data]="data_device_group" [allowCustom]="allowCustom" [textField]="'name'"
          [valueField]="'id'" [value]="search_device_group" (valueChange)="handleValueDeviceGroup($event)"
          (filterChange)="handleFilterDeviceGroup($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
        </kendo-combobox>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <div>ประเภทอุปกรณ์ :</div>
      <div>
        <kendo-combobox class="w-100" [data]="data_device_type" [allowCustom]="allowCustom" [textField]="'name'"
          [valueField]="'type_id'" [value]="search_device_type" (valueChange)="handleValueDeviceType($event)"
          (filterChange)="handleFilterDeviceType($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
        </kendo-combobox>
      </div>
    </div>

    <div class="col-lg-4 col-md-4 d-flex align-items-end justify-content-end">
      <div class="mt-1">
        <button type="button" (click)="search()" class="btn btn-primary pull-right text-white ml-1"> <i
            class="fa fa-search"></i>
          ค้นหา </button>
      </div>
    </div>

  </div>
  <div class="table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <label class="btn text-white pointer" [ngClass]="(viewType == view.device)?'btn-primary':'btn-dark'"
        (click)="changeView(view.device)">
        <input type="radio" name="options" id="option1" autocomplete="off" [value]="view.device" [(ngModel)]="viewType">
        <i class="fa fa-th mr-1"></i>Device
      </label>
      <label class="btn text-white pointer"
        [ngClass]="(viewType == view.station || viewType == view.map)?'btn-primary':'btn-dark'"
        (click)="changeView(view.station)">
        <input type="radio" name="options" id="option1" autocomplete="off" [value]="view.station"
          [(ngModel)]="viewType"><i class="fa fa-bars mr-1"></i> Station
      </label>
    </div>
    <button *ngIf="viewType != view.map" type="button" class="btn btn-primary text-white"
      (click)="changeView(view.map)"> <i class="fa fa-map"></i>
      Map View </button>
    <button *ngIf="viewType == view.map" type="button" class="btn btn-primary text-white"
      (click)="changeView(view.station)"> <i class="fa fa-bars"></i>
      List View </button>
  </div>
  <div *ngIf="viewType == view.station" class="p-3" style="min-height: 400px;">
    <div class="mt-4 d-flex align-items-center justify-content-center"
      *ngIf="!this.is_loading && data_list?.length == 0">
      <div>
        ไม่พบข้อมูล
      </div>
    </div>
    <div class="mt-4 d-flex align-items-center justify-content-center" *ngIf="this.is_loading">
      <div>
        กำลังโหลด ..
      </div>
    </div>
    <div *ngFor="let item of data_list; let i = index" [ngClass]="(i != 0) ? 'mt-3' : ''"
      class="c-panel rounded d-flex align-items-center justify-content-between pl-3 pr-3 pt-2 pb-2">
      <div>{{item?.name}}</div>
      <div class="d-flex align-items-center justify-content-end">
        <div *ngFor="let item_d of item?.devices let i_d = index">
          <img tooltip="{{item_d?.name}}" placement="top" *ngIf="item_d?.device_subtype?.image"
            [src]="item_d?.device_subtype?.image" class="rounded-circle device-status-on mr-1 ml-1" width="45"
            height="45"
            [ngClass]="{'device-status-on':item_d?.connection_status == connectionStatusEnum.Online, 'device-status-off':item_d?.connection_status == connectionStatusEnum.Offline,'device-status-tmpoff':item_d?.connection_status == connectionStatusEnum.TmpOffline, 'device-status-null':item_d?.connection_status == null}">
          <div tooltip="{{item_d?.name}}" placement="top" *ngIf="!item_d?.device_subtype?.image"
            class="rounded-circle device-sub-null mr-1 ml-1"
            [ngClass]="{'device-status-on':item_d?.connection_status == connectionStatusEnum.Online, 'device-status-off':item_d?.connection_status == connectionStatusEnum.Offline,'device-status-tmpoff':item_d?.connection_status == connectionStatusEnum.TmpOffline, 'device-status-null':item_d?.connection_status == null}">
          </div>
        </div>
        <button *ngIf="isDescHardware" type="button" class="btn btn-primary text-white ml-1"
          (click)="changeView(view.device,item?.id)"> <i class="icon-info"></i></button>
      </div>
    </div>
    <!-- <div class="c-panel rounded d-flex align-items-center justify-content-between pl-3 pr-3 pt-2 pb-2 mt-3">
      <div>58 LMS Sta.2-27+280 (OUT)</div>
      <div class="d-flex align-items-center justify-content-end">
        <img src="https://jarvis.shw.transcode.co.th/api/image?code=65908a7e35c9105be80b489ba521a876"
          class="rounded-circle device-status-on mr-1 ml-1" width="45" height="45">
        <img src="https://jarvis.shw.transcode.co.th/api/image?code=26bff47201fc75443edc2609e3ca2f44"
          class="rounded-circle device-status-on mr-1 ml-1" width="45" height="45">
        <img src="https://jarvis.shw.transcode.co.th/api/image?code=dafa3e2afef9b07674980f722f4e6ef7"
          class="rounded-circle device-status-off mr-1 ml-1" width="45" height="45">
        <img src="https://jarvis.shw.transcode.co.th/api/image?code=c2358beef87b49f61aa788d94019ea72"
          class="rounded-circle device-status-tmpoff mr-1 ml-1" width="45" height="45">
        <img src="https://jarvis.shw.transcode.co.th/api/image?code=48a9b8b3e026857b61d9ca75d8235dde"
          class="rounded-circle device-status-on mr-1 ml-1" width="45" height="45">
        <img src="https://jarvis.shw.transcode.co.th/api/image?code=8f52d75ce8a1536189b210b9b84cf6f9"
          class="rounded-circle device-status-off mr-1 ml-1" width="45" height="45">
        <button type="button" class="btn btn-primary text-white ml-1"> <i class="icon-info"></i></button>
      </div>
    </div> -->
  </div>
  <div *ngIf="viewType == view.device" class="p-3 row" style="min-height: 400px;">
    <div class="col-12">
      <div class="mt-4 mb-4 d-flex align-items-center justify-content-center"
        *ngIf="!this.is_loading && data_list_device?.length == 0">
        <div>
          ไม่พบข้อมูล
        </div>
      </div>
      <div class="mt-4 mb-4 d-flex align-items-center justify-content-center" *ngIf="this.is_loading">
        <div>
          กำลังโหลด ..
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let item of data_list_device">
      <div class="card w-100">
        <div class="card-header bg-gray-900 d-flex justify-content-between align-items-center">
          <div class="d-flex"><i class="fa fa-wifi fa-2x"
              [ngClass]="{'text-success':item?.connection_status == connectionStatusEnum.Online, 'text-danger':item?.connection_status == connectionStatusEnum.Offline,'text-warning':item?.connection_status == connectionStatusEnum.TmpOffline, 'text-gray':item?.connection_status == null}"></i>
            <span>
              <img *ngIf="item.device_subtype?.image" [src]="item.device_subtype?.image"
                class="rounded-circle mr-1 ml-1" width="30" height="30"
                [ngClass]="{'device-status-on':item?.connection_status == connectionStatusEnum.Online, 'device-status-off':item?.connection_status == connectionStatusEnum.Offline,'device-status-tmpoff':item?.connection_status == connectionStatusEnum.TmpOffline, 'device-status-null':item?.connection_status == null}">
              <div *ngIf="!item?.device_subtype?.image" class="rounded-circle device-sub-null-30 mr-1 ml-1"
                [ngClass]="{'device-status-on':item?.connection_status == connectionStatusEnum.Online, 'device-status-off':item?.connection_status == connectionStatusEnum.Offline,'device-status-tmpoff':item?.connection_status == connectionStatusEnum.TmpOffline, 'device-status-null':item?.connection_status == null}">
              </div>
            </span>
            <span class="ml-1 d-flex align-items-center">{{item?.device_name}}</span></div>
          <h5>
            <span *ngIf="item?.device_status == statusEnum.Active"
              class="badge badge-success text-dark font-3">Active</span>
            <span *ngIf="item?.device_status == statusEnum.Inactive"
              class="badge badge-danger text-dark font-3">Inactive</span>
            <span *ngIf="item?.device_status == statusEnum.Ma" class="badge badge-warning text-dark font-3">MA</span>
          </h5>
        </div>
        <div class="mt-1 pl-3 pr-3 d-flex justify-content-between align-items-center font-2">
          <div *ngIf="item?.route != null && item?.km != null && item?.m != null">
            ทล. {{item?.route | number:'1.0-0'}} ({{item?.km}}+{{item?.m | number:'3.0-0'}})
          </div>
          <!-- <div *ngIf="s?.sensor_data?.ts">{{s?.sensor_data?.ts | date:"HH:mm yyyy/MM/dd"}} </div>
            <div *ngIf="s?.sensor_tt?.ts">{{s?.sensor_tt?.ts | date:"HH:mm yyyy/MM/dd"}} </div> -->
        </div>
        <div
          *ngIf="!(item?.device_subtype?.subtype_id == vsw_device_subtype || item?.device_subtype?.subtype_id == security_device_subtype)"
          class="card-body d-flex justify-content-around align-items-center w-100">
          <div class="mr-2">
            <kendo-chart>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>
              <kendo-chart-area background="none" height="100" [margin]="0">
              </kendo-chart-area>
              <kendo-chart-series>
                <kendo-chart-series-item [line]="{ style: style }" type="line" [data]="item.graph_data"
                  [markers]="{ visible: false }">
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item>
                  <kendo-chart-value-axis-item-labels [visible]="false">
                  </kendo-chart-value-axis-item-labels>
                </kendo-chart-value-axis-item>
              </kendo-chart-value-axis>
            </kendo-chart>
          </div>
          <div class="ml-2">
            <kendo-chart>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>
              <kendo-chart-area background="none" height="100" width="100" [margin]="0">
              </kendo-chart-area>
              <kendo-chart-series *ngIf="!is_loading">
                <kendo-chart-series-item type="pie" colorField="color" [data]="item.pie_chart" categoryField="label"
                  field="value" [startAngle]="80" [padding]="0" [margin]="0" [size]="10">
                  <kendo-chart-series-item-tooltip background="#0f0f10" color="#fff" format="{0} %">
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>
          </div>
        </div>
        <div *ngIf="(item?.device_subtype?.subtype_id == vsw_device_subtype)" class="pt-1 pr-3 pl-3 pb-3">
          <div class="d-flex align-items-center text-BG">
            <div *ngIf="item?.vsw_info?.image_url">
              <img [src]="item?.vsw_info?.image_url" width="60">
            </div>
            <div class="pr-2">
              <div class="text">
                <p class="m-0 text text-right">Board :</p>
                <p class="m-0">
                  <span *ngIf="item?.vsw_info?.board_status == 1" class="text-success"> Online </span>
                  <span *ngIf="item?.vsw_info?.board_status == 0" class="text-danger"> Offline </span>
                </p>
              </div>
              <div class="text">
                <p class="m-0 text text-right">Temperature :</p>
                <p class="m-0">
                  <span class="mb-1">{{item?.vsw_info?.temp}}</span>
                </p>
              </div>
              <div class="text">
                <p class="m-0 text text-right">Play :</p>
                <p class="m-0">
                  <span class="mb-1">{{item?.vsw_info?.image_name}}</span>
                </p>
              </div>
              <div class="text">
                <p class="m-0 text text-right">Brightness :</p>
                <p class="m-0">
                  <span class="mb-1">{{item?.vsw_info?.brightness}}</span>
                </p>
              </div>
              <div class="text">
                <p class="m-0 text text-right">Image sync :</p>    
                <p class="m-0">
                  <span class="mb-1" *ngIf="item?.vsw_info?.image_sync == 1" class=""> sync </span>
                  <span class="mb-1" *ngIf="item?.vsw_info?.image_sync == 0" class=""> not sync </span>
                </p>
              </div>
              <div class="text">
                <p class="m-0 text text-right">Last Checked :</p>
                <p class="m-0">
                  <span>{{item?.connection_datetime | date:'HH:mm dd/MM/yyyy'}}</span>
                </p>
              </div>
            </div>
          </div>
          
        </div>
        <div *ngIf="(item?.device_subtype?.subtype_id == security_device_subtype)" class="pt-1 pr-3 pl-3 pb-3">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 130px;">
            <div class="row row1 no-gutters font-2">
              <div class="col-6 text-right"><span class="mr-1 mb-1">Summary Volt :</span></div>
              <div class="col-6"><span class="ml-1 mb-1">{{item?.security_info?.sum_volt}} V</span></div>
              <div class="col-6 text-right"><span class="mr-1 mb-1">State Of Charge :</span></div>
              <div class="col-6"><span class="ml-1 mb-1">{{item?.security_info?.charge_stage}} %</span></div>
              <div class="col-6 text-right"><span class="mr-1 mb-1">AMP :</span></div>
              <div class="col-6"><span class="ml-1 mb-1">{{item?.security_info?.amp}}</span></div>
              <div class="col-6 text-right"><span class="mr-1">ispy station :</span></div>
              <div class="col-6"><span class="ml-1">{{item?.security_info?.ispy_station}}</span></div>
              <div class="col-6 text-right"><span class="mr-1">Door State :</span></div>
              <div class="col-6"><span class="ml-1">
                {{item?.security_info?.door_state === null? 'No Data':item?.security_info?.door_state == 0? 'Close':item?.security_info?.door_state === 1? 'Open':''}}
              </span></div>
              <div class="col-6 text-right"><span class="mr-1">Last Checked :</span></div>
              <div class="col-6"><span class="ml-1">{{item?.connection_datetime | date:'HH:mm dd/MM/yyyy'}}</span></div>
            </div>
          </div>
        </div>
        <div *ngIf="isDescHardware" class="card-footer bg-gray-900">
          <div class="d-flex justify-content-center align-items-center">
            <button type="button" class="cursor-default btn btn-info btn-sm"
              (click)="openAddMa(1,item?.device_id,item.device_status)">
              <i class="icon-info"></i> รายละเอียด </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="viewType == view.station || viewType == view.device"
    class="table-header pl-3 pr-3 pt-2 d-flex align-items-center justify-content-between">
    <div class="">
      <pagination [totalItems]="totalItems" [maxSize]="5" [itemsPerPage]="itemsPerPage" [(ngModel)]="currentPage"
        (pageChanged)="pageChanged($event)" class="pagination" previousText="&lsaquo;" nextText="&rsaquo;"
        [boundaryLinks]="true" firstText="&laquo;" lastText="&raquo;"></pagination>
    </div>
    <div></div>
    <div class="">total: {{totalItems}}</div>
  </div>
  <div [ngClass]="(viewType == view.map) ? 'p-3' : 'panel-hide'">
    <div class="map" id="map-monitor" [ngClass]="(viewType == view.map) ? 'map' : 'map-hide'"></div>
  </div>


</div>
<div *ngIf="tabSelect?.id == permission.software_id">
  <div class="row p-3">
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
      <div>Server name :</div>
      <div>
        <kendo-combobox class="w-100" [data]="data_server" [allowCustom]="allowCustom" [textField]="'name'"
          [valueField]="'id'" [value]="search_server" (valueChange)="handleValueServer($event)"
          (filterChange)="handleFilterServer($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
        </kendo-combobox>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
      <div>Algorithm type :</div>
      <div>
        <kendo-combobox class="w-100" [data]="data_algorithm_type" [allowCustom]="allowCustom" [textField]="'name'"
          [valueField]="'id'" [value]="search_algorithm_type" (valueChange)="handleValueAlgorithmType($event)"
          (filterChange)="handleFilterAlgorithmType($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
        </kendo-combobox>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
      <div>สถานะ :</div>
      <div>
        <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
          [valueField]="'id'" [value]="search_status" (valueChange)="handleValueStatus($event)" [filterable]="true"
          [placeholder]="'ทั้งหมด'">
        </kendo-combobox>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
      <div>Algorithm group :</div>
      <div>
        <kendo-combobox class="w-100" [data]="data_algorithm_group" [allowCustom]="allowCustom" [textField]="'name'"
          [valueField]="'id'" [value]="search_algorithm_group" (valueChange)="handleValueAlgorithmGroup($event)"
          (filterChange)="handleFilterAlgorithmGroup($event)" [placeholder]="'ทั้งหมด'">
        </kendo-combobox>
      </div>
    </div>
    <div class="col-xl-12 col-lg-8 col-md-12 col-sm-12 d-flex align-items-end justify-content-end">
      <div class="mt-1">
        <button type="button" (click)="searchSoftware()" class="btn btn-primary pull-right text-white ml-1"> <i
            class="fa fa-search"></i>
          ค้นหา </button>
      </div>
    </div>

  </div>
  <div class="table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
    <div class="font-2 mt-1 mb-1">
      <i class="fa fa-th mr-2"></i> Software
    </div>
  </div>
  <div class="p-3 row" style="min-height: 470px;">
    <!-- <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="card w-100">
        <div class="card-header bg-gray-900 d-flex justify-content-between align-items-center">
          <div class="d-flex"><i class="fa fa-wifi fa-2x text-success"></i>
            <span>
              <img src="https://jarvis.shw.transcode.co.th/api/image?code=65908a7e35c9105be80b489ba521a876"
                class="rounded-circle device-status-on mr-1 ml-1" width="30" height="30">
            </span>
            <span class="ml-1 d-flex align-items-center">Software 3</span></div>
            <h5>
              <span class="badge badge-warning text-dark font-3">MA</span>
            </h5>
        </div>
        <div class="card-body d-flex justify-content-around align-items-center w-100">
          <div class="mr-2">
            <kendo-chart>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>
              <kendo-chart-area background="none" height="100" [margin]="0">
              </kendo-chart-area>
              <kendo-chart-series>
                <kendo-chart-series-item [line]="{ style: style }" type="line"
                  [data]="[1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]"
                  [markers]="{ visible: false }">
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item>
                  <kendo-chart-value-axis-item-labels [visible]="false">
                  </kendo-chart-value-axis-item-labels>
                </kendo-chart-value-axis-item>
              </kendo-chart-value-axis>
            </kendo-chart>
          </div>
          <div class="ml-2">
            <kendo-chart>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>
              <kendo-chart-area background="none" height="100" width="100" [margin]="0">
              </kendo-chart-area>
              <kendo-chart-series *ngIf="!is_loading">
                <kendo-chart-series-item type="pie" colorField="color" [data]="data1" categoryField="type" field="value"
                  [startAngle]="80" [padding]="0" [margin]="0" [size]="10">
                  <kendo-chart-series-item-tooltip background="#0f0f10" color="#fff" format="{0} %">
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>
          </div>
        </div>
        <div class="card-footer bg-gray-900">
          <div class="d-flex justify-content-center align-items-center">
            <button type="button" class="cursor-default btn btn-info btn-sm">
              <i class="fa fa-wrench"></i> รายละเอียดการแจ้งซ่อม </button>
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-12">
      <div class="mt-4 d-flex align-items-center justify-content-center"
        *ngIf="!this.is_loading && data_list_software?.length == 0">
        <div>
          ไม่พบข้อมูล
        </div>
      </div>
      <div class="mt-4 d-flex align-items-center justify-content-center" *ngIf="this.is_loading">
        <div>
          กำลังโหลด ..
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let item of data_list_software">
      <div class="card w-100">
        <div class="card-header bg-gray-900 d-flex justify-content-between align-items-center">
          <div class="d-flex"><i class="fa fa-wifi fa-2x"
              [ngClass]="{'text-success':item?.connection_status == connectionStatusEnum.Online, 'text-danger':item?.connection_status == connectionStatusEnum.Offline,'text-warning':item?.connection_status == connectionStatusEnum.TmpOffline, 'text-gray':item?.connection_status == null}"></i>
            <!-- <span>
              <img src="https://jarvis.shw.transcode.co.th/api/image?code=65908a7e35c9105be80b489ba521a876"
                class="rounded-circle mr-1 ml-1" width="30" height="30"
                [ngClass]="{'device-status-on':item?.connection_status == connectionStatusEnum.Online, 'device-status-off':item?.connection_status == connectionStatusEnum.Offline,'device-status-tmpoff':item?.connection_status == connectionStatusEnum.TmpOffline, 'device-status-null':item?.connection_status == null}">
            </span> -->
            <span class="ml-1 d-flex align-items-center">{{item?.app_name}}</span></div>
          <h5>
            <span *ngIf="item?.status == statusEnum.Active" class="badge badge-success text-dark">Active</span>
            <span *ngIf="item?.status == statusEnum.Inactive" class="badge badge-danger text-dark">Inactive</span>
            <span *ngIf="item?.status == statusEnum.Ma" class="badge badge-warning text-dark">MA</span>
          </h5>
        </div>
        <div class="mt-1 pl-3 pr-3 d-flex justify-content-between align-items-center">
          <div>Server : {{item?.server_name}}</div>
        </div>
        <div class="card-body d-flex justify-content-around align-items-center w-100">
          <div class="mr-2">
            <kendo-chart>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>
              <kendo-chart-area background="none" height="100" [margin]="0">
              </kendo-chart-area>
              <kendo-chart-series>
                <kendo-chart-series-item [line]="{ style: style }" type="line" [data]="item.graph_data"
                  [markers]="{ visible: false }">
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item>
                  <kendo-chart-value-axis-item-labels [visible]="false">
                  </kendo-chart-value-axis-item-labels>
                </kendo-chart-value-axis-item>
              </kendo-chart-value-axis>
            </kendo-chart>
          </div>
          <div class="ml-2">
            <kendo-chart>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>
              <kendo-chart-area background="none" height="100" width="100" [margin]="0">
              </kendo-chart-area>
              <kendo-chart-series *ngIf="!is_loading">
                <kendo-chart-series-item type="pie" colorField="color" [data]="item.pie_chart" categoryField="label"
                  field="value" [startAngle]="80" [padding]="0" [margin]="0" [size]="10">
                  <kendo-chart-series-item-tooltip background="#0f0f10" color="#fff" format="{0} %">
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>
          </div>
        </div>
        <div *ngIf="isDescSoftware" class="card-footer bg-gray-900">
          <div class="d-flex justify-content-center align-items-center">
            <button type="button" class="cursor-default btn btn-info btn-sm"
              (click)="openAddMa(2,item?.id,item.status)">
              <i class="icon-info"></i> รายละเอียด </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="table-header pl-3 pr-3 pt-2 d-flex align-items-center justify-content-between">
    <div class="">
      <pagination [totalItems]="totalItemsSoftware" [maxSize]="5" [itemsPerPage]="itemsPerPage"
        [(ngModel)]="currentPageSoftware" (pageChanged)="pageChangedSoftware($event)" class="pagination"
        previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true" firstText="&laquo;" lastText="&raquo;">
      </pagination>
    </div>
    <div></div>
    <div class="">total: {{totalItemsSoftware}}</div>
  </div>
</div>

<app-ma-management [clickOpen]="clickOpenAdd" [view]="selectMaView" [typeId]="typeId" [refId]="refId"
  (onClose)="closeAddMa()" (onSuccess)="addMaSuccess()" [isDescDevice]="isDescDevice" [isDescMa]="isDescMa"
  [isAddMa]="isAddMa" [isMaLog]="isMaLog" [isAddMaLog]="isAddMaLog"></app-ma-management>
