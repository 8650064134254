export class DeviceType {
    type_id: number;
    create_date:  Date;
    update_date: Date;
    name:  string;
    desc:  string;
    status:  number;
    status_str: string;
}
export class ReqAddDeviceType {
    name: number;
    status: number;
    desc: string;
}

export class DeviceSubType {
    subtype_id: number;
    type_id: number;
    device_id:number;
    create_date:  Date;
    update_date: Date;
    name: string;
    desc: string;
    code: string;
    status:  number;
    status_str: string;
    image: string;
}
export class ReqAddDeviceSubType {
    name: number;
    status: number;
    desc: string;
    code: string;
    image?:string;
}