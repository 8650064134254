import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PeopleComponent } from './people/people.component';
import { GroupComponent } from './group/group.component';
import { InstitutionComponent } from './institution/institution.component';

export let routes: Routes = [
  {
    path: '',
    redirectTo: 'people',
    pathMatch: 'full',
  },
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'people',
        component: PeopleComponent
      },
      {
        path: 'group',
        component: GroupComponent
      },
      {
        path: 'institution',
        component: InstitutionComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserManagementRoutingModule { }
