import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ResSocialsEvent, SocialsTwTrain } from '../../models/dashboard/socials_event';
import { ApiResponse, PagableData } from '../../models/common';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class SocialsService {
  domain: any;
  socials_url: any;
  t = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjIjoiNWIwZTQzODM0NTc3OTQxOTAxMWU2Yzk1IiwicyI6WyJUTUMiXSwiciI6Ikh3SnVpNkRmdUxEZ05TM1lyUWZtNnZIV2hqWU1qeE02VjFtTDFCV0JvbWVTIiwiaWF0IjoxNjA3OTM0MDgwfQ.9MhE1LIdqTx1xiG6lspi_QOWF6YP7EiDhQ6v3y9KOu8`;
  constructor(private http: HttpClient) {
    this.domain = environment.api.socials_api;
  }
  /**
   *  1. inc_id
   *  2. inc_type, near, time
   *  โดย time ส่งเป็น timestamp in second มา
   *  แต่ละข้อจะใช้คู่กัน เช่น inc_type, near, time จะมาคู่กันเสมอ ส่วน inc_id จะมาแค่โดด ๆ ตัวเดียว
   *  ถ้า กำหนดมาหมด ตัวข้อ 1. จะถูกเลือกไปก่อน แล้วค่อยไล่ลงมา
   */
  getEventAssistance(near?: number[], inc_type?: number[], inc_id?: string, time?: number) {
    const uri = `assistance/incident`;
    const url = `${this.domain}/${uri}`;
    let para = [];
    let params = new HttpParams();
    if (!_.isNil(near) && near.length === 3) {
      params = params.append('near', near.join(','));
      para.push('near=' + near.join(','))
    }
    if (!_.isNil(inc_type)) {
      params = params.append('inc_type', inc_type.join(','));
      para.push('inc_type=' + inc_type.join(','))
    }
    if (!_.isNil(inc_id)) {
      params = params.append('inc_id', inc_id.toString());
      para.push('inc_id=' + inc_id.toString())
    }
    if (!_.isNil(time)) {
      params = params.append('time', time.toString());
      para.push('time=' + time.toString())
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.t
      }),
      params: params
    };
    this.socials_url = url;
    if (params) {
      this.socials_url = `${url}?${_.join(para, '&')}`;
    }
    return this.http.get<ApiResponse<ResSocialsEvent>>(url, httpOptions);
  }

  setEventAssistanceTrain(train: SocialsTwTrain) {
    const uri = `assistance/train`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.t
      })
    };
    return this.http.post<ApiResponse<any>>(url, train);
  }
}
