import { MediaInfo } from './media';
import { SignInfo, StructureLayerInfo } from './boardsign';

export class FavoriteInfo {
    id?: number;
    name?: string;
    boardsign_structure?: string;
    boardsign_structure_name?: string;
    boardsign_id?: number;
    create_date?: Date;
    lastupdate_date?: Date;
    items?: FavoriteInfoItem[];

    // UI
    layers?: Array<StructureLayerInfo[]>;
}

export class FavoriteInfoItem {
    id?: number;
    fav_id?: number;
    media_id?: number;
    media_type?: number;
    sign_no?: number;
    layer_no?: number;
    sign_type?: number;
    sign_type_id?: number;
    ratio?: string;
    dir?: number;
    status?: number;
    create_date?: Date;
    lastupdate_date?: Date;
    media?: MediaInfo;
}

export class ReqAddFavorite {
    id?: number;
    boardsign_id?: number;
    boardsign_structure?: string;
    name?: string;
    items?: AddFavoriteItem[];
    status?: number;
}

export class AddFavoriteItem {
    id?: number;
    media_id?: number;
    media_type?: number;
    sign_no?: number;
}

// View
export class StructureInfo {
    fav_id?: number;
    name?: string;
    boardsign_structure?: string;
    layers?: Array<StructureLayerInfo[]>;
}
// export class StructureLayerInfo {
//     id?: number;
//     sign_no?: number;
//     board_type?: number;
//     ratio?: string;
//     dir?: number;
//     data?: SignInfo;
//     media?: MediaInfo;
//     media_id?: number;
//     media_type?: number;
// }