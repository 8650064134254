import { Component, OnInit,EventEmitter,ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddsensorModalComponent } from '../addsensor-modal/addsensor-modal.component';
import * as _ from 'lodash'
import * as mm from 'moment'
import { ReversibleLane } from './model';
import { ConfigroadService } from 'src/app/services/user/configroad.service';
import { ReversibleService } from 'src/app/services/user/reversible.service';
import { DeviceService } from 'src/app/services/user/device.service';
import { FuncService } from 'src/app/services/user/func.service';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
import { ApiService } from 'src/app/services/user/api.service';
@Component({
  selector: 'app-reversible-lane-modal',
  templateUrl: './reversible-lane-modal.component.html',
  styleUrls: ['./reversible-lane-modal.component.scss']
})
export class ReversibleLaneModalComponent implements OnInit {
  bsModal_addsensor: BsModalRef;
  public event: EventEmitter<any> = new EventEmitter();
  values : ReversibleLane = new ReversibleLane();
  _changed: boolean = false;

  qqM : number;
  saveBT: any = false;
  lane: number = 1
  reversible_name : string = ''
  _Direction: any = [true,true]
  directionBT: number = 1
  description: string
  samp_day : number = null
  samp_night : number = null
  Idel_day : number = null
  Idel_night : number = null
  km : number
  m : number
  route_list:any = []
  // route_list:any = this.rvS.getRoute()
  setRouteList:any = []
  route_id:any
  allowCustom : boolean = false
  ResMessage:string
  rm_data : any
  criteria: number = 1;
  cal_period: number = null
  error_text: string = null
  direction_list : any = [
    {
      direction:1,
      free_flow_speed : null,
      min_speed : null,
      max_speed : null,
      max_flow_rate : null,
      min_rate : null,
      max_rate : null,
      vc_rv : null,
    },
    {
      direction:2,
      free_flow_speed : null,
      min_speed : null,
      max_speed : null,
      max_flow_rate : null,
      min_rate : null,
      max_rate : null,
      vc_rv : null,
    },
  ]
  loading: boolean = false;
  st_time : any
  en_time : any
  disabledSave_BT: boolean = false;

  constructor(
    private func : FuncService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private dvS : ConfigroadService,
    private rS: DeviceService,
    private rvS: ReversibleService,
    private commonService: CommonService,
    private apiService: ApiService,
  ) { }

  async ngOnInit() {
    this.route_list = await this.rS.getRoute()
    this.setRouteList = _.cloneDeep(this.route_list)
    if(!_.isEmpty(this.values)){
      this.values = (await this.rvS.getReversible({rv_id : this.values.id}))[0]
      console.log(this.values)
      this.setRouteList.push(this.values.route_id+'')
      if((typeof this.values.sensor_in) == 'string'){
        this.values.sensor_in = JSON.parse(this.values.sensor_in)
      }
      if((typeof this.values.sensor_out) == 'string'){
        this.values.sensor_out = JSON.parse(this.values.sensor_out)
      }
      this.reversible_name = this.values.reversible_name
      this.description = this.values.description
      this.route_id = (this.values.route_id+'').padStart(4, '0')
      this.criteria = Number(this.values.criteria)||1
      this.km = this.values.km
      this.m = this.values.m
      this.samp_day = this.values.sampling_day
      this.samp_night = this.values.sampling_night
      this.Idel_day = this.values.idle_day
      this.Idel_night = this.values.idle_night
      this.st_time = (this.values.st_time)? new Date(mm(this.values.st_time,"HHmm").format()) : null;
      // this.st_time = new Date(mm("1030","HHmm").format());
      this.en_time = (this.values.en_time)? new Date(mm(this.values.en_time,"HHmm").format()) : null;
      this.cal_period = (this.values.cal_period)? this.values.cal_period: null
      if(this.values.direction_list && this.values.direction_list.length){
        let num : number = 0
        for (const i of this.direction_list) {
          for (const key in i) {
            if (Object.prototype.hasOwnProperty.call(i, key)) {
              if(key !=='direction') i[key] = this.values.direction_list[num][key]
            }
          }
          num++  
        }
      }
    }else{
    }
  }
  async getData(){
    this.values = (await this.rvS.getReversible({rv_id : this.values.id}))[0]
    this.ngOnInit()
  }
  close() {
    this.bsModalRef.hide();
  } 
  
  confirm(){
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  cancel(){
    this.event.emit(this._changed);
    this.bsModalRef.hide();
  }

  changeRoute(evt:any){
    this.route_list = evt
  }
  FilterRoute(evt:any){
    if(evt.length>0){
      this.setRouteList = this.route_list.filter(res => res.includes(evt))
    }else if(evt.length==0){
      this.setRouteList = this.route_list
    }else{

    }
  }

  async addSensor(direction?:number) {
    this.loading = true
    let req : any = {route : this.route_id.padStart(4, '0')}
    // if(this.km){
    //   req.start_km = Number(this.km) - 1
    //   req.end_km = Number(this.km) + 1
    // }
    // if(this.m){
    //   req.start_m = Number(this.m) - 1
    //   req.end_m = Number(this.m) + 1
    // }
    // if(direction) req.direction = direction
    console.log(this.route_id)
    let res : any = await this.dvS.getSmc()
    this.loading = false
    for (const i of res) {
      if(direction==1&&this.values.sensor_in&&this.values.sensor_in.length){
        let chk = _.find(this.values.sensor_in, { 'device_id': i.device_id, })
        if(chk) i.checked = true
      }
      if(direction==2&&this.values.sensor_out&&this.values.sensor_out.length){
        let chk = _.find(this.values.sensor_out, { 'device_id': i.device_id, })
        if(chk) i.checked = true
      }
    }
    const initialState = {
      values: res,
      reversible_id: this.values.id,
      route : this.route_id,
      direction: direction
    };
    console.log(initialState)
    this.bsModal_addsensor = this.modalService.show(AddsensorModalComponent, { initialState, id: 2,ignoreBackdropClick : true,class:'w-80' });
    this.bsModal_addsensor.content.event.subscribe(data => {
      if(data) {
        this._changed = true
        this.getData()
      }
      // if (data) this.getList()

    });
    // this.bsModal_addsensor = this.modalService.show(PeopleModalComponent, { initialState,ignoreBackdropClick: true,backdrop : true, class: 'gray-BG modal-BG second w-80'});
  }
  async save(){
    this.disabledSave_BT = false
    this.ResMessage = null
    this.error_text = null
    let data : any = {}
    if(this.reversible_name) data.reversible_name = this.reversible_name
    if(this.description) data.description = this.description
    if(Number(this.route_id)) data.route_id = Number(this.route_id)
    if(this.criteria) data.criteria = this.criteria
    if(this.km) data.km = this.km
    if(this.m) data.m = this.m
    if(this.samp_day) data.sampling_day = this.samp_day
    if(this.samp_night) data.sampling_night = this.samp_night
    if(this.Idel_day) data.idle_day = this.Idel_day
    if(this.Idel_night) data.idle_night = this.Idel_night
    if(this.st_time) data.st_time = mm(this.st_time).format("HHmm")
    if(this.en_time) data.en_time = mm(this.en_time).format("HHmm")
    if(this.direction_list) data.direction_list = this.direction_list
    let checked = true
    for (const i of this.direction_list) {
      for (const key in i) {
          if(i[key]) i[key] = Number(i[key])
          const element = i[key];
          if(!element && (element!==0)) checked = false
      }
      
    }
    if(!checked){
      // this.error_text = 'กรุณากรอกในช่องขาเข้า ขาออก ให้ครบค่ะ';
      // this.ResMessage = 'กรุณากรอกในช่องขาเข้า ขาออก ให้ครบค่ะ';
      // setTimeout(() => {
      //   // this.swalError.show();
      // }, 500);
      return
    }
    if(this.cal_period) data.cal_period = Number(this.cal_period)
    if(!_.isEmpty(this.values)){
      data.rv_id = this.values.id
      data.latitude = this.values.latitude
      data.longitude = this.values.longitude
    }
    const req1 = _.cloneDeep(data);
    let txt_req = _.cloneDeep(req1);

    console.log(JSON.stringify(data))
    let res : any = (data.rv_id)? await this.rvS.updateReversible(data):await this.rvS.addReversible(data)
    
    this.disabledSave_BT = false
    if(res){
      this._changed = true
      this.func.alertpopup("OK")
      // this.swalSuccess.show();

      let action = ActionEnum.Add;
      let request_data = { url: `${this.apiService.api.reversible}`, request: req1 };
      if (req1.rv_id) {
        action = ActionEnum.Edit;
        delete txt_req.rv_id
        request_data = { url: `${this.apiService.api.reversible}/${req1.rv_id}`, request: txt_req };
      }
      this.commonService.activityLog(action, `Assets Management ➡ Setting ➡ Reversible`, JSON.stringify(request_data)).subscribe(res => { }, error => { console.log(error); });
    }
  }
  async rmlist(data){
    console.log(data)
    let result = await this.func.alertpopup('<h4>คุณต้องการลบใช่หรือไม่</h4>', `<i class="icon-info mr-2"></i>ลบผู้ใช้งาน`, 1);
    if (result) {
      let res: any = await this.rvS.rmRvSs(data.id)
      if (res) {
        let request_data = { url: `${this.apiService.api.reversible}/sensor/${data.id}}` };
        this.commonService.activityLog(ActionEnum.Delete, `Assets Management ➡ Setting ➡ Reversible ➡ Sensor`, JSON.stringify(request_data)).subscribe(res => { }, error => { console.log(error); });
        this.getData()
      }
    }
  }
  // async deleteList(){
  //   let res : any = await this.rvS.rmRvSs({
  //     rv_id:this.values.id,
  //     ss_id:this.rm_data.id
  //   })
  //   if(res){
  //     this._changed = true
  //     this.getData()
  //     // this.ResMessage = 'OK';
  //     // this.swalSuccess.show();
  //   }

  // }
  checkDegree(evt){
    let min : number = Number(evt.target.min)
    // let max : number = Number(evt.target.max)
    let val : number = Number(evt.target.value)
    if(val<min) evt.target.value = evt.target.min
    // if(val>max) evt.target.value = evt.target.max
    return evt.target.value
  }
  
  checkminmaxDegree(evt){
    let min : number = Number(evt.target.min)
    let max : number = Number(evt.target.max)
    let val : number = Number(evt.target.value)
    if(val<min) evt.target.value = evt.target.min
    if(val>max) evt.target.value = evt.target.max
    return evt.target.value
  }
  // ==================================
  
  // async getConfigReversibleLane(data?:any) {
  //   return new Promise((reslove,reject)=>{
  //   //   this.rvS.getConfigReversibleLane(data).subscribe(
  //   //     (res:any) => {
  //   //       if (res.code == 1) {
  //   //         reslove(res.data[0])
  //   //       }
  //   //     },
  //   //     err => {
  //   //       console.log(JSON.stringify(err.statusText));
  //         reslove(false)
  //   //     });
  //   });
  // }
  // async rmConfigReversibleLaneSensor(data?:any) {
  //   return new Promise((reslove,reject)=>{
  //         reslove(false)
  //   });
  // }
  // async updateConfigReversibleLane(data?:any) {
  //   return new Promise((reslove,reject)=>{
  //         reslove(false)
  //   });
  // }
  // async getDevice(data?:any) {
  //   return new Promise((reslove,reject)=>{
  //         reslove(false)
  //   });
  // }

}
