import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShapefileService {

  constructor(private http: HttpClient) { }

  getShapefileAll(): Observable<any> {
    let url = 'assets/data/shapefile/shapefile_all.txt';
    return this.http.get(url);
  }
  getShapefileTHA(): Observable<any> {
    let url = 'assets/data/shapefile/shapefile_THA.txt';
    return this.http.get(url);
  }
  getShapefileN(): Observable<any> {
    let url = 'assets/data/shapefile/shapefile_n_black.txt';
    return this.http.get(url);
  }
  getShapefileNE(): Observable<any> {
    let url = 'assets/data/shapefile/shapefile_ne_black.txt';
    return this.http.get(url);
  }
  getShapefileE(): Observable<any> {
    let url = 'assets/data/shapefile/shapefile_e_black.txt';
    return this.http.get(url);
  }
  getShapefileS(): Observable<any> {
    let url = 'assets/data/shapefile/shapefile_s_black.txt';
    return this.http.get(url);
  }

  getShapefile(id): Observable<any> {
    let url:any;
    if(id == 1){
      url = 'assets/data/shapefile/shapefile_all.txt';
    }else if(id == 2){
      url = 'assets/data/shapefile/shapefile_n_black.txt';
    }else if(id == 3){
      url = 'assets/data/shapefile/shapefile_ne_black.txt';
    }else if(id == 4){
      url = 'assets/data/shapefile/shapefile_e_black.txt';
    }else if(id == 5){
      url = 'assets/data/shapefile/shapefile_s_black.txt';
    }
    return this.http.get(url);
  }
}
